import { useQuery, UseQueryOptions } from "react-query";
import { AxiosError } from "axios";
import { QUERY_KEYS } from "query";

import { IZone } from "store/redux/zone";
import { request } from "api/common";
import paths from "api/paths";

const useClusterList = (options?: UseQueryOptions<IZone[], AxiosError>) => {
  return useQuery<IZone[], AxiosError>(
    QUERY_KEYS.clusterList(),
    async () => {
      const { data } = await request.get(paths.zones.index());
      return data;
    },
    {
      cacheTime: 60 * 1000,
      ...options,
    }
  );
};

export default useClusterList;
