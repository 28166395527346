import React, { useState } from "react";
import { Button, Flex } from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";
import { Form, Input, message, Modal, Select, Tag } from "antd";
import { useForm } from "antd/lib/form/Form";

import {
  IMemberSearchResult,
  useCurrentUser,
  UserGroup,
  useTenancyMembersSearch,
  useUserGroup,
} from "hooks/user";
import { request } from "api/common";

import { Member } from "./common";

function isEmailValid(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}

export const CreateUserGroupModal: React.FC<{
  showModal: boolean;
  onClose(): void;
}> = ({ showModal, onClose }) => {
  const [groupId, setGroupId] = useState<number | undefined>();

  return (
    <Modal
      title={groupId ? "Add Members" : "Create New User Group"}
      centered
      visible={showModal}
      onCancel={() => {
        onClose();
        setGroupId(undefined);
      }}
      footer={null}
      closable
      width={1000}
    >
      {!groupId ? (
        <CreateUserGroup
          onFinish={(userGroup) => {
            setGroupId(userGroup.id);
          }}
        />
      ) : (
        <AddUserGroupMember groupId={groupId} />
      )}
    </Modal>
  );
};

export const EditUserGroupModal: React.FC<{
  showModal: boolean;
  onClose(): void;
  settingGroupId?: number;
}> = ({ showModal, onClose, settingGroupId }) => {
  if (!settingGroupId) {
    return null;
  }

  return (
    <Modal
      title="Add Members"
      centered
      visible={showModal}
      onCancel={() => {
        onClose();
      }}
      footer={null}
      closable
      width={1000}
    >
      <AddUserGroupMember groupId={settingGroupId} />
    </Modal>
  );
};

const CreateUserGroup: React.FC<{ onFinish(u: UserGroup): void }> = ({
  onFinish,
}) => {
  const [form] = useForm<{ name: string; description: string }>();
  return (
    <x.div>
      <Form layout="vertical" form={form}>
        <Form.Item
          name="name"
          label="User group name"
          rules={[{ required: true, message: "Please put user group name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="User group description"
          rules={[
            { required: true, message: "Please put user group description" },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
      <Flex justifyContent="flex-end" mt="20px">
        <Button
          variant="primary"
          onClick={async () => {
            try {
              await form.validateFields();
              const v = form.getFieldsValue();

              const { data } = await request.post<UserGroup>(
                "/users/groups",
                v
              );

              onFinish(data);
              form.resetFields();
            } catch (e) {}
          }}
        >
          Create Group
        </Button>
      </Flex>
    </x.div>
  );
};

const AddUserGroupMember: React.FC<{ groupId: number }> = ({ groupId }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchType, setSearchType] = useState<"username" | "email">(
    "username"
  );
  const { data: searchResult } = useTenancyMembersSearch(
    searchQuery ? searchQuery : null,
    searchType
  );
  const { data: userGroup, refetch } = useUserGroup(groupId);

  return (
    <Flex>
      <x.div w="60%">
        <x.div pb="20px">You can search for users by username or email.</x.div>
        <Input.Group compact>
          <Select
            onChange={(value) => {
              setSearchType(value);
            }}
            value={searchType}
          >
            <Select.Option value="username">username</Select.Option>
            <Select.Option value="email">email</Select.Option>
          </Select>
          {searchType === "username" ? (
            <Input
              style={{ width: "70%" }}
              placeholder="Search by username"
              onChange={(p) => {
                setSearchQuery(p.target.value);
              }}
              value={searchQuery}
              allowClear
            />
          ) : (
            <Input.Search
              style={{ width: "70%" }}
              placeholder="Search by email (exact)"
              onSearch={(query) => {
                setSearchQuery(query);
              }}
              allowClear
            />
          )}
        </Input.Group>
        <x.div py="20px">
          <MemberSearchResult
            searchResult={searchResult}
            searchQuery={searchQuery}
            groupId={groupId}
            onUpdate={() => {
              refetch();
            }}
          />
        </x.div>
      </x.div>
      <x.div w="40%">
        {userGroup?.members?.map((member, i) => {
          return (
            <Member
              key={i}
              member={member}
              groupId={groupId}
              onUpdate={() => {
                refetch();
              }}
            />
          );
        })}
      </x.div>
    </Flex>
  );
};

const MemberSearchResult: React.FC<{
  searchResult: IMemberSearchResult | undefined;
  searchQuery: string;
  groupId: number;
  onUpdate(): void;
}> = ({ searchResult, groupId, onUpdate, searchQuery }) => {
  const members = searchResult?.content;

  if (!members) {
    return null;
  }

  if (members.length === 0 && isEmailValid(searchQuery)) {
    return (
      <x.div>
        <Flex py="5px">
          <x.div w="50%">{searchQuery}</x.div>
          <x.div justifySelf="flex-end" ml="auto">
            <MemberSearchInviteButton
              groupId={groupId}
              targetMail={searchQuery}
              onUpdate={onUpdate}
            />
          </x.div>
        </Flex>
      </x.div>
    );
  }

  return (
    <x.div>
      {members.map(({ user }, i) => (
        <Flex py="5px" key={i}>
          <x.div w="20%">{user.name}</x.div>
          <x.div w="50%">{user.email}</x.div>
          <x.div justifySelf="flex-end" ml="auto">
            <MemberSearchAddButton
              groupId={groupId}
              userId={user.id}
              onUpdate={onUpdate}
            />
          </x.div>
        </Flex>
      ))}
    </x.div>
  );
};

const MemberSearchAddButton: React.FC<{
  groupId: number;
  userId: number;
  onUpdate(): void;
}> = ({ groupId, userId, onUpdate }) => {
  const [added, setAdded] = useState(false);

  if (added) {
    return <>Added</>;
  }

  return (
    <Tag
      onClick={async () => {
        await request.post(`/users/groups/${groupId}/members`, [
          {
            userId,
          },
        ]);
        setAdded(true);
        onUpdate();
        message.success("Added user to the group");
      }}
      style={{ cursor: "pointer" }}
    >
      Add User
    </Tag>
  );
};

const MemberSearchInviteButton: React.FC<{
  groupId: number;
  onUpdate(): void;
  targetMail: string;
}> = ({ groupId, targetMail, onUpdate }) => {
  const [added, setAdded] = useState(false);
  const { data: me } = useCurrentUser();

  if (added) {
    return <>Added</>;
  }

  return (
    <Tag
      onClick={async () => {
        if (!me) {
          return;
        }

        await request.post(`/users/groups/${groupId}/invites`, {
          inviterEmail: me.email,
          inviterName: me.name,
          joinSiteUrl: `${window.location.origin}/join`,
          targetUsers: [
            {
              targetMail,
            },
          ],
        });
        setAdded(true);
        onUpdate();
        message.success("Invite sent");
      }}
      style={{ cursor: "pointer" }}
    >
      Invite User
    </Tag>
  );
};
