import { useParams } from "react-router-dom";
import { Button, EmptyBox, Section, Table } from "@nexcloud/nc-ui";

import {
  AlertNoCell,
  AlertRecipientCell,
  AlertSeverityCell,
  AlertSourcePortCell,
  SummaryCell,
  TimeCell,
} from "components/common/TableCell";

import { useIncidentAlert } from "hooks/incident";

const alertListColumn = [
  {
    Header: "No. / Incident",
    accessor: "id",
    Cell: AlertNoCell,
    id: "IncidentNumber",
  },
  {
    Header: "Time",
    accessor: "time",
    Cell: TimeCell,
  },
  {
    Header: "Summary",
    accessor: "summary",
    Cell: SummaryCell,
  },
  {
    Header: "Severity",
    accessor: "severity",
    Cell: AlertSeverityCell,
  },
  {
    Header: "Source/Port",
    accessor: "source",
    Cell: AlertSourcePortCell,
  },
  {
    Header: "From",
    accessor: "from",
    disableSortBy: true,
  },
  {
    Header: "Recipient",
    accessor: "recipients",
    Cell: AlertRecipientCell,
    disableSortBy: true,
  },
];

const IncAlertsTab: React.FC<{ setIsEditMode: any }> = ({ setIsEditMode }) => {
  const handleMode = () => {
    setIsEditMode(true);
  };

  const params = useParams<{ id: string }>();
  const incidentId = Number(params.id);
  const { data: incidentAlert } = useIncidentAlert(incidentId);
  if (!incidentAlert) {
    return null;
  }

  return (
    <>
      <Section>
        <Button layoutVariant="outlined" variant="primary" onClick={handleMode}>
          EDIT ALERTS
        </Button>
      </Section>
      <Section>
        {incidentAlert.length === 0 ? (
          <EmptyBox />
        ) : (
          <Table
            columns={alertListColumn}
            data={incidentAlert ? incidentAlert : []}
            getRowProps={(row: any) => {
              return {
                ...row,
              };
            }}
          />
        )}
      </Section>
    </>
  );
};

export default IncAlertsTab;
