import { ApartmentOutlined } from "@ant-design/icons";
import { List, Map } from "immutable";
import { createReducer } from "typesafe-actions";

import { RESET_ZONE, SET_DATA } from "./actions";
import { MonitoringData, PlatformData, ZoneAction, ZoneState } from "./types";

import Aws from "assets/aws_logo.png";
import Baremetal from "assets/baremetal_logo.png";
import Grafana from "assets/grafana.png";
import Kubernetes from "assets/kubernetes_logo.png";
import MetricArk from "assets/metricArk.png";
import Prometheus from "assets/prometheus.png";
import PromLens from "assets/promlens.png";
import Provbee from "assets/provbee.png";

export const platformList: List<PlatformData> = List([
  Map({
    id: "kubernetes",
    title: "Kubernetes",
    icon: Kubernetes,
    active: true,
  }),
  Map({
    id: "baremetal",
    title: "Baremetal/VM",
    icon: Baremetal,
    active: true,
  }),
  Map({
    id: "AWS",
    title: "AWS",
    icon: Aws,
    active: false,
  }),
]);

export const monitoringList: List<MonitoringData> = List([
  Map({
    id: "prometheus",
    title: "Prometheus",
    icon: Prometheus,
  }),
  Map({
    id: "promlens",
    title: "PromLens",
    icon: PromLens,
  }),
  Map({
    id: "metricArk",
    title: "MetricARK",
    icon: MetricArk,
  }),
  Map({
    id: "provbee",
    title: "Provbee",
    icon: Provbee,
  }),
  Map({
    id: "grafana",
    title: "Grafana",
    icon: Grafana,
  }),
  Map({
    id: "pushGateway",
    title: "Push Gateway",
    icon: ApartmentOutlined,
  }),
  Map({
    id: "alertManager",
    title: "Alertmanager",
    icon: ApartmentOutlined,
  }),
  Map({
    id: "metricArk_api",
    title: "MetricARK_API",
    icon: MetricArk,
  }),
]);

const initialState: ZoneState = Map({
  // zoneList: List([]),
  detailZone: null,
  error: false,
  alertConfig: null,
  alertConfigStatus: null,
  prometheusConfig: null,
  p8sConfigStatus: null,
  p8sServices: List([]),
  clusterHealth: null,
  // overallStatus: null,
  // taskData: List([]),
});

const reducer = createReducer<ZoneState, ZoneAction>(initialState, {
  [SET_DATA]: (state, action) => {
    const { key, value } = action.payload;
    return state.set(key, value);
  },
  [RESET_ZONE]: (state, ation) => {
    return state
      .set("error", false)
      .set("detailZone", null)
      .set("prometheusConfig", null)
      .set("p8sServices", null)
      .set("clusterHealth", null);
  },
});
export default reducer;
