import type { QueryFunction } from "react-query";

import { IInstallParams } from "store/redux/create";
import { IEditP8SConfig, IP8SConfigPayload } from "store/redux/zone";

import { authCheck } from "./common";

interface ICreateZone {
  name: string;
  platform: string;
  description: string;
  installParams: IInstallParams;
}

interface IProvisionP8S {
  zoneId: number;
  parameters: string;
}

export const createZone = (data: ICreateZone) =>
  authCheck({
    url: "/lari/clusters",
    method: "POST",
    data,
  });

export const getZone = (zoneId: string) => {
  return authCheck({
    url: `/lari/clusters/${zoneId}`,
    method: "GET",
  });
};

export const getAllZone = () =>
  authCheck({
    url: "/lari/clusters",
    method: "GET",
  });

export const getAgentInstall = (clusterId: number) => {
  return authCheck({
    url: "/lari/clusters/commands/agent-install",
    method: "GET",
    params: { clusterId },
  });
};

// export const getAgentList = (zoneId: string) => {
//   return authCheck({
//     url: `/lari/clusters/${zoneId}/agents`,
//     method: "GET",
//   });
// };

// export const getPrimary = (zoneId: string) => {
//   return authCheck({
//     url: `/lari/clusters/${zoneId}/agents/primary`,
//     method: "GET",
//   });
// };

export const getP8SConfig = (
  zoneId: number,
  statusOnly?: boolean,
  yamlName?: string
) => {
  const params: { [k: string]: any } = {};
  if (statusOnly) {
    params.statusOnly = statusOnly;
  }
  if (yamlName) {
    params.yamlName = yamlName;
  }

  return authCheck({
    url: `/lari/clusters/${zoneId}/p8s/configuration`,
    params,
    method: "GET",
  });
};

export const getP8SServices = (zoneId: number) => {
  return authCheck({
    url: `/lari/clusters/${zoneId}/p8s/services`,
    method: "GET",
  });
};

export const getP8sVolumes: QueryFunction<
  {
    timescaleTotalSize: null | string;
    timescaleUsedPercent: null | string;
    timescaleUsedSize: null | string;
    walTotalSize: null | string;
    walUsedPercent: null | string;
    walUsedSize: null | string;
  },
  ["p8sVolumes", string?]
> = ({ queryKey }) => {
  const [, zoneId] = queryKey;
  if (!zoneId) {
    return Promise.resolve();
  }
  return authCheck({
    url: `/lari/clusters/${zoneId}/p8s/volumes`,
    method: "GET",
  });
};

export const getAlertConfig = (
  zoneId: number,
  statusOnly?: boolean,
  yamlName?: string
) => {
  return authCheck({
    url: `/lari/clusters/${zoneId}/alertmanager/configuration`,
    params: { statusOnly, yamlName },
    method: "GET",
  });
};

export const putP8SConfig = ({
  zoneId,
  contents,
  yamlName,
}: IEditP8SConfig) => {
  return authCheck({
    url: `/lari/clusters/${zoneId}/p8s/configuration`,
    method: "PUT",
    data: contents,
    params: {
      yamlName,
    },
    headers: {
      "Content-Type": "text/plain",
    },
  });
};

export const putAlertConfig = ({
  zoneId,
  contents,
  yamlName,
}: IEditP8SConfig) => {
  return authCheck({
    url: `/lari/clusters/${zoneId}/alertmanager/configuration`,
    method: "PUT",
    data: contents,
    params: {
      yamlName,
    },
    headers: {
      "Content-Type": "text/plain",
    },
  });
};

export const postP8SConfig = ({ zoneId, yamlName }: IP8SConfigPayload) => {
  return authCheck({
    url: `/lari/clusters/${zoneId}/p8s/configuration`,
    method: "POST",
    params: {
      yamlName,
    },
  });
};

export const postAlertConfig = ({ zoneId, yamlName }: IP8SConfigPayload) => {
  return authCheck({
    url: `/lari/clusters/${zoneId}/alertmanager/configuration`,
    method: "POST",
    params: {
      yamlName,
    },
  });
};

export const getClusterHealth = (zoneId: number) => {
  return authCheck({
    url: `/lari/clusters/${zoneId}/cluster/health`,
    method: "GET",
  });
};

export const validateClusterName = (clusterName: string) => {
  return authCheck({
    url: `/lari/clusters/verification/name?name=${clusterName}`,
    method: "GET",
  });
};

export const provisinP8S = (params: IProvisionP8S) => {
  return authCheck({
    url: `/lari/clusters/${params.zoneId}/p8s`,
    method: "POST",
    data: params.parameters,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const resetP8SConfig = ({ zoneId, yamlName }: IP8SConfigPayload) => {
  return authCheck({
    url: `/lari/clusters/${zoneId}/p8s/configuration/reset`,
    method: "POST",
    params: {
      yamlName,
    },
  });
};

export const resetAlertConfig = ({ zoneId, yamlName }: IP8SConfigPayload) => {
  return authCheck({
    url: `/lari/clusters/${zoneId}/alertmanager/configuration/reset`,
    method: "POST",
    params: {
      yamlName,
    },
  });
};
