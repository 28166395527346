import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import { x } from "@xstyled/emotion";
import { Form, Input, Switch } from "antd";

import { checkNumber, Field, LockedField } from "components/common/Form";

import Label from "./Label";

const SwitchBox = styled.div`
  display: flex;
  align-items: center;

  span {
    color: #717171;
    font-size: 0.9rem;
    margin-right: 5px;
  }
`;

const PrometheusForm: React.FC<{ showPersistanceOption?: boolean }> = ({
  showPersistanceOption,
}) => {
  const [toggleOpen, setToggleOpen] = useState(false);

  const namespaceField = (
    <>
      <Label text="Namespace" />
      <Form.Item name="p8s_namespace" initialValue="nexclipper">
        <Input disabled={true} autoComplete="off" />
      </Form.Item>
    </>
  );

  if (showPersistanceOption === false) {
    return namespaceField;
  }

  return (
    <>
      {namespaceField}
      <SwitchBox>
        <x.span mr="7px">Persistent Volume</x.span>
        <Switch
          checked={toggleOpen}
          onChange={(value) => {
            setToggleOpen(value);
          }}
        />
      </SwitchBox>
      {toggleOpen && (
        <x.div pt="20px">
          <Field name="p8s_storageclass" label="Storage Class Name" padded />
          <LockedField
            initialValue={10}
            name="p8s_volumesize"
            label="TSDB Volume"
            required
            requiredMessage="Please input volume size"
            rules={[checkNumber]}
          />
          <LockedField
            initialValue={10}
            name="wal_volumesize"
            label="WAL Volume"
            required
            requiredMessage="Please input volume size"
            rules={[checkNumber]}
          />
          <LockedField
            initialValue={10}
            name="svr_volumesize"
            label="Prometheus Volume"
            required
            requiredMessage="Please input volume size"
            rules={[checkNumber]}
          />
          <LockedField
            initialValue={10}
            name="alm_volumesize"
            label="Alertmanager Volume"
            required
            requiredMessage="Please input volume size"
            rules={[checkNumber]}
          />
        </x.div>
      )}
    </>
  );
};

export default PrometheusForm;
