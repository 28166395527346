import { useQuery, UseQueryOptions } from "react-query";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import qs from "query-string";

import { Filter } from "components/DynamicFilter/types";

import { request } from "api/common";

export interface IAlertItem {
  alertName: string;
  from: "LARI" | "PROMETHEUS";
  id: number;
  incidentId: number[];
  lastUpdatedAt: string;
  recipients: {
    id: number;
    name: string;
  }[];
  severity: "Information" | "Warning" | "Critical";
  source: string;
  startAt: string;
  status: "CLOSED" | "HIDED" | "OPEN" | "REFERENCED";
  summary: string;
  isPin?: boolean;
}

export interface IAlertList {
  content: IAlertItem[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    page: number;
    size: number;
    sort: string[];
  };
  size: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  totalElements: number;
  totalPages: number;
}

export interface IAlertListDetail {
  alertName: string;
  alertRule: string;
  clusterId: string;
  clusterName: string;
  createdAt: string;
  description: string;
  from: "LARI" | "PROMETHEUS";
  id: number;
  incidents: [
    {
      asignee: {
        accessgroups: {
          description: string;
          groupName: string;
          icon: string;
          id: number;
        }[];
        active: boolean;
        email: string;
        id: number;
        lastAccessTime: string;
        name: string;
        photo: string;
      };
      createdAt: string;
      dueDate: string;
      id: number;
      lastUpdatedAt: string;
      priority: "0" | "1" | "2" | "3";
      status: "CLOSED" | "IN_PROGRESS" | "OPEN" | "RESOLVED";
      titile: string;
    }
  ];
  lastUpdatedAt: string;
  node: string;
  note: string;
  numberOfOccurrences: number;
  recipients: {
    id: number;
    name: string;
  }[];
  severity: "Information" | "Warning" | "Critical";
  source: string;
  summary: string;
}

export interface IAlertCount {
  count: number;
  severity: string;
}

const setParamsForAlertFilters = (filters?: Filter[]) => {
  if (!filters) {
    return {
      fromDateTime: dayjs
        .utc()
        .subtract(15, "day")
        .format("YYYY-MM-DDTHH:mm:ssz"),

      toDateTime: dayjs
        .utc()
        .add(1, "day")
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ssz"),
    };
  }

  const ret: any = {
    fieldType: [],
    searchKey: [],
    searchValue: [],
  };

  filters.forEach((filter) => {
    if (filter.type === "CLUSTER") {
      ret["clusterIds"] = filter.value.id;
    } else if (filter.type === "TIME_RANGE") {
      ret.fromDateTime = dayjs(filter.value.starts)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ssz");
      ret.toDateTime = dayjs(filter.value.until)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ssz");
    } else if (filter.type === "SEVERITY") {
      ret.fieldType.push("LIKE");
      ret.searchKey.push("severity");
      ret.searchValue.push(filter.value);
    } else if (filter.type === "DESCRIPTION") {
      ret.fieldType.push("LIKE");
      ret.searchKey.push("description");
      ret.searchValue.push(filter.value);
    } else if (filter.type === "FROM") {
      ret.fieldType.push("LIKE");
      ret.searchKey.push("from");
      ret.searchValue.push(filter.value);
    } else if (filter.type === "RECIPIENT") {
      ret.fieldType.push("LIKE");
      ret.searchKey.push("recipient");
      ret.searchValue.push([{ ...filter.value }]);
    } else if (filter.type === "SUMMARY") {
      ret.fieldType.push("LIKE");
      ret.searchKey.push("from");
      ret.searchValue.push(filter.value);
    }
  });

  if (!ret.fromDateTime && !ret.toDateTime) {
    ret.fromDateTime = dayjs
      .utc()
      .subtract(15, "day")
      .format("YYYY-MM-DDTHH:mm:ssz");

    ret.toDateTime = dayjs
      .utc()
      .add(1, "day")
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ssz");
  }

  return ret;
};

export const useAlertList = (
  options?: UseQueryOptions<IAlertList, AxiosError>,
  filters?: Filter[]
) => {
  return useQuery<IAlertList, AxiosError>(
    ["alertList", filters],
    async () => {
      const params = qs.stringify(setParamsForAlertFilters(filters));
      const { data } = await request.get(`/lari/alerts?${params}`);
      return data;
    },
    {
      cacheTime: 60 * 1000,
      ...options,
    }
  );
};

export const useAlertListDetail = (
  id: number,
  options?: UseQueryOptions<IAlertListDetail, AxiosError>
) => {
  return useQuery<IAlertListDetail, AxiosError>(
    ["alertDetail", id],
    async () => {
      const { data } = await request.get(`/lari/alerts/${id}`);
      return data;
    },
    {
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};

export const useAlertCount = (
  options?: UseQueryOptions<IAlertCount[], AxiosError>
) => {
  return useQuery<IAlertCount[], AxiosError>(
    "alertCount",
    async () => {
      const { data } = await request.get("/lari/alerts/counts/severity");
      return data;
    },
    {
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};

export interface IAlertPinned {
  alertName: string;
  from: "LARI" | "PROMETHEUS";
  id: number;
  incidentId: number[];
  lastUpdatedAt: string;
  recipients: {
    id: number;
    name: string;
  }[];
  severity: "Information" | "Warning" | "Critical";
  source: string;
  startAt: string;
  status: "CLOSED" | "HIDED" | "OPEN" | "REFERENCED";
  summary: string;
}

export const useAlertPinnedList = (
  options?: UseQueryOptions<IAlertItem[], AxiosError>
) => {
  return useQuery<IAlertItem[], AxiosError>(
    "pinnedAlertList",
    async () => {
      const { data } = await request.get("/lari/alerts/pinned");

      const pinnedData = data?.filter(
        (alert: IAlertItem) => (alert.isPin = true)
      );

      return pinnedData;
    },
    {
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};
