import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import styled from "@emotion/styled/macro";
import { fromJS } from "immutable";

import { IWdigetCommonProps } from "./types";

interface ILineProps extends IWdigetCommonProps {}

const Widget = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`;

const Value = styled.div`
  flex: 1;
  height: 10px;
  position: relative;
`;

const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        type: "time",
        ticks: {
          autoSkip: true,
          maxTicksLimit: 5,
          maxRotation: 0,
          minRotation: 0,
        },
        time: {
          displayFormats: {
            millisecond: "h:m",
            second: "h:m",
            minute: "h:m",
            hour: "h:m",
            day: "h:m",
            week: "h:m",
            month: "h:m",
            quarter: "h:m",
            year: "h:m",
          },
        },
      },
    ],
  },
};

const colorset: any = {
  "200": "#f9d423",
  "201": "#fc913a",
  "404": "#ff4e50",
  "409": "#ede574",
  "500": "#34314c",
  "0": "#47b8e0",
};

function LineComponent({ title, data, render }: ILineProps) {
  const [chartData, setChartData] = useState<any>({ labels: [], datasets: [] });

  useEffect(() => {
    if (!data) return;
    const parseData = typeof data === "string" ? JSON.parse(data) : data;
    const iParsed = fromJS(parseData) as any; // FIXME: remove immutable
    const formatedData = iParsed.filter(
      (d: any) => Object.keys(d.toJS()).length > 0
    );

    if (formatedData.size <= 0) return;

    const labels = formatedData
      .map((d: any) => Object.keys(d.toJS())[0])
      .toJS();

    const codeList = formatedData
      .getIn([0, labels[0]])
      .map((d: any) => d.getIn(["metric", "code"]))
      .toJS();

    const datasets = codeList.map((c: any, i: number) => {
      const _data = formatedData.map((d: any, j: number) =>
        d.getIn([labels[j], i, "value", 1])
      );
      return {
        label: c,
        fill: false,
        data: _data.toJS(),
        backgroundColor: colorset[c],
        borderColor: colorset[c],
      };
    });
    setChartData({ labels: labels.map((l: any) => +l), datasets });
  }, [data]);

  return (
    <Widget>
      <Title>{title}</Title>
      <Value>
        <Line options={options} data={chartData} />
      </Value>
    </Widget>
  );
}

export default LineComponent;
