import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  ClusterCell,
  Column,
  FormInput,
  IconDisplay,
  LinkTextCell,
  Loading,
  Page,
  Section,
  Table,
  TitleSection,
} from "@nexcloud/nc-ui";
import styled, { x } from "@xstyled/emotion";

import { AccessGroupMemberCell } from "components/common/TableCell";

import { useDetailAccessGroups } from "hooks/cluster/service";
import { request } from "api/common";

import Onboarding from "./Onboarding";

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  color: #b2b3bd;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

const Error = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: #ff754c;
  padding-left: 10px;
  font-weight: 500;
`;

const columns = [
  {
    Header: "ACCESS GROUP NAME",
    accessor: "name",
    Cell: (props: any) => {
      const iconValue = props.row.original[props.column.iconAccessor];
      return (
        <>
          <Column columnsGap="5px">
            <IconDisplay
              value={props.value}
              selected={iconValue}
              type="accessGroup"
            />
            <LinkTextCell {...props} base="/access-groups" />
          </Column>
        </>
      );
    },
    idAccessor: "id",
    iconAccessor: "icon",
  },
  {
    Header: "CLUSTERS",
    accessor: "clusters",
    Cell: ClusterCell,
  },
  {
    Header: "MEMBERS",
    accessor: "members",
    Cell: AccessGroupMemberCell,
  },
];

interface ICreateAccessGroup {
  groupName: string;
  description: string;
  icon: string;
}

const AccessGroupsList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: group, refetch } = useDetailAccessGroups();
  const content = group?.content;

  const showCreateModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ICreateAccessGroup>({
    mode: "onChange",
  });

  const onSubmit = async (data: ICreateAccessGroup) => {
    try {
      await request.post(`/lari/accessgroups`, {
        groupName: data.groupName,
        description: data.description,
        icon: data.icon,
      });
      await reset({
        groupName: "",
        description: "",
        icon: "",
      });
      await refetch();
      onClose();
    } catch (e) {
      alert("There was an error. Please try again later.");
      onClose();
    }
  };

  if (!group) {
    return <Loading />;
  }

  return (
    <Page>
      {content && content?.length > 0 ? (
        <Section>
          <TitleSection>
            <x.p fontSize="24px">ACCESS GROUPS</x.p>
            <Button
              variant="primary"
              onClick={showCreateModal}
              to="/access-groups/add"
            >
              Create New
            </Button>
          </TitleSection>
          {isModalOpen && (
            <x.div p="20px" border="1px solid #000">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                  <Label htmlFor="groupName">Group Name</Label>
                  <FormInput
                    id="groupName"
                    type="text"
                    {...register("groupName", {
                      minLength: 1,
                    })}
                  />
                  {errors.groupName && <Error>Please enter group name.</Error>}
                </div>
                <div className="field">
                  <Label htmlFor="description">Description</Label>
                  <FormInput
                    id="description"
                    type="text"
                    {...register("description")}
                  />
                </div>
                <div className="field">
                  <Label htmlFor="icon">Icon</Label>
                  <FormInput id="icon" type="text" {...register("icon")} />
                </div>
                <Button
                  layoutVariant="outlined"
                  variant="primary"
                  type="submit"
                >
                  Create
                </Button>
              </form>
            </x.div>
          )}
          <Table columns={columns} data={content ? content : []} />
        </Section>
      ) : (
        <Onboarding />
      )}
    </Page>
  );
};

export default AccessGroupsList;
