import { useCallback } from "react";
import { useDispatch } from "react-redux";

import * as ZoneActions from "store/redux/zone";
import {
  IEditP8SConfig,
  IGetConfig,
  IGetZone,
  IResetConfig,
  ISetData,
} from "store/redux/zone";

export default function useZoneActions() {
  const dispatch = useDispatch();

  const onSetData = useCallback(
    (param: ISetData) => dispatch(ZoneActions.setData(param)),
    [dispatch]
  );

  const onGetZones = useCallback(
    () => dispatch(ZoneActions.getZones()),
    [dispatch]
  );

  const onGetZone = useCallback(
    (param: IGetZone) => dispatch(ZoneActions.getZone(param)),
    [dispatch]
  );

  const onResetZone = useCallback(
    () => dispatch(ZoneActions.resetZone()),
    [dispatch]
  );

  const onGetP8SConfig = useCallback(
    (param: IGetConfig) => dispatch(ZoneActions.getP8SConfig(param)),
    [dispatch]
  );

  const onGetP8SServices = useCallback(
    (param: number) => dispatch(ZoneActions.getP8SServices(param)),
    [dispatch]
  );

  const onEditP8SConfig = useCallback(
    (param: IEditP8SConfig) => dispatch(ZoneActions.editP8SConfig(param)),
    [dispatch]
  );

  const onGetAlertConfig = useCallback(
    (param: IGetConfig) => dispatch(ZoneActions.getAlertConfig(param)),
    [dispatch]
  );

  const onEditAlertConfig = useCallback(
    (param: IEditP8SConfig) => dispatch(ZoneActions.editAlertConfig(param)),
    [dispatch]
  );

  const onApplyP8SConfig = useCallback(
    (zoneId: number, yamlName: string) =>
      dispatch(ZoneActions.applyP8SConfig({ zoneId, yamlName })),
    [dispatch]
  );

  const onApplyAlertConfig = useCallback(
    (zoneId: number, yamlName: string) =>
      dispatch(ZoneActions.applyAlertConfig({ zoneId, yamlName })),
    [dispatch]
  );

  const onResetConfig = useCallback(
    (param: IResetConfig) => dispatch(ZoneActions.resetConfig(param)),
    [dispatch]
  );

  return {
    onSetData,
    onResetZone,
    onGetZone,
    onGetZones,
    onGetP8SServices,
    onGetP8SConfig,
    onEditP8SConfig,
    onGetAlertConfig,
    onEditAlertConfig,
    onApplyP8SConfig,
    onApplyAlertConfig,
    onResetConfig,
  };
}
