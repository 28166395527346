import { ButtonHTMLAttributes } from "react";
import {
  AiOutlineFolderOpen,
  AiOutlineLoading,
  AiOutlinePlus,
  AiOutlineRise,
} from "react-icons/ai";
import { FaRegSave } from "react-icons/fa";
import { FiPlusSquare, FiUserPlus } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { ReactComponent as GoogleLogo } from "./assets/google.svg";

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  layoutVariant?: "contained" | "outlined";
  variant?: "primary" | "secondary" | "tertiary" | "danger" | "default";
  to?: String;
  disabled?: true | false;
  loading?: true | false;
  icon?:
    | "file"
    | "plus"
    | "plus-no-border"
    | "google"
    | "invite"
    | "save"
    | "escalate"; // FIXME : 별도의 컴포넌트로 분리할 것
  iconRight?: true | false;
  expand?: true | false;
  onClick?: any;
  htmlType?: "submit";
  size?: "tiny" | "small" | "medium";
}

const handleColorType = (variant: string): string => {
  switch (variant) {
    case "primary":
      return "#1F29CA";
    case "secondary":
      return "#6FB62C";
    case "tertiary":
      return "#00959F";
    case "danger":
      return "#ff754c";
    case "default":
      return "#1B1D21";
    default:
      return "none";
  }
};

const Button: React.FC<IButton> = ({
  children,
  onClick,
  htmlType,
  ...props
}) => {
  const {
    layoutVariant,
    variant,
    disabled,
    loading,
    iconRight,
    expand,
    size,
    icon,
    to = "",
  } = props;

  const history = useHistory();

  const onClickHandler = (
    to: any,
    onClick: Function,
    e: React.MouseEvent<HTMLElement>
  ) => {
    if (to) {
      history.replace(to);
    }

    if (typeof onClick === "function") {
      onClick(e);
    }
  };

  return (
    <ButtonComponent
      layoutVariant={layoutVariant}
      variant={variant}
      disabled={disabled || loading}
      iconRight={iconRight}
      expand={expand}
      onClick={(e: React.MouseEvent<HTMLElement>) =>
        onClickHandler(to, onClick, e)
      }
      htmlType={htmlType}
      size={size ? size : "small"}
      {...props}
    >
      {icon === "plus" && !loading && <FiPlusSquare className="icon" />}
      {icon === "plus-no-border" && !loading && (
        <AiOutlinePlus className="icon" />
      )}
      {icon === "file" && !loading && <AiOutlineFolderOpen className="icon" />}

      {icon === "invite" && !loading && <FiUserPlus className="icon" />}
      {icon === "google" && !loading && <GoogleLogo className="google" />}
      {icon === "save" && !loading && <FaRegSave className="icon" />}
      {icon === "escalate" && !loading && <AiOutlineRise className="icon" />}

      {loading && <AiOutlineLoading className="loading" />}
      {!loading && <span>{children}</span>}
    </ButtonComponent>
  );
};

const ButtonComponent = styled.button<IButton>((props) => {
  const { size, variant, disabled, layoutVariant, iconRight, expand, loading } =
    props;

  return css`
    display: flex;
    justify-content: center;
    height: fit-content;
    align-items: center;
    min-width: max-content;
    border: none;
    color: #ffffff;
    line-height: 20px;
    outline: none;
    font-family: Roboto;

    ${size === "tiny" &&
    `
      padding: 8px 21px 9px 21px;
      border-radius: 5px;
      span {
        font-size: 13px;
        line-height: 13px;
      }
      svg {
        width: 18px;
      }
    `}

    ${size === "small" &&
    `
      padding: 12px 24px;
      border-radius: 8px;
      span {
      font-size: 14px;
      line-height: 16px;
      }
    `}

    ${size === "medium" &&
    `
      
      padding: 16px 28px;
      border-radius: 8px;
      span {
      font-size: 16px;
      line-height: 18px;
      }
    `}

    background-color: ${variant ? handleColorType(variant) : "#1B1D21"};

    &:hover {
      background: #ffffff;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.250929);
      color: ${variant ? handleColorType(variant) : "#1B1D21"};
    }

    ${disabled &&
    `
    background-color: #E4E4E4;
    color: #929292;
    cursor: default;
    &:hover {
    background-color: #E4E4E4;
    box-shadow: none;
    color: #929292;
    }
    `}

    ${loading &&
    `
     background-color: #E4E4E4;
     color: #929292;
     pointer-events: none;
    `}

    ${layoutVariant === "outlined" &&
    `
      background-color: inherit;
      border: 1px solid;
      border-color: ${variant ? handleColorType(variant) : "#1B1D21"};
      color : ${variant ? handleColorType(variant) : "#1B1D21"};
      
      &:hover {
        border-color :  ${variant ? handleColorType(variant) : "#1B1D21"};
        background-color: ${variant ? handleColorType(variant) : "#1B1D21"};
      color: #ffffff;
      }
    `}

    .loading {
      animation: load 1.1s infinite linear;
    }

    @keyframes load {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    .icon {
      margin-right: 10px;
    }
    .google {
      width: 15px;
      margin-right: 10px;
    }
    ${iconRight &&
    `
      flex-direction: row-reverse;
      .icon {
        margin-right: -7px;
        margin-left:10px;
      }
    `}

    ${expand &&
    `
     width: 100%;
    `}

    ${!expand &&
    `
     max-width: fit-content;
    `}
    
&:active,
&:focus {
      outline: 0;
    }
  `;
});

export default Button;
