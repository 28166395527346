import { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useParams } from "react-router-dom";
import type { Row } from "react-table";
import { Page, Section, Table } from "@nexcloud/nc-ui";
import styled from "@xstyled/emotion";

import {
  AlertNoPinCell,
  AlertRecipientCell,
  AlertSeverityCell,
  AlertSourcePortCell,
  IAlertListItem,
  SummaryCell,
  TimeCell,
} from "components/common/TableCell";
import DynamicFilter from "components/DynamicFilter";
import { Filter } from "components/DynamicFilter/types";

import { useAlertList } from "hooks/alert";

const AddBtn = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2px 0 10px;
  color: #909aae;
  background-color: inherit;
  outline: 0;
  &:focus {
    outline: 0;
  }
  p {
    font-size: 11px;
    line-height: 16px;
  }
`;

const IncidentAddCell: React.FC<{
  row: Row<IAlertListItem>;
  addedIds: Set<number>;
  onAddId(id: number): void;
  onRemoveId(id: number): void;
}> = ({ row, addedIds, onAddId, onRemoveId }) => {
  const added = addedIds.has(row.original.id);

  if (added) {
    return (
      <AddBtn
        type="button"
        onClick={() => {
          onRemoveId(row.original.id);
        }}
      >
        <span>REMOVE</span>
      </AddBtn>
    );
  }

  return (
    <AddBtn
      type="button"
      onClick={() => {
        onAddId(row.original.id);
      }}
    >
      <FiPlus />
      <span>ADD</span>
    </AddBtn>
  );
};

export const AllAlertsTab: React.FC<{
  addedIds: Set<number>;
  onAddId(id: number): void;
  onRemoveId(id: number): void;
}> = ({ addedIds, onAddId, onRemoveId }) => {
  const params = useParams<{ id: string }>();
  const incidentId = Number(params.id);
  const [filters, setFilters] = useState<Filter[]>([]);
  const { data } = useAlertList(
    {
      onSuccess(data) {
        data?.content.map(
          (alert) => alert.incidentId?.includes(incidentId) && onAddId(alert.id)
        );
      },
    },
    // undefined,
    filters
  );

  return (
    <Page>
      <Section>
        <DynamicFilter
          onChange={(filters) => {
            setFilters(filters);
          }}
        />
        {/* <FormInput searchIcon /> */}
        <Section>
          <Table
            columns={alertListColumn}
            data={data?.content ?? []}
            userCellProps={{ addedIds, onAddId, onRemoveId }}
          />
        </Section>
      </Section>
    </Page>
  );
};

export const SelectedAlertsTab: React.FC<{
  addedIds: Set<number>;
  onAddId(id: number): void;
  onRemoveId(id: number): void;
}> = ({ addedIds, onAddId, onRemoveId }) => {
  const { data } = useAlertList();

  return (
    <div>
      {/* <FormInput searchIcon /> */}
      <Section>
        <Table
          columns={alertListColumn}
          data={data?.content?.filter(({ id }) => addedIds.has(id)) ?? []}
          userCellProps={{ addedIds, onAddId, onRemoveId }}
        />
      </Section>
    </div>
  );
};

const alertListColumn = [
  {
    Header: "",
    Cell: IncidentAddCell,
    id: "idForAdd",
    disableSortBy: true,
  },
  {
    Header: "No. / Incident",
    accessor: "id",
    Cell: AlertNoPinCell,
    id: "IncidentNumber",
  },
  {
    Header: "Time",
    accessor: "startAt",
    Cell: TimeCell,
  },
  {
    Header: "Summary",
    accessor: "summary",
    Cell: SummaryCell,
  },
  {
    Header: "Severity",
    accessor: "severity",
    Cell: AlertSeverityCell,
  },
  {
    Header: "Source/Port",
    accessor: "source",
    Cell: AlertSourcePortCell,
  },
  {
    Header: "Description",
    accessor: "description",
    disableSortBy: true,
  },
  {
    Header: "From",
    accessor: "from",
    disableSortBy: true,
  },
  {
    Header: "Recipient",
    accessor: "recipients",
    Cell: AlertRecipientCell,
    disableSortBy: true,
  },
];
