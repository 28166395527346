import React, { useEffect, useState } from "react";
import { GridGenerator, Hex, Hexagon, HexGrid, Layout } from "react-hexgrid";
import { Link } from "react-router-dom";
import styled from "@emotion/styled/macro";
import { Button, Copyboard, Loading } from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";
import { Checkbox, Tooltip } from "antd";

import BorderBox from "components/common/BorderBox";

import type { IGlobalView } from "store/redux/zone";
import { useInstallParams, useUpdateInstallParams } from "hooks/cluster";
import {
  useCreateGlobalView,
  useDeleteGlobalView,
  useGlobalView,
  useGlobalViewStatus,
} from "hooks/cluster/globalView";

import { AdvancedClusterConfig } from "../CreateAdvancedCluster";

import paths from "paths";

import { device } from "style/device";

const Container = styled(BorderBox)`
  width: 100%;
  height: auto;
  border-radius: 5px;
  background: #fff;

  @media ${device.mobileL} {
    overflow: auto;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Paragraph = styled.p`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 1.5em;
`;

interface IGlobalViewProps {
  globalView?: IGlobalView;
  error: any;
}

const Card: React.FC<{ title: string; contentProps?: any }> = ({
  children,
  title,
  contentProps,
  ...props
}) => {
  return (
    <x.div
      className="cluster-card"
      col={{ _: 1, sm: 1 / 2, lg: 1 / 4 }}
      h="200px"
      overflow="hidden"
      mb="20px"
      bg="white"
      {...props}
    >
      <x.div w="100%" p="10px">
        <x.div
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          fontWeight="bold"
          mb="5px"
          h="30px"
          overflow="hidden"
        >
          {title}
        </x.div>
        <x.div
          display="flex"
          minHeight="150px"
          alignItems="center"
          justifyContent="center"
          {...contentProps}
        >
          {children}
        </x.div>
      </x.div>
    </x.div>
  );
};

const GridWrapper = styled.div`
  [draggable] {
    user-select: none;
  }

  svg g {
    fill: #3f51b5;
    fill-opacity: 0.6;
  }
  svg g:hover {
    fill-opacity: 1;
  }
  svg g:hover text {
    fill-opacity: 1;
  }

  svg g polygon {
    stroke: #3f51b5;
    stroke-width: 0.2;
    transition: fill-opacity 0.2s;
  }
  svg g text {
    font-size: 0.3em;
    fill: #ffffff;
    fill-opacity: 0.4;
    transition: fill-opacity 0.2s;
  }
  svg path {
    fill: none;
    stroke: hsl(60, 20%, 70%);
    stroke-width: 0.4em;
    stroke-opacity: 0.3;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
`;

const Grid: React.FC<{ data: any; dataKey: string }> = ({ data, dataKey }) => {
  const dataSlice = data[dataKey];
  if (!dataSlice) {
    return null;
  }

  const len = dataSlice.length;
  let radius = 1;
  if (len > 7) {
    radius = 2;
  } else if (len > 16) {
    radius = 3;
  }

  let hs = GridGenerator.spiral(new Hex(0, 0, 0), radius);
  hs = hs.slice(0, len);

  return (
    <GridWrapper>
      <HexGrid width={150} height={150}>
        <Layout size={{ x: 10, y: 10 }}>
          {hs.map((h, i) => {
            const obj = dataSlice[i];
            let hx = (
              <Hexagon
                key={i}
                q={h.q}
                r={h.r}
                s={h.s}
                className={`${obj.cluster_name}_${obj.value}`}
              />
            );

            if (obj.cluster_id && obj.cluster_name !== "nexglobal") {
              hx = (
                <Link key={i} to={paths.cluster.detail(obj.cluster_id)}>
                  {hx}
                </Link>
              );
            }

            if (obj.cluster_name) {
              hx = (
                <Tooltip key={i} placement="top" title={obj.cluster_name}>
                  {hx}
                </Tooltip>
              );
            }

            return hx;
          })}
        </Layout>
      </HexGrid>
    </GridWrapper>
  );
};

const CardList = styled(({ ...props }) => <x.div row {...props} />)`
  @media (min-width: 0px) {
    .cluster-card {
      border: 1px solid #e4e4e4;
      border-right-width: 1px;
    }
  }
  // 2 cards per row
  @media (min-width: 640px) {
    .cluster-card {
      border-right-width: 0px;
    }
    .cluster-card:nth-of-type(2n) {
      border-right-width: 1px;
    }
    .cluster-card:nth-of-type(2n - 1) {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    .cluster-card:nth-of-type(2n) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  // 4 cards per row
  @media (min-width: 1024px) {
    .cluster-card:nth-of-type(2n) {
      border-right-width: 0px;
    }
    .cluster-card:nth-of-type(4n) {
      border-right-width: 1px;
    }
    .cluster-card:nth-of-type(2n - 1) {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    .cluster-card:nth-of-type(2n) {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .cluster-card:nth-of-type(4n - 3) {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    .cluster-card:nth-of-type(4n) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;

const GlobalViewDetail: React.FC = () => {
  const { data, isLoading } = useGlobalViewStatus();

  const status = data?.status;
  if (isLoading || !status) {
    return <Loading>Global view is loading...</Loading>;
  }

  return (
    <CardList>
      <Card
        title="Clusters"
        contentProps={{
          fontWeight: "bold",
          fontSize: "3rem",
        }}
      >
        {data.clusterCount}
      </Card>
      <Card title="Promscale Instances">
        <Grid data={status} dataKey="status_promscale_instance" />
      </Card>
      <Card title="Kubernetes API">
        <Grid data={status} dataKey="status_kube_api" />
      </Card>
      <Card title="Kubernetes Pod Capacity">
        <Grid data={status} dataKey="status_node_pod" />
      </Card>
      <Card title="Kubernetes Nodes">
        <Grid data={status} dataKey="status_node_ready" />
      </Card>
      <Card title="Kubernetes Memory Pressure">
        <Grid data={status} dataKey="status_node_mem" />
      </Card>
      <Card title="Kubernetes Out of disk">
        <Grid data={status} dataKey="status_node_disk" />
      </Card>
      <Card title="Kubernetes Process">
        <Grid data={status} dataKey="status_node_process" />
      </Card>
    </CardList>
  );
};

const NonInitiatedGlobalView: React.FC<IGlobalViewProps> = ({
  globalView,
  error,
}) => {
  const [isSetupVisible, setIsSetupVisible] = useState(false);
  const { data: installParamsSet } = useInstallParams(globalView?.id, {
    enabled: !!globalView?.id,
  });
  const { mutateAsync: createGlobalView, isLoading: isCreating } =
    useCreateGlobalView();
  const {
    mutate: updateInstallParams,
    isLoading: requesting,
    data: installParamsUpdated,
  } = useUpdateInstallParams();

  const installParams = installParamsSet || installParamsUpdated;

  return (
    <Container>
      <Content>
        {!(isSetupVisible || installParams) && (
          <NonInitiatedGlobalViewMessage
            onSetup={async () => {
              setIsSetupVisible(true);
            }}
          />
        )}
        {!installParams && isSetupVisible && (
          <x.div w="100%">
            <AdvancedClusterConfig
              onSubmit={async (installParams) => {
                if (requesting) {
                  return;
                }

                // create global view instance first
                if (isCreating) {
                  return;
                }

                if (error) {
                  alert(
                    "Due to server error, it was unavailable to process your request."
                  );
                  return;
                }

                let globalViewId = globalView?.id;
                if (!globalView) {
                  try {
                    const globalView = await createGlobalView();
                    globalViewId = globalView.id;
                  } catch (e) {
                    alert("There was an server error, please try again later.");
                    return;
                  }
                }

                updateInstallParams({
                  clusterId: globalViewId,
                  installParams,
                });
              }}
            >
              <x.div justifyContent="flex-end" display="flex">
                <Button
                  onClick={() => {
                    setIsSetupVisible(false);
                  }}
                  loading={requesting}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  loading={requesting}
                  htmlType="submit"
                >
                  Continue
                </Button>
              </x.div>
            </AdvancedClusterConfig>
          </x.div>
        )}
        {installParams && (
          <ScriptView script={globalView?.agentScript} id={globalView?.id} />
        )}
      </Content>
    </Container>
  );
};

const ScriptView: React.FC<{ script?: string; id?: number }> = ({
  script,
  id,
}) => {
  const [installK3sChecked, setInstallK3sChecked] = useState(false);
  const { mutateAsync, isLoading } = useDeleteGlobalView();

  if (!script) {
    return <Loading />;
  }

  if (installK3sChecked) {
    script = script.replace("K3S_SET=Y", "K3S_SET=N");
  }

  return (
    <x.div mt="30px">
      Requires a new instance for installing dedicated long-term storage cluster
      with global-view in K3s. It has to be installed in the same network with
      the other clusters for monitoring.
      <Paragraph>
        Run the following command against your cluster for NexClipper
        <Checkbox
          defaultChecked={installK3sChecked}
          onChange={() => {
            setInstallK3sChecked(!installK3sChecked);
          }}
        >
          Check if you already have installed Kubernetes
        </Checkbox>
      </Paragraph>
      <Copyboard content={script} />
      <x.div justifyContent="flex-end" display="flex" pt="20px">
        <Button
          onClick={async () => {
            if (id && !isLoading) {
              await mutateAsync({ id });
              window.location.reload();
            }
          }}
        >
          Cancel
        </Button>
      </x.div>
    </x.div>
  );
};

const NonInitiatedGlobalViewMessage: React.FC<{
  onSetup: React.MouseEventHandler;
}> = ({ onSetup }) => {
  return (
    <x.div color="gray-600">
      Global view requires{" "}
      <x.a
        onClick={onSetup}
        color="#40a9ff"
        cursor="pointer"
        textDecoration="underline"
      >
        setup
      </x.a>{" "}
      for Primary longterm-storage.
    </x.div>
  );
};

const DEFAULT_INTERVAL = 5000;
function GlobalView() {
  const [refetchInterval, setRefetchInterval] = useState(DEFAULT_INTERVAL);
  const {
    data: globalView,
    error,
    isLoading,
  } = useGlobalView({
    refetchInterval,
  });

  const initStatus = globalView?.initStatus;
  useEffect(() => {
    if (initStatus === "ACTIVE" && refetchInterval === DEFAULT_INTERVAL) {
      setRefetchInterval(DEFAULT_INTERVAL * 2);
    }
  }, [initStatus, refetchInterval]);

  if (!globalView && isLoading) {
    return <Loading />;
  }

  if (
    !globalView ||
    initStatus === "NOT_INSTALLED" ||
    initStatus === "INSTALL_FAILED"
  ) {
    return <NonInitiatedGlobalView globalView={globalView} error={error} />;
  }

  if (
    initStatus === "BEING_INSTALLED" ||
    initStatus === "GLOBALVIEW_CHANGING"
  ) {
    return <Loading>Global view is being installed.</Loading>;
  }

  if (initStatus === "GLOBALVIEW_REMOVING") {
    return <Loading>Global view is being removed.</Loading>;
  }

  return <GlobalViewDetail />;
}

export default GlobalView;
