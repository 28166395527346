import React from "react";
import type { Row } from "react-table";
import { EmptyBox, Flex, Section, Table } from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";

import { useAccessGroupDetail } from "hooks/cluster/service";
import {
  IClusterAccessGroupListItem,
  useClusterAccessGroups,
} from "hooks/exporter";

const AccessGroupNameCell: React.FC<{
  value: string;
  row: Row<IClusterAccessGroupListItem>;
}> = ({ value, row }) => {
  return (
    <Flex>
      {row.original.icon && <x.div>{row.original.icon}</x.div>}
      <x.div>{value}</x.div>
    </Flex>
  );
};

const AccessGroupClustersCell: React.FC<{
  value: number;
  row: Row<IClusterAccessGroupListItem>;
}> = ({ value, row }) => {
  return (
    <Flex>
      {Array(10)
        .fill(null)
        .map(() => (
          <x.div w="15px" h="15px" mr="5px" backgroundColor="blue-200" />
        ))}
    </Flex>
  );
};

const AccessGroupMembersCell: React.FC<{
  value: number;
  row: Row<IClusterAccessGroupListItem>;
}> = ({ value, row }) => {
  const { data: group } = useAccessGroupDetail(value);
  if (!group) {
    return null;
  }
  const members = group.members;

  return (
    <>
      {members.groups?.length
        ? members.groups.map((group) => (
            <x.div
              w="15px"
              h="15px"
              mr="5px"
              backgroundColor="blue-200"
              borderRadius="100%"
            >
              {group.name?.[0]}
            </x.div>
          ))
        : null}
      {members.users?.length
        ? members.users.map((user) => (
            <x.div
              w="15px"
              h="15px"
              mr="5px"
              backgroundColor="blue-200"
              borderRadius="100%"
            >
              {user.name?.[0]}
            </x.div>
          ))
        : null}
    </>
  );
};

const columns = [
  {
    Header: "Access Group Name",
    accessor: "groupName",
    Cell: AccessGroupNameCell,
  },
  {
    Header: "Clusters",
    Cell: AccessGroupClustersCell,
  },
  {
    Header: "Members",
    accessor: "id",
    Cell: AccessGroupMembersCell,
  },
];

const AccessGroupsTab: React.FC<{ clusterId: number }> = ({ clusterId }) => {
  const { data: groups } = useClusterAccessGroups(clusterId);

  if (!groups) {
    return null;
  }

  return (
    <Section>
      {groups?.length === 0 ? (
        <EmptyBox />
      ) : (
        <Table columns={columns} data={groups} />
      )}
    </Section>
  );
};

export default AccessGroupsTab;
