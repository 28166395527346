import { Button, CenterCenter, Section } from "@nexcloud/nc-ui";
import styled from "@xstyled/emotion";

import upload from "assets/upload.png";

const Wrapper = styled.div`
  width: 100%;
  padding: 40px;
  border: 1px dashed #1f29ca;
`;
const UploadBox = styled.div`
  width: 100px;
`;
const UploadImg = styled.img`
  width: 100%;
  filter: invert(20%) sepia(100%) saturate(6876%) hue-rotate(243deg)
    brightness(79%) contrast(100%);
`;
UploadImg.defaultProps = { src: upload };
const UploadTab = () => {
  return (
    <Section>
      <Wrapper>
        <CenterCenter>
          <UploadBox>
            <UploadImg />
          </UploadBox>
          <p>Drag and Drop</p>
          <p>To Upload Images</p>
          <br />
          <Button
            icon="file"
            iconRight
            variant="primary"
            layoutVariant="outlined"
          >
            SELECT FILES
          </Button>
        </CenterCenter>
      </Wrapper>
    </Section>
  );
};

export default UploadTab;
