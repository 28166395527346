import styled from "@emotion/styled";
import { x } from "@xstyled/emotion";

interface IPriorityBadge {
  priority: string | "URGENT" | "HIGH" | "MEDIUM" | "LOW";
  onClick?(): void;
}

const handleColor = (priority: string): string => {
  switch (priority) {
    case "URGENT":
      return "#000";
    case "HIGH":
      return "#FF0A0A";
    case "MEDIUM":
      return "#FFE600";
    case "LOW":
      return "#08CE04";
    default:
      return "#08CE04";
  }
};

const handleBarLength = (priority: string): string => {
  switch (priority) {
    case "URGENT":
      return "100px";
    case "HIGH":
      return "80px";
    case "MEDIUM":
      return "60px";
    case "LOW":
      return "40px";
    default:
      return "none";
  }
};

const Wrapper = styled.div<IPriorityBadge>`
  color: ${(props) => props.priority && handleColor(props.priority)};
`;

const PriorityBadge: React.FC<IPriorityBadge> = ({ priority }) => {
  return (
    <>
      <x.p fontFamily="poppins" fontSize="16px" lineHeight="24px">
        {priority}
      </x.p>
      <Wrapper priority={priority}>
        <x.div
          w={handleBarLength(priority)}
          borderColor={handleColor(priority)}
          border="2px solid"
        />
      </Wrapper>
    </>
  );
};

export default PriorityBadge;
