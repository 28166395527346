import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  CenterCenter,
  FormInput,
  HorizontalCenter,
  Page,
  Section,
  TitleSection,
} from "@nexcloud/nc-ui";
import styled, { x } from "@xstyled/emotion";

import { useDetailAccessGroups } from "hooks/cluster/service";
import { request } from "api/common";

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  color: #b2b3bd;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

const Error = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: #ff754c;
  padding-left: 10px;
  font-weight: 500;
`;

interface ICreateAccessGroup {
  groupName: string;
  description: string;
  icon: string;
}

const Onboarding = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { refetch } = useDetailAccessGroups();

  const showCreateModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ICreateAccessGroup>({
    mode: "onChange",
  });

  const onSubmit = async (data: ICreateAccessGroup) => {
    try {
      await request.post(`/lari/accessgroups`, {
        groupName: data.groupName,
        description: data.description,
        icon: data.icon,
      });
      await reset({
        groupName: "",
        description: "",
        icon: "",
      });
      refetch();
      onClose();
    } catch (e) {
      alert("There was an error. Please try again later.");
    }
  };
  return (
    <Page>
      <CenterCenter>
        <Section>
          <TitleSection>
            <HorizontalCenter>
              <h1>Add Your First Access Group</h1>
            </HorizontalCenter>
          </TitleSection>
          <HorizontalCenter>
            <Button
              layoutVariant="contained"
              variant="primary"
              onClick={showCreateModal}
              to="/access-groups/add"
              size="medium"
            >
              Add Group
            </Button>
          </HorizontalCenter>
        </Section>
      </CenterCenter>
      {isModalOpen && (
        <x.div p="20px" border="1px solid #000">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="field">
              <Label htmlFor="groupName">Group Name</Label>
              <FormInput
                id="groupName"
                type="text"
                {...register("groupName", {
                  minLength: 1,
                })}
              />
              {errors.groupName && <Error>Please enter group name.</Error>}
            </div>
            <div className="field">
              <Label htmlFor="description">Description</Label>
              <FormInput
                id="description"
                type="text"
                {...register("description")}
              />
            </div>
            <div className="field">
              <Label htmlFor="icon">Icon</Label>
              <FormInput id="icon" type="text" {...register("icon")} />
            </div>
            <Button layoutVariant="outlined" variant="primary" type="submit">
              Create
            </Button>
          </form>
        </x.div>
      )}
    </Page>
  );
};

export default Onboarding;
