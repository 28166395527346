import styled from "@emotion/styled";

interface ITitleSection {
  type?: "withBreadcrumb";
}

const Wrapper = styled.div<{ type?: "withBreadcrumb" }>`
  width: 100%;
  max-width: 1600px;
  /* margin: 32px auto 32px auto; */
  margin: ${(props) =>
    props.type === "withBreadcrumb"
      ? "8px auto 8px auto"
      : "32px auto 32px auto"};
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between; // start center space-between space-around space-evenly;
  align-items: center; // flex-start, flex-end, center, baseline, stretch
  h2 {
    margin-bottom: 0;
  }
`;

const Section: React.FC<ITitleSection> = ({ children, type }) => {
  return <Wrapper type={type}>{children}</Wrapper>;
};

export default Section;
