import { Map } from "immutable";
import { createReducer } from "typesafe-actions";

import { RESET_ACCOUNT, SET_DATA } from "./actions";
import { AccountAction, AccountState } from "./types";

const initialState: AccountState = Map({
  login: undefined,
  googleAccount: null,
});

const reducer = createReducer<AccountState, AccountAction>(initialState, {
  [SET_DATA]: (state, action) => {
    const { key, value } = action.payload;
    return state.set(key, value);
  },
  [RESET_ACCOUNT]: (state, action) => {
    return state.set("login", false).set("googleAccount", null);
  },
});
export default reducer;
