const STAGES = {
  LOCAL: "LOCAL",
  DEV: "DEV",
  PROD: "PROD",
};

const PROD_DOMAIN = "console.nexclipper.io";

const getStage = () => {
  if (process.env.NODE_ENV === "development") {
    return STAGES.LOCAL;
  }
  if (window.location.hostname === PROD_DOMAIN) {
    return STAGES.PROD;
  }
  return STAGES.DEV;
};

export const isProduction = () => {
  return getStage() === STAGES.PROD;
};
