import React, { useState } from "react";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { HorizontalCenter } from "@nexcloud/nc-ui";
import styled, { x } from "@xstyled/emotion";

import ClusterMapImg from "assets/grid-v1.png";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 800px;
  overflow: hidden;
  position: relative;
`;

const GridBg = styled.div`
  transform: rotateX(55deg) rotateY(0deg) rotateZ(-45deg);
  transform-origin: center center;
  z-index: 1;
  background: url(${ClusterMapImg});
  width: 100%;
  height: 100%;
  opacity: 1;
`;

const GridContent = styled.div`
  width: 1500px;
  height: 1500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  vertical-align: top;
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  background-position: center;
  transform: translate(-50%, -50%) rotateX(55deg) rotateY(0deg) rotateZ(-45deg);
`;

const ZoomWrapper = styled.div`
  display: block;
  width: 5000px;
  height: 5000px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
`;

interface IZoomContent {
  zoomLevel: number;
}

const ZoomContent = styled.div<IZoomContent>`
  position: relative;
  width: 100%;
  height: 100%;
  transform: scale(
    ${(props) => {
      return props.zoomLevel;
    }}
  );
  transition: transform 0.5s;
`;

const Grid = styled.div`
  border-right: 2px solid rgb(186, 242, 242);
  border-bottom: 2px solid rgb(186, 242, 242);
  width: 50px;
  height: 50px;

  cursor: pointer;

  &.center {
    background-color: blue;
  }

  &:hover {
    background-color: rgba(0, 228, 228, 0.5);
  }
`;

const IsometricTopology: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleZoomLevel = (zoomLevel: number) => {
    setZoomLevel(zoomLevel);
  };

  return (
    <Wrapper>
      <ZoomWrapper>
        <ZoomContent zoomLevel={zoomLevel}>
          <GridBg />
          <GridContent>
            {Array(900)
              .fill(0)
              .map((_, index) => {
                if (index === 436) {
                  return <Grid className="center" />;
                } else {
                  return <Grid />;
                }
              })}
          </GridContent>
        </ZoomContent>
      </ZoomWrapper>
      <ZoomControl onChange={handleZoomLevel} zoomLevel={zoomLevel} />
    </Wrapper>
  );
};

const ZoomControl: React.FC<{ zoomLevel: number; onChange: any }> = ({
  zoomLevel,
  onChange,
}) => {
  const zoomIn = () => {
    let newZoomLevel = (zoomLevel || 0) + 0.25;
    if (newZoomLevel > 1) {
      newZoomLevel = 1;
    }
    onChange(newZoomLevel);
  };

  const zoomOut = () => {
    let newZoomLevel = (zoomLevel || 0) - 0.25;
    if (newZoomLevel < 0.25) {
      newZoomLevel = 0.25;
    }
    onChange(newZoomLevel);
  };

  return (
    <ZoomControlWrapper>
      <HorizontalCenter>
        <x.div
          fontSize="25px"
          mb="10px"
          cursor="pointer"
          color="#1F29CA"
          onClick={zoomIn}
        >
          <FiPlusCircle />
        </x.div>
        <x.div h="150px" w="3px" background="#E7E7E7" position="relative">
          <x.div
            h="75px"
            w="3px"
            position="absolute"
            background="#1F29CA"
            top={`${(1 - zoomLevel) * 100}px`}
            transition="top 0.35s"
          ></x.div>
        </x.div>
        <x.div
          fontSize="25px"
          mt="10px"
          cursor="pointer"
          color="#1F29CA"
          onClick={zoomOut}
        >
          <FiMinusCircle />
        </x.div>
      </HorizontalCenter>
    </ZoomControlWrapper>
  );
};

const ZoomControlWrapper = styled.div`
  position: absolute;
  transform: translateY(-50%);
  left: 40px;
  top: 40%;
`;

export default IsometricTopology;
