import styled from "@emotion/styled";
import { Switch as AntdSwitch } from "antd";

interface ISwitch {
  leftLabel?: React.ReactNode;
  rightLabel?: React.ReactNode;
  checked?: boolean;
  onChange?: () => void;
}

const Switch: React.FC<ISwitch> = ({
  checked,
  onChange,
  leftLabel,
  rightLabel,
}) => {
  return (
    <Wrapper>
      {leftLabel && <LeftLabel>{leftLabel}</LeftLabel>}
      <AntdSwitch checked={checked} onChange={onChange} />
      {rightLabel && <RightLabel>{rightLabel}</RightLabel>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 0.9rem;
  }
`;

const LeftLabel = styled.span`
  margin-right: 10px;
`;

const RightLabel = styled.span`
  margin-left: 10px;
`;

export default Switch;
