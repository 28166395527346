import React from "react";
import styled from "@emotion/styled/macro";

import { IWdigetCommonProps } from "./types";

const Widget = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`;

const Value = styled.div`
  flex: 1;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.2em;
  font-weight: 600;

  span {
    font-size: 1em;
  }
`;

const Empty = styled.div`
  opacity: 0.5;
  font-size: 1em;
`;

interface IStatusBoardProps extends IWdigetCommonProps {}

function StatusBoard({ title, data, render }: IStatusBoardProps) {
  return (
    <Widget>
      <Title>{title}</Title>
      <Value>
        {data === null || data < 0 ? (
          <Empty>N/A</Empty>
        ) : render ? (
          render(data)
        ) : (
          data
        )}
      </Value>
    </Widget>
  );
}

export default StatusBoard;
