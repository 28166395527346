import { x } from "@xstyled/emotion";

const EmptyBox = () => {
  return (
    <x.div w="100%" h="50vh" bg="#fff">
      Empty
    </x.div>
  );
};

export default EmptyBox;
