import React, { useEffect, useState } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import type { Row } from "react-table";
import {
  Breadcrumb,
  Button,
  Column,
  Description,
  Flex,
  LinkButton,
  Section,
  Table,
  TitleSection,
} from "@nexcloud/nc-ui";
import styled, { x } from "@xstyled/emotion";
import Drawer from "antd/es/drawer";

import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "components/common/Dropdown";
import DynamicFilter from "components/DynamicFilter";
import { Filter } from "components/DynamicFilter/types";

import snapshots from "assets/snapshots.png";

interface IMetricItem {
  title: string;
  description: string;
  updatedAt: string;
  id: number;
}

const Menu = styled.div`
  .menuIcon {
    font-size: 16px;
    color: #909aae;
    cursor: pointer;
  }
`;

const TitleLinkCell: React.FC<{
  value: string;
  row: Row<IMetricItem>;
  setDepth: any;
  setDetailInfo: any;
}> = ({ value, row, setDepth, setDetailInfo }) => {
  const handleDepth = () => {
    setDetailInfo({
      title: row.original.title,
      description: row.original.description,
      id: row.original.id,
    });
    setDepth(1);
  };

  return (
    <x.div onClick={handleDepth}>
      <LinkButton to="#">{value}</LinkButton>
    </x.div>
  );
};

const UseButtonCell: React.FC<{
  value: number;
  row: Row<IMetricItem>;
}> = ({ value, row }) => {
  return (
    <Button variant="primary" layoutVariant="outlined">
      USE
    </Button>
  );
};

const MenuCell: React.FC<{
  value: number;
  row: Row<IMetricItem>;
}> = ({ value, row }) => {
  return (
    <Menu>
      <Dropdown
        placement="left-start"
        trigger="click"
        render={(attrs) => {
          return (
            <DropdownMenu>
              <x.div w="222px" p="12px 14px" pb="30px">
                {[["Delete snapshot", "", <RiDeleteBinLine />]].map(
                  ([title, link, icon, onClick], i) => (
                    <DropdownMenuItem
                      key={i}
                      icon={icon}
                      title={title}
                      // @ts-expect-error
                      link={link}
                      // onClick={onClick}
                    />
                  )
                )}
              </x.div>
            </DropdownMenu>
          );
        }}
      >
        <FiMoreVertical className="menuIcon" />
      </Dropdown>
    </Menu>
  );
};

const snapShotColumns = [
  {
    Header: "Title",
    accessor: "title",
    Cell: TitleLinkCell,
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Updated At",
    accessor: "updatedAt",
  },
  {
    Header: "",
    accessor: "id",
    disableSortBy: true,
    Cell: UseButtonCell,
  },
  {
    Header: "",
    accessor: "id",
    Cell: MenuCell,
    id: "popupMenu",
    disableSortBy: true,
  },
];

const SnapshotDetail: React.FC<{ setDepth: any; detailInfo: any }> = ({
  setDepth,
  detailInfo,
}) => {
  return (
    <Section>
      <Flex justifyContent="space-between">
        <span>
          <Breadcrumb title="SNAPSHOTS" path="#" />
          <TitleSection type="withBreadcrumb">
            <h2>{detailInfo.title}</h2>
          </TitleSection>
        </span>
        <Column columnsGap="10px">
          <Button layoutVariant="outlined" variant="primary">
            EDIT SNAPSHOT
          </Button>
          <Button variant="primary" onClick={() => setDepth(0)}>
            BACK TO SNAPSHOTS
          </Button>
        </Column>
      </Flex>
      <Section>
        <x.img src={snapshots} w="100%" />
      </Section>
      <Section>
        <Description label="Description">{detailInfo.description}</Description>
      </Section>
      <Section>
        <h3>Meta Data</h3>
        <x.div w="90%" display="flex">
          <Description label="Upload Date">2021-07-06 02:31:23 PM</Description>
          <Description label="Type">Area Graph</Description>
          <Description label="Renderer">
            AraeGraph (Charts.js @ 3.5.1)
          </Description>
        </x.div>
      </Section>
      <Section>
        <LinkButton to="#">DOWNLOAD RAW JSON</LinkButton>
        <LinkButton to="#" variant="danger">
          DELETE SNAPSHOT
        </LinkButton>
      </Section>
    </Section>
  );
};

const AddMetricDrawer: React.FC<{
  visible: boolean;
  onClose(): void;
  options: any;
}> = ({ visible, onClose, options }) => {
  const [
    ,
    //   filters
    setFilters,
  ] = useState<Filter[]>([]);
  const [depth, setDepth] = useState(0);
  const [detailInfo, setDetailInfo] = useState({
    title: "",
    descriptoin: "",
    id: "",
  });

  useEffect(() => {
    !visible && setDepth(0);
  }, [visible]);

  return (
    <Drawer visible={visible} onClose={onClose} width="80%">
      {depth === 0 && (
        <Section>
          <TitleSection>
            <h2>Your SnapShots</h2>
            <Button variant="primary" onClick={onClose}>
              DONE
            </Button>
          </TitleSection>
          <Section>
            <DynamicFilter
              onChange={(filters) => {
                setFilters(filters);
              }}
            />
          </Section>
          <Table
            columns={snapShotColumns}
            data={mockSnapShots}
            userCellProps={{ setDepth: setDepth, setDetailInfo: setDetailInfo }}
          />
        </Section>
      )}
      {depth === 1 && (
        <SnapshotDetail setDepth={setDepth} detailInfo={detailInfo} />
      )}
    </Drawer>
  );
};

const mockSnapShots = [
  {
    title: "Alerts from 2021-07-30 to 2021-08-02",
    description: "For Mongodb down",
    updatedAt: "2021-07-06 02:31:23",
    id: 1,
  },
  {
    title: "Alerts from 2021-08-05 to 2021-08-21",
    description: "For Mongodb down",
    updatedAt: "2021-07-06 02:29:51",
    id: 2,
  },
];

export default AddMetricDrawer;
