import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import type { QueryArgs } from "./utils";
import { getClusterQueryParams } from "./utils";

const paths = {
  dashboard() {
    return `/`;
  },
  tempTenancy() {
    return `/tempTenancy`;
  },
  cluster: {
    detail(queryArgs?: QueryArgs) {
      return `/cluster/prometheus-detail${getClusterQueryParams(queryArgs)}`;
    },
    dashboard(queryArgs?: QueryArgs) {
      return `/cluster/prometheus-dashboard${getClusterQueryParams(queryArgs)}`;
    },
    config(queryArgs?: QueryArgs) {
      return `/cluster/config${getClusterQueryParams(queryArgs)}`;
    },
    prometheusConfig(queryArgs?: QueryArgs) {
      return `/cluster/prometheus-config${getClusterQueryParams(queryArgs)}`;
    },
    alertRulesConfig(queryArgs?: QueryArgs) {
      return `/cluster/alert-rules${getClusterQueryParams(queryArgs)}`;
    },
    recordingRulesConfig(queryArgs?: QueryArgs) {
      return `/cluster/recording-rules${getClusterQueryParams(queryArgs)}`;
    },
    alertManagerConfig(queryArgs?: QueryArgs) {
      return `/cluster/prometheus-alert${getClusterQueryParams(queryArgs)}`;
    },
  },
  notification() {
    return `/notification`;
  },
  settings: {
    my: () => "/settings/my",
    organization: {
      index: () => "/settings/organization",
      general: () => "/settings/organization/general",
      permission: () => "/settings/organization/permission",
    },
    users: {
      index: () => "/settings/users",
      manage: () => "/settings/users/manage",
      groups: () => "/settings/users/groups",
    },
    channel: () => "/settings/channel",
  },
  // non-auth routes
  join(verificationCode?: string | null) {
    return `/join${verificationCode ? `?inviteCode=${verificationCode}` : ""}`;
  },
  login(verificationCode?: string | null) {
    return `/login${verificationCode ? `?inviteCode=${verificationCode}` : ""}`;
  },
  reset() {
    return `/reset`;
  },
  welcome() {
    return "/welcome";
  },
  notFound() {
    return "/404";
  },
} as const;

/**
 * const go = usePaths(paths => paths.prometheus.detail);
 * go(5);
 */
export const usePath = (
  selector: (p: typeof paths) => (...args: any[]) => string,
  method: "push" | "replace" = "push"
) => {
  const history = useHistory();
  const pathFn = selector(paths);
  const historyFn = useCallback(
    (...args: any[]) => history[method](pathFn(...args)),
    [history, method, pathFn]
  );
  return historyFn;
};

export default paths;
