import Whiteboard from "components/Incident/WhiteBoard/Whiteboard";

const IncWhiteboardTab = () => {
  return (
    <div>
      <Whiteboard />
    </div>
  );
};

export default IncWhiteboardTab;
