import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import styled from "@emotion/styled/macro";
import { Button as ResetButton, Form, Input, Modal } from "antd";

import {
  showPasswordResetSuccess,
  showTryAgain,
} from "components/NotificationMessage";

import useQueryParams from "hooks/util/useQueryParams";
import { request } from "api/common";

import modal from "utils/modal";
import { passwordValid } from "utils/regExp";
import paths from "paths";

const required = (styledComponent: any) => styled(styledComponent)`
  &:after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
`;
const Label = styled.div`
  font-size: 0.9em;
  color: #808090;
  margin-bottom: 5px;
  * {
    font-size: 1em;
  }
`;
const RequireLabel = required(Label);

export default function JoinForm() {
  const [password, setPassword] = useState("");

  const history = useHistory();
  const query = useQueryParams();
  const loginId = query.get("loginId");
  const verificationCode = query.get("code");

  if (loginId === null || verificationCode === null) {
    return null;
  }

  const onFinish = async (value: any) => {
    const dto = {
      loginId: loginId,
      newPassword: password,
      verificationCode,
    };

    try {
      const response = await request.put(
        `/users/login/conventional/reset`,
        dto
      );
      const result = response.data;
      if (result === "OK") {
        showPasswordResetSuccess();
        history.push(paths.login());
      } else if (
        result === "VERIFICATION_CODE_EXPIRED" ||
        "INVALID_STATE_FOR_RESET"
      ) {
        modal.warning(
          "The session is expired. Please try reset password again."
        );
        history.push(paths.reset());
      } else {
        return showTryAgain();
      }
    } catch (e) {
      if (e.response?.data?.message) {
        modal.warning(e.response.data.message);
      } else {
        showTryAgain();
      }
    }
  };

  const onFinishFailed = (val: any) => {
    Modal.warning({
      title: "Warning",
      content: "Please try again.",
    });
  };

  return (
    <Form
      name="resetpassword"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Label>
        <span>Email</span>
      </Label>
      <Form.Item>
        <Input value={loginId} />
      </Form.Item>
      <RequireLabel>
        <span>New Password</span>
      </RequireLabel>
      <Form.Item
        name="newPassword"
        rules={[
          { required: true, message: "Please enter new password." },
          { pattern: passwordValid, message: "Invalid password format." },
        ]}
      >
        <Input.Password
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const password = e.target.value;
            setPassword(password);
          }}
          placeholder="min 8 letter, with at least a symbol and a number"
          iconRender={(visble) =>
            visble ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
      <Form.Item>
        <ResetButton type="primary" htmlType="submit" block>
          Reset
        </ResetButton>
      </Form.Item>
    </Form>
  );
}
