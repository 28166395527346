import { useQuery, UseQueryOptions } from "react-query";

import { IAlertStatus } from "store/redux/zone";
import { accountApi } from "api";

export const useOverallAlert = (
  options?: UseQueryOptions<any, unknown, any>
) => {
  return useQuery<IAlertStatus>(
    ["overallAlert"],
    () => accountApi.getOverallAlert(),
    {
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};
