import styled from "@emotion/styled";

const Panel: React.FC = ({ children, ...props }) => {
  return <Elem>{children}</Elem>;
};

export default Panel;

const Elem = styled.div`
  height: auto;
  min-height: 100%;
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 25px 25px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
`;
