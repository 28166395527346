import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Page,
  Section,
  Tab,
  TitleSection,
} from "@nexcloud/nc-ui";
import { TabPane } from "@nexcloud/nc-ui/src/Tab";

import { useIncidentAlert, useIncidentDetail } from "hooks/incident";
import { request } from "api/common";

import IncWhiteboardTab from "./WhiteBoard/IncWhiteboardTab";
// import EditAllAlertsTab from "./EditAllAlertsTab";
// import EditSelectedTab from "./EditSelectedTab";
import IncActionsTab from "./IncActionsTab";
import { AllAlertsTab, SelectedAlertsTab } from "./IncAlertsEditMode";
import IncAlertsTab from "./IncAlertsTab";
import IncidentInfoPanel from "./IncidentInfoPanel";

const IncidentDetail = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedAlertIds, setSelectedAlertIds] = useState<Set<number>>(
    new Set()
  );
  const params = useParams<{ id: string }>();
  const incidentId = Number(params.id);
  const { data: incident } = useIncidentDetail(incidentId);
  const { data: incidentAlert, refetch } = useIncidentAlert(incidentId);

  const panes = useMemo(() => {
    const alertTabProps = {
      addedIds: selectedAlertIds,
      onAddId: (alertId: number) => {
        setSelectedAlertIds(new Set(selectedAlertIds.add(alertId)));
      },
      onRemoveId: (alertId: number) => {
        selectedAlertIds.delete(alertId);
        setSelectedAlertIds(new Set(selectedAlertIds));
      },
    };
    return [
      ["All Alerts", AllAlertsTab, alertTabProps],
      [
        `Selected Alerts (${selectedAlertIds.size})`,
        SelectedAlertsTab,
        alertTabProps,
      ],
    ];
  }, [selectedAlertIds]) as TabPane[];

  if (!incident) {
    return null;
  }

  const handleEditComfirm = async () => {
    const initailAlertIds =
      incidentAlert && [...incidentAlert].map((alert) => alert.id);

    const confirmAlertIds = Array.from(selectedAlertIds);

    if (initailAlertIds === confirmAlertIds) {
      setIsEditMode(false);
      return;
    }

    const addIds =
      confirmAlertIds &&
      [...confirmAlertIds]?.filter((id) => !initailAlertIds?.includes(id));

    const deleteIds = initailAlertIds
      ? [...initailAlertIds]?.filter((id) => !confirmAlertIds.includes(id))
      : [];

    // console.log("추가될거 : ", addIds);
    // console.log("지울거 : ", deleteIds);

    try {
      if (addIds.length > 0) {
        await request.post(
          `/lari/incidents/${incidentId}/relates/alerts`,
          addIds
        );
      }

      if (deleteIds.length > 0) {
        await request.delete(`/lari/incidents/${incidentId}/relates/alerts`, {
          data: deleteIds,
        });
      }

      refetch();
      setIsEditMode(false);
    } catch (e) {
      alert(e.message);
    }
  };

  return (
    <Page type="item">
      <TitleSection>
        <span>
          <Breadcrumb
            title="Incident"
            path="/incident"
            subPathTitle={`INC-${params.id}`}
            subPath={`/${params.id}`}
          />
          <TitleSection type="withBreadcrumb">
            <h2>{incident.title}</h2>
          </TitleSection>
        </span>
        <Button
          layoutVariant="outlined"
          variant="primary"
          to={`/incident/${params.id}/edit`}
        >
          EDIT INCIDENT
        </Button>
      </TitleSection>
      <Section>
        <IncidentInfoPanel incident={incident} />
      </Section>
      <Section>
        {!isEditMode && (
          <Tab
            panes={[
              ["Actions", IncActionsTab],
              [
                `Alerts (${incidentAlert?.length})`,
                IncAlertsTab,
                { setIsEditMode },
              ],
              ["Whiteboard", IncWhiteboardTab],
            ]}
          />
        )}

        {isEditMode && (
          // <Tab
          //   panes={[
          //     ["All Alerts", EditAllAlertsTab, { setIsEditMode }],
          //     ["Selected Alerts (4)", EditSelectedTab, { setIsEditMode }],
          //   ]}
          // />
          <>
            <TitleSection>
              <h3>Edit Alerts</h3>
              <Button variant="primary" onClick={() => handleEditComfirm()}>
                CONFIRM
              </Button>
            </TitleSection>
            <Tab panes={panes} />
          </>
        )}
      </Section>
    </Page>
  );
};

export default IncidentDetail;
