import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled/macro";
import {
  Button,
  CenterCenter,
  CompanyCodeInput,
  Error,
  FormInput,
  FormTextArea,
  HorizontalCenter,
  IconSelector,
  InputLayout,
  Link,
  Page,
  ProgressBar,
  Section,
  SmallFormLayout,
  SpaceBetween,
  SubInfo,
  TitleSection,
  UserBadge,
} from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";

// import InstallationDrawer from "components/common/InstallationDrawer";
import FixContainer from "components/Layout/FixContainer";

import { useCurrentUser } from "hooks/user";
import { getTenancyId, request } from "api/common";

import ClusterForm from "../../forms/Cluster";

import modal from "utils/modal";

import Logo from "assets/logo.png";

const RESULTS = {
  INVALID_TENANCY_CODE_PATTERN: "INVALID_TENANCY_CODE_PATTERN",
  DUPLICATED_CODE: "DUPLICATED_CODE",
  ALREADY_CREATED: "ALREADY_CREATED",
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
`;

const LogoImg = styled.img`
  width: 250px;
  margin-bottom: 25px;
`;
LogoImg.defaultProps = { src: Logo };

const WelcomeTitle = styled.div`
  font-size: 1.8em;
  font-weight: 500;
  color: #797979;
  margin-bottom: 40px;
`;

const SubTitle = styled.div`
  font-weight: 900;
  font-size: 1.5em;
  color: #4c4c4c;
  margin-bottom: 15px;
`;

const Content = styled.div`
  font-size: 1.1em;
  line-height: 1.8em;
  text-align: center;
  color: #8c8c8c;
  margin-bottom: 20px;
`;

const OnboardingTitle = styled.h1`
  color: #1f29ca;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  margin-top: 25px;
`;

interface ICompoanyInfo {
  setShowOnboarding: any;
  setStep: any;
}

interface IOnboardingInput {
  userName?: string;
  tenancyCode: string;
  tenancyName: string;
  tenancyDescription: string;
}

const SetCompanyInfo: React.FC<ICompoanyInfo> = ({
  setShowOnboarding,
  setStep,
}) => {
  const { data: user } = useCurrentUser();
  const tenancy = user?.currentTenancy;

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<IOnboardingInput>({
    mode: "onChange",
    defaultValues: {
      userName: user?.name,
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const watchSubdomain = watch(["tenancyCode"]);

  const onNext = async (data: IOnboardingInput) => {
    setIsSubmitting(true);

    try {
      if (!user) {
        return;
      }

      const { userName, tenancyName, tenancyDescription, tenancyCode } = data;

      const isOwner = tenancy?.owner;

      if (isOwner) {
        const { data } = await request.post(
          `/users/tenancies/${getTenancyId()}`,
          {
            tenancyCode,
            tenancyDescription,
            tenancyName,
            userName,
          }
        );

        setIsSubmitting(false);

        // organization code not suitable for domain name
        if (data === RESULTS.INVALID_TENANCY_CODE_PATTERN) {
          modal.error(
            "The organization code contains not allowed pattern. Organization code pattern is 4 to 16 lowercase alphanumeric character and non-continuous hyphen(-)."
          );
          return;
        }

        if (data === RESULTS.DUPLICATED_CODE) {
          modal.error(
            "The organization code is used by another organization already, Please try another code."
          );
          return;
        }

        if (data === RESULTS.ALREADY_CREATED) {
          modal.error("The organization is activated already.");
        }

        setStep(1);
        return;
      } else {
        await request.post(`/users/tenancies/${getTenancyId()}/members`, {
          userName,
        });

        setIsSubmitting(false);
        setStep(1);
      }
    } catch (e) {
      alert("There was an error. Please try again later.");
    }
  };

  return (
    <Section>
      <TitleSection>
        <h2>1. Company information</h2>
      </TitleSection>
      <form onSubmit={handleSubmit(onNext)}>
        <SmallFormLayout>
          <InputLayout>
            <FormInput
              placeholder="Username"
              type="text"
              label="USERNAME"
              id="userName"
              {...register("userName", {
                required: true,
              })}
            />
            {errors.userName && <Error>Enter a user name.</Error>}
          </InputLayout>
          <InputLayout>
            <CompanyCodeInput
              id="tenancyCode"
              placeholder="Subdomain"
              label="SUBDOMAIN NAME"
              {...register("tenancyCode", {
                required: true,
              })}
            />
            <SubInfo>
              We will be able to access https://<b>{watchSubdomain}</b>
              .nexclipper.io
            </SubInfo>
            {errors.tenancyCode && <Error>Enter Subdomain Name.</Error>}
          </InputLayout>
          <InputLayout>
            <FormInput
              placeholder="Company name"
              type="text"
              label="YOUR COMPANY NAME"
              id="tenancyName"
              {...register("tenancyName", {
                required: true,
              })}
            />
            <SubInfo>This will be show up on your bill</SubInfo>
            {errors.tenancyName && <Error>Enter Company Name.</Error>}
          </InputLayout>
          <InputLayout>
            <FormTextArea
              placeholder="Company description"
              maxLength="250"
              label="COMPANY DESCRIPTION"
              rows={6}
              id="tenancyDescription"
              {...register("tenancyDescription", {
                required: false,
              })}
            />
          </InputLayout>
        </SmallFormLayout>
        <SpaceBetween>
          <Button
            variant="primary"
            type="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Submit and Next
          </Button>
          <Button
            layoutVariant="outlined"
            variant="primary"
            onClick={() => setShowOnboarding(false)}
          >
            Back
          </Button>
        </SpaceBetween>
      </form>
    </Section>
  );
};

const SetYourTeam: React.FC<{
  setStep: any;
}> = ({ setStep }) => {
  const [isAddTeam, setIsAddTeam] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupDesc, setGroupDesc] = useState("");
  const [groupIcon, setGroupIcon] = useState("");
  const [groupId, setGroupId] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onCreateAccessGroup = async () => {
    setIsSubmitting(true);

    try {
      const response = await request.post(`/lari/accessgroups`, {
        groupName: groupName,
        description: groupDesc,
        icon: groupIcon,
      });

      if (response.status === 200) {
        setGroupId(response.data);
        setIsSubmitting(false);
        setIsAddTeam(true);
      }
    } catch (e) {
      alert("There was an error. Please try again later.");
    }
  };

  return (
    <Section>
      <TitleSection>
        <h2>3. INVITE MEMBERS TO THE WORKPLACE</h2>
      </TitleSection>
      {!isAddTeam && (
        <>
          <SmallFormLayout>
            <InputLayout>
              <FormInput
                label="New Access Group Name"
                onChange={(e) => {
                  setGroupName(e.target.value);
                }}
              />
            </InputLayout>
            <InputLayout>
              <FormTextArea
                maxLength="250"
                label="Access Group Description"
                rows={6}
                onChange={(e) => {
                  setGroupDesc(e.target.value);
                }}
              />
            </InputLayout>
            <InputLayout>
              <IconSelector
                onChange={(value: string) => {
                  setGroupIcon(value);
                }}
                type="accessGroup"
              />
            </InputLayout>
          </SmallFormLayout>
          <SpaceBetween>
            <Button
              variant="primary"
              onClick={onCreateAccessGroup}
              disabled={groupName === "" || isSubmitting}
            >
              Next : Add Members
            </Button>
            <Button
              layoutVariant="outlined"
              variant="primary"
              onClick={() => setStep(3)}
            >
              Skip
            </Button>
          </SpaceBetween>
        </>
      )}
      {isAddTeam && <SetYourTeamMember setStep={setStep} groupId={groupId} />}
    </Section>
  );
};

function isEmailValid(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}

const SetYourTeamMember: React.FC<{
  setStep: any;
  groupId: number | undefined;
}> = ({ setStep, groupId }) => {
  const [emails, setEmails] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const { data: me } = useCurrentUser();

  return (
    <Section>
      <SmallFormLayout>
        <InputLayout>
          <FormInput type="text" label="INVITE BY EMAIL" ref={inputRef} />
        </InputLayout>
        <Button
          variant="primary"
          onClick={() => {
            const v = inputRef.current?.value;
            if (!v) {
              return;
            }

            if (!isEmailValid(v)) {
              alert("Please put correct email address");
              return;
            }

            setEmails([...emails, inputRef.current.value]);
            inputRef.current.value = "";
          }}
        >
          ADD TO INVITE LIST
        </Button>
      </SmallFormLayout>
      <Section>
        {emails.map((email, i) => (
          <UserBadge key={i} name={email} />
        ))}
        {emails.length > 0 && (
          <Button
            variant="primary"
            onClick={async () => {
              if (!me) {
                return;
              }
              try {
                await request.post("/users/invite", {
                  inviterEmail: me.email,
                  inviterName: me.name,
                  joinSiteUrl: `${window.location.origin}/join`,
                  targetUsers: emails.map((targetMail) => ({ targetMail })),
                });
                alert("Invite successful!");
                setEmails([]);
                setStep(3);
              } catch (e) {}
            }}
          >
            SEND INVITES
          </Button>
        )}
      </Section>
      <Section>
        <Button variant="primary" onClick={() => setStep(3)}>
          SKIP
        </Button>
      </Section>
    </Section>
  );
};

const SetCluster: React.FC<{ setStep: any }> = ({ setStep }) => {
  return (
    <Section>
      <TitleSection>
        <h2>2. CREATE NEW CLUSTER</h2>
      </TitleSection>
      <ClusterForm
        onFormUpdate={(opts: any) => {}}
        comeBackLaterEnabled={false}
      />
      <Button
        layoutVariant="outlined"
        variant="primary"
        onClick={() => setStep(2)}
      >
        COME BACK LATER
      </Button>
    </Section>
  );
};

const StartUsing = () => {
  return (
    <Section>
      <x.div h="50vh">
        <CenterCenter>
          <h1>You are all set!</h1>
          <Section>
            <HorizontalCenter>
              <Button
                layoutVariant="outlined"
                variant="primary"
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                Start using NexClipper
              </Button>
            </HorizontalCenter>
          </Section>
        </CenterCenter>
      </x.div>
    </Section>
  );
};

const Onboarding: React.FC<{ setShowOnboarding: any }> = ({
  setShowOnboarding,
}) => {
  const { tenancy } = useCurrentUser();

  const [step, setStep] = useState(
    !tenancy?.owner || !tenancy?.activated ? 0 : 1
  );
  const ownerProgress = [
    "1. Company Information",
    "2. Your First Cluster",
    "3. Your Team",
    "4. Done",
  ];

  const userProgress = ["1. Personal Information", "2. Done"];

  return (
    <Page>
      <TitleSection>
        <OnboardingTitle>LET'S GET YOU STARTED</OnboardingTitle>
      </TitleSection>
      <Section>
        <ProgressBar
          progress={tenancy?.owner ? ownerProgress : userProgress}
          step={step}
          setStep={setStep}
        />
      </Section>
      {tenancy?.owner && (
        <>
          {step === 0 && (
            <SetCompanyInfo
              setStep={setStep}
              setShowOnboarding={setShowOnboarding}
            />
          )}
          {step === 1 && <SetCluster setStep={setStep} />}
          {step === 2 && <SetYourTeam setStep={setStep} />}
          {step === 3 && <StartUsing />}
        </>
      )}
    </Page>
  );
};

function Welcome() {
  const [showOnboarding, setShowOnboarding] = useState(false);

  return (
    <>
      {!showOnboarding && (
        <FixContainer>
          <Container>
            <LogoImg />
            <WelcomeTitle>Welcome NexClipper Cloud</WelcomeTitle>
            <SubTitle>Let's get you started</SubTitle>
            <Content>
              For NexClipper Agent to install successfully, make sure you have
              <br />
              enabled privileged admin within your Kubernetes cluster.
            </Content>
            <Link to="https://nexclipper.github.io/docs/" openNewWindow={true}>
              <x.span fontSize="1.1em" mb="30px">
                Learn more about NexClipper Cloud
              </x.span>
            </Link>
            <br />
            <Button
              expand
              variant="primary"
              onClick={() => {
                setShowOnboarding(true);
              }}
            >
              Start NexClipper
            </Button>
          </Container>
        </FixContainer>
      )}
      {showOnboarding && <Onboarding setShowOnboarding={setShowOnboarding} />}
    </>
  );
}

export default Welcome;
