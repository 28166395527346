import { forwardRef, InputHTMLAttributes } from "react";
import styled from "@emotion/styled";
import { x } from "@xstyled/emotion";

import Search from "../assets/Search_Icon.svg";

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  searchIcon?: boolean;
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  label {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 21px;
    color: #374151;
    font-family: poppins;
  }

  input {
    width: 100%;
    color: #111827;
    font-size: 16px;
    line-height: 28px;
    border: 1px solid #9ca3af;
    border-radius: 6px;
    padding: 10px 16px;
    font-family: Roboto;
    transition: border-color 0.75s;

    &:active,
    &:focus {
      outline: 0;
      border-color: #1f29ca;
    }
  }
`;

const FormInput = forwardRef<HTMLInputElement, IInput>(
  ({ children, label, searchIcon, ...props }, ref) => {
    return (
      <Wrapper>
        {label && <label>{label}</label>}
        {searchIcon && (
          <x.img
            src={Search}
            w="25px"
            h="25px"
            position="absolute"
            top="12px"
            right="19px"
          />
        )}
        <input ref={ref} {...props} />
      </Wrapper>
    );
  }
);

export default FormInput;
