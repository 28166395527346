import { useQuery } from "react-query";
import type { AxiosError } from "axios";
import { QUERY_KEYS } from "query";

import { request } from "api/common";

const EXPORTERHUB_API_DOMAIN = "https://exporterhub.io:8000";

export const useExporters = () => {
  return useQuery<Exporter[], AxiosError>(
    ["userGroups"],
    async () => {
      const { data } = await request.get<{
        exporters: Exporter[];
      }>(`${EXPORTERHUB_API_DOMAIN}/exporter`);
      return data.exporters;
    },
    {
      cacheTime: 30 * 1000,
    }
  );
};

interface Exporter {
  category: string;
  description: string;
  exporter_id: number;
  is_bucket: boolean;
  is_new: boolean;
  is_star: boolean;
  logo_url: string;
  name: string;
  official: string;
  repository_url: string;
  stars: number;
}

export const useServiceList = (clusterId: number) => {
  return useQuery<ServiceListResponse, AxiosError>(
    QUERY_KEYS.serviceList(clusterId),
    async () => {
      const { data } = await request.get<ServiceListResponse>(
        `/lari/clusters/${clusterId}/services`
      );
      return data;
    },
    {
      cacheTime: 30 * 1000,
    }
  );
};

interface ServiceListResponse {
  [serviceKey: string]: ClusterService;
}

export interface ClusterService {
  clusterId: number;
  groupId: number;
  id: number;
  labels: [
    {
      createdAt: string;
      createdBy: number;
      deleted: boolean;
      enable: boolean;
      id: number;
      label: string;
      name: string;
      new: boolean;
      serviceId: number;
      updatedAt: string;
      updatedBy: number;
    }
  ];
  name: string;
  namespace: string;
  ports: [
    {
      createdAt: string;
      createdBy: number;
      deleted: boolean;
      enable: boolean;
      id: number;
      name: string;
      new: boolean;
      nodePort: string;
      port: string;
      protocol: string;
      serviceId: number;
      targetPort: string;
      updatedAt: string;
      updatedBy: number;
    }
  ];
  tenancyId: number;
  type: { description: string };
}

export const useServiceExporters = (clusterId: number, serviceId: number) => {
  return useQuery<Exporter[], AxiosError>(
    QUERY_KEYS.serviceExporters(clusterId, serviceId),
    () => {
      // const { data } = await request.get<Exporter[]>(
      //   `/lari/clusters/${clusterId}/services/${serviceId}/exporters`
      // );
      // return data;
      // FIXME: should wait until exporterhub integration is done
      return [
        {
          title: "NGINX",
          exporterHubKey: "nginx-exporter",
          repoName: "NGINX",
          paramDatas: { port: "{INPUT_PORT}" },
        },
        {
          title: "MySQL",
          exporterHubKey: "mysql-exporter",
          repoName: "MySQL",
          paramDatas: {
            pass: "{INPUT_PASSWORD}",
            port: "{INPUT_PORT}",
            user: "{INPUT_USER}",
          },
        },
      ] as Exporter[];
    },
    {
      cacheTime: 30 * 1000,
    }
  );
};

interface Exporter {
  exporterHubKey: string;
  paramDatas: { port: string; user?: string; pass?: string };
  port: string;
  repoName: string;
  title: string;
}

export interface IClusterAccessGroupListItem {
  desscription: string | null;
  groupName: string;
  id: number;
  icon: string | null;
}

export const useClusterAccessGroups = (clusterId: number) => {
  return useQuery<IClusterAccessGroupListItem[], AxiosError>(
    QUERY_KEYS.clusterAccessGroups(clusterId),
    async () => {
      const { data } = await request.get<IClusterAccessGroupListItem[]>(
        `/lari/clusters/${clusterId}/accessgroups`
      );
      return data;
    },
    {
      cacheTime: 30 * 1000,
    }
  );
};

export const useClusterService = (clusterId: number, serviceId: number) => {
  return useQuery<ClusterService, AxiosError>(
    QUERY_KEYS.clusterService(clusterId, serviceId),
    async () => {
      const { data } = await request.get<ClusterService>(
        `/lari/clusters/${clusterId}/services/${serviceId}`
      );
      return data;
    },
    {
      cacheTime: 30 * 1000,
    }
  );
};

interface IClusterNodesItem {
  creationDateTime: string;
  description: string;
  enabled: boolean;
  id: number;
  name: string;
  nickName: string;
  projectId: string;
  uid: string;
}

export const useClusterNode = (clusterId: number) => {
  return useQuery<IClusterNodesItem[], AxiosError>(
    QUERY_KEYS.clusterNode(clusterId),
    async () => {
      const { data } = await request.get<IClusterNodesItem[]>(
        `/lari/clusters/${clusterId}/nodes`
      );
      return data;
    },
    {
      cacheTime: 30 * 1000,
    }
  );
};
