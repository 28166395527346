import React, { ComponentProps } from "react";
import Copy from "react-copy-to-clipboard";
import { CopyOutlined as CopyOutlinedIcon } from "@ant-design/icons";
import styled from "@emotion/styled/macro";
import { x } from "@xstyled/emotion";

const Wrapper = styled.div`
  width: 100%;
  padding: 15px 20px;
  border-radius: 3px;
  background-color: #e3e4e4;
  color: #353535;
  position: relative;
  border: 1px solid #cccccc;
`;

const Content = styled.pre`
  line-height: 1.2em;
  white-space: break-spaces;
`;

interface IXStyled extends ComponentProps<typeof x.div> {}

const CopyOutlined = styled(CopyOutlinedIcon)`
  cursor: pointer;
  svg {
    font-size: 1.2em;
    color: #1890ff;
  }
`;

interface ICopyToClipboard extends IXStyled {
  text: string;
}

interface IBoardProps {
  content: string;
}

const CopyToClipboard: React.FC<ICopyToClipboard> = ({ text, ...props }) => {
  return (
    <x.div {...props}>
      <Copy
        text={text}
        onCopy={() => {
          window.alert("Copied");
        }}
      >
        <CopyOutlined />
      </Copy>
    </x.div>
  );
};

function Copyboard({ content }: IBoardProps) {
  return (
    <Wrapper>
      <Content>{content}</Content>
      <CopyToClipboard
        text={content}
        position="absolute"
        top="5px"
        right="5px"
      />
    </Wrapper>
  );
}

export default Copyboard;
