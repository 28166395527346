import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface IGauge {
  title?: string;
  icon: React.ReactNode;
  type?: "warning";
  size?: "small";
}

const Wrapper = styled.div<{ type?: "warning"; size?: "small" }>((props) => {
  const { type, size } = props;

  return css`
    width: fit-content;
    margin: 0px 5px 0px 5px;
    font-family: Poppins;

    h3 {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.5px;
      line-height: 18px;
      color: ${type === "warning" ? "#C6005A" : "#4D4D4D"};

      span {
        font-size: 18px;
        margin-right: 5px;
      }
    }

    .child-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 8px 10px;
      width: ${size === "small" ? "87px" : "154px"};
      height: 58px;
      border-radius: 18px;
      background-color: ${type === "warning" ? "#FBD7E0" : "#F9FAFB;"};
    }
  `;
});

const Gauge: React.FC<IGauge> = ({ children, icon, title, type, size }) => {
  return (
    <Wrapper type={type} size={size}>
      <h3>
        <span>{icon}</span>
        {title}
      </h3>
      <div className="child-wrapper">{children}</div>
    </Wrapper>
  );
};

export default Gauge;
