import { ComponentProps } from "react";
import { x } from "@xstyled/emotion";

interface IXStyled extends ComponentProps<typeof x.div> {}

export const Flex: React.FC<IXStyled> = ({ ...props }) => (
  <x.div display="flex" alignItems="center" {...props} />
);

export const SpaceBetween: React.FC<IXStyled> = ({ ...props }) => (
  <x.div
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    {...props}
  />
);
