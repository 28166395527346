import React, { useState } from "react";
import {
  DeleteOutlined,
  ExclamationCircleTwoTone,
  ExportOutlined,
  SettingOutlined,
  TableOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
// import { fromJS } from "immutable";
import { Button, Flex, Link, Switch } from "@nexcloud/nc-ui";
import styled, { x } from "@xstyled/emotion";
import {
  Button as AntdButton,
  Checkbox,
  Drawer,
  message,
  Modal,
  Steps,
  Tooltip,
} from "antd";
import { fetchOption } from "config";

import ClusterIconInfo from "components/Cluster/ClusterIconInfo";
import BorderBox from "components/common/BorderBox";
import InstallationDrawer from "components/common/InstallationDrawer";

import {
  IGlobalView,
  IOverAllStatus,
  IP8SService,
  IZone,
} from "store/redux/zone";
import { useClustersStatus } from "hooks/cluster";
import { useDeleteGlobalView, useGlobalView } from "hooks/cluster/globalView";
import { useUpdateCurrentCluster } from "hooks/cluster/useClusterDetailQuery";
import useClusterList from "hooks/cluster/useClusterList";
import { useZoneGet } from "hooks/state/get";
import useZoneActions from "hooks/state/useZoneActions";
// import { useCurrentUser } from "hooks/user";
import useInterval from "hooks/util/useInterval";
import useLocalStorage from "hooks/util/useLocalStorage";

// import { prdCode, request } from "api/common";
import { DeleteScript } from "../DeleteCluster";
import { ExposeModal } from "../P8sButtons";

import CardClusters from "./CardClusters";
import GlobalViewBox from "./GlobalViewBox";
import { DashboardInfo } from "./shared";
import TableClusters from "./TableClusters";

import { ReactComponent as Bell } from "assets/icon/bell.svg";
import { ReactComponent as Cube } from "assets/icon/cube.svg";
import { ReactComponent as Hexagon } from "assets/icon/hexagon.svg";
import { ReactComponent as Layer } from "assets/icon/layer.svg";
import { ReactComponent as Rectangle } from "assets/icon/rectangle.svg";
import { device } from "style/device";

const Container = styled.div`
  width: 80%;
  margin: auto;
  max-width: 1200px;

  @media ${device.tablet} {
    width: 95%;
  }
`;

const SubTitle = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: gray-600;
  @media ${device.tablet} {
    flex-wrap: wrap;
  }
`;

const StatusList = styled(BorderBox)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;

  @media ${device.tablet} {
    flex-wrap: wrap;
  }
`;

const Status = styled.div`
  width: 20%;
  padding: 20px;
  height: 100px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ${device.tablet} {
    width: 100%;
    margin-bottom: 10px;
    flex: 0.5;
  }
`;

const StatusTitle = styled.div`
  font-size: 1em;
  color: #999999;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const StatusValue = styled.div`
  font-size: 1.4em;
  font-weight: bold;
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
`;

const AlertValue = styled(StatusValue)`
  color: #f4524a;
`;

const SubTitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  min-height: 64px;
`;

const StyledClusterIconInfo = styled.div`
  @media ${device.tablet} {
    width: 100%;
    margin-top: 10px;
  }
`;

const CreateButton = styled.div`
  @media ${device.tablet} {
    width: 100%;
    text-align: right;
    margin-bottom: 10px;
  }
`;

interface IStatusList {
  id: keyof IOverAllStatus;
  title: string;
  icon: any;
  color: string;
}

const statusList: IStatusList[] = [
  {
    id: "zones",
    title: "Zones",
    icon: Rectangle,
    color: "#096dd9",
  },
  {
    id: "clusterGroups",
    title: "Cluster Groups",
    icon: Layer,
    color: "#faad14",
  },
  {
    id: "clusters",
    title: "Clusters",
    icon: Hexagon,
    color: "#1890ff",
  },
  {
    id: "nodes",
    title: "Nodes",
    icon: Cube,
    color: "#52c41a",
  },
];

const GlobalViewInfo = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  return (
    <x.div mr="7px">
      <Tooltip title="About Global View">
        <ExclamationCircleTwoTone onClick={() => setShowDrawer(true)} />
      </Tooltip>
      <Drawer
        title="Global view"
        placement="right"
        closable={false}
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}
      >
        <p>
          Requires a new instance for installing dedicated long-term storage
          cluster with global-view in K3s. It has to be installed in the same
          network with the other clusters for monitoring.
        </p>
        <br />
        <Link
          to="https://nexclipper.github.io/docs/globalview"
          openNewWindow={true}
        >
          Link to Docs
          <x.span ml="7px">
            <ExportOutlined />
          </x.span>
        </Link>
      </Drawer>
    </x.div>
  );
};

const StepsContent = styled.div`
  margin-top: 16px;
  border-radius: 2px;
  background-color: #fafafa;
  text-align: center;
  padding: 15px;
`;

const StepsAction = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;

const Delete: React.FC<{ clusterId?: number }> = ({ clusterId }) => {
  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState(0);
  const [agreed, setAgreed] = useState(false);
  const { mutateAsync: deleteGlobalView, isLoading } = useDeleteGlobalView();

  const steps = [
    {
      title: "Notice",
      content: (
        <>
          <x.p pb="20px">
            This action cannot be undone. This will{" "}
            <x.span color="red-500" display="inline">
              permanently delete
            </x.span>{" "}
            your agent and remove all configurations.
          </x.p>
          <Checkbox
            onChange={(e) => {
              setAgreed(e.target.checked);
            }}
            checked={agreed}
          >
            Agree
          </Checkbox>
        </>
      ),
    },
    {
      title: "Clean up script",
      content: <DeleteScript clusterId="global" />,
    },
  ];

  const resetState = () => {
    setShowModal(false);
    setStep(0);
    setAgreed(false);
  };

  return (
    <x.div ml="7px">
      <Tooltip title="Delete Global View">
        <DeleteOutlined
          style={{ color: "#444" }}
          onClick={() => setShowModal(true)}
        />
      </Tooltip>
      {showModal && (
        <Modal visible={showModal} centered footer={null} onCancel={resetState}>
          <Steps current={step} style={{ paddingRight: "40px" }}>
            {steps.map((item, i) => (
              <Steps.Step key={i} title={item.title} />
            ))}
          </Steps>
          <StepsContent>{steps[step].content}</StepsContent>
          <StepsAction>
            {step === 0 && agreed && (
              <AntdButton
                type="primary"
                danger
                onClick={() => {
                  setStep(step + 1);
                }}
              >
                OK
              </AntdButton>
            )}
            {step === 1 && (
              <x.div display="flex">
                <AntdButton
                  danger
                  onClick={async () => {
                    if (!clusterId || isLoading) {
                      return;
                    }
                    if (
                      !window.confirm(
                        "Please run force delete on this cluster only when the cleanup script excution fails."
                      )
                    ) {
                      return;
                    }
                    try {
                      await deleteGlobalView({ id: clusterId });
                      message.success("Cluster deleted.");
                    } catch (e) {
                      message.error(
                        "Failed to delete cluster. Please try again later."
                      );
                    }
                  }}
                  style={{
                    marginRight: "10px",
                  }}
                >
                  Force Delete
                </AntdButton>
                <AntdButton type="primary" onClick={resetState}>
                  Done
                </AntdButton>
              </x.div>
            )}
          </StepsAction>
        </Modal>
      )}
    </x.div>
  );
};

const ACTIVE_EXPOSE_CHECK_INTERVAL = 3 * 1000;
const IDLE_EXPOSE_CHECK_INTERVAL = 5 * 1000;

function OverallDashboard() {
  const [defaultView, setDefaultView] = useLocalStorage("defaultView", false);
  const [showGlobalView, setShowGlobalView] = useLocalStorage(
    "showGlobalView",
    false
  );
  const { data: globalViewCluster } = useGlobalView();
  const { data: _clusterList } = useClusterList(fetchOption);

  let clusterList: Array<IZone | IGlobalView> = [];
  if (globalViewCluster) {
    clusterList = clusterList.concat(globalViewCluster);
  }
  if (_clusterList) {
    clusterList = clusterList.concat(_clusterList);
  }

  const { data: clustersStatus } = useClustersStatus(fetchOption);
  const zoneActions = useZoneActions();

  useUpdateCurrentCluster();

  const [showCollapBox, setShowCollapBox] = useState(false);
  const [showExposeModal, setShowExposeModal] = useState(false);
  const [exposeServiceType, setExposeServiceType] = useState<
    "ClusterIP" | "NodePort" | "LoadBalancer"
  >("ClusterIP");
  const [exposedCheckInterval, setExposedCheckInterval] = useState<number>(
    IDLE_EXPOSE_CHECK_INTERVAL
  );

  const p8sServices = useZoneGet("p8sServices");
  const serviceObjects = p8sServices?.toJS() as IP8SService[] | undefined;
  const service = serviceObjects?.filter(
    (sv) => sv.serviceName === "GRAFANA"
  )[0];
  const changing = service?.status === "EXPOSE_CHANGING";

  useInterval(
    () => {
      const clusterId = globalViewCluster?.id;
      if (clusterId) {
        zoneActions.onGetP8SServices(clusterId);
      }

      if (!p8sServices) {
        return;
      }

      if (changing) {
        setExposedCheckInterval(IDLE_EXPOSE_CHECK_INTERVAL);
      }
    },
    exposedCheckInterval,
    true
  );

  const display = changing
    ? `Exposing...`
    : service?.serviceType
    ? "Change expose type"
    : "Change expose type - Loading...";

  // const { data: user } = useCurrentUser();

  // useEffect(() => {
  // request
  //   .post(`/notis/plugin/user/${user?.id}`, {
  //     tenancyId: user?.currentTenancy.tenancyId,
  //     prdCode,
  //   })
  //   .then((response) => {
  //     console.log(response.data);
  //   });
  // request
  //   .post(`/notis/note/msg`, {
  //     data: JSON.stringify({ key: "value" }),
  //     eventCode: "MSG_POST",
  //     sendType: "EMAIL",
  //     senderInfoType: "USERID",
  //     sender: user?.id,
  //     senderName: "보낸사람이름(opt.)",
  //     recieverInfoType: "EMAIL",
  //     reciever: "jian@nexclipper.io",
  //     title: "제목제목(opt.)",
  //     text: "본문본문(opt.)",
  //   })
  //   .then((response) => {
  //     console.log(response.data);
  //   });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Container>
      <DashboardInfo borderless>
        <SubTitleBox>
          <x.div display="flex" alignItems="center">
            <SubTitle>Global view</SubTitle>
            <Flex ml="7px">
              <GlobalViewInfo />
              {globalViewCluster?.grafanaUrl ? (
                <>
                  <x.div mr="7px">
                    <Tooltip title={display}>
                      <SettingOutlined
                        style={{ color: changing ? "#999" : "#444" }}
                        onClick={() => {
                          if (!changing && service?.serviceType) {
                            setExposeServiceType(service.serviceType);
                            setShowExposeModal(true);
                          }
                        }}
                      />
                    </Tooltip>
                  </x.div>
                  <Tooltip title="To Grafana">
                    <Link
                      to={"http://" + globalViewCluster.grafanaUrl}
                      openNewWindow={true}
                    >
                      <ExportOutlined />
                    </Link>
                  </Tooltip>
                </>
              ) : null}
              {globalViewCluster?.id ? (
                <Delete clusterId={globalViewCluster.id} />
              ) : null}
            </Flex>
          </x.div>
          <Switch
            checked={showGlobalView}
            onChange={() => {
              setShowGlobalView(!showGlobalView);
            }}
          />
        </SubTitleBox>
        {showGlobalView && <GlobalViewBox />}
        {globalViewCluster ? (
          <ExposeModal
            clusterId={globalViewCluster.id}
            showingModal={showExposeModal ? "grafana" : null}
            exposeServiceType={exposeServiceType}
            setExposeServiceType={setExposeServiceType}
            onSuccess={() => {
              zoneActions.onGetP8SServices(globalViewCluster.id);
              setExposedCheckInterval(ACTIVE_EXPOSE_CHECK_INTERVAL);
            }}
            onCancel={() => {
              setShowExposeModal(false);
            }}
          />
        ) : null}
      </DashboardInfo>

      <DashboardInfo>
        <SubTitleBox>
          <SubTitle>Status</SubTitle>
        </SubTitleBox>
        <StatusList>
          {statusList.map((status) => (
            <Status key={status.id}>
              <StatusTitle>
                <status.icon
                  style={{
                    width: "17px",
                    height: "17px",
                    fill: status.color,
                    marginRight: "10px",
                  }}
                />
                {status.title}
              </StatusTitle>
              <StatusValue>{clustersStatus?.[status.id] || 0}</StatusValue>
            </Status>
          ))}
          <Status>
            <StatusTitle>
              <Bell
                style={{
                  width: "17px",
                  height: "17px",
                  fill: "#f4524a",
                  marginRight: "10px",
                }}
              />
              Firing Alerts
            </StatusTitle>
            <AlertValue>{clustersStatus?.firingAlerts || 0}</AlertValue>
          </Status>
        </StatusList>
      </DashboardInfo>

      <DashboardInfo>
        <SubTitleBox>
          <SubTitle>Clusters</SubTitle>
          <x.div display="flex">
            <Switch
              leftLabel={<UnorderedListOutlined />}
              rightLabel={<TableOutlined />}
              checked={defaultView}
              onChange={() => {
                setDefaultView(!defaultView);
              }}
            />
            <CreateButton>
              <Button
                variant="primary"
                onClick={() => {
                  setShowCollapBox(true);
                }}
              >
                Create Cluster
              </Button>
              {showCollapBox && (
                <InstallationDrawer
                  showCollapBox={showCollapBox}
                  onClose={() => {
                    setShowCollapBox(false);
                  }}
                />
              )}
            </CreateButton>
          </x.div>
        </SubTitleBox>
        {defaultView ? (
          <CardClusters data={clusterList} />
        ) : (
          <TableClusters data={clusterList} />
        )}
        <x.div display="flex" justifyContent="space-between" py="10px">
          <StyledClusterIconInfo>
            <ClusterIconInfo />
          </StyledClusterIconInfo>
        </x.div>
      </DashboardInfo>
    </Container>
  );
}

export default OverallDashboard;
