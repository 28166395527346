import React from "react";
import { Button, Link } from "@nexcloud/nc-ui";

import { baseURL, prdCode } from "api/common";

const GoogleForm: React.FC<{
  verificationCode: string | null;
  tenancyId: number | undefined;
}> = ({ verificationCode, tenancyId }) => {
  return (
    <Link
      to={`${baseURL}/login/oauth2/authorization/google?prdCode=${prdCode}&redirectUrl=${
        window.location.origin
      }${verificationCode ? `&verificationCode=${verificationCode}` : ""}${
        tenancyId ? `&tenancyId=${tenancyId}` : ""
      }`}
      openNewWindow={false}
    >
      <Button expand icon="google">
        Sign in with Google
      </Button>
    </Link>
  );
};

export default GoogleForm;
