import styled from "@emotion/styled/macro";

import { Platform } from "store/redux/create";
import { platformList } from "store/redux/zone/reducer";

interface IPlatformIconProps {
  styles?: any;
  id: Platform;
  type: "icon" | "text";
}

const TypeBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconBox = styled.div`
  width: 20px;
  height: 20px;

  img {
    width: 100%;
  }
`;

const Type = styled.div`
  margin-left: 7px;
  color: #8a8a8a;
`;

function PlatformIcon({ styles, id, type }: IPlatformIconProps) {
  const platform = platformList.find((p) => p.get("id") === id) ?? null;

  if (!platform) {
    return null;
  }

  if (type === "icon") {
    return (
      <IconBox style={{ ...styles }}>
        <img src={platform.get("icon")} alt="icon" />
      </IconBox>
    );
  }
  return (
    <TypeBox style={{ ...styles }}>
      <IconBox>
        <img src={platform.get("icon")} alt="icon" />
      </IconBox>
      <Type>{platform.get("title")}</Type>
    </TypeBox>
  );
}

export default PlatformIcon;
