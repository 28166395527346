import { Map } from "immutable";
import { createReducer } from "typesafe-actions";

import { RESET_CONFIG, SET_DATA } from "./actions";
import { CreateAction, CreateState } from "./types";

const initialState: CreateState = Map({
  zoneName: "",
  platform: "",
  loading: false,
  install: "",
  description: "",
  zoneInfo: null,
});

const reducer = createReducer<CreateState, CreateAction>(initialState, {
  [SET_DATA]: (state, action) => {
    const { key, value } = action.payload;
    return state.set(key, value);
  },
  [RESET_CONFIG]: (state, action) => {
    return state
      .set("zoneName", "")
      .set("platform", "")
      .set("install", "")
      .set("description", "")
      .set("zoneInfo", null);
  },
});
export default reducer;
