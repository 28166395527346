import React, { useState } from "react";
import { FiSettings } from "react-icons/fi";
import { GiConverseShoe } from "react-icons/gi";
import { MdComputer } from "react-icons/md";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled/macro";

import WorkspaceDrawer from "components/OrganizationDrawer";

import { useCurrentUser } from "hooks/user";

import UserMenu from "./UserMenu";

import { ReactComponent as Football } from "assets/icon/football.svg";
import { ReactComponent as NexClipperBlueLogo } from "assets/icon/NexClipperBlueLogo.svg";
import { ReactComponent as SmallBell } from "assets/icon/smallBell.svg";
import { ReactComponent as SmallBox } from "assets/icon/smallBox.svg";
import { ReactComponent as SmallFireExtinguisher } from "assets/icon/smallFireExtinguisher.svg";
import { device } from "style/device";

const UserNameWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  margin-right: 15px;
  margin-left: 15px;
`;

const NavButtonFill = styled.div``;
const NavButtonStroke = styled.div``;

const SettingsIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  * {
    font-size: 22px;
    color: #d1d5db;
  }
`;

const NavigationWrapper = styled.div`
  height: 61px;
  background-color: #fff;
  display: flex;
  align-items: center;
  box-shadow: 0px 8px 15px rgba(149, 156, 182, 0.09);
  padding: 0px 15px 3px 20px;
  z-index: 1000;
  position: relative;
  width: 100%;
`;

const NavigationItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  svg {
    width: 14px;
    overflow: visible !important;
    transition: transform 0.5s;
  }

  a.active {
    .btn-wrapper {
      background-color: #1f29ca;
      color: #fff;
      svg {
        transform: scale(4) translateX(-35%);
        opacity: 0.25;
      }

      .fill {
        * {
          fill: #fff;
        }
      }

      .stroke {
        * {
          stroke: #fff;
        }
      }
    }
  }

  .btn-wrapper {
    border-radius: 5px;
    overflow: hidden;
    transition: background-color 0.5s;
    &:hover {
      background-color: #1f2aca15;
    }

    .fill {
      * {
        fill: #1f29ca;
      }
    }

    .stroke {
      * {
        stroke: #1f29ca;
      }
    }
  }
`;

const LogoBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  left: 30px;
  a {
    display: block;
    padding-top: 2px;
    min-width: 182px;
    user-select: none;
  }

  @media ${device.mobileL} {
    padding-right: 0px;
    padding-left: 15px;

    a {
      font-size: 1em;
      min-width: 100px;
    }
  }
`;

const LogoBoxContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  svg {
    font-size: 1.3em;
    color: #ffffff;
  }
`;

const OrganizationName = styled.div`
  font-size: 16px;
  font-family: "Poppins";
  margin-left: 15px;
`;

const NavButtonWrapper = styled.div`
  font-family: "Poppins";
  display: flex;
  font-size: 14px;
  color: #1f29ca;
  padding: 15px 22px;

  .wide-icon {
    width: 16px;
    margin-top: -1px;
  }

  * {
    margin-right: 5px;
  }
`;

const RightMenu = styled.div`
  display: flex;
  position: absolute;
  right: 10px;

  a.active {
    .setting-icon {
      * {
        color: #1f29ca;
      }
    }
  }
`;

const Header: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const { data: user, showOnboarding } = useCurrentUser();
  const history = useHistory();
  return (
    <NavigationWrapper>
      <LogoBox onClick={() => history.push("/")}>
        <NexClipperBlueLogo />
        <LogoBoxContent
          onClick={() => {
            setShowDrawer(true);
          }}
        >
          <OrganizationName>
            {user?.currentTenancy?.tenancyName}
          </OrganizationName>
        </LogoBoxContent>
      </LogoBox>
      {showOnboarding !== true && (
        <NavigationItemsWrapper>
          <NavLink to="/" activeClassName="active" exact>
            <NavButtonWrapper className="btn-wrapper">
              <NavButtonFill>
                <SmallBox className="fill" />
              </NavButtonFill>
              DASHBOARD
            </NavButtonWrapper>
          </NavLink>
          <NavLink to="/alerts" activeClassName="active">
            <NavButtonWrapper className="btn-wrapper">
              <NavButtonFill>
                <SmallBell className="fill" />
              </NavButtonFill>
              ALERTS
            </NavButtonWrapper>
          </NavLink>
          <NavLink to="/incident" activeClassName="active">
            <NavButtonWrapper className="btn-wrapper">
              <NavButtonStroke>
                <SmallFireExtinguisher className="stroke" />
              </NavButtonStroke>
              INCIDENTS
            </NavButtonWrapper>
          </NavLink>
          <NavLink to="/access-groups" activeClassName="active">
            <NavButtonWrapper className="btn-wrapper">
              <NavButtonStroke>
                <Football className="fill wide-icon" />
              </NavButtonStroke>
              TEAM
            </NavButtonWrapper>
          </NavLink>
          <NavLink to="/operations" activeClassName="active">
            <NavButtonWrapper className="btn-wrapper">
              <NavButtonStroke>
                <GiConverseShoe className="fill wide-icon" />
              </NavButtonStroke>
              OPERATION
            </NavButtonWrapper>
          </NavLink>
          {process.env.NODE_ENV === "development" && (
            <NavLink to="/dev" activeClassName="active">
              <NavButtonWrapper className="btn-wrapper">
                <NavButtonStroke>
                  <MdComputer className="fill wide-icon" />
                </NavButtonStroke>
                DEV
              </NavButtonWrapper>
            </NavLink>
          )}
        </NavigationItemsWrapper>
      )}
      <RightMenu>
        {showOnboarding !== true && (
          <NavLink to="/settings">
            <SettingsIconWrapper>
              <FiSettings className="setting-icon" />
            </SettingsIconWrapper>
          </NavLink>
        )}
        <UserNameWrapper>{user?.name}</UserNameWrapper>
        <UserMenu />
      </RightMenu>
      <WorkspaceDrawer
        showDrawer={showDrawer}
        onClose={() => {
          setShowDrawer(false);
        }}
      />
    </NavigationWrapper>
  );
};

export default Header;
