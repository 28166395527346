import { createGlobalStyle } from "@xstyled/emotion";

import reset from "./reset";

export default createGlobalStyle`
  ${reset}

  html body {
    font-family: globalFont;
    font-weight: 400;
    line-height: 1;
    color: #0a0a0a;
    h1 {
      font-size: 36px;
      font-weight: 400;
      font-family: "poppins";
      margin-bottom: 15px;
    }
    h2{
      font-size: 24px;
      font-weight:500;
      line-height:32px;
      font-family: "poppins";
      color: #4D4D4D;
      margin-bottom: 15px;
    }
    h3{
      font-size: 18px;
      font-weight: 600;
      line-height:24px;
      font-family: "poppins";
      color: #4D4D4D;
      margin-bottom: 15px;
    }
    h4,h5,h6 {
      font-family: "poppins";
      margin-bottom: 15px;
    }
  }

  p {
    margin-bottom: 15px;
    font-size: 16px;
  }
`;
