import React from "react";
import { Button, Section, Tab, TitleSection } from "@nexcloud/nc-ui";
import Drawer from "antd/es/drawer";

import ImageTab from "./ImageTab";
import UploadTab from "./UploadTab";

const AddImageDrawer: React.FC<{
  setShowImageModal: any;
  visible: boolean;
  onClose(): void;
  options: any;
}> = ({ visible, onClose, options, setShowImageModal }) => {
  return (
    <Drawer visible={visible} onClose={onClose} width="80%">
      <TitleSection>
        <h2>Images</h2>
        <Button
          variant="primary"
          onClick={() => {
            setShowImageModal(false);
          }}
        >
          DONE
        </Button>
      </TitleSection>
      <Section>
        <Tab
          panes={[
            ["Your Image", ImageTab],
            ["Upload", UploadTab],
          ]}
        />
      </Section>
    </Drawer>
  );
};

export default AddImageDrawer;
