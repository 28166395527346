import styled from "@emotion/styled";

const Component: React.FC = ({ children, ...props }) => {
  return <Elem>{children}</Elem>;
};

export default Component;

const Elem = styled.div`
  margin: 0px 0px 21px 0px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between; // start center space-between space-around space-evenly;
  align-items: center; // flex-start, flex-end, center, baseline, stretch
`;
