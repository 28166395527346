import React from "react";
import styled from "@emotion/styled/macro";

import { useOverallAlert } from "hooks/cluster/alert";
import { useStatusGet } from "hooks/state/get";

import { device } from "style/device";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;

  @media ${device.mobileL} {
    margin: 10px 0;
    width: 100%;
    justify-content: center;
  }
`;

const StatusBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const Status = styled.div`
  background-color: ${({ color }: { color: string }) => color};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
`;

const StatusTitle = styled.div`
  color: ${({ color }: { color: string }) => color};
  font-weight: bold;
  margin: 0 5px;
`;

function StatusIcon() {
  const intervalTime = useStatusGet("intervalTime");

  const { data: alertStatus } = useOverallAlert({
    refetchInterval: intervalTime,
  });

  return (
    <Container>
      <StatusBox>
        <Status color="#096dd9">{alertStatus?.inactiveAlerts ?? 0}</Status>
        <StatusTitle color="#737373">Inactive</StatusTitle>
      </StatusBox>
      <StatusBox>
        <Status color="#faad14">{alertStatus?.pendingAlerts ?? 0}</Status>
        <StatusTitle color="#737373">Pending</StatusTitle>
      </StatusBox>
      <StatusBox>
        <Status color="#f4524a">{alertStatus?.firingAlerts ?? 0}</Status>
        <StatusTitle color="#737373">Firing</StatusTitle>
      </StatusBox>
    </Container>
  );
}

export default StatusIcon;
