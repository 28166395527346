import React from "react";
import { AiFillStar } from "react-icons/ai";
import styled from "@emotion/styled";
import { Button } from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";

interface IExporterCard {
  name: string;
  logoUrl: string;
  stars: number;
  category: string;
  official: boolean;
  description: string;
  selected?: boolean;
  onClick?(): void;
}

const Wrapper = styled.div<{ selected: boolean }>`
  width: 200px;
  height: 320px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #eee;
  cursor: pointer;
  position: relative;
  ${({ selected }) => (selected ? "border: 1px solid gray;" : "")}
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: #000;
  }
  div {
    color: gray;
    margin-bottom: 20px;
    span {
      &:first-child {
        margin-right: 20px;
      }
      font-weight: 400;
      font-size: 14px;
    }
  }
  p {
    font-size: 14px;
    line-height: 1.2;
    height: 2.4em;
    overflow: hidden;
    color: #000;
  }
`;
const Icon = styled.span`
  vertical-align: middle;
`;

const ExporterCard: React.FC<IExporterCard> = ({
  name,
  logoUrl,
  category,
  official,
  stars,
  description,
  selected,
  onClick,
}) => {
  return (
    <Wrapper selected={!!selected} onClick={onClick}>
      <x.div w="100%" fontSize="12px">
        <x.span color="#000" display="flex" justifyContent="flex-end">
          <Icon>
            <AiFillStar />
          </Icon>
          {stars}
        </x.span>
      </x.div>
      <div>
        <div>
          <x.img w="100px" mb="15px" mx="auto" src={logoUrl} alt={name} />
        </div>
        <Section>
          <span>{name}</span>
          <div>
            <span>{official ? "Officical" : "Unofficial"}</span>
            <span>{category}</span>
          </div>
          <p>{description}</p>
        </Section>
      </div>
      <x.div bottom="0">
        <Button onClick={onClick}>INSTALL</Button>
      </x.div>
    </Wrapper>
  );
};

export default ExporterCard;
