import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface IGaugeItem {
  value?: number;
  unit?: string;
  unitPlural?: string;
  type?: "warning";
}

const GaugeItem: React.FC<IGaugeItem> = ({ value, unit, unitPlural, type }) => {
  return (
    <Item type={type}>
      <span>{value}</span>
      {unit && (
        <sub>
          {unitPlural ? (value && value <= 1 ? unit : unitPlural) : unit}
        </sub>
      )}
    </Item>
  );
};

const Item = styled.div<{ type?: "warning"; unit?: any }>((props) => {
  const { type, unit } = props;

  return css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    span {
      font-size: 28px;
      font-weight: 500;
      line-height: 42px;
      letter-spacing: -0.5px;
      margin-right: 4px;
    }
    sub {
      font-size: 12px;
      font-weight: 500px;
      line-height: 18px;
      letter-spacing: -0.5px;
      margin-right: 10px;
    }

    ${type === "warning" &&
    `
     color: #C6005A;
    `}

    ${!unit &&
    `
    span {
      text-align: center;
      width: 100%;
    }
    `}
  `;
});

export default GaugeItem;
