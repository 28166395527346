import {
  FaBroom,
  FaBrush,
  FaBullhorn,
  FaBusAlt,
  FaCalculator,
  FaCalendar,
  FaCameraRetro,
  FaCampground,
  FaCarBattery,
  FaCarSide,
  FaCat,
  FaChartBar,
  FaCheckCircle,
  FaChessBoard,
  FaChessKnight,
  FaChild,
  FaCode,
  FaCoffee,
  FaCog,
  FaCoins,
  FaCreditCard,
  FaCrow,
  FaCrown,
  FaDatabase,
  FaDice,
  FaDog,
  FaDove,
  FaDrum,
  FaEgg,
  FaEraser,
  FaFilm,
} from "react-icons/fa";
import {
  HiChevronDoubleDown,
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiChevronDoubleUp,
} from "react-icons/hi";

const Icon = [
  {
    value: "FaBroom",
    icon: <FaBroom />,
  },
  {
    value: "FaBrush",
    icon: <FaBrush />,
  },
  {
    value: "FaBullhorn",
    icon: <FaBullhorn />,
  },
  {
    value: "FaBusAlt",
    icon: <FaBusAlt />,
  },
  {
    value: "FaCalculator",
    icon: <FaCalculator />,
  },
  {
    value: "FaCalendar",
    icon: <FaCalendar />,
  },
  {
    value: "FaCameraRetro",
    icon: <FaCameraRetro />,
  },
  {
    value: "FaCampground",
    icon: <FaCampground />,
  },
  {
    value: "FaCarSide",
    icon: <FaCarSide />,
  },
  {
    value: "FaCarBattery",
    icon: <FaCarBattery />,
  },
  {
    value: "FaChartBar",
    icon: <FaChartBar />,
  },
  {
    value: "FaCheckCircle",
    icon: <FaCheckCircle />,
  },
  {
    value: "FaChessKnight",
    icon: <FaChessKnight />,
  },
  {
    value: "FaChild",
    icon: <FaChild />,
  },
  {
    value: "FaChessBoard",
    icon: <FaChessBoard />,
  },
  {
    value: "FaCat",
    icon: <FaCat />,
  },
  {
    value: "FaCoins",
    icon: <FaCoins />,
  },
  {
    value: "FaCog",
    icon: <FaCog />,
  },
  {
    value: "FaCode",
    icon: <FaCode />,
  },
  {
    value: "FaCoffee",
    icon: <FaCoffee />,
  },
  {
    value: "FaCreditCard",
    icon: <FaCreditCard />,
  },
  {
    value: "FaCrow",
    icon: <FaCrow />,
  },
  {
    value: "FaCrown",
    icon: <FaCrown />,
  },
  {
    value: "FaDatabase",
    icon: <FaDatabase />,
  },
  {
    value: "FaDice",
    icon: <FaDice />,
  },
  {
    value: "FaDog",
    icon: <FaDog />,
  },
  {
    value: "FaDove",
    icon: <FaDove />,
  },
  {
    value: "FaDrum",
    icon: <FaDrum />,
  },
  {
    value: "FaEgg",
    icon: <FaEgg />,
  },
  {
    value: "FaEraser",
    icon: <FaEraser />,
  },
  {
    value: "FaFilm",
    icon: <FaFilm />,
  },
  {
    value: "HiChevronDoubleDown",
    icon: <HiChevronDoubleDown />,
  },
  {
    value: "HiChevronDoubleLeft",
    icon: <HiChevronDoubleLeft />,
  },
  {
    value: "HiChevronDoubleRight",
    icon: <HiChevronDoubleRight />,
  },
  {
    value: "HiChevronDoubleUp",
    icon: <HiChevronDoubleUp />,
  },
];

export default Icon;
