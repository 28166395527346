import styled from "@emotion/styled";

const InputLayout: React.FC = ({ children, ...props }) => {
  return <Layout>{children}</Layout>;
};

export default InputLayout;

const Layout = styled.div`
  width: 100%;
  margin: 10px 0 35px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between; // start center space-between space-around space-evenly;
  align-items: center; // flex-start, flex-end, center, baseline, stretch
`;
