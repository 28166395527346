import { useParams } from "react-router";
import {
  Breadcrumb,
  Button,
  Page,
  Section,
  SpaceBetween,
  Tab,
  TitleSection,
} from "@nexcloud/nc-ui";

import { useAlertListDetail } from "hooks/alert";

import AlertInfoPanel from "./AlertInfoPanel";
import HistoryTab from "./HistoryTab";
import MetricsTab from "./MetricsTab";

const AlertDetail = () => {
  const { alertId } = useParams<{ alertId: string }>();
  const { data: alertDetail } = useAlertListDetail(Number(alertId));

  return (
    <Page>
      <Section>
        <SpaceBetween>
          <Section>
            <Breadcrumb
              title="ALERTS"
              path="/alerts"
              subPathTitle={`NEX-${alertId}`}
              subPath={`/${alertId}`}
            />
            <TitleSection type="withBreadcrumb">
              <h2>{alertDetail?.alertName}</h2>
            </TitleSection>
          </Section>
          <Button
            layoutVariant="outlined"
            variant="primary"
            icon="escalate"
            iconRight
            to={`/incident/add?alertId=${alertId}`}
          >
            ESCALATE AS AN INCIDENT
          </Button>
        </SpaceBetween>
        <AlertInfoPanel alertDetail={alertDetail} />
        <Section>
          <Tab
            panes={[
              ["METRICS", MetricsTab],
              ["HISTORY", HistoryTab],
            ]}
          />
        </Section>
      </Section>
    </Page>
  );
};

export default AlertDetail;
