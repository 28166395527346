import React, { useState } from "react";
import { x } from "@xstyled/emotion";

import { Flex } from "./Flex";

export type TabPane = [string, React.ComponentType<any>, any?];
const Tab: React.FC<{ panes: TabPane[] }> = ({ panes }) => {
  const [selected, setSelected] = useState(0);
  const selectedPane = panes[selected];
  const [, Selected, props] = selectedPane;
  const rendered = <Selected {...props} />;

  return (
    <x.div>
      <Flex borderBottom="1px solid #909AAE">
        {panes.map(([paneTitle], i) => {
          return (
            <x.div
              p="13px 20px"
              cursor="pointer"
              key={i}
              borderBottom={selected === i ? "1px solid" : undefined}
              borderColor="#1F29CA"
              onClick={() => {
                setSelected(i);
              }}
              color={selected === i ? "#1F29CA" : "#909AAE"}
              fontFamily="poppins"
              fontSize="16px"
              fontWeight={selected === i ? "500" : "400"}
              position="relative"
              top={selected === i ? "1px" : undefined}
            >
              {paneTitle}
            </x.div>
          );
        })}
      </Flex>
      {rendered}
    </x.div>
  );
};

export default Tab;
