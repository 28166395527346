import styled from "@emotion/styled";
import { x } from "@xstyled/emotion";

interface IDescription {
  label: string;
}

const Wrapper = styled.div<IDescription>`
  width: 100%;
  font-family: poppins;
  margin-top: 15px;
`;

const DescriptionContent = styled.div`
  min-height: 40px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Description: React.FC<IDescription> = ({ children, label }) => {
  return (
    <Wrapper label={label}>
      <x.p color="#9c9c9c">{label.toUpperCase()}</x.p>
      <DescriptionContent>
        <span>{children}</span>
      </DescriptionContent>
    </Wrapper>
  );
};

export default Description;
