import { FiMoreVertical } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import type { Row } from "react-table";
import { Section, Table } from "@nexcloud/nc-ui";
import styled, { x } from "@xstyled/emotion";

import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "components/common/Dropdown";
import { IAlertListItem, TimeCell } from "components/common/TableCell";

import { useIncidentAction } from "hooks/incident";
import { request } from "api/common";

const IncActionsTab = () => {
  const params = useParams<{ id: string }>();
  const incidentId = Number(params.id);
  const { data: action } = useIncidentAction(incidentId);
  if (!action) {
    return null;
  }
  return (
    <x.div p="20px">
      <Section>
        <Table
          columns={actionListColumn}
          data={action}
          getRowProps={(row: any) => {
            return {
              ...row,
            };
          }}
        />
      </Section>
    </x.div>
  );
};
const Menu = styled.div`
  .menuIcon {
    font-size: 16px;
    color: #909aae;
    cursor: pointer;
  }
`;

const AlertMenu: React.FC<{
  value: string;
  row: Row<IAlertListItem>;
}> = ({ value, row }) => {
  const params = useParams<{ id: string }>();
  const incidentId = Number(params.id);
  const onDelete = async () => {
    try {
      await request.delete(
        `/lari/incidents/${incidentId}/actions/${row.original.id}`
      );
      alert("Successfully deleted the action");
      window.location.reload();
    } catch (e) {
      alert(e.response?.data?.message);
    }
  };
  return (
    <Menu>
      <Dropdown
        placement="left-start"
        trigger="click"
        render={(attrs) => {
          return (
            <DropdownMenu>
              <x.div w="222px" p="12px 14px" pb="30px">
                <DropdownMenuItem
                  icon={<RiDeleteBinLine />}
                  title="Delete"
                  link=""
                  onClick={onDelete}
                />
              </x.div>
            </DropdownMenu>
          );
        }}
      >
        <FiMoreVertical className="menuIcon" />
      </Dropdown>
    </Menu>
  );
};
const actionListColumn = [
  {
    Header: "Time",
    accessor: "time",
    Cell: TimeCell,
  },
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Description",
    accessor: "description",
    disableSortBy: true,
  },
  {
    Header: "",
    accessor: "id",
    Cell: AlertMenu,
    id: "popupMenu",
    disableSortBy: true,
  },
];

export default IncActionsTab;
