import React from "react";
import styled from "@emotion/styled/macro";

const LabelStyle = styled.div`
  margin-bottom: 5px;
  font-size: 0.9em;
  color: #8a8a8a;
`;

interface ILabelProps {
  text: string;
}

function Label({ text }: ILabelProps) {
  return <LabelStyle>{text}</LabelStyle>;
}

export default Label;
