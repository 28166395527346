import { useState } from "react";
import ReactTooltip from "react-tooltip";
import { css } from "@emotion/react";
import styled, { x } from "@xstyled/emotion";

import icons from "./icons";

interface IIconsPerValue {
  [key: string]: any;
}

const iconsPerValue: IIconsPerValue = {};
icons.forEach((i) => {
  iconsPerValue[i.value] = i.icon;
});

//FIXME 타입 재정의

interface IIconSelector {
  label?: string;
  selected?: any;
  onChange?: any;
  initialValue?: any;
  type: "cluster" | "accessGroup";
}

interface IIconDisplay {
  selected: any;
  name?: any;
  type: "cluster" | "accessGroup";
  value?: any;
}

const iconColorType = (type: string, target: "color" | "background") => {
  if (target === "color") {
    switch (type) {
      case "cluster":
        return "#1f29ca";
      case "accessGroup":
        return "#08CE04";
      default:
        return "none";
    }
  }

  if (target === "background") {
    switch (type) {
      case "cluster":
        return "#eefaff";
      case "accessGroup":
        return "#FDFFFC";
      default:
        return "none";
    }
  }
};

const IconBox = styled.div<IIconSelector>((props) => {
  const { type } = props;

  return css`
    width: 40px;
    height: 40px;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #909aae;

    label {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 21px;
      color: #374151;
      font-family: poppins;
    }

    &.selected {
      border: 1px solid ${iconColorType(type, "color")};
      color: ${iconColorType(type, "color")};
      background-color: ${iconColorType(type, "background")};
      border-radius: 5px;
    }

    &:hover {
      background-color: ${iconColorType(type, "background")};
      border-radius: 5px;
      border: 1px solid ${iconColorType(type, "color")};
      color: ${iconColorType(type, "color")};
    }
  `;
});

export const IconSelector: React.FC<IIconSelector> = ({
  initialValue,
  onChange,
  label,
  type,
}) => {
  const [selected, setSelected] = useState(initialValue);

  return (
    <x.div>
      <x.div display="flex" flexDirection="row" flexWrap="wrap">
        {label && <label>{label}</label>}
        {icons.map((item: any, i: any) => {
          return (
            <IconBox
              key={i}
              className={item.value === selected ? "selected" : ""}
              onClick={() => {
                setSelected(item.value);
                onChange(item.value);
              }}
              type={type}
            >
              {item.icon}
            </IconBox>
          );
        })}
      </x.div>
    </x.div>
  );
};

export const IconDisplay: React.FC<IIconDisplay> = ({
  selected,
  name,
  type,
  value,
}) => {
  return (
    <x.div color={type === "cluster" ? "#08CE04" : "#1f29ca"}>
      <p data-tip data-for="displayingIcon">
        {iconsPerValue[selected]}
      </p>

      <ReactTooltip id="displayingIcon" place="top" effect="solid">
        {type === "cluster" ? name : value}
      </ReactTooltip>
    </x.div>
  );
};
