import React, { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";
import styled from "@emotion/styled/macro";

import { IWdigetCommonProps } from "./types";

interface IGaugeProps extends IWdigetCommonProps {}

const Widget = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Title = styled.div`
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`;

const Value = styled.div`
  flex: 1;
  height: 10px;
`;

function Gauge({ title, data, id }: IGaugeProps) {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (!data || data < 0) {
      setPercent(0);
      return;
    }
    const _percent = +data > 1 ? +data / 100 : +data;
    setPercent(_percent);
  }, [data]);

  return (
    <Widget>
      <Title>{title}</Title>
      <Value>
        <GaugeChart
          id={id}
          percent={percent}
          style={{ width: "100%", maxWidth: "280px", margin: "auto" }}
          textColor="#000"
          needleColor="#949494"
          colors={["#38c108", "#F5CD19", "#EA4228"]}
          arcsLength={[0.8, 0.1, 0.1]}
          formatTextValue={(value: any) => {
            return +data === null || +data < 0 ? "N/A" : `${value}%`;
          }}
        />
      </Value>
    </Widget>
  );
}

export default Gauge;
