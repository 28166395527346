import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import styled from "@emotion/styled";
import {
  Button,
  ClusterNameInput,
  Column,
  Copyboard,
  FormTextArea,
  IconSelector,
  InputLayout,
  LoadingIcon,
  PlatformButton,
  Section,
  SmallFormLayout,
} from "@nexcloud/nc-ui";

import { getTenancyId, request } from "api/common";

import Aws from "assets//aws_logo.png";
import Azure from "assets/azure.png";
import Baremetal from "assets/baremetal_logo.png";
import Google from "assets/Google_Cloud.png";
import Kubernetes from "assets/kubernetes_logo.png";

interface IClusterForm {
  avilablePlatforms?: Array<string>;
  onFormUpdate?: Function;
  comeBackLaterEnabled?: Boolean;
}

interface IClusterOption {
  name: string;
  description: string;
  platform: string | null;
  tenancyId: number;
  icon: string;
}
const Error = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: #ff754c;
  padding-left: 10px;
  font-weight: 500;
  font-family: poppins;
`;
const ClusterForm: React.FC<IClusterForm> = ({
  onFormUpdate,
  comeBackLaterEnabled,
}) => {
  const [selectedPlatform, setlectedPlatform] = useState<
    "kubernetes" | "baremetal" | "AWS" | null
  >(null);
  const [installScript, setInstallScript] = useState<string>("");
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitSuccessful, isSubmitting, isDirty, errors },
  } = useForm<IClusterOption>({
    mode: "onChange",
  });
  const onSubmit: SubmitHandler<IClusterOption> = async (
    data: IClusterOption
  ) => {
    if (typeof onFormUpdate === "function") {
      onFormUpdate({
        isSubmitSuccessful: false,
        isSubmitting: true,
      });
    }
    try {
      data.platform = selectedPlatform;
      const response = await request.post(`lari/clusters`, {
        name: data.name,
        description: data.description,
        platform: selectedPlatform,
        tenancyId: getTenancyId(),
        icon: data.icon,
      });
      const installScript = response.data.installCommand;
      setInstallScript(installScript);

      if (typeof onFormUpdate === "function") {
        onFormUpdate({
          isSubmitSuccessful: true,
          isSubmitting: false,
        });
      }
    } catch (e) {
      alert("There was an error. Please try again later.");
      throw e;
    }
  };
  return (
    <>
      <Section>
        <h3>1. SELECT PLATFORM</h3>
        <Column columnsGap="10px">
          <PlatformButton
            platformName="KUBERNETES"
            platformIcon={Kubernetes}
            onClick={() => {
              setlectedPlatform("kubernetes");
            }}
            isSelected={selectedPlatform === "kubernetes"}
          />
          <PlatformButton
            platformName="BAREMETAL/VM"
            platformIcon={Baremetal}
            onClick={() => {
              setlectedPlatform("baremetal");
            }}
            isSelected={selectedPlatform === "baremetal"}
          />
          <PlatformButton platformName="AWS" platformIcon={Aws} disabled />
          <PlatformButton
            platformName="GOOGLE CLOUD"
            platformIcon={Google}
            disabled
          />
          <PlatformButton platformName="AZURE" platformIcon={Azure} disabled />
        </Column>
      </Section>
      <Section>
        <h3>2. CLUSTER OPTIONS</h3>
        <SmallFormLayout>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputLayout>
              <ClusterNameInput
                label="CLUSTER NAME"
                placeholder="Cluser name"
                {...register("name", {
                  required: true,
                  pattern: /^[a-z0-9_-]+$/gi,
                })}
              />
            </InputLayout>
            <InputLayout>
              <FormTextArea
                label="CLUSTER DESCRIPTION"
                placeholder="Description"
                rows={6}
                {...register("description", {})}
              />
              {/* {errors?.description?.type === "pattern" && (
                <p>Name must be alphanumeric.</p>
              )} */}
            </InputLayout>
            <InputLayout>
              <Controller
                control={control}
                name="icon"
                render={({ field: { onChange } }) => (
                  <IconSelector onChange={onChange} type="cluster" />
                )}
                rules={{ required: true }}
              />
              {errors.icon && <Error>Please pick an Icon.</Error>}
            </InputLayout>
            {isSubmitSuccessful !== true && (
              <Button
                variant="primary"
                type="submit"
                loading={isSubmitting}
                disabled={selectedPlatform === null || !isDirty}
                onClick={handleSubmit(onSubmit, (errors) => {
                  // handle error
                })}
              >
                CREATE CLUSTER
              </Button>
            )}
          </form>
        </SmallFormLayout>
      </Section>
      {isSubmitSuccessful && (
        <Section>
          <h3>3. INSTALL AGENT</h3>
          <h3>Install Agent on your Kubernetes</h3>
          <p>
            For NexClipper Agent to install successfully, make sure you have
            enabled privileged admin within your Kubernetes cluster.
          </p>
          <p>Run the following command against your cluster for NexClipper</p>
          <Copyboard content={installScript} />
          <Section>
            <Column columnsGap="10px">
              <p>Verifying Installation...</p>
              <LoadingIcon />
            </Column>
          </Section>
          {comeBackLaterEnabled !== false && (
            <Section>
              <Button layoutVariant="outlined" variant="primary" to="/clusters">
                {"COME BACK LATER"}
              </Button>
            </Section>
          )}
        </Section>
      )}
    </>
  );
};

export default ClusterForm;
