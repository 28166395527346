import { Redirect, Switch, useRouteMatch } from "react-router-dom";

import PrivateRoute from "components/PrivateRoute";

import AddIncident from "./AddIncident";
import EditIncident from "./EditIncident";
import IncidentDetail from "./IncidentDetail";
import IncidentList from "./IncidentList";

const Incident: React.FC<{ authed: boolean }> = ({ authed }) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute
        exact
        path={path}
        component={IncidentList}
        authed={authed}
      />
      <PrivateRoute path={`${path}/add`} authed component={AddIncident} exact />
      <PrivateRoute
        path={`${path}/:id/edit`}
        authed
        component={EditIncident}
        exact
      />
      <PrivateRoute path={`${path}/:id`} authed={authed}>
        <IncidentDetail />
      </PrivateRoute>
      <Redirect to={path} />
    </Switch>
  );
};

export default Incident;
