const apiPaths = {
  globalView: {
    health() {
      return "/lari/clusters/global/health";
    },
  },
  zones: {
    index() {
      return "/lari/clusters";
    },
    globalZone() {
      return "/lari/clusters/global/cluster";
    },
    detail(clusterId: number | string) {
      return `/lari/clusters/${clusterId}`;
    },
    installParams(clusterId: number | string) {
      return `/lari/clusters/${clusterId}/installParams`;
    },
    getClusterDeleteScript(clusterId: number | string) {
      if (clusterId === "global") {
        return "/lari/clusters/commands/cluster-delete/global";
      }
      return `/lari/clusters/commands/cluster-delete?clusterId=${clusterId}`;
    },
  },
};

export default apiPaths;
