import { Description, Panel, PriorityBadge, UserBadge } from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";

import { IIncidentDetail } from "hooks/incident";

const IncidentInfoPanel: React.FC<{ incident: IIncidentDetail }> = ({
  incident,
}) => {
  const { priority, dueDate, note, createdAt, assignee } = incident;
  if (!incident) {
    return null;
  }
  return (
    <Panel>
      <x.div display="flex" w="60%">
        <Description label="priority">
          <PriorityBadge priority={priority} />
        </Description>
        <Description label="created at">{createdAt.split("T")[0]}</Description>
        <Description label="due">{dueDate.split("T")[0]}</Description>
        <Description label="assignee">
          <UserBadge isSmall name={assignee?.name} avatar={assignee?.photo} />
        </Description>
      </x.div>
      <Description label="description">{note}</Description>
    </Panel>
  );
};

export default IncidentInfoPanel;
