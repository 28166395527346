import { fromJS } from "immutable";
import { call, put, select } from "redux-saga/effects";

import { RootState } from "store/redux";
import { ICreateCluster, IValidateProps } from "store/redux/create";
import * as CreateActions from "store/redux/create";
import * as ZoneActions from "store/redux/zone";
import { zonesApi } from "api";
import { getTenancyId } from "api/common";

import { IAction } from "./types";

const getCreateDataFromStore = (state: RootState) => state.create;

export function* createCluster(action: IAction<ICreateCluster>) {
  // @ts-ignore
  const createStore = yield select(getCreateDataFromStore);
  let {
    callback,
    platform = createStore.get("platform"),
    zoneName,
    zoneId = 0,
    installParams,
  } = action.payload;

  yield put(CreateActions.setData({ key: "loading", value: true }));

  if (zoneName) {
    yield put(CreateActions.setData({ key: "zoneName", value: zoneName }));
  } else {
    zoneName = createStore.get("zoneName");
  }

  if (platform) {
    yield put(CreateActions.setData({ key: "platform", value: platform }));
  } else {
    platform = createStore.get("platform");
  }

  const description = createStore.get("description");

  let id = zoneId;

  // Cluster id가 없을경우 새로 생성
  if (!id) {
    // @ts-ignore
    const data = yield call(zonesApi.createZone, {
      name: zoneName,
      platform,
      description,
      tenancyId: getTenancyId(),
      installParams: JSON.stringify(installParams),
    });

    id = data.id as number;
    yield put(CreateActions.setData({ key: "zoneInfo", value: fromJS(data) }));
    yield put(ZoneActions.getZones());
  }

  // @ts-ignore
  const installData = yield call(zonesApi.getAgentInstall, id);
  yield put(CreateActions.setData({ key: "install", value: installData }));
  if (callback) callback();
}

export function* validateClusterName(action: IAction<IValidateProps>) {
  yield put(CreateActions.setData({ key: "loading", value: true }));
  const { clusterName, callback } = action.payload;
  // @ts-ignore
  const data = yield call(zonesApi.validateClusterName, clusterName);
  callback(data === "OK");
}
