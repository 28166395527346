import { GiNetworkBars } from "react-icons/gi";
import styled from "@emotion/styled";
import { x } from "@xstyled/emotion";

interface ISeverityBadge {
  severity: "Information" | "Warning" | "Critical";
  onClick?(): void;
  small?: boolean;
}

const handleColor = (severity: string): string => {
  switch (severity) {
    case "Information":
      return "#599223";
    case "Warning":
      return "#F6CF43";
    case "Critical":
      return "#B91C1C";
    default:
      return "none";
  }
};

const Wrapper = styled.div<ISeverityBadge>`
  display: flex;
  align-items: center;
  color: ${(props) => props.severity && handleColor(props.severity)};
`;

const SeverityBadge: React.FC<ISeverityBadge> = ({ severity, small }) => {
  return (
    <Wrapper severity={severity}>
      <GiNetworkBars />
      <x.p fontSize={small ? "13px" : "16px"} ml="10px">
        {severity}
      </x.p>
    </Wrapper>
  );
};

export default SeverityBadge;
