import axios, { AxiosError } from "axios";
import type { Action } from "redux";
import {
  ActionPattern,
  call,
  put,
  takeEvery as sagaTakeEvery,
  takeLatest as sagaTakeLatest,
} from "redux-saga/effects";

import * as AccountActions from "store/redux/account";
import * as StatusActions from "store/redux/status";

function getStatusMessage(statusCode: number) {
  if (statusCode === 400) {
    return "Bad request";
  }

  if (statusCode === 401) {
    return "Not authorized";
  }

  if (statusCode === 403) {
    return "Not authorized";
  }

  if (statusCode === 500) {
    return "Server error";
  }

  if (statusCode === 502) {
    return "Bad gateway";
  }

  return "";
}

function* handleApiException(error: AxiosError | Error) {
  if (axios.isAxiosError(error)) {
    const statusCode = error.response?.status ?? 999;
    const message = getStatusMessage(statusCode);

    // auth error
    if (statusCode === 401) {
      yield put(AccountActions.resetAccount());
      return;
    }

    if (message) {
      yield put(
        StatusActions.openMessage({
          type: "error",
          msg: message,
        })
      );
    }
  }
}

const safe = (
  saga: any,
  onCatch: any = handleApiException,
  onFinally: any = null,
  ...args: any
) =>
  function* (action: any) {
    try {
      yield call(saga, ...args, action);
    } catch (err) {
      if (onCatch) {
        yield call(onCatch, ...args, err);
      }
    } finally {
      if (onFinally) {
        yield call(onFinally, ...args);
      }
    }
  };

type PTakeEvery = Parameters<typeof sagaTakeEvery>;
export function takeEveryWithException(
  patternOrChannel: ActionPattern<Action<any>>,
  worker: PTakeEvery[1],
  onCatch?: any,
  onFinally?: any
) {
  return sagaTakeEvery(patternOrChannel, safe(worker, onCatch, onFinally));
}

type PTakeLatest = Parameters<typeof sagaTakeLatest>;
export function takeLatestWithException(
  patternOrChannel: ActionPattern<Action<any>>,
  worker: PTakeLatest[1],
  onCatch?: any,
  onFinally?: any
) {
  return sagaTakeLatest(patternOrChannel, safe(worker, onCatch, onFinally));
}
