import styled from "@emotion/styled";

const SubInfo = styled.p`
  margin-top: 6px;
  font-style: italic;
  font-size: 13px;
  line-height: 18px;
`;

export default SubInfo;
