import React, { useState } from "react";
import { Breadcrumb, Button, Page, TitleSection } from "@nexcloud/nc-ui";

import ClusterForm from "../../forms/Cluster";

const AddCluster = () => {
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const onFormUpdateHandler = (opts: any) => {
    setIsSubmitSuccessful(opts.isSubmitSuccessful);
  };

  return (
    <Page type="item">
      <TitleSection>
        <span>
          <Breadcrumb title="Clusters" path="/" />
          <h2>ADD CLUSTER</h2>
        </span>
        <Button layoutVariant="outlined" variant="primary" to="/">
          {isSubmitSuccessful !== true && "CANCEL"}
          {isSubmitSuccessful === true && "COME BACK LATER"}
        </Button>
      </TitleSection>
      <ClusterForm
        onFormUpdate={(opts: any) => {
          onFormUpdateHandler(opts);
        }}
      />
    </Page>
  );
};

export default AddCluster;
