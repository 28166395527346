import { GoLinkExternal } from "react-icons/go";
import { Link } from "react-router-dom";
import { x } from "@xstyled/emotion";

interface ILinkButton {
  to: string;
  variant?: "danger";
}

const LinkButton: React.FC<ILinkButton> = ({ to, variant, children }) => {
  return (
    <Link to={to}>
      <x.p
        textDecoration="underline"
        fontSize="14px"
        fontFamily="poppins"
        color={variant === "danger" ? "#C6005A" : "#1F29CA"}
      >
        {children}
      </x.p>
    </Link>
  );
};

export const IDLinkButton: React.FC<ILinkButton> = ({ to, children }) => {
  return (
    <Link to={to}>
      <x.div
        w="107px"
        h="25px"
        pl="10px"
        pr="3px"
        borderRadius="5px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        background="#1F29CA"
        color="#fff"
        fontFamily="poppins"
      >
        {children}
        <x.div fontSize="12px" mb="5px">
          <GoLinkExternal />
        </x.div>
      </x.div>
    </Link>
  );
};

export default LinkButton;
