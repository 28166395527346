import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Column,
  Copyboard,
  Description,
  Flex,
  IconDisplay,
  Loading,
  LoadingIcon,
  Page,
  Panel,
  Section,
  Tab,
  TitleSection,
} from "@nexcloud/nc-ui";
import styled from "@xstyled/emotion";

import { useCluster } from "hooks/cluster/useClusterDetailQuery";
import { request } from "api/common";

import AccessGroupsTab from "./AccessGroupsTab";
import ExporterTab from "./ExporterTab";
import NodeTab from "./NodeTab";
import ServicesTab from "./ServicesTab";
import SettingsTab from "./SettingTab";

const AccessGroupIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  font-size: 25px;
  border-radius: 5px;
`;

const ClusterDetail = () => {
  const { clusterId } = useParams<{ clusterId: string }>();
  const id = Number(clusterId);
  const { data: cluster } = useCluster(clusterId);
  const clusterInitStatus = cluster?.initStatus;
  const installCommand = cluster?.installCommand;
  const history = useHistory();
  const deleteCluster = async () => {
    try {
      await request.delete(`/lari/clusters/${id}`);
      history.push("/");
    } catch (e) {
      alert("There was an error. Please try again.");
    }
  };

  if (!cluster) {
    return <Loading />;
  }
  return (
    <Page>
      <TitleSection>
        <span>
          <Breadcrumb title="Clusters" path="/clusters" />
          <TitleSection type="withBreadcrumb">
            <Flex>
              {cluster?.icon && (
                <AccessGroupIcon>
                  <IconDisplay
                    selected={cluster?.icon}
                    name={cluster?.name}
                    type="cluster"
                  />
                </AccessGroupIcon>
              )}
              <h2>{cluster.name}</h2>
            </Flex>
          </TitleSection>
        </span>
        <Button
          layoutVariant="outlined"
          variant="primary"
          size="tiny"
          to={`/clusters/${clusterId}/edit`}
        >
          EDIT
        </Button>
      </TitleSection>
      <Section>
        <Panel>
          <Description label="Description">{cluster.description}</Description>
        </Panel>
      </Section>
      {clusterInitStatus === "NOT_INSTALLED" ||
      clusterInitStatus === "BEING_INSTALLED" ? (
        <Section>
          <h1>You're almost there...</h1>
          <p>
            For NexClipper Agent to install successfully, make sure you have
            enabled privileged admin within your Kubernetes cluster.
          </p>
          <p>Run the following command against your cluster for NexClipper</p>
          <Section>
            <Copyboard content={installCommand ? installCommand : ""} />
          </Section>
          {clusterInitStatus === "BEING_INSTALLED" && (
            <Section>
              <Column columnsGap="10px">
                <p>Verifying Installation...</p>
                <LoadingIcon />
              </Column>
            </Section>
          )}
          <Section>
            <h2>Delete Cluster</h2>
            <Button layoutVariant="outlined" onClick={deleteCluster}>
              Delete
            </Button>
          </Section>
        </Section>
      ) : (
        <Section>
          <Tab
            panes={[
              ["Services", ServicesTab, { clusterId }],
              ["Exporters", ExporterTab, { clusterId }],
              ["Nodes", NodeTab, { clusterId }],
              ["Access Groups", AccessGroupsTab, { clusterId }],
              ["Setting", SettingsTab, { clusterId }],
            ]}
          />
        </Section>
      )}
    </Page>
  );
};

export default ClusterDetail;
