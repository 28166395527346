import { useState } from "react";
import { AiOutlineRise } from "react-icons/ai";
import { CgNotes } from "react-icons/cg";
import { FiMoreVertical } from "react-icons/fi";
import { GoPin } from "react-icons/go";
import { UseQueryOptions } from "react-query";
import type { Row } from "react-table";
import {
  CopyID,
  Flex,
  PrintButton,
  Section,
  SpaceBetween,
  Table,
} from "@nexcloud/nc-ui";
import styled, { x } from "@xstyled/emotion";
import { AxiosError } from "axios";

import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "components/common/Dropdown";
import {
  AlertNoPinCell,
  AlertRecipientCell,
  AlertSeverityCell,
  AlertSourcePortCell,
  SummaryCell,
  TimeCell,
} from "components/common/TableCell";
import DynamicFilter from "components/DynamicFilter";
import { Filter } from "components/DynamicFilter/types";

import {
  IAlertItem,
  IAlertList,
  useAlertList,
  useAlertPinnedList,
} from "hooks/alert";
import { request } from "api/common";

const Menu = styled.div`
  .menuIcon {
    font-size: 16px;
    color: #909aae;
    cursor: pointer;
  }
`;

const AlertMenu: React.FC<{
  value: string;
  row: Row<IAlertItem>;
}> = ({ value, row }) => {
  const { refetch } = useAlertPinnedList();

  const onPin = async () => {
    try {
      const response = await request.put(`/lari/alerts/pin/${row.original.id}`);

      const { status } = response;
      if (status === 200) {
        refetch();
      }
    } catch (e) {
      alert("There was an error. Please try again later.");
    }
  };

  const onUnPin = async () => {
    try {
      const response = await request.delete(
        `/lari/alerts/pin/${row.original.id}`
      );

      const { status } = response;
      if (status === 200) {
        refetch();
      }
    } catch (e) {
      alert("There was an error. Please try again later.");
    }
  };

  return (
    <Menu>
      <Dropdown
        placement="left-start"
        trigger="click"
        render={(attrs) => {
          return (
            <DropdownMenu>
              <x.div w="222px" p="12px 14px" pb="30px">
                <x.div mb="8px">
                  <CopyID id={value} type="alert" menu />
                </x.div>
                {(
                  [
                    [
                      "Escalate",
                      `/incident/add?alertId=${row.original.id}`,
                      <AiOutlineRise />,
                    ],
                    ["Note", "", <CgNotes />],
                    [
                      row.original.isPin ? "Unpin from Top" : "Pin to Top",
                      "",
                      <GoPin />,
                      row.original.isPin ? onUnPin : onPin,
                    ],
                  ] as const
                ).map(([title, link, icon, onClick], i) => (
                  <DropdownMenuItem
                    key={i}
                    icon={icon}
                    title={title}
                    link={link}
                    onClick={onClick}
                  />
                ))}
              </x.div>
            </DropdownMenu>
          );
        }}
      >
        <FiMoreVertical className="menuIcon" />
      </Dropdown>
    </Menu>
  );
};

const columns = [
  {
    Header: "No. Incident",
    accessor: "id",
    Cell: AlertNoPinCell,
    id: "IncidentNumber",
  },
  {
    Header: "Time",
    accessor: "lastUpdatedAt",
    Cell: TimeCell,
  },
  {
    Header: "Summary",
    accessor: "summary",
    Cell: SummaryCell,
  },
  {
    Header: "Severity",
    accessor: "severity",
    Cell: AlertSeverityCell,
  },
  {
    Header: "Source/Port",
    accessor: "source",
    Cell: AlertSourcePortCell,
  },
  {
    Header: "From",
    accessor: "from",
    disableSortBy: true,
  },
  {
    Header: "Recipient",
    accessor: "recipients",
    Cell: AlertRecipientCell,
    disableSortBy: true,
  },
  {
    Header: "",
    accessor: "id",
    Cell: AlertMenu,
    id: "popupMenu",
    disableSortBy: true,
  },
];

function filterByPin(alertListArr: IAlertItem[], pinnedListArr: IAlertItem[]) {
  const idSet = new Set(pinnedListArr.map((obj: any) => obj.id));
  return alertListArr?.filter((obj: any) => !idSet.has(obj.id));
}

function useAlertWithPinned(
  options?: UseQueryOptions<IAlertList, AxiosError>,
  filters?: Filter[]
) {
  const {
    data: alertList,
    isLoading: alertListLoading,
    error: alertListError,
  } = useAlertList(options, filters);

  const alertListContent = alertList?.content;

  const {
    data: pinnedList,
    isLoading: alertPinnedListLoading,
    error: alertPinnedListError,
  } = useAlertPinnedList();

  const alertListWithPin = pinnedList?.concat(
    filterByPin(alertListContent ?? [], pinnedList)
  );

  return {
    data: alertListWithPin,
    loading: alertListLoading || alertPinnedListLoading,
    error: alertListError || alertPinnedListError,
  };
}

const AlertsTab = () => {
  const [filters, setFilters] = useState<Filter[]>([]);
  const { data: alertWithPinnedList } = useAlertWithPinned(undefined, filters);

  return (
    <>
      <Section>
        <SpaceBetween>
          <DynamicFilter
            onChange={(filters) => {
              setFilters(filters);
            }}
          />
          <Flex>
            <PrintButton />
          </Flex>
        </SpaceBetween>
      </Section>
      <Section>
        <Table
          columns={columns}
          data={alertWithPinnedList ? alertWithPinnedList : []}
          getRowProps={(row: any) => {
            return {
              ...row,
            };
          }}
        />
      </Section>
    </>
  );
};

export default AlertsTab;
