import { Modal } from "antd";
import type { ModalFuncProps } from "antd/lib/modal";

type ModalTypes = "info" | "success" | "error" | "warning" | "confirm";

function modalFn(
  type: ModalTypes = "error",
  content: string,
  options?: ModalFuncProps
) {
  Modal[type]({
    title: options?.title ?? type.toUpperCase(),
    content: content,
    ...options,
  });
}

const modal = {
  info: (content: string, options?: ModalFuncProps) =>
    modalFn("info", content, options),
  success: (content: string, options?: ModalFuncProps) =>
    modalFn("success", content, options),
  error: (content: string, options?: ModalFuncProps) =>
    modalFn("error", content, options),
  warning: (content: string, options?: ModalFuncProps) =>
    modalFn("warning", content, options),
  confirm: (content: string, options?: ModalFuncProps) =>
    modalFn("confirm", content, options),
};

export default modal;
