import React, { useEffect, useRef, useState } from "react";
import { Responsive } from "react-grid-layout";
import styled from "@emotion/styled/macro";
import { useAtom } from "jotai";

import WidgetComponent from "components/Widget";
import { Widget } from "components/Widget/types";

import { currentClusterAtom } from "atoms";
import { IClusterHealth } from "store/redux/zone";
import { useClusterHealth } from "hooks/cluster/useClusterDetailQuery";

import "react-grid-layout/css/styles.css";

interface Dashboard {
  widgetId: Widget;
  key: keyof IClusterHealth;
  title: string;
  layout: { x: number; y: number; w: number; h: number };
  render?: (value: any) => any;
}

const Container = styled.div`
  width: 100%;
  background-color: #f5f7f9;
`;

const dashboardList: Dashboard[] = [
  {
    widgetId: "statusBoard",
    key: "clusterAge",
    layout: { x: 0, y: 0, w: 2, h: 5 },
    title: "Age",
    render: (value: any) => {
      if (!value) return "N/A";
      return `${value} Days`;
    },
  },
  {
    widgetId: "statusBoard",
    key: "clusterStatus",
    layout: { x: 2, y: 0, w: 2, h: 5 },
    title: "Cluster Status",
    render: (value: any) => {
      const color: any = {
        "N/A": "#afafaf",
        "Partial Ready": "#ff696b",
        "All Ready": "#52c41a",
        "Not Ready": "#ff696b",
      };
      return (
        <span style={{ color: color[value] || "", fontSize: ".9em" }}>
          {value}
        </span>
      );
    },
  },
  {
    widgetId: "statusBoard",
    key: "clusterNodes",
    layout: { x: 4, y: 0, w: 2, h: 5 },
    title: "Nodes",
  },
  {
    widgetId: "statusBoard",
    key: "clusterNaNodes",
    layout: { x: 6, y: 0, w: 2, h: 5 },
    title: "Unavailable Node",
    render: (value: any) => {
      return <span style={{ color: +value > 0 ? "red" : "" }}>{value}</span>;
    },
  },
  {
    widgetId: "statusBoard",
    key: "clusterNamespaces",
    layout: { x: 8, y: 0, w: 2, h: 5 },
    title: "Namespaces",
  },
  {
    widgetId: "gauge",
    key: "clusterPodUsage",
    layout: { x: 0, y: 5, w: 2, h: 5 },
    title: "Cluster Pod Usage",
  },
  {
    widgetId: "gauge",
    key: "clusterCpuUsage",
    layout: { x: 2, y: 5, w: 2, h: 5 },
    title: "Cluster CPU Usage",
  },
  {
    widgetId: "gauge",
    key: "clusterMemoryUsage",
    layout: { x: 4, y: 5, w: 2, h: 5 },
    title: "Cluster Memory Usage",
  },
  {
    widgetId: "gauge",
    key: "clusterDiskUsage",
    layout: { x: 6, y: 5, w: 2, h: 5 },
    title: "Cluster Disk Usage",
  },
  {
    widgetId: "statusBoard",
    key: "clusterPods",
    layout: { x: 8, y: 5, w: 2, h: 5 },
    title: "Pods",
  },
  {
    widgetId: "statusBoard",
    key: "restartedPods",
    layout: { x: 0, y: 10, w: 2, h: 5 },
    title: "Restarted Pods(30m)",
  },
  {
    widgetId: "statusBoard",
    key: "failedPods",
    layout: { x: 2, y: 10, w: 2, h: 5 },
    title: "Failed Pods",
    render: (value: any) => {
      return <span style={{ color: +value > 0 ? "red" : "" }}>{value}</span>;
    },
  },
  {
    widgetId: "statusBoard",
    key: "pendingPods",
    layout: { x: 4, y: 10, w: 2, h: 5 },
    title: "Pending Pods",
    render: (value: any) => {
      return <span style={{ color: +value > 0 ? "red" : "" }}>{value}</span>;
    },
  },
  {
    widgetId: "statusBoard",
    key: "pvcs",
    layout: { x: 6, y: 10, w: 2, h: 5 },
    title: "PVCs",
  },
  {
    widgetId: "statusBoard",
    key: "p8sStatus",
    layout: { x: 8, y: 10, w: 2, h: 5 },
    title: "Prometheus Status",
    render: (value: any) => {
      const up = value === "Up";
      return (
        <span style={{ color: up ? "green" : "" }}>{up ? "Up" : "Down"}</span>
      );
    },
  },
  {
    widgetId: "statusBoard",
    key: "alertManagerStatus",
    layout: { x: 0, y: 15, w: 2, h: 5 },
    title: "Alertmanager Status",
    render: (value: any) => {
      const up = value === "Up";
      return (
        <span style={{ color: up ? "green" : "" }}>{up ? "Up" : "Down"}</span>
      );
    },
  },
  {
    widgetId: "statusBoard",
    key: "apiServerStatus",
    layout: { x: 2, y: 15, w: 2, h: 5 },
    title: "API Server Status",
    render: (value: any) => {
      const up = value === "Up";
      return (
        <span style={{ color: up ? "green" : "" }}>{up ? "Up" : "Down"}</span>
      );
    },
  },
  {
    widgetId: "line",
    key: "apiServerResponseStatus",
    layout: { x: 4, y: 15, w: 4, h: 5 },
    title: "API server total requests code",
  },
];

const layouts = {
  lg: dashboardList.map((d) => ({ ...d.layout, i: d.key })),
  sm: [
    { w: 2, h: 5, x: 0, y: 0, i: "clusterAge", moved: false, static: false },
    { w: 2, h: 5, x: 2, y: 0, i: "clusterStatus", moved: false, static: false },
    { w: 2, h: 5, x: 4, y: 0, i: "clusterNodes", moved: false, static: false },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 5,
      i: "clusterNaNodes",
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 2,
      y: 5,
      i: "clusterNamespaces",
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 4,
      y: 5,
      i: "clusterPodUsage",
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 10,
      i: "clusterCpuUsage",
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 2,
      y: 10,
      i: "clusterMemoryUsage",
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 4,
      y: 10,
      i: "clusterDiskUsage",
      moved: false,
      static: false,
    },
    { w: 2, h: 5, x: 0, y: 15, i: "clusterPods", moved: false, static: false },
    {
      w: 2,
      h: 5,
      x: 2,
      y: 15,
      i: "restartedPods",
      moved: false,
      static: false,
    },
    { w: 2, h: 5, x: 4, y: 15, i: "failedPods", moved: false, static: false },
    { w: 2, h: 5, x: 0, y: 20, i: "pendingPods", moved: false, static: false },
    { w: 2, h: 5, x: 2, y: 20, i: "pvcs", moved: false, static: false },
    { w: 2, h: 5, x: 4, y: 20, i: "p8sStatus", moved: false, static: false },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 25,
      i: "alertManagerStatus",
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 2,
      y: 25,
      i: "apiServerStatus",
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 4,
      y: 25,
      i: "apiServerResponseStatus",
      moved: false,
      static: false,
    },
  ],
  xs: [
    { w: 2, h: 5, x: 0, y: 0, i: "clusterAge", moved: false, static: false },
    { w: 2, h: 5, x: 2, y: 0, i: "clusterStatus", moved: false, static: false },
    { w: 2, h: 5, x: 0, y: 5, i: "clusterNodes", moved: false, static: false },
    {
      w: 2,
      h: 5,
      x: 2,
      y: 5,
      i: "clusterNaNodes",
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 10,
      i: "clusterNamespaces",
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 2,
      y: 10,
      i: "clusterPodUsage",
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 15,
      i: "clusterCpuUsage",
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 2,
      y: 15,
      i: "clusterMemoryUsage",
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 20,
      i: "clusterDiskUsage",
      moved: false,
      static: false,
    },
    { w: 2, h: 5, x: 2, y: 20, i: "clusterPods", moved: false, static: false },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 25,
      i: "restartedPods",
      moved: false,
      static: false,
    },
    { w: 2, h: 5, x: 2, y: 25, i: "failedPods", moved: false, static: false },
    { w: 2, h: 5, x: 0, y: 30, i: "pendingPods", moved: false, static: false },
    { w: 2, h: 5, x: 2, y: 30, i: "pvcs", moved: false, static: false },
    { w: 2, h: 5, x: 0, y: 35, i: "p8sStatus", moved: false, static: false },
    {
      w: 2,
      h: 5,
      x: 2,
      y: 35,
      i: "alertManagerStatus",
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 40,
      i: "apiServerStatus",
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 2,
      y: 40,
      i: "apiServerResponseStatus",
      moved: false,
      static: false,
    },
  ],
};

const defaultRowHeight = 31;

function PrometheusDashboard() {
  const [width, setWidth] = useState(0);
  const [rowHeight, setRowHeight] = useState(defaultRowHeight);
  const containerRef = useRef<null | HTMLDivElement>(null);

  const [{ id: clusterId }] = useAtom(currentClusterAtom);
  const { data: clusterHealth } = useClusterHealth(clusterId, {
    refetchInterval: 5000,
    onSuccess() {
      if (width === 0) {
        handleResize();
      }
    },
  });

  const handleResize = () => {
    if (!containerRef?.current) {
      return;
    }
    const _width = containerRef.current.offsetWidth;
    setWidth(_width);

    if (_width > 1900) {
      setRowHeight(42);
    } else if (_width < 1100) {
      setRowHeight(37);
    } else {
      setRowHeight(defaultRowHeight);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleResize();
    }, 300);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!clusterHealth) {
    return null;
  }

  return (
    <Container ref={containerRef}>
      {width <= 0 ? (
        <div />
      ) : (
        <Responsive
          className="layout"
          isDraggable={false}
          isResizable={false}
          rowHeight={rowHeight}
          width={width}
          margin={[5, 5]}
          containerPadding={[10, 10]}
          transformScale={0.5}
          breakpoints={{ lg: 2200, md: 1200, sm: 768, xs: 480, xxs: 0 }}
          layouts={layouts}
        >
          {dashboardList.map((d) => (
            <div key={d.key}>
              <WidgetComponent
                widgetId={d.widgetId}
                title={d.title}
                data={clusterHealth[d.key]}
                id={d.key}
                render={d.render}
              />
            </div>
          ))}
        </Responsive>
      )}
    </Container>
  );
}

export default PrometheusDashboard;
