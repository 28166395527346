import React from "react";
import styled from "@emotion/styled/macro";

import Gauge from "./Gauge";
import Line from "./Line";
import StatusBoard from "./StatusBoard";
import { IWdigetCommonProps, IWidgetList, Widget } from "./types";

interface IWidgetProps extends IWdigetCommonProps {
  widgetId: Widget;
  x?: number;
}

const WidgetFrame = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
`;

const WidgetBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
`;

const widgetList: IWidgetList = {
  gauge: Gauge,
  line: Line,
  statusBoard: StatusBoard,
};

function WidgetComponent({ widgetId, x, ...props }: IWidgetProps) {
  const Component = widgetList[widgetId];

  return (
    <WidgetFrame>
      <WidgetBox>
        <Component {...props} />
      </WidgetBox>
    </WidgetFrame>
  );
}

export default WidgetComponent;
