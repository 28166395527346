import styled from "@emotion/styled";

const Component: React.FC = ({ children, ...props }) => {
  return <Elem>{children}</Elem>;
};

export default Component;

const Elem = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: #4d4d4d;
  margin-bottom: 10px;
`;
