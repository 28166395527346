import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { AvatarCell, Button, FormInput, Table } from "@nexcloud/nc-ui";
import styled, { x } from "@xstyled/emotion";

import { useAccessUsers, useCurrentUser, useInvitedUsers } from "hooks/user";
import { request } from "api/common";

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  color: #b2b3bd;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

interface InvitePayload {
  targetName: string;
  targetMail: string;
}

const columns = [
  {
    Header: "USER NAME",
    accessor: "name",
    Cell: AvatarCell,
    imgAccessor: "photo",
    idAccessor: "id",
  },
  {
    // accessor: "accessgroups",
    Header: "ACCESS GROUPS",
  },
  {
    accessor: "lastAccessTime",
    Header: "LAST SIGN-IN",
  },
];

const AccessGroupUsersList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: userList, refetch } = useAccessUsers();
  const { data: invitedUsers, refetch: refetchInvitedUser } = useInvitedUsers();
  const { data: user } = useCurrentUser();

  const userListContent = userList?.content;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<InvitePayload>({
    mode: "onChange",
  });

  const onSubmit = async (data: InvitePayload) => {
    if (!user) {
      return;
    }
    try {
      await request.post(`/users/invite`, {
        inviterEmail: user.email,
        inviterName: user.name,
        joinSiteUrl: `${window.location.origin}/join`,
        targetUsers: [data],
      });
      await reset({
        targetName: "",
        targetMail: "",
      });
      await refetchInvitedUser();
      await refetch();
      onClose();
    } catch (e) {
      alert("There was an error. Please try again later.");
    }
  };

  return (
    <>
      <x.p fontSize="24px">USERS</x.p>
      <Button onClick={showModal}>Invite</Button>
      {isModalOpen && (
        <x.div m="20px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="field">
              <Label htmlFor="targetName">Inviter Name</Label>
              <FormInput
                id="targetName"
                type="text"
                {...register("targetName", {
                  required: true,
                })}
              />
              {errors.targetName?.type === "required" && (
                <p>Enter inviter name.</p>
              )}
            </div>
            <div className="field">
              <Label htmlFor="targetMail">Inviter Email</Label>
              <FormInput
                id="targetMail"
                type="text"
                {...register("targetMail", {
                  required: true,
                  pattern: /^[\w.]+@[\w.]+\.[A-Za-z]{2,3}$/i,
                })}
              />
              {errors.targetMail?.type === "required" && (
                <p>Please enter a email address to be invited.</p>
              )}
              {errors.targetMail?.type === "pattern" && (
                <p>Please enter a valid email address.</p>
              )}
            </div>
            <Button layoutVariant="outlined" variant="primary" type="submit">
              Invite
            </Button>
          </form>
        </x.div>
      )}
      <Table columns={columns} data={userListContent ? userListContent : []} />
      {invitedUsers?.content.map((user) => (
        <p>{`${user.name}, activate Status: ${user.activate}`}</p>
      ))}
    </>
  );
};

export default AccessGroupUsersList;
