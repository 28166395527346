import { Map } from "immutable";
import { createReducer } from "typesafe-actions";

import {
  CLOSE_MESSAGE,
  COLLAPSE_MOBILE_MENU,
  OPEN_MESSAGE,
  SET_DATA,
} from "./actions";
import { StatusAction, StatusState } from "./types";

const initialState: StatusState = Map({
  message: Map({
    type: "",
    status: false,
    msg: "",
  }),
  mobileSize: false,
  intervalTime: 3000,
  statusColor: Map({
    active: "#52c41a",
    inActive: "#878787",
  }),
  mobileMenuOpen: false,
});

const reducer = createReducer<StatusState, StatusAction>(initialState, {
  [CLOSE_MESSAGE]: (state, action) => {
    const message = Map({ type: "", status: false, msg: "" });
    return state.set("message", message);
  },
  [OPEN_MESSAGE]: (state, action) => {
    const { type, msg } = action.payload;
    const message = Map({ type, status: true, msg });
    return state.set("message", message);
  },
  [SET_DATA]: (state, action) => {
    const { key, value } = action.payload;
    return state.set(key, value);
  },
  [COLLAPSE_MOBILE_MENU]: (state, action) => {
    return state.set("mobileMenuOpen", !state.get("mobileMenuOpen"));
  },
});

export default reducer;
