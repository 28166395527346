import { FaFireExtinguisher } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { RiDeleteBinLine, RiFireFill } from "react-icons/ri";
import { UseMutateFunction, useMutation } from "react-query";
import type { Row } from "react-table";
import {
  Button,
  CopyID,
  EmptyBox,
  Flex,
  Gauge,
  GaugeItem,
  Section,
  Table,
  TitleSection,
} from "@nexcloud/nc-ui";
import styled, { x } from "@xstyled/emotion";

import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "components/common/Dropdown";
import {
  IIncidentListItem,
  IncidentAssigneeCell,
  IncidentNoCell,
  IncidentPriorityCell,
  IncidentStatusCell,
  TimeCell,
} from "components/common/TableCell";
import { showTryAgain } from "components/NotificationMessage";

import { useIncidentCount, useIncidentList } from "hooks/incident";
import { request } from "api/common";

const Menu = styled.div`
  .menuIcon {
    font-size: 16px;
    color: #909aae;
    cursor: pointer;
  }
`;

const IncidentMenu: React.FC<{
  value: string;
  row: Row<IIncidentListItem>;
  mutate: UseMutateFunction<void, unknown, { incidentId: number }>;
}> = ({ value, row, mutate }) => {
  const onDelete = () => {
    mutate({ incidentId: row.original.id });
  };

  return (
    <Menu>
      <Dropdown
        placement="left-start"
        trigger="click"
        render={(attrs) => {
          return (
            <DropdownMenu>
              <x.div w="222px" p="12px 14px" pb="30px">
                <x.div mb="8px">
                  <CopyID
                    title={row.original.title}
                    id={value}
                    menu
                    type="incident"
                  />
                </x.div>
                {[["Delete", "", <RiDeleteBinLine />, onDelete]].map(
                  ([title, link, icon, onClick], i) => (
                    <DropdownMenuItem
                      key={i}
                      icon={icon}
                      title={title}
                      // @ts-expect-error
                      link={link}
                      // @ts-expect-error
                      onClick={onClick}
                    />
                  )
                )}
              </x.div>
            </DropdownMenu>
          );
        }}
      >
        <FiMoreVertical className="menuIcon" />
      </Dropdown>
    </Menu>
  );
};
const incidentColumns = [
  {
    Header: "Status",
    accessor: "status",
    Cell: IncidentStatusCell,
  },
  {
    Header: "Incident No.",
    accessor: "id",
    Cell: IncidentNoCell,
  },
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Time",
    accessor: "createdAt",
    Cell: TimeCell,
  },
  {
    Header: "Priority",
    accessor: "priority",
    Cell: IncidentPriorityCell,
  },
  {
    Header: "Due",
    accessor: "dueDate",
    Cell: TimeCell,
  },
  {
    Header: "Assignee",
    // FIXME : 타입 재정의 하기
    accessor: (d: any) => d.assignee?.name,
    Cell: IncidentAssigneeCell,
  },
  {
    Header: "",
    accessor: "id",
    Cell: IncidentMenu,
    id: "popupMenu",
    disableSortBy: true,
  },
];

const IncidentList = () => {
  const { data: incidentList, refetch } = useIncidentList();
  const incidentListData = incidentList?.content;

  const mutation = useMutation<void, unknown, { incidentId: number }>(
    async ({ incidentId }) => {
      try {
        await request.delete(`/lari/incidents/${incidentId}`);
        alert("Successfully deleted the incident");
        refetch();
      } catch (e) {
        if (e.response?.data?.message) {
          window.alert(e.response.data.message);
        } else {
          showTryAgain();
        }
      }
    }
  );

  return (
    <>
      <TitleSection>
        <h2>Incidents</h2>
        <Button
          variant="primary"
          icon="plus-no-border"
          iconRight
          to="/incident/add"
        >
          NEW INCIDENT
        </Button>
      </TitleSection>
      <GaugeContainer />
      <Section>
        {incidentListData?.length === 0 ? (
          <EmptyBox />
        ) : (
          <Table
            loading={mutation.isLoading}
            columns={incidentColumns}
            data={incidentListData ? incidentListData : []}
            getRowProps={(row: any) => {
              return { ...row };
            }}
            userCellProps={{ mutate: mutation.mutate }}
          />
        )}
      </Section>
    </>
  );
};

const GaugeContainer = () => {
  const { data: incidentCount } = useIncidentCount();
  const resolved = incidentCount?.resolvedCount;
  const inprogress = incidentCount?.inprogressCount;
  return (
    <Section>
      <Flex justifyContent="start">
        <Gauge title="Incidents resolved" icon={<FaFireExtinguisher />}>
          <GaugeItem value={resolved} />
        </Gauge>
        <Gauge title="Current Incidents" icon={<RiFireFill />} type="warning">
          <GaugeItem value={inprogress} type="warning" />
        </Gauge>
      </Flex>
    </Section>
  );
};

export default IncidentList;
