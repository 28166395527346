// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    exporterId: 4,
    name: "cAdvisor Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/1342004?v=4",
    category: "Miscellaneous",
    official: "Unofficial",
    stars: 12421,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/google/cadvisor",
    description:
      "Analyzes resource usage and performance characteristics of running containers.",
  },
  {
    exporterId: 26,
    name: "Node Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/3380462?v=4",
    category: "Hardware",
    official: "Official",
    stars: 6377,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus/node_exporter",
    description: "Exporter for machine metrics",
  },
  {
    exporterId: 31,
    name: "Go Client Library",
    logoUrl: "https://avatars.githubusercontent.com/u/3380462?v=4",
    category: "Library",
    official: "Official",
    stars: 3268,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus/client_golang",
    description: "Prometheus instrumentation library for Go applications",
  },
  {
    exporterId: 7,
    name: "kube-state-metrics",
    logoUrl: "https://avatars.githubusercontent.com/u/13629408?v=4",
    category: "Miscellaneous",
    official: "Unofficial",
    stars: 3165,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/kubernetes/kube-state-metrics",
    description: "Add-on agent to generate and expose cluster-level metrics.",
  },
  {
    exporterId: 16,
    name: "Blackbox Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/3380462?v=4",
    category: "Miscellaneous",
    official: "Official",
    stars: 2404,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus/blackbox_exporter",
    description: "Blackbox prober exporter",
  },
  {
    exporterId: 33,
    name: "Python Client Library",
    logoUrl: "https://avatars.githubusercontent.com/u/3380462?v=4",
    category: "Library",
    official: "Official",
    stars: 2311,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus/client_python",
    description: "Prometheus instrumentation library for Python applications",
  },
  {
    exporterId: 23,
    name: "JMX Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/3380462?v=4",
    category: "Monitoring",
    official: "Official",
    stars: 1995,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus/jmx_exporter",
    description:
      "A process for exposing JMX Beans via HTTP for Prometheus consumption",
  },
  {
    exporterId: 11,
    name: "Redis Metrics Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/1222339?v=4",
    category: "Database",
    official: "Unofficial",
    stars: 1923,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/oliver006/redis_exporter",
    description:
      "Prometheus Exporter for Redis Metrics. Supports Redis 2.x, 3.x, 4.x, 5.x and 6.x",
  },
  {
    exporterId: 35,
    name: "Push Gateway",
    logoUrl: "https://avatars.githubusercontent.com/u/3380462?v=4",
    category: "Monitoring",
    official: "Official",
    stars: 1903,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus/pushgateway",
    description: "Push acceptor for ephemeral and batch jobs.",
  },
  {
    exporterId: 32,
    name: "Java Client Library",
    logoUrl: "https://avatars.githubusercontent.com/u/3380462?v=4",
    category: "Library",
    official: "Official",
    stars: 1561,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus/client_java",
    description: "Prometheus instrumentation library for JVM applications",
  },
  {
    exporterId: 37,
    name: "Kubecost",
    logoUrl: "https://avatars.githubusercontent.com/u/45108136?v=4",
    category: "Miscellaneous",
    official: "Unofficial",
    stars: 1487,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/kubecost/cost-model",
    description: "Cross-cloud cost allocation models for Kubernetes workloads",
  },
  {
    exporterId: 30,
    name: "PostgreSQL Server Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/42581734?v=4",
    category: "Database",
    official: "Unofficial",
    stars: 1425,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/wrouesnel/postgres_exporter",
    description: "A PostgreSQL metric exporter for Prometheus",
  },
  {
    exporterId: 15,
    name: "Windows Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/42581734?v=4",
    category: "Hardware",
    official: "Unofficial",
    stars: 1394,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus-community/windows_exporter",
    description: "Prometheus exporter for Windows machines",
  },
  {
    exporterId: 25,
    name: "MySQL Server Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/3380462?v=4",
    category: "Database",
    official: "Official",
    stars: 1234,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus/mysqld_exporter",
    description: "Exporter for MySQL server metrics",
    paramSchema: {
      user: {
        type: "text",
      },
      pass: {
        type: "password",
      },
      port: {
        type: "number",
        default: 3306,
      },
    },
  },
  {
    exporterId: 1,
    name: "eBPF Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/314135?v=4",
    category: "Miscellaneous",
    official: "Unofficial",
    stars: 974,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/cloudflare/ebpf_exporter",
    description: " Prometheus exporter for custom eBPF metrics",
  },
  {
    exporterId: 8,
    name: "Process Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/5587236?v=4",
    category: "Miscellaneous",
    official: "Unofficial",
    stars: 892,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/ncabatoff/process-exporter",
    description:
      "Prometheus exporter that mines /proc to report on selected processes ",
  },
  {
    exporterId: 10,
    name: "NVIDIA GPU Monitoring Tools",
    logoUrl: "https://avatars.githubusercontent.com/u/1728152?v=4",
    category: "Hardware",
    official: "Unofficial",
    stars: 822,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/NVIDIA/gpu-monitoring-tools",
    description: "Tools for monitoring NVIDIA GPUs on Linux ",
  },
  {
    exporterId: 27,
    name: "SNMP Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/3380462?v=4",
    category: "Monitoring",
    official: "Official",
    stars: 801,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus/snmp_exporter",
    description: "SNMP Exporter for Prometheus",
  },
  {
    exporterId: 9,
    name: "NGINX Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/8629072?v=4",
    category: "HTTP",
    official: "Unofficial",
    stars: 701,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/nginxinc/nginx-prometheus-exporter",
    description: "NGINX Prometheus Exporter for NGINX and NGINX Plus",
  },
  {
    exporterId: 28,
    name: "Statsd Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/3380462?v=4",
    category: "Monitoring",
    official: "Official",
    stars: 655,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus/statsd_exporter",
    description: "StatsD to Prometheus metrics exporter",
  },
  {
    exporterId: 3,
    name: "Grok Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/330535?v=4",
    category: "Logging",
    official: "Unofficial",
    stars: 640,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/fstab/grok_exporter",
    description:
      "Export Prometheus metrics from arbitrary unstructured log data.",
  },
  {
    exporterId: 17,
    name: "CloudWatch Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/3380462?v=4",
    category: "Monitoring",
    official: "Official",
    stars: 571,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus/cloudwatch_exporter",
    description: "Metrics exporter for Amazon AWS CloudWatch",
  },
  {
    exporterId: 5,
    name: "RabbitMQ Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/1072181?v=4",
    category: "Messaging",
    official: "Unofficial",
    stars: 506,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/kbudde/rabbitmq_exporter",
    description: "Prometheus exporter for RabbitMQ",
  },
  {
    exporterId: 21,
    name: "HAProxy Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/3380462?v=4",
    category: "HTTP",
    official: "Official",
    stars: 488,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus/haproxy_exporter",
    description:
      "Simple server that scrapes HAProxy stats and exports them via HTTP for Prometheus consumption",
  },
  {
    exporterId: 34,
    name: "Ruby Client Library",
    logoUrl: "https://avatars.githubusercontent.com/u/3380462?v=4",
    category: "Library",
    official: "Official",
    stars: 387,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus/client_ruby",
    description: "Prometheus instrumentation library for Ruby applications",
  },
  {
    exporterId: 19,
    name: "Consul Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/3380462?v=4",
    category: "Database",
    official: "Official",
    stars: 338,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus/consul_exporter",
    description: "Exporter for Consul metrics",
  },
  {
    exporterId: 2,
    name: "Ceph Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/4650108?v=4",
    category: "Storage",
    official: "Unofficial",
    stars: 323,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/digitalocean/ceph_exporter",
    description:
      "Prometheus exporter that scrapes meta information about a ceph cluster.",
  },
  {
    exporterId: 13,
    name: "JSON Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/42581734?v=4",
    category: "Miscellaneous",
    official: "Unofficial",
    stars: 268,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus-community/json_exporter",
    description: "A prometheus exporter which scrapes remote JSON by JSONPath",
  },
  {
    exporterId: 20,
    name: "Graphite Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/3380462?v=4",
    category: "Monitoring",
    official: "Official",
    stars: 233,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus/graphite_exporter",
    description:
      "Server that accepts metrics via the Graphite protocol and exports them as Prometheus metrics",
  },
  {
    exporterId: 18,
    name: "Collectd Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/3380462?v=4",
    category: "Monitoring",
    official: "Official",
    stars: 205,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus/collectd_exporter",
    description:
      "A server that accepts collectd stats via HTTP POST and exports them via HTTP for Prometheus consumption",
  },
  {
    exporterId: 14,
    name: "Google Stackdriver Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/42581734?v=4",
    category: "Monitoring",
    official: "Unofficial",
    stars: 177,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl:
      "https://github.com/prometheus-community/stackdriver_exporter",
    description: "Google Stackdriver Prometheus exporter",
  },
  {
    exporterId: 22,
    name: "InfluxDB Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/3380462?v=4",
    category: "Database",
    official: "Official",
    stars: 162,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus/influxdb_exporter",
    description:
      "A server that accepts InfluxDB metrics via the HTTP API and exports them via HTTP for Prometheus consumption",
  },
  {
    exporterId: 6,
    name: "Kong Prometheus Plugin",
    logoUrl: "https://avatars.githubusercontent.com/u/962416?v=4",
    category: "Software",
    official: "Unofficial",
    stars: 115,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/Kong/kong-plugin-prometheus",
    description: "Prometheus plugin for Kong",
  },
  {
    exporterId: 24,
    name: "Memcached Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/3380462?v=4",
    category: "Database",
    official: "Official",
    stars: 114,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/prometheus/memcached_exporter",
    description:
      "Exports metrics from memcached servers for consumption by Prometheus.",
  },
  {
    exporterId: 12,
    name: "OpenStack Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/51098375?v=4",
    category: "Miscellaneous",
    official: "Unofficial",
    stars: 113,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/openstack-exporter/openstack-exporter",
    description: "OpenStack Exporter for Prometheus",
  },
  {
    exporterId: 36,
    name: "k8s-image-availability-exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/1169905?v=4",
    category: "Miscellaneous",
    official: "Unofficial",
    stars: 113,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/flant/k8s-image-availability-exporter",
    description:
      "Alert if an image used in Kubernetes cannot be pulled from container registry",
  },
  {
    exporterId: 29,
    name: "Azure Metrics Exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/13131447?v=4",
    category: "Monitoring",
    official: "Unofficial",
    stars: 94,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/RobustPerception/azure_metrics_exporter",
    description: "Azure metrics exporter for Prometheus",
  },
  {
    exporterId: 40,
    name: "klevr",
    logoUrl: "https://avatars.githubusercontent.com/u/66052972?v=4",
    category: "Software",
    official: "Unofficial",
    stars: 52,
    isStar: false,
    isBucket: false,
    isNew: true,
    repositoryUrl: "https://github.com/Klevry/klevr",
    description: "Hyper-connected Cloud-native delivery solution for SaaS",
  },
  {
    exporterId: 39,
    name: "query-exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/50811687?v=4",
    category: "Monitoring",
    official: "Unofficial",
    stars: 9,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/go-gywn/query-exporter",
    description: "exporter that creates metrics from queries",
  },
  {
    exporterId: 38,
    name: "dnsbl-exporter",
    logoUrl: "https://avatars.githubusercontent.com/u/13489019?v=4",
    category: "Monitoring",
    official: "Unofficial",
    stars: 6,
    isStar: false,
    isBucket: false,
    isNew: false,
    repositoryUrl: "https://github.com/Luzilla/dnsbl_exporter",
    description: "Prometheus compatible exporter to query DNSBLs/RBLs.",
  },
];
