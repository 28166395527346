import { useState } from "react";
import styled from "@emotion/styled";
import { Button } from "@nexcloud/nc-ui";

import WhiteboardItem from "./WhiteboardItem";
// import { Panel } from "@nexcloud/nc-ui";

const WhiteBoardWrapper = styled.div`
  padding-left: 30px;
  padding-right: 30px;
`;

const ButtonWrapper = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: right;
`;

const Whiteboard = () => {
  const [isAddWhiteboardVisible, setIsAddWhiteboardVisible] = useState(false);

  return (
    <WhiteBoardWrapper>
      <ButtonWrapper>
        {!isAddWhiteboardVisible && (
          <Button
            onClick={() => setIsAddWhiteboardVisible(true)}
            layoutVariant="contained"
            variant="primary"
            icon="plus-no-border"
            iconRight
          >
            ADD WHITEBOARD
          </Button>
        )}
      </ButtonWrapper>
      {isAddWhiteboardVisible && (
        <WhiteboardItem
          isAddWhiteboardVisible={isAddWhiteboardVisible}
          setIsAddWhiteboardVisible={setIsAddWhiteboardVisible}
          avatar="https://www.w3schools.com/images/picture.jpg"
          userFullName="Jian Kim"
          role="DevOps"
          newItem={true}
          editable={false}
          unpinable={false}
        />
      )}
      <WhiteboardItem
        isAddWhiteboardVisible
        setIsAddWhiteboardVisible
        incidentDate="2021-05-01 08:31:01 PM"
        avatar="https://www.w3schools.com/images/picture.jpg"
        userFullName="Jian Kim"
        role="DevOps"
        incidentComment="This highlighted instance is not running."
        incidentScreenshots={[
          "https://i.ibb.co/931ZWyD/image-10-1.jpg",
          "https://i.ibb.co/7JjcL8r/image-12-1.png",
          "https://i.ibb.co/YhT3dhy/Screen-Shot-2021-08-31-at-4-12-00-PM.png",
        ]}
      ></WhiteboardItem>
      <WhiteboardItem
        isAddWhiteboardVisible
        setIsAddWhiteboardVisible
        incidentDate="2021-05-01 08:31:01 PM"
        avatar="https://www.w3schools.com/images/picture.jpg"
        userFullName="Jian Kim"
        role="DevOps"
        incidentComment="This highlighted instance is not running."
        incidentScreenshots={[
          "https://i.ibb.co/931ZWyD/image-10-1.jpg",
          "https://i.ibb.co/7JjcL8r/image-12-1.png",
          "https://i.ibb.co/YhT3dhy/Screen-Shot-2021-08-31-at-4-12-00-PM.png",
        ]}
      ></WhiteboardItem>
      <WhiteboardItem
        isAddWhiteboardVisible
        setIsAddWhiteboardVisible
        incidentDate="2021-05-01 08:31:01 PM"
        avatar="https://www.w3schools.com/images/picture.jpg"
        userFullName="Jian Kim"
        role="DevOps"
        incidentComment="This highlighted instance is not running."
        incidentScreenshots={[
          "https://i.ibb.co/931ZWyD/image-10-1.jpg",
          "https://i.ibb.co/7JjcL8r/image-12-1.png",
          "https://i.ibb.co/YhT3dhy/Screen-Shot-2021-08-31-at-4-12-00-PM.png",
        ]}
      ></WhiteboardItem>
    </WhiteBoardWrapper>
  );
};

export default Whiteboard;
