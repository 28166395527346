import axios, { Method } from "axios";

interface IAuthCheck {
  url: string;
  method: Method;
  headers?: any;
  data?: any;
  params?: any;
}

/**
 * API request시 권한 체크 후 에러일경우 에러를 리턴한다.
 * @param param IAuthCheck
 */
export const baseURL = process.env.REACT_APP_API_URL;
export const prdCode = "lari";
let tenancyId: number | undefined;

export const getTenancyId = () => {
  return tenancyId;
};

export const setTenancyId = (settingTenancyId: typeof tenancyId) => {
  tenancyId = settingTenancyId;
  if (tenancyId) {
    request.defaults.headers.tenancyId = settingTenancyId;
  } else {
    delete request.defaults.headers.tenancyId;
  }
};

export const request = axios.create({
  withCredentials: true,
  baseURL,
  headers: {
    prdCode,
  },
});

request.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response?.data.path !== "/accounts/me" &&
      error.response?.status === 401
    ) {
      window.location.href = "/";
      return;
    }
    throw error;
  }
);

export async function authCheck(param: IAuthCheck) {
  const { data } = await request({
    ...param,
  });
  return data;
}
