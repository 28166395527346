import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Redirect } from "react-router";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  EllipsisOutlined,
  ExclamationCircleTwoTone,
  LoadingOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled/macro";
import { Modal, notification, Tag, Tooltip } from "antd";
import { fetchOption } from "config";

import CreatePrometheus from "components/Cluster/CreatePrometheus";
import PlatformIcon from "components/PlatformIcon";

import { Platform } from "store/redux/create";
import { IZone } from "store/redux/zone";
import { ITaskCount, useTasksCount } from "hooks/cluster/task";
import { useCurrentCluster } from "hooks/cluster/useClusterDetailQuery";
import { zonesApi } from "api";

import ClusterServices from "./ClusterServices";
import { STATUS } from "./common";
import { InstallAgent } from "./InstallAgent";
import P8sButtons from "./P8sButtons";
import P8sVolumes from "./P8sVolumes";

import { timeZone } from "const";
import day from "utils/day";
import { usePath } from "paths";

import Forward from "assets/forward.png";
import { device } from "style/device";

const Content = styled.div`
  padding-top: 15px;
  border-top: 1px solid #ececec;
  margin-top: 15px;
`;

const TopContent = styled.div`
  width: 50%;
  @media ${device.tablet} {
    flex-wrap: wrap;
    width: 100%;
  }
  .cluster-detail {
    border-left: 1px solid #ececec;
  }
`;

const TopFlex = styled.div`
  display: flex;
  @media ${device.tablet} {
    flex-wrap: wrap;
  }
`;
const Wrapper = styled.div`
  padding: 15px;
`;

const ContentTitle = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  @media ${device.mobileL} {
    flex-wrap: wrap;
  }
`;

const ContentLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const SubTitle = styled.div`
  width: 120px;
  font-weight: bold;
  margin-right: 7px;
  font-size: 1.1em;
`;

const SubValue = styled.div`
  font-size: 1.1em;
  display: flex;
`;

const TaskList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media ${device.mobileL} {
    flex-wrap: wrap;
  }
`;

const Task = styled.div`
  padding: 10px;
  margin-right: 10px;
  width: 150px;
  height: 100px;
  display: flex;
  flex-direction: column;

  @media ${device.mobileL} {
    width: 100%;
  }
`;

const TaskTitle = styled.div`
  width: 100%;
  font-size: 1.05em;
  color: #999999;

  span {
    margin-right: 5px;
  }
`;

const TaskValue = styled.div`
  font-size: 2em;
  font-weight: bold;
  flex: 1;
  display: flex;
  align-items: center;
  color: ${({ color }: { color: string }) => color};
`;

const TimeTitle = styled.div`
  font-size: 0.8em;
  margin-left: 5px;
  font-weight: normal;
  color: #969696;

  span {
    padding-left: 5px;
  }

  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0;
    margin-bottom: 5px;
    margin-top: 5px;
  }
`;

const ForwardButton = styled.img`
  margin-left: 5px;
  width: 20px;
  cursor: pointer;
`;
ForwardButton.defaultProps = { src: Forward };

const ScriptBox = styled.div`
  max-width: 800px;
`;

const Loading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Ripple = styled(ExclamationCircleTwoTone)`
  cursor: pointer;
  font-size: 20px;
  animation: blink-animation 1s steps(5, start) infinite;
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
`;

type ClusterDetailObject = {
  title: string;
  key: keyof IZone;
  render?: (value: any) => any;
};

const clusterDetailList: ClusterDetailObject[] = [
  {
    title: "License",
    key: "license",
  },
  {
    title: "Version",
    key: "version",
    render: (value: any) => {
      return <Tag style={{ fontWeight: "bold" }}>{value || "0.0.0"}</Tag>;
    },
  },
  {
    title: "Node Count",
    key: "nodeCount",
  },
  {
    title: "Account",
    key: "account",
  },
];

const openInstallNotification = () => {
  const args = {
    message: (
      <Loading>
        <LoadingOutlined
          style={{ color: "#096dd9", fontSize: "1.5em", marginRight: "7px" }}
        />
        <span style={{ fontWeight: "bold" }}>
          Prometheus is being installed.
        </span>
      </Loading>
    ),
    duration: null,
    key: "installing",
  };
  notification.open(args);
};

const openGlobalNotification = () => {
  const args = {
    message: (
      <Loading>
        <LoadingOutlined
          style={{ color: "#096dd9", fontSize: "1.5em", marginRight: "7px" }}
        />
        <span style={{ fontWeight: "bold" }}>
          Status of Global View is being changed.
        </span>
      </Loading>
    ),
    duration: null,
    key: "globalviewChanging",
  };
  notification.open(args);
};

function ClusterDetailLegacy() {
  const [formVisible, setFormVisible] = useState(false);
  const [installNoti, setInstallNoti] = useState(false);
  const [globalNoti, setGlobalNoti] = useState(false);
  const [scriptOpen, setScriptOpen] = useState(false);

  const { data: cluster, error } = useCurrentCluster.apply(
    fetchOption ? [fetchOption] : [undefined, false, true]
  );
  const clusterId = cluster?.id;
  const { data: task } = useTasksCount(clusterId, {
    refetchInterval: 5000,
  });

  const initStatus = cluster?.initStatus;
  useEffect(() => {
    if (!initStatus) {
      return;
    }

    const installing = initStatus === "BEING_INSTALLED";
    const globalViewChanging = initStatus === "GLOBALVIEW_CHANGING";

    if (installing && !installNoti) {
      openInstallNotification();
      setInstallNoti(true);
    }
    if (!installing && installNoti) {
      notification.close("installing");
      setInstallNoti(false);
    }
    if (globalViewChanging && !globalNoti) {
      openGlobalNotification();
      setGlobalNoti(true);
    }
    if (!globalViewChanging && globalNoti) {
      notification.close("globalviewChanging");
      setGlobalNoti(false);
    }
  }, [initStatus, installNoti, globalNoti]);

  // const installP8S = query.get("installP8S");
  // useEffect(() => {
  //   const installed = initStatus === "ACTIVE";
  //   const installing = initStatus === "BEING_INSTALLED";

  //   if (installP8S === "true" && !installed && !installing) {
  //     setFormVisible(true);
  //   }
  // }, [initStatus, installP8S]);

  useEffect(() => {
    return () => {
      notification.close("installing");
      notification.close("globalviewChanging");
    };
  }, []);

  const moveToP8sDashboard = usePath((paths) => paths.cluster.dashboard);
  const goToP8SDashboard = () => {
    const installedP8S = initStatus === "ACTIVE";
    if (installedP8S) {
      moveToP8sDashboard(clusterId);
    }
  };

  const errorStatus = error?.response?.status;
  if (errorStatus === 404) {
    return <Redirect to="/" />;
  }

  if (!cluster) {
    return null;
  }

  const agentStatus = STATUS[cluster.agentStatus];
  const p8sStatus = STATUS[cluster.p8sStatus];

  return (
    <Wrapper>
      <TopFlex>
        <TopContent>
          <ContentTitle>Platform</ContentTitle>
          <ContentLine>
            <SubTitle>Type</SubTitle>
            <SubValue>
              <PlatformIcon id="kubernetes" type="text" />
            </SubValue>
          </ContentLine>
          <ContentLine>
            <SubTitle>Agent</SubTitle>
            {agentStatus && (
              <SubValue>
                <Tag color={agentStatus.color}>{agentStatus.status}</Tag>
                <Tooltip
                  title={
                    <>
                      Checking the agent status... <br />* Please make sure the
                      agent status if you haven't installed yet.
                    </>
                  }
                >
                  {agentStatus.status !== "Running" && (
                    <Ripple
                      twoToneColor="#f4524a"
                      onClick={() => setScriptOpen(true)}
                    >
                      <div></div>
                      <div></div>
                    </Ripple>
                  )}
                </Tooltip>
                {scriptOpen && clusterId && (
                  <InstallScriptModal
                    isOpen={scriptOpen}
                    clusterId={clusterId}
                    platform={cluster.platform}
                    onClose={() => setScriptOpen(false)}
                  />
                )}
              </SubValue>
            )}
          </ContentLine>
          <ContentLine>
            <SubTitle>Prometheus</SubTitle>
            <SubValue>
              {p8sStatus && (
                <Tag color={p8sStatus.color}>{p8sStatus.status}</Tag>
              )}
            </SubValue>
          </ContentLine>
        </TopContent>
        <TopContent className="cluster-detail">
          <ContentTitle>
            Cluster Detail
            <TimeTitle>
              <ClockCircleOutlined />
              <span>
                Last Updated :{" "}
                {day(cluster.updatedAt)
                  .tz(timeZone)
                  .format("YYYY-MM-DD HH:mm:ss")}
              </span>
            </TimeTitle>
          </ContentTitle>
          {clusterDetailList.map((detail) => {
            const text = cluster?.[detail.key];
            const value = detail.render ? detail.render(text) : text;

            return (
              <ContentLine key={detail.key}>
                <SubTitle>{detail.title}</SubTitle>
                <SubValue>{value}</SubValue>
              </ContentLine>
            );
          })}
        </TopContent>
      </TopFlex>
      {formVisible ? (
        <CreatePrometheus onCancel={() => setFormVisible(false)} />
      ) : (
        <>
          <Tasks task={task} />
          {initStatus === "ACTIVE" && (
            <Content>
              <ContentTitle>
                Stacks
                <Tooltip title="Move to Prometheus Dashboard">
                  <ForwardButton onClick={goToP8SDashboard} />
                </Tooltip>
              </ContentTitle>
              <P8sButtons clusterId={clusterId} />
            </Content>
          )}
        </>
      )}
      <P8sVolumes clusterId={clusterId} />
      <ClusterServices services={cluster.clusterServices} />
    </Wrapper>
  );
}

const Tasks: React.FC<{ task?: ITaskCount }> = ({ task }) => {
  if (!task) {
    return null;
  }

  return (
    <Content>
      <ContentTitle>Tasks</ContentTitle>
      <TaskList>
        <Task>
          <TaskTitle>
            <CheckCircleOutlined style={{ color: "#52c41a" }} />
            Complete
          </TaskTitle>
          <TaskValue color="#000">{task.complete}</TaskValue>
        </Task>
        <Task>
          <TaskTitle>
            <RedoOutlined style={{ color: "#096dd9" }} />
            Running
          </TaskTitle>
          <TaskValue color="#000">{task.running}</TaskValue>
        </Task>
        <Task>
          <TaskTitle>
            <EllipsisOutlined style={{ color: "#faad14" }} />
            Waiting
          </TaskTitle>
          <TaskValue color="#000">{task.waiting}</TaskValue>
        </Task>
      </TaskList>
    </Content>
  );
};

const InstallScriptModal: React.FC<{
  isOpen: boolean;
  onClose(): void;
  clusterId: number;
  platform?: Platform;
}> = ({ isOpen, onClose, clusterId, platform }) => {
  const { data } = useQuery(["installScript", clusterId], () =>
    zonesApi.getAgentInstall(clusterId)
  );

  if (!data) {
    return null;
  }

  return (
    <Modal visible={isOpen} centered onCancel={onClose} footer={null}>
      <ScriptBox>
        <InstallAgent platform={platform} installScript={data} />
      </ScriptBox>
    </Modal>
  );
};

export default ClusterDetailLegacy;
