import React, { ComponentProps } from "react";
import { NavLink } from "react-router-dom";
import styled, { x } from "@xstyled/emotion";

import { usePermission } from "hooks/user";

import paths from "paths";

const ClusterLayout: React.FC = ({ children }) => {
  const { isTenancyMaintainer, isGroupMaintainer } = usePermission();

  return (
    <x.div display="flex" w="80%" m="auto" maxWidth="1200px" mt="40px">
      <x.div w="20%">
        <SettingsMenu single title="My" to={paths.settings.my()} />

        {isTenancyMaintainer() && (
          <SettingsMenu title="Organization Settings" mt="20px">
            <SettingsMenuItem
              title="Organization"
              to={paths.settings.organization.general()}
            />
            <SettingsMenuItem
              title="Permission"
              to={paths.settings.organization.permission()}
            />
          </SettingsMenu>
        )}

        {isGroupMaintainer() && (
          <SettingsMenu title="User Management" mt="20px">
            <SettingsMenuItem
              title="User Groups"
              to={paths.settings.users.groups()}
            />
            {isTenancyMaintainer() && (
              <SettingsMenuItem
                title="All Users"
                to={paths.settings.users.manage()}
              />
            )}
          </SettingsMenu>
        )}

        {isTenancyMaintainer() && (
          <SettingsMenu
            title="Channel"
            single
            to={paths.settings.channel()}
            mt="20px"
          />
        )}
      </x.div>
      <x.div w="80%">{children}</x.div>
    </x.div>
  );
};

type XStyledProps = ComponentProps<typeof x.div>;

const SettingsMenu: React.FC<
  { title: string; to?: string; single?: boolean } & XStyledProps
> = ({ children, to, title, single, ...props }) => {
  const menu = (
    <x.div
      border="1px solid"
      borderColor="gray-200"
      borderRadius="6px"
      {...(single ? { borderRadius: "6px 0 0 6px" } : undefined)}
      backgroundColor="white"
      divideY="500px"
      {...props}
    >
      <x.div
        p="14px 18px"
        w="100%"
        fontWeight={single ? undefined : "bold"}
        fontSize="0.95rem"
        color="gray-700"
      >
        {title}
      </x.div>
      {children}
    </x.div>
  );

  if (single && to) {
    return <StyledNavLink to={to}>{menu}</StyledNavLink>;
  }

  return menu;
};

const StyledNavLink = styled(NavLink)`
  color: gray-700;
  > div {
    font-size: 0.95rem;
  }

  &.active > div {
    border-right: 2px solid;
    border-right-color: blue-500;
  }
`;

const SettingsMenuItem: React.FC<{ title: string; to: string }> = ({
  to,
  title,
}) => {
  return (
    <StyledNavLink to={to}>
      <x.div
        p="14px 18px"
        w="100%"
        borderTop="1px solid"
        borderColor="gray-200"
      >
        {title}
      </x.div>
    </StyledNavLink>
  );
};

export const SettingsPane: React.FC<{ title?: string } & XStyledProps> = ({
  children,
  title,
  ...props
}) => {
  return (
    <x.div px="40px" mb="20px">
      <x.h3 fontSize="1.1rem" color="gray-700" py="16px">
        {title}
      </x.h3>
      <x.div
        backgroundColor="white"
        border="1px solid"
        borderColor="gray-100"
        p="20px"
        {...props}
      >
        {children}
      </x.div>
    </x.div>
  );
};

export default ClusterLayout;
