import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Error,
  FormInput,
  FormTextArea,
  IconSelector,
  InputLayout,
  Page,
  Section,
  SmallFormLayout,
  TitleSection,
} from "@nexcloud/nc-ui";

import { IZone } from "store/redux/zone";
import { useCluster } from "hooks/cluster/useClusterDetailQuery";
import { request } from "api/common";

interface IClusterOption {
  name: string;
  description: string;
  platform: string | null;
  tenancyId: number;
  icon: string;
}

const EditCluster = () => {
  const { clusterId } = useParams<{ clusterId: string }>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<IClusterOption>({
    mode: "onChange",
  });

  const { data: cluster } = useCluster(clusterId, {
    onSuccess(data: IZone) {
      setValue("name", data.name);
      setValue("description", data.description ? data.description : "");
      setValue("icon", data.icon ? data.icon : "");
    },
  });

  const history = useHistory();

  if (!cluster) {
    return null;
  }

  const onSubmit = async (data: IClusterOption) => {
    setIsSubmitting(true);

    try {
      await request.put(`/lari/clusters/${clusterId}`, {
        description: data.description,
        icon: data.icon,
        name: data.name,
      });
      setIsSubmitting(false);
      history.push(`/clusters/${clusterId}`);
    } catch (e) {
      alert(e.message);
    }
  };

  return (
    <Page type="item">
      <TitleSection>
        <span>
          <Breadcrumb
            title="Clusters"
            path="/clusters"
            subPathTitle={`${cluster?.name}`}
            subPath={`/${clusterId}`}
          />
          <TitleSection type="withBreadcrumb">
            <h2>Edit Cluster</h2>
          </TitleSection>
        </span>
        <Button
          size="tiny"
          layoutVariant="outlined"
          variant="primary"
          to={`/clusters/${clusterId}`}
        >
          CANCEL
        </Button>
      </TitleSection>
      <Section>
        <SmallFormLayout>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputLayout>
              <FormInput
                label="CLUSTER NAME"
                placeholder="Cluser name"
                {...register("name", {
                  required: true,
                  pattern: /^[a-z0-9_-]+$/gi,
                })}
                onChange={(e) => {
                  const value = e.target.value || "";

                  e.target.value = value
                    .replace(/[^a-z0-9_-]/g, "-")
                    .replace(/-{2,}/g, "-")
                    .toLocaleLowerCase();
                }}
              />
              {errors.name && <Error>Enter Cluster Name.</Error>}
            </InputLayout>
            <InputLayout>
              <FormTextArea
                label="CLUSTER DESCRIPTION"
                placeholder="Description"
                rows={6}
                {...register("description", {})}
              />
            </InputLayout>
            <InputLayout>
              <Controller
                control={control}
                name="icon"
                render={({ field: { onChange } }) => (
                  <IconSelector
                    onChange={onChange}
                    type="cluster"
                    initialValue={cluster?.icon}
                  />
                )}
              />
            </InputLayout>
            <Button
              variant="primary"
              type="submit"
              loading={isSubmitting}
              icon="save"
              iconRight
            >
              SAVE
            </Button>
          </form>
        </SmallFormLayout>
      </Section>
    </Page>
  );
};

export default EditCluster;
