import { useHistory } from "react-router-dom";
import { Button, Link } from "@nexcloud/nc-ui";
import styled, { x } from "@xstyled/emotion";
import { Drawer, Typography } from "antd";

import CollapBox from "components/Cluster/CollapBox";

import paths from "paths";

import { device } from "style/device";

const { Title } = Typography;

const CollapContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 50px;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
`;

const DescContainer = styled.div`
  width: 500px;
  margin-right: 50px;
  height: 100%;
  overflow: auto;
  max-width: 1100px;
`;

const SkipButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  @media ${device.tablet} {
    width: 100%;
    text-align: right;
    margin-bottom: 10px;
  }
`;

const InstallationDrawer: React.FC<{
  showCollapBox: boolean;
  onClose(): void;
}> = ({ onClose, showCollapBox }) => {
  const history = useHistory();

  const closeDrawer = () => {
    onClose();
    history.push(paths.dashboard());
  };

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={closeDrawer}
      visible={showCollapBox}
      width="90%"
    >
      <CollapContainer>
        <DescContainer>
          <Title level={3}>New Cluster Installation</Title>
          <p>
            For more detail:{" "}
            <Link
              to="https://nexclipper.github.io/docs/quickstart"
              openNewWindow
            >
              NexClipper Quick Start
            </Link>
          </p>
        </DescContainer>
        <x.div>
          <CollapBox />
        </x.div>
      </CollapContainer>
      <SkipButton>
        <Button
          variant="primary"
          //Group 페이지가 만들어지면 해당 페이지로 이동하도록 수정 예정
          onClick={closeDrawer}
        >
          Skip
        </Button>
      </SkipButton>
    </Drawer>
  );
};

export default InstallationDrawer;
