import pathTool from "path";

import { IoIosArrowForward } from "react-icons/io";
import styled from "@emotion/styled";
import { x } from "@xstyled/emotion";

import { Link } from "./index";

interface IBreadcrumb {
  title: string;
  path: string;
  subPathTitle?: string;
  subPath?: string;
}

const Wrapper = styled.div`
  display: flex;
  font-family: poppins;
  font-style: regular;
  color: #1f29ca;
  margin-top: 15px;
`;

const Breadcrumb: React.FC<IBreadcrumb> = ({
  title,
  path,
  subPathTitle,
  subPath,
}) => {
  return (
    <Wrapper>
      <x.div display="flex">
        <Link to={path}>
          <x.p color="#1f29ca" fontSize="14px">
            {title.toUpperCase()}
          </x.p>
        </Link>
        <x.div m="0 5px 0 5px">
          <IoIosArrowForward />
        </x.div>
      </x.div>
      {subPathTitle && subPath && (
        <x.div>
          <Link to={pathTool.join(path, subPath)}>
            <x.p color="#1f29ca">{subPathTitle.toUpperCase()}</x.p>
          </Link>
        </x.div>
      )}
    </Wrapper>
  );
};

export default Breadcrumb;
