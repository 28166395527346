import React from "react";
import styled from "@emotion/styled/macro";

import type { Platform } from "store/redux/create";
import { platformList } from "store/redux/zone/reducer";
import { useCreateGet } from "hooks/state/get";
import useCreateActions from "hooks/state/useCreateActions";

import { device } from "style/device";

const Container = styled.div`
  width: 100%;
`;

const PlatformWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 40px 0;
  flex-wrap: wrap;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const LogoBox = styled.div`
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  img {
    width: 100%;
  }
`;

const PlatformBox = styled.div`
  width: 150px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1), 0 2px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 20px;
  opacity: ${({ loading, active }: { loading: string; active: boolean }) =>
    loading === "true" || !active ? "0.5" : "1"};
  cursor: ${({ loading, active }: { loading: string; active: boolean }) =>
    loading === "true" || !active ? "not-allowed" : "pointer"};
`;
const PlatformTitle = styled.div`
  width: 100%;
  text-align: center;
  color: #ffffff;
  background-color: #828282;
  padding: 10px;
`;

interface ISelectPlatform {
  onNext: (index: number) => void;
  onSelect: (value: Platform) => void;
  index: number;
}

function SelectPlatform({ onNext, index }: ISelectPlatform) {
  const loading = useCreateGet("loading");
  const createActions = useCreateActions();

  const onClickPlatform = (value: Platform, active: boolean) => {
    if (loading || !active) {
      return;
    }
    createActions.onSetData({ key: "platform", value });
    onNext(index);
  };

  return (
    <Container>
      <PlatformWrapper>
        {platformList.map((platform) => (
          <PlatformBox
            key={platform.get("id")}
            onClick={() =>
              onClickPlatform(platform.get("id"), platform.get("active"))
            }
            loading={loading.toString()}
            active={platform.get("active")}
          >
            <LogoBox>
              <img src={platform.get("icon")} />
            </LogoBox>
            <PlatformTitle>{platform.get("title")}</PlatformTitle>
          </PlatformBox>
        ))}
      </PlatformWrapper>
    </Container>
  );
}

export default SelectPlatform;
