import React from "react";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled/macro";

type param = { installed: any };

const Button = styled.div`
  cursor: ${({ installed }: param) => (installed ? "pointer" : "not-allowed")};
  filter: ${({ installed }: param) =>
    installed ? "grayscale(0)" : "grayscale(100%)"};
  img {
    width: 100%;
  }
`;

interface IManageButtonProps {
  url?: string | null;
  img: string;
  innerUrl?: string;
  installed: boolean;
}

function MonitoringButton({
  url,
  img,
  innerUrl,
  installed,
}: IManageButtonProps) {
  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    // 페이지 이동할 url이 없다면 아무것도 하지않는다.
    if (!url && !innerUrl) return;

    if (innerUrl) {
      history.push(innerUrl);
    } else {
      window.open(`http://${url}`);
    }
  };

  return (
    <Button installed={installed} onClick={handleClick}>
      <img src={img} />
    </Button>
  );
}

export default MonitoringButton;
