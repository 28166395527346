import React from "react";
import { RiCloseLine } from "react-icons/ri";
import { Flex } from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";

export const SelectedFilter: React.FC<{ label?: string; onRemove(): void }> = ({
  label,
  onRemove,
  children,
}) => {
  return (
    <Flex
      backgroundColor="#EEFAFF"
      color="#1F29CA"
      borderRadius="5px"
      p="10px 20px"
      mr="15px"
      position="relative"
    >
      {label && (
        <x.div mr="5px" fontSize="12px">
          {label}:
        </x.div>
      )}
      {children}
      <x.div
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top="-9px"
        right="-7px"
        w="16px"
        h="16px"
        borderRadius="50%"
        cursor="pointer"
        backgroundColor="#F9FAFB"
        fontSize="12px"
        onClick={onRemove}
      >
        <RiCloseLine />
      </x.div>
    </Flex>
  );
};
