import { combineReducers } from "redux";

import account from "./account";
import create from "./create";
import prometheus from "./prometheus";
import status from "./status";
import zone from "./zone";

const rootReducer = combineReducers({
  zone,
  status,
  create,
  account,
  prometheus,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
