import React, { useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { CaretDownOutlined, MoreOutlined } from "@ant-design/icons";
import { Flex, SpaceBetween } from "@nexcloud/nc-ui";
import styled, { x } from "@xstyled/emotion";
import { Select } from "antd";

import ClusterStatusIcon from "components/Cluster/ClusterStatusIcon";
import DeleteCluster from "components/Cluster/DeleteCluster";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  SmallDropdownMenuItem,
} from "components/common/Dropdown";

import { IZone } from "store/redux/zone";
import { useCurrentCluster } from "hooks/cluster/useClusterDetailQuery";
import useClusterList from "hooks/cluster/useClusterList";

import paths, { getClusterQueryParams } from "paths";

import { device } from "style/device";

const SelectBoxContainer = styled.div`
  display: flex;
  margin: auto 0;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  background-color: white;

  .ant-select .ant-select-selector {
    padding: 0 8px;
  }

  .ant-select {
    width: 150px;
  }

  .ant-select-selection-placeholder {
    font-weight: bold;
  }

  .ant-select-arrow {
  }

  @media ${device.tablet} {
    width: auto;
    height: auto;
    display: flex;
  }
  @media ${device.mobileL} {
    height: auto;
    flex-wrap: wrap;
  }
`;

const ClusterSelectBox = () => {
  const { data: currentCluster } = useCurrentCluster();
  const { data: clusterList } = useClusterList();
  const history = useHistory();
  const { pathname } = useLocation();

  const handleCluster = (value: number, option: any) => {
    const targetCluster = clusterList?.find((c) => c.id === value);
    if (!targetCluster) {
      return;
    }
    history.push(`${pathname}${getClusterQueryParams(targetCluster.id)}`);
  };

  if (!currentCluster) {
    return null;
  }

  return (
    <SelectBoxContainer>
      <Select
        placeholder="Select Cluster"
        value={currentCluster.id}
        bordered={false}
        onChange={handleCluster}
      >
        {clusterList?.map((c) => (
          <Select.Option key={currentCluster.id} value={currentCluster.id}>
            <SpaceBetween>
              <x.div overflow="hidden" textOverflow="ellipsis">
                {c.name}
              </x.div>
              <ClusterStatusIcon status={c.agentStatus} />
            </SpaceBetween>
          </Select.Option>
        )) ?? null}
      </Select>
    </SelectBoxContainer>
  );
};

const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const ContentBox = styled.div`
  height: calc(100vh - 255px);
  overflow: auto;
  display: flex;
  margin-top: 20px;
  padding: 5px;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #eee;

  @media ${device.tablet} {
    margin-left: 0;
    max-height: 700px;
  }

  @media ${device.mobileL} {
    max-height: none;
  }
`;

const StyledClusterMenu = styled(Flex)`
  .btn {
    font-weight: normal;
    margin-right: 10px;
    border: 1px solid;
    border-color: gray-200;
    padding: 10px 8px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      border-color: gray-400;
    }
  }
  a.btn,
  div.btn {
    color: gray-700;
  }

  .btn.active {
    color: gray-800;
    border-color: gray-500;
    font-weight: bold;
  }
`;

const CONFIG_PATHS = [
  paths.cluster.prometheusConfig(),
  paths.cluster.alertRulesConfig(),
  paths.cluster.recordingRulesConfig(),
  paths.cluster.alertManagerConfig(),
];

const ClusterMenu: React.FC<{ cluster: IZone }> = ({ cluster }) => {
  const { pathname } = useLocation();
  const configActive = CONFIG_PATHS.includes(pathname);

  return (
    <StyledClusterMenu>
      <NavLink className="btn" to={paths.cluster.detail(cluster.id)}>
        Cluster Info
      </NavLink>
      <NavLink className="btn" to={paths.cluster.dashboard(cluster.id)}>
        Health
      </NavLink>
      <Dropdown
        rootPath={paths.cluster.prometheusConfig(cluster.id)}
        active={configActive}
        icon={<CaretDownOutlined />}
        render={() => {
          return (
            <DropdownMenu>
              {[
                [
                  "Prometheus Config",
                  paths.cluster.prometheusConfig(cluster.id),
                ],
                ["Alert rules", paths.cluster.alertRulesConfig(cluster.id)],
                [
                  "Recording rules",
                  paths.cluster.recordingRulesConfig(cluster.id),
                ],
                ["Alertmanager", paths.cluster.alertManagerConfig(cluster.id)],
              ].map(([title, link], i) => (
                <DropdownMenuItem title={title} link={link} key={i} />
              ))}
            </DropdownMenu>
          );
        }}
      >
        <x.div>Edit Config</x.div>
      </Dropdown>
    </StyledClusterMenu>
  );
};

const ClusterLayout: React.FC = ({ children }) => {
  const { data: cluster } = useCurrentCluster(undefined, true);
  const [showModal, setShowModal] = useState(false);

  return (
    <x.div w="80%" m="auto" maxWidth="1200px">
      {cluster ? (
        <SpaceBetween px="20px" pt="10px">
          <Flex fontSize="1.3rem" fontWeight="bold">
            <ClusterSelectBox />
            <ClusterMenu cluster={cluster} />
          </Flex>
          <Dropdown
            render={() => {
              return (
                <DropdownMenu>
                  <SmallDropdownMenuItem
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    Delete this cluster
                  </SmallDropdownMenuItem>
                </DropdownMenu>
              );
            }}
          >
            <Flex
              backgroundColor="gray-50"
              borderRadius="100%"
              justifyContent="center"
              w="30px"
              h="30px"
              border="1px solid"
              borderColor="gray-300"
              cursor="pointer"
            >
              <MoreOutlined size={20} />
            </Flex>
          </Dropdown>
        </SpaceBetween>
      ) : null}
      <Container>
        <ContentBox>{children}</ContentBox>
      </Container>
      <DeleteCluster
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      />
    </x.div>
  );
};

export default ClusterLayout;
