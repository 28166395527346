import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  FormInput,
  FormTextArea,
  InputLayout,
  TitleSection,
} from "@nexcloud/nc-ui";
import styled, { x } from "@xstyled/emotion";

interface IActionEditor {
  title: "Report a New Action" | "Add a New Action";
  setIsOpenActionEditor?: any;
  setIncidentActionTitle?: any;
  setIncidentActionDesc?: any;
  onSave: any;
}

const Wrapper = styled.div`
  width: 100%;
  font-family: poppins;
`;
type IAddAction = {
  title: string;
  description: string;
};
const ActionEditor: React.FC<IActionEditor> = ({
  title,
  setIsOpenActionEditor,
  setIncidentActionTitle,
  setIncidentActionDesc,
  onSave,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const { register, handleSubmit, reset } = useForm<IAddAction>({
    mode: "onChange",
  });

  const onClose = () => {
    setIsOpen(false);
    setIsOpenActionEditor(false);
  };

  const handleOnSave = (data: IAddAction) => {
    if (typeof setIncidentActionTitle === "function") {
      setIncidentActionTitle(data.title);
    }
    if (typeof setIncidentActionDesc === "function") {
      setIncidentActionDesc(data.description);
    }
    onSave({
      title: data.title,
      description: data.description,
    });
    reset({
      title: "",
      description: "",
    });
  };

  return (
    <>
      {isOpen && (
        <form onSubmit={handleSubmit(handleOnSave)}>
          <Wrapper>
            <TitleSection>
              <h2>{title}</h2>
              <Button
                layoutVariant="outlined"
                variant="primary"
                onClick={onClose}
              >
                {title === "Add a New Action" ? "CANCEL" : "CLOSE"}
              </Button>
            </TitleSection>
            <InputLayout>
              <x.div w="734px">
                <FormInput
                  label="Action Title"
                  id="title"
                  type="text"
                  {...register("title", {
                    required: true,
                  })}
                />
              </x.div>
            </InputLayout>
            <InputLayout>
              <x.div w="734px">
                <FormTextArea
                  maxLength="250"
                  label="Action Description"
                  id="description"
                  {...register("description")}
                  rows={6}
                />
              </x.div>
            </InputLayout>
            <Button
              layoutVariant="contained"
              variant="primary"
              icon="save"
              iconRight
              type="submit"
            >
              SAVE
            </Button>
          </Wrapper>
        </form>
      )}
    </>
  );
};

export default ActionEditor;
