import { useQuery, UseQueryOptions } from "react-query";
import { AxiosError } from "axios";

import { request } from "api/common";

export interface IAccessGroupClusterList {
  clusterId: number;
  description: string;
  name: string;
}

export const useAccessGroupClusterList = (
  id: number,
  options?: UseQueryOptions<IAccessGroupClusterList[], AxiosError>
) => {
  return useQuery<IAccessGroupClusterList[], AxiosError>(
    ["accessClusterList", id],
    async () => {
      const { data } = await request.get(`/lari/accessgroups/${id}/clusters`);
      return data;
    },
    {
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};
