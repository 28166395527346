import { useQuery } from "react-query";
import type { AxiosError } from "axios";

import { request } from "api/common";

export interface IChannel {
  channelType: string;
  createdAt: string;
  createdBy: number;
  description: string;
  id: number;
  name: string;
  updatedAt: string;
  updatedBy: number;
}

export const useChannels = () => {
  return useQuery<IChannel[], AxiosError>(
    ["channels"],
    async () => {
      const { data } = await request.get<IChannel[]>(`/lari/alertchannels`);
      return data;
    },
    {
      cacheTime: 30 * 1000,
    }
  );
};

export const useChannelsDetail = (channelId: any) => {
  return useQuery<IChannel, AxiosError>(
    ["channelsDetail", channelId],
    async () => {
      const { data } = await request.get<IChannel>(
        `/lari/alertchannels/${channelId}`
      );
      return data;
    },
    {
      cacheTime: 30 * 1000,
    }
  );
};
