import React, { useState } from "react";
import {
  Button,
  Column,
  ColumnSection,
  Loading,
  SpaceBetween,
  TitleSection,
} from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";
import { message, Table } from "antd";

import { useChannels, useChannelsDetail } from "hooks/channel";
import { request } from "api/common";

import { AddChannelModal, EditChannelModal } from "./Modal";

const Channel = () => {
  const { data: channels, refetch } = useChannels();
  const [showingModal, setShowingModal] = useState<"CREATE" | "EDIT" | null>(
    null
  );
  const [selectedChannel, setSelectedChannel] = useState<number | undefined>(
    undefined
  );
  const [selectedChannelIds, setSelectedChannelIds] = useState<React.Key[]>([]);

  if (!channels) {
    return (
      <ColumnSection>
        <TitleSection>
          <h3>Channel</h3>
        </TitleSection>
        <Loading />
      </ColumnSection>
    );
  }

  return (
    <ColumnSection>
      <TitleSection>
        <h3>Channel</h3>
        <Column columnsGap="10px">
          <Button
            layoutVariant="outlined"
            onClick={async () => {
              if (selectedChannelIds.length === 0) {
                message.warn("Please select channel first");
                return;
              }
              const confirmed = window.confirm(
                "Are you sure removing this channel?"
              );
              if (!confirmed) {
                return;
              }

              await Promise.all(
                selectedChannelIds.map((id) => {
                  return request.delete(`/lari/alertchannels/${id}`);
                })
              );

              message.success(`Successfully deleted alert channel`);
              refetch();
            }}
          >
            Delete
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setSelectedChannel(undefined);
              setShowingModal("CREATE");
            }}
          >
            Add Channel
          </Button>
        </Column>
      </TitleSection>
      <SpaceBetween>
        <x.div w={selectedChannel ? "68%" : "100%"} h="500px">
          <x.div>
            <Table
              dataSource={channels}
              onRow={(record) => {
                return {
                  onClick: () => {
                    setSelectedChannel(record.id);
                  },
                };
              }}
              rowSelection={{
                selectedRowKeys: selectedChannelIds,
                onChange(selectedRowKeys) {
                  setSelectedChannelIds(selectedRowKeys);
                },
              }}
              rowKey="id"
              columns={[
                {
                  title: "Type",
                  dataIndex: "channelType",
                  key: "type",
                },
                {
                  title: "Channel Info",
                  dataIndex: "name",
                  key: "name",
                },
              ]}
            />
          </x.div>
        </x.div>
        {selectedChannel ? (
          <SelectedChannel
            refetch={refetch}
            showingModal={showingModal}
            setShowingModal={setShowingModal}
            channelId={selectedChannel}
            onSelect={(channelId) => {
              setSelectedChannel(channelId);
              setShowingModal("EDIT");
            }}
            onClose={() => {
              setSelectedChannel(undefined);
            }}
          />
        ) : null}
      </SpaceBetween>
      {showingModal === "CREATE" && (
        <AddChannelModal
          showModal={showingModal}
          onClose={() => {
            setShowingModal(null);
            refetch();
          }}
        />
      )}
    </ColumnSection>
  );
};

const SelectedChannel: React.FC<{
  refetch(): void;
  channelId: number;
  onSelect(channelId: number): void;
  onClose(): void;
  setShowingModal: any;
  showingModal: "CREATE" | "EDIT" | null;
}> = ({
  channelId,
  onClose,
  onSelect,
  setShowingModal,
  showingModal,
  refetch,
}) => {
  const { data: channelsDetail, refetch: refetchDetail } =
    useChannelsDetail(channelId);

  if (!channelsDetail) {
    return <Loading />;
  }
  return (
    <x.div
      w="30%"
      h="500px"
      overflowY="scroll"
      p="15px"
      border="1px solid"
      borderColor="gray-100"
    >
      <x.div pb="20px" position="relative">
        <x.div
          onClick={onClose}
          position="absolute"
          top="5px"
          right="5px"
          cursor="pointer"
        >
          x
        </x.div>
        <x.div fontWeight="bold" pb="10px">
          {channelsDetail.id}
        </x.div>
        <x.div color="gray-500" fontSize="0.9rem">
          {channelsDetail.name}
        </x.div>
      </x.div>
      <Button
        variant="primary"
        onClick={() => {
          setShowingModal("EDIT");
          onSelect(channelsDetail.id);
        }}
      >
        Edit Channel
      </Button>
      {showingModal === "EDIT" && (
        <EditChannelModal
          channelId={channelId}
          showModal={showingModal}
          onClose={() => {
            setShowingModal(null);
            refetch();
            refetchDetail();
          }}
        />
      )}
    </x.div>
  );
};

export default Channel;
