import styled from "@emotion/styled";

const Error = styled.p`
  margin-top: 6px;
  font-size: 13px;
  line-height: 18px;
  color: #ff754c;
  font-weight: 500;
  font-family: poppins;
`;

export default Error;
