import React from "react";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled/macro";
import { Button } from "@nexcloud/nc-ui";
import { Result } from "antd";

import paths from "paths";

import { device } from "style/device";

const ContentBox = styled.div`
  width: 95%;
  height: 100%;
  overflow: auto;
  max-width: 1100px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;

  @media ${device.tablet} {
    width: 100%;
  }
`;

function TempTenancy() {
  const history = useHistory();

  return (
    <Container>
      <ContentBox>
        <Result
          title="There's no organization you belong to"
          extra={
            <Button
              variant="primary"
              onClick={() => {
                history.push(paths.settings.my());
              }}
            >
              Go to Settings
            </Button>
          }
        />
      </ContentBox>
    </Container>
  );
}

export default TempTenancy;
