import React, { useEffect, useState } from "react";
import styled from "@emotion/styled/macro";

import type { Monitoring } from "store/redux/create";
import type { MonitoringData } from "store/redux/zone";
import { monitoringList } from "store/redux/zone/reducer";

interface IMonitoringIconProps {
  styles?: any;
  id: Monitoring;
  type: "icon" | "text";
  disabled?: boolean;
  onClick?: (e?: any) => void;
}

const TypeBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const IconBox = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  filter: ${({ disabled }: { disabled: any }) =>
    !disabled ? "grayscale(0)" : "grayscale(100%)"};

  img {
    width: 100%;
  }
`;

const Type = styled.div`
  margin-left: 7px;
  color: #8a8a8a;
`;

const MonitoringIcon: React.FC<IMonitoringIconProps> = ({
  styles,
  id,
  type,
  disabled,
  children = null,
  onClick,
}) => {
  const [monitoring, setMonitoring] = useState<MonitoringData | null>(null);

  useEffect(() => {
    if (!id) return;
    const targetMonitoring = monitoringList.find((m) => m.get("id") === id)!;
    setMonitoring(targetMonitoring);
  }, [id]);

  if (!monitoring) return null;

  let img;
  if (typeof monitoring.get("icon") === "string") {
    img = <img src={monitoring.get("icon")} alt={monitoring.get("title")} />;
  } else {
    const Icon = monitoring.get("icon");
    img = <Icon />;
  }

  if (type === "icon") {
    return (
      <IconBox style={{ ...styles }} disabled={disabled} onClick={onClick}>
        {img}
      </IconBox>
    );
  }

  return (
    <TypeBox style={{ ...styles }}>
      <IconBox disabled={disabled}>{img}</IconBox>
      <Type>{monitoring.get("title")}</Type>
      {children}
    </TypeBox>
  );
};

export default MonitoringIcon;
