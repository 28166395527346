import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "@emotion/styled/macro";

import FixContainer from "components/Layout/FixContainer";

import useQueryParams from "hooks/util/useQueryParams";

import JoinForm from "./Form";

import paths from "paths";

import Logo from "assets/logo-hor.png";

const JoinContainer = styled.div`
  max-width: 500px;
  width: 90%;
  padding: 30px;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
`;

const LogoBox = styled.div`
  width: 60%;
  margin: auto;
  margin-bottom: 30px;
  cursor: pointer;
`;

const LogoImg = styled.img`
  width: 100%;
`;
LogoImg.defaultProps = { src: Logo };

const SignInBox = styled.div`
  width: 100%;
  font-size: 0.9em;
  margin-top: 20px;

  * {
    font-size: 1em;
  }

  span {
    margin-right: 5px;
    color: #808090;
  }
`;

function Join() {
  const { push } = useHistory();
  const query = useQueryParams();
  const verificationCode = query.get("inviteCode");

  return (
    <FixContainer>
      <JoinContainer>
        <LogoBox
          onClick={() => {
            push("/");
          }}
        >
          <LogoImg />
        </LogoBox>
        <JoinForm verificationCode={verificationCode} />
        <SignInBox>
          <span>Already have an account? </span>
          <Link to={paths.login(verificationCode)}>Sign In</Link>
        </SignInBox>
      </JoinContainer>
    </FixContainer>
  );
}

export default Join;
