import React from "react";
import ReactDOM from "react-dom";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { Preflight, ThemeProvider } from "@xstyled/emotion";
import { message, notification } from "antd";
import { queryClient } from "query";

import App from "components/App";

import configure from "store/configure";

import "antd/dist/antd.css";
import GlobalStyle from "style/global";
import theme from "style/theme";

const store = configure();

Bugsnag.start({
  apiKey:
    process.env.REACT_APP_BUGSNAG_API_KEY || "ca58cd5085a5a198ba2755763cc15932",
  enabledReleaseStages: ["production"],
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV,
  logger: null,
});

const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);
message.config({ maxCount: 1, duration: 1.5 });
notification.config({
  top: 60,
});

const showReactQueryDevTools = window.location.search
  .toLowerCase()
  .includes("showrqdevtools");

function Root() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <Preflight />
              <App />
            </ThemeProvider>
            {showReactQueryDevTools && <ReactQueryDevtools initialIsOpen />}
          </QueryClientProvider>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));
