import React, { ComponentProps } from "react";
import { x } from "@xstyled/emotion";

type IBorderBox = ComponentProps<typeof x.div>;

const BorderBox: React.FC<IBorderBox> = ({ ...props }) => {
  return <x.div borderRadius="5px" border="1px solid #e4e4e4" {...props} />;
};

export default BorderBox;
