import React, { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import styled from "@emotion/styled/macro";
import { x } from "@xstyled/emotion";
import { Modal, Table } from "antd";
import { format, formatDistance } from "date-fns";
import type { Notification } from "notification";

import { useNotifications } from "hooks/notis";
import { request } from "api/common";

import { getProcessedNotiParams } from "./format";

import { device } from "style/device";

const columns = [
  {
    title: "Title",
    dataIndex: "title",
    render(value: any, noti: Notification) {
      const params = getProcessedNotiParams(
        noti.eventCode,
        noti.data?.paramDatas ?? noti.data?.params
      );
      return params.title;
    },
  },
  {
    title: "Description",
    dataIndex: "text",
    render(value: any, noti: Notification) {
      const params = getProcessedNotiParams(
        noti.eventCode,
        noti.data?.paramDatas ?? noti.data?.params
      );
      return params.message;
    },
  },

  {
    title: "Type",
    dataIndex: "eventCode",
    // filters: [
    //   { text: "TASK", value: "TASK" },
    //   { text: "ALERT", value: "ALERT" },
    //   { text: "INFO", value: "INFO" },
    // ],
    // onFilter: (value: any, row: any) => row.eventCode.indexOf(value) === 0,
    render(value: any) {
      return <x.div fontSize="0.85em">{value}</x.div>;
    },
  },
  // {
  //   title: "Action",
  //   dataIndex: "groupCode",
  // },
  {
    title: "Date",
    dataIndex: "createTime",
    render(_date: string) {
      const date = _date + "Z"; // FIXME: server sends UTC date (ISO 8601) without trailing Z
      return (
        <x.div>
          {formatDistance(new Date(date), new Date(), {
            addSuffix: true,
          })}{" "}
          <x.span fontSize="0.9em">
            ({format(new Date(date), "yy-MM-dd hh:mm:ss")})
          </x.span>
        </x.div>
      );
    },
  },
];

const Title = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  margin-right: 0.5em;
`;

const Content = () => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const deleteNotification = () => {
    setDeleteModalOpen(false);
    request.delete(`notis/note/note`, {
      params: {
        noteId: selectedRowKeys[0],
      },
    });
    window.location.reload();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const { data: value } = useNotifications();
  const notis = value?.notes?.content;

  return (
    <div>
      <x.div display="flex" justifyContent="space-between">
        <x.div display="flex" alignItems="center" mb="25px">
          <Title>Notifications</Title>
          {selectedRowKeys.length > 0 && (
            <RiDeleteBin6Line
              style={{ fontSize: "1.3em", cursor: "pointer" }}
              onClick={() => setDeleteModalOpen(true)}
            />
          )}
        </x.div>
      </x.div>
      <Table
        rowSelection={rowSelection}
        rowKey={"id"}
        dataSource={notis}
        columns={columns}
      />
      <Modal
        visible={deleteModalOpen}
        okText="Delete"
        okType="danger"
        onCancel={() => {
          setDeleteModalOpen(false);
        }}
        onOk={deleteNotification}
        style={{ top: 300 }}
      >
        <p
          style={{
            fontWeight: "bold",
            fontSize: "1.3em",
            marginBottom: "1em",
          }}
        >
          Confirm Delete
        </p>
        <p>Are you sure you want to Delete?</p>
      </Modal>
    </div>
  );
};

const ContentBox = styled.div`
  width: 95%;
  height: 100%;
  overflow: auto;
  max-width: 1100px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;

  @media ${device.tablet} {
    width: 100%;
  }
`;

function NotificationList() {
  return (
    <Container>
      <ContentBox>
        <Content />
      </ContentBox>
    </Container>
  );
}

export default NotificationList;
