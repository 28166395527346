import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled/macro";
import { Button, Loading } from "@nexcloud/nc-ui";
import { Checkbox, Form, Input, Modal } from "antd";

import {
  showEmailLinkError,
  showTryAgain,
  showWrongPwd,
} from "components/NotificationMessage";

import { prdCode, request } from "api/common";

import modal from "utils/modal";

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ant-form-item {
    margin-bottom: 7px;
  }

  span {
    font-size: 0.9em;
  }
`;

const Label = styled.div`
  font-size: 0.9em;
  color: #808090;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;

  * {
    font-size: 1em;
  }
`;

const LoginForm: React.FC<{
  verificationCode: string | null;
  tenancyId: number | undefined;
}> = ({ verificationCode, tenancyId }) => {
  const [userMail, setUserMail] = useState("");
  const [userPwd, setUserPwd] = useState("");
  const [loading, setLoading] = useState(false);

  const onUserMail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const mail = e.target.value;
    setUserMail(mail);
  };

  const onUserPwd = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setUserPwd(password);
  };

  const onFinish = async (values: any) => {
    if (loading) {
      return <Loading />;
    }
    setLoading(true);

    const payload = {
      loginId: userMail,
      loginPw: userPwd,
    } as any;

    if (verificationCode) {
      payload.verificationCode = verificationCode;
    }

    if (tenancyId) {
      payload.tenancyId = tenancyId;
    }

    try {
      const response = await request.post(
        `/users/login/conventional/${prdCode}`,
        payload,
        {
          withCredentials: true,
        }
      );

      const { result } = response.data;
      if (result === "SUCCESS") {
        window.location.pathname = "/";
      } else if (result === "NOT_EMAIL_VERIFIED_YET") {
        return showEmailLinkError();
      } else if (result === "LOCKED") {
        return modal.error("Please contact support", {
          title: "Your account is locked",
        });
      } else if (result === "FAILED") {
        return showWrongPwd();
      }
    } catch (e) {
      if (e.response?.data?.message) {
        modal.warning(e.response.data.message);
      } else {
        showTryAgain();
      }
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (values: any) => {
    Modal.warning({
      title: "Warning",
      content: "Please check your email and password",
    });
  };

  return (
    <Form
      name="login"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Label>
        <span>Email address</span>
      </Label>
      <Form.Item
        name={["user", "email"]}
        rules={[
          {
            type: "email",
            message: "Invalid email",
          },
          {
            required: true,
            message: "Please enter your email",
          },
        ]}
      >
        <Input placeholder="Email address" onChange={onUserMail} />
      </Form.Item>
      <Label>
        <span>Password</span>
        <Link to="/reset">Forgot Password?</Link>
      </Label>

      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your password" }]}
      >
        <Input.Password placeholder="Password" onChange={onUserPwd} />
      </Form.Item>
      <CheckBoxContainer>
        <Form.Item name="save" valuePropName="checked">
          <Checkbox name="save">Save Email</Checkbox>
        </Form.Item>
        <Form.Item name="remember" valuePropName="checked">
          <Checkbox name="remember">Keep me signed in</Checkbox>
        </Form.Item>
      </CheckBoxContainer>
      <Form.Item>
        <Button variant="primary" htmlType="submit" expand>
          Sign In
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
