import styled from "@emotion/styled";

interface IPage {
  type?: "item" | "list";
}

const Layout = styled.div`
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding-bottom: 150px;
  padding-left: 20px;
  padding-right: 20px;
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1;
  &.item {
    background-color: #f9fafb;
  }
  &.list {
  }
`;

const Page: React.FC<IPage> = ({ children, type }) => {
  return <Layout className={type}>{children}</Layout>;
};

export default Page;
