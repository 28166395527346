import React from "react";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import { Loading } from "@nexcloud/nc-ui";

import PrivateRoute from "components/PrivateRoute";
import Channel from "components/Settings/Channel/Channel";
import OrgAdmin from "components/Settings/OrgAdmin";
import Organization from "components/Settings/Organization";
import SettingsLayout from "components/Settings/SettingsLayout";
import UserGroups from "components/Settings/UserGroups";
import UserProfile from "components/Settings/UserProfile";
import ManageUsers from "components/Settings/Users";

import { usePermission } from "hooks/user";

const Settings: React.FC<{ authed: boolean }> = ({ authed }) => {
  const { path } = useRouteMatch();
  const { data: perm } = usePermission();

  if (perm === undefined) {
    return <Loading />;
  }

  return (
    <Switch>
      <SettingsLayout>
        <Switch>
          <PrivateRoute
            exact
            path={`${path}/my`}
            authed={authed}
            component={UserProfile}
          />
          <PrivateRoute
            exact
            path={`${path}/organization/general`}
            authed={authed}
          >
            <Organization />
          </PrivateRoute>
          <PrivateRoute
            path={`${path}/organization/permission`}
            authed={authed}
          >
            <OrgAdmin />
          </PrivateRoute>
          <PrivateRoute path={`${path}/users/manage`} authed={authed}>
            <ManageUsers />
          </PrivateRoute>
          <PrivateRoute
            path={`${path}/channel`}
            authed={authed}
            component={Channel}
          ></PrivateRoute>
          <PrivateRoute path={`${path}/users/groups`} authed={authed}>
            <UserGroups />
          </PrivateRoute>
          <Redirect from={`${path}/users`} to={`${path}/users/groups`} />
          <Redirect
            from={`${path}/organization`}
            to={`${path}/organization/general`}
          />
          <Redirect to={`${path}/my`} />
        </Switch>
      </SettingsLayout>
    </Switch>
  );
};

export default Settings;
