import { useForm } from "react-hook-form";
import { BsThreeDots } from "react-icons/bs";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Flex,
  FormInput,
  FormTextArea,
  InputLayout,
  InputLayout2Col,
  Page,
  Section,
  Selector,
  SmallFormLayout,
  TitleSection,
  UserBadge,
} from "@nexcloud/nc-ui";

import { useIncidentDetail } from "hooks/incident";
import { request } from "api/common";

type IEditIncident = {
  actions: [
    {
      description: string;
      title: string;
    }
  ];
  assigneeId: number;
  dueDate: string;
  note: string;
  priority: "HIGH" | "LOW" | "MEDIUM" | "URGENT";
  relatedAlertIds: number[];
  title: string;
};
const EditIncident = () => {
  const params = useParams<{ id: string }>();
  const incidentId = Number(params.id);
  const history = useHistory();
  const onSubmit = async (data: IEditIncident) => {
    try {
      await request.put(`/lari/incidents/${incidentId}`, {
        assigneeId: 0,
        dueDate: `${data.dueDate}T00:00:00.000Z`,
        note: data.note,
        priority: "HIGH",
        relatedAlertIds: [0],
        title: data.title,
      });
      history.push("/incident");
    } catch (e) {
      alert(e.message);
    }
  };
  const { data: incident } = useIncidentDetail(incidentId);
  const { register, handleSubmit, control } = useForm<IEditIncident>({
    mode: "onChange",
    defaultValues: {
      title: incident?.title,
      dueDate: (incident?.dueDate || "").split("T")[0],
      note: incident?.note,
    },
  });
  if (!incident) {
    return null;
  }
  return (
    <Page type="item">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Section>
          <TitleSection>
            <span>
              <Breadcrumb
                title="Incident"
                path="/incident"
                subPathTitle={`INC-${params.id}`}
                subPath={`/${params.id}`}
              />
              <h2>Edit Incident</h2>
            </span>
            <Button
              layoutVariant="outlined"
              variant="primary"
              to={`/incident/${params.id}`}
            >
              CANCEL
            </Button>
          </TitleSection>
          <SmallFormLayout>
            <InputLayout>
              <FormInput
                label="Title"
                id="title"
                type="text"
                {...register("title", {
                  required: true,
                })}
              />
            </InputLayout>
            <InputLayout2Col>
              <Selector type="Priority" label="Priority" control={control} />
              <FormInput
                label="Due Date"
                type="date"
                id="dueDate"
                {...register("dueDate")}
              />
            </InputLayout2Col>

            <p>Assignee</p>
            <Flex color="#9C9C9C">
              <UserBadge isUnassigned />
              <BsThreeDots />
            </Flex>
            <InputLayout>
              <FormTextArea
                maxLength="500"
                label="Incident Description"
                id="note"
                rows={6}
                {...register("note")}
              />
            </InputLayout>
          </SmallFormLayout>
        </Section>
      </form>

      <Section>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Button layoutVariant="contained" variant="primary" type="submit">
            SAVE
          </Button>
        </form>
      </Section>
    </Page>
  );
};

export default EditIncident;
