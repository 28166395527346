import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  FormInput,
  FormTextArea,
  InputLayout,
  InputLayout2Col,
  Section,
  SelectingTable,
  SmallFormLayout,
} from "@nexcloud/nc-ui";
import styled from "@xstyled/emotion";
import { fetchOption } from "config";

import useClusterList from "hooks/cluster/useClusterList";
import { getTenancyId, request } from "api/common";

const Error = styled.p`
  font-size: 14px;
  margin: 0 0 8px;
  line-height: 24px;
  color: #ff754c;
  font-weight: 500;
  font-family: poppins;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 16px;
  color: #374151;
  font-family: poppins;
`;

const clusterColumn = [
  {
    Header: "CLUSTER NAMES",
    accessor: "name",
  },
  {
    Header: "DESCRIPTION",
    accessor: "description",
    disableSortBy: true,
  },
];

type IAddAlert = {
  alertName: string;
  alertRule: string;
  clusterId: number;
  createdAt: string;
  description: string;
  from: "LARI" | "PROMETHEUS";
  lastUpdatedAt: string;
  node: string;
  note: string;
  numberOfOccurrences: 0;
  severity: string;
  source: string;
  status: "CLOSED" | "HIDED" | "OPEN" | "REFERENCED";
  summary: string;
};

const AlertsTab = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IAddAlert>({
    mode: "onChange",
    defaultValues: {
      severity: "Warning",
      alertRule: "mongodb up{} == 0 for 2 min",
      source: "150.25.33.221 : 35001",
      from: "PROMETHEUS",
      status: "OPEN",
      node: "NODE-1",
    },
  });

  const { data } = useClusterList(fetchOption);
  const [selectedRow, setSelectedRow] = useState([]);
  const tenancyID = getTenancyId();

  const onSubmit = async (data: IAddAlert) => {
    if (selectedRow.length !== 1) {
      window.alert("Please choose one cluster.");
      return;
    }

    try {
      await request.post(
        `/lari/alerts`,
        {
          alertName: data.alertName,
          alertRule: data.alertRule,
          //  @ts-ignore
          clusterId: selectedRow[0]?.original.id
            ? //  @ts-ignore
              selectedRow[0]?.original.id
            : 1,
          createdAt: "2021-09-13T05:59:53.637Z",
          description: data.description,
          from: data.from,
          lastUpdatedAt: "2021-09-13T05:59:53.637Z",
          node: data.node,
          note: data.note,
          numberOfOccurrences: 0,
          severity: data.severity,
          source: data.source,
          status: data.status,
          summary: data.summary,
        },
        { headers: { tenancyId: tenancyID } }
      );
    } catch (e) {
      alert("There was an error. Please try again later.");
    }
  };

  return (
    <Section>
      <SmallFormLayout>
        <h3>Create New Alert</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputLayout>
            <FormInput
              label="Alert Name*"
              id="alertName"
              type="text"
              {...register("alertName", {
                required: true,
              })}
            />
            {errors.alertName && <Error>Enter Alert Name.</Error>}
          </InputLayout>
          <InputLayout>
            <FormTextArea
              label="Description*"
              id="description"
              maxLength={500}
              {...register("description", {
                required: true,
              })}
            />
            {errors.description && <Error>Enter Alert Description.</Error>}
          </InputLayout>
          <InputLayout>
            <FormInput
              label="Summary*"
              id="summary"
              type="text"
              {...register("summary", {
                required: true,
              })}
            />
            {errors.summary && <Error>Enter Alert Summary.</Error>}
          </InputLayout>
          <InputLayout>
            <FormTextArea
              label="Note"
              id="note"
              maxLength={500}
              {...register("note", {
                required: false,
              })}
            />
          </InputLayout>

          <Section>
            <Label>Choose only one cluster*</Label>
            <SelectingTable
              columns={clusterColumn}
              data={data}
              setSelectedRow={setSelectedRow}
            />
          </Section>

          <InputLayout2Col>
            <FormInput
              label="Severity* (ex : 'Information', 'Warning', 'Critical')"
              id="severity"
              type="text"
              {...register("severity", {
                required: true,
              })}
            />
            {errors.severity && <Error>Enter Alert Severity.</Error>}
            <FormInput
              label="Alert Rule*"
              id="alertRule"
              type="text"
              {...register("alertRule", {
                required: true,
              })}
            />
            {errors.alertRule && <Error>Enter Alert Rule.</Error>}
          </InputLayout2Col>
          <InputLayout2Col>
            <FormInput
              label="Source*"
              id="source"
              type="text"
              {...register("source", {
                required: true,
              })}
            />
            {errors.source && <Error>Enter Alert Source.</Error>}
            <FormInput
              label="From* (ex : 'LARI', 'PROMETHEUS')"
              id="from"
              type="text"
              {...register("from", {
                required: true,
              })}
            />
            {errors.from && <Error>Enter from.</Error>}
          </InputLayout2Col>
          <InputLayout2Col>
            <FormInput
              label="status* (ex : 'CLOSED', 'HIDED', 'OPEN', 'REFERENCED')"
              id="status"
              type="text"
              {...register("status", {
                required: true,
              })}
            />
            {errors.status && <Error>Enter Alert Status.</Error>}
            <FormInput
              label="Node*"
              id="node"
              type="text"
              {...register("node", {
                required: true,
              })}
            />
            {errors.node && <Error>Enter Node.</Error>}
          </InputLayout2Col>
          <Button layoutVariant="outlined" variant="primary" type="submit">
            Add
          </Button>
        </form>
      </SmallFormLayout>
    </Section>
  );
};

export default AlertsTab;
