import { all, fork, put } from "redux-saga/effects";

import * as AccountActions from "store/redux/account";
import * as CreateActions from "store/redux/create";
import * as PrometheusActions from "store/redux/prometheus";
import * as ZoneActions from "store/redux/zone";

import * as AccountSaga from "./account";
import {
  takeEveryWithException as takeEvery,
  takeLatestWithException as takeLatest,
} from "./common";
import * as CreateSaga from "./create";
import * as PrometheusSaga from "./prometheus";
import * as ZoneSaga from "./zone";

export default function* rootSaga() {
  yield all([
    fork(handleAccount),
    fork(handleCreate),
    fork(handleZone),
    fork(handlePrometheus),
  ]);
}

function* handleAccount() {
  yield takeEvery(AccountActions.logout, AccountSaga.logout);
  yield takeEvery(AccountActions.getAccount, AccountSaga.getAccount);
}

function* handleCreate() {
  function* onFinally() {
    yield put(CreateActions.setData({ key: "loading", value: false }));
  }

  yield takeEvery(
    CreateActions.createCluster,
    CreateSaga.createCluster,
    undefined,
    onFinally
  );
  yield takeEvery(
    CreateActions.validateClusterName,
    CreateSaga.validateClusterName,
    undefined,
    onFinally
  );
}

function* handleZone() {
  // yield takeEvery(ZoneActions.getZones, ZoneSaga.getZones);
  yield takeEvery(ZoneActions.getZone, ZoneSaga.getZone);
  yield takeEvery(ZoneActions.getP8SConfig, ZoneSaga.getP8SConfig);
  yield takeEvery(ZoneActions.getP8SServices, ZoneSaga.getP8SServices);
  yield takeEvery(ZoneActions.editP8SConfig, ZoneSaga.editP8SConfig);
  yield takeEvery(ZoneActions.getAlertConfig, ZoneSaga.getAlertConfig);
  yield takeEvery(ZoneActions.editAlertConfig, ZoneSaga.editAlertConfig);
  yield takeEvery(ZoneActions.getClusterHealth, ZoneSaga.getClusterHealth);
  // yield takeEvery(ZoneActions.getTasks, ZoneSaga.getTasks);
  yield takeEvery(ZoneActions.applyP8SConfig, ZoneSaga.applyP8SConfig);
  yield takeLatest(ZoneActions.applyAlertConfig, ZoneSaga.applyAlertConfig);
  yield takeLatest(ZoneActions.resetConfig, ZoneSaga.resetConfig);
}

function* handlePrometheus() {
  yield takeEvery(PrometheusActions.create, PrometheusSaga.create);
}
