import { fetchOption } from "config";
import { QUERY_KEYS, queryClient } from "query";
import { Pageable } from "types";

import { useCurrentUser } from "hooks/user";
import useInterval from "hooks/util/useInterval";
import { request } from "api/common";

export interface INotiResponse {
  notes?: Pageable<Notification[]>;
  timeStamp: string;
  userId: number;
}

export interface Notification {
  actions: any;
  createTime: string; //time
  eventCode: string;
  groupCode: string;
  id: number;
  prdCode: string;
  read: boolean;
  system: boolean;
  tenancyId: number;
  text: string;
  title: string;
  userId: number;
  visable: boolean;
  data?: {
    paramDatas: any;
    params: any;
  };
}

let lastTimestamp = new Date().toISOString(); // TODO: check if timestamp is correct
// singleton noti queue
let taskQueue: Notification[];

function emptyTaskQueue() {
  taskQueue.length = 0;
}

const NOTI_REQUEST_INTERVAL = 5000;
let isRequesting = false;

// This is singleton hook, shouldn't be used other than root component
export function useNotificationService() {
  const { data: user } = useCurrentUser();

  useInterval(async () => {
    const userId = user?.id;
    if (fetchOption) {
      return;
    }
    if (!userId || isRequesting) {
      return;
    }
    isRequesting = true;

    try {
      const { data } = await request.get<INotiResponse>("/notis/note/notes", {
        params: {
          size: 50,
          userId,
          viewInvisable: false,
          timestamp: lastTimestamp,
        },
      });

      lastTimestamp = data.timeStamp;

      if (!data.notes) {
        return;
      }

      const notes = data.notes.content.filter(
        ({ groupCode }) => groupCode === "TASK"
      );

      taskQueue = taskQueue?.concat(notes) ?? notes;
      await invalidateNotiMatchedQueries(taskQueue);
      emptyTaskQueue();
    } catch (e) {
      // handle error
    } finally {
      isRequesting = false;
    }
  }, NOTI_REQUEST_INTERVAL);
}

interface TaskCategory {
  taskName: string;
  eventType: "SUCCESS" | "FAILED" | "START";
}

const TASK_QUERY_INVALIDATION_MAP: {
  [taskName: string]: (noti: Notification, cat: TaskCategory) => Promise<void>;
} = {
  CREATE_CLUSTER(noti, cat) {
    if (cat.eventType === "START") {
      return Promise.resolve();
    }

    if (noti.data?.paramDatas.IS_GLOBALVIEW === "TRUE") {
      return new Promise(async (res) => {
        await queryClient.invalidateQueries(QUERY_KEYS.globalViewStatus());
        await queryClient.invalidateQueries(QUERY_KEYS.globalCluster());
        res();
      });
    }

    return new Promise(async (res) => {
      await queryClient.invalidateQueries(QUERY_KEYS.clusterList());
      await queryClient.invalidateQueries(QUERY_KEYS.clustersStatus());
      await queryClient.invalidateQueries(
        QUERY_KEYS.clusterDetail(noti.data!.paramDatas!.CLUSTER_ID)
      );
      res();
    });
  },
  INIT_CLUSTER(noti, cat) {
    if (cat.eventType === "START") {
      return Promise.resolve();
    }

    const invalidatingQueryKey = QUERY_KEYS.clusterDetail(
      noti.data!.paramDatas!.CLUSTER_ID
    );
    return queryClient.invalidateQueries(invalidatingQueryKey);
  },
  DELETE_CLUSTER(noti, cat) {
    if (cat.eventType === "START") {
      return Promise.resolve();
    }

    const invalidatingQueryKey = QUERY_KEYS.clusterDetail(
      noti.data!.paramDatas!.CLUSTER_ID
    );
    return queryClient.invalidateQueries(invalidatingQueryKey);
  },
  CONFIGCHANGE_CLUSTER(noti) {
    // const invalidatingQueryKey = QUERY_KEYS.clusterDetail(
    //   noti.data!.paramDatas!.CLUSTER_ID
    // );
    // return queryClient.invalidateQueries(invalidatingQueryKey);
    return Promise.resolve();
  },
  EXPOSE_CLUSTER(noti) {
    // TODO: need to convert `zoneActions.onGetP8SServices(clusterId)` as request hook
    return Promise.resolve();
  },
  MONITORING_CLUSTER(noti) {
    const invalidatingQueryKey = QUERY_KEYS.clusterDetail(
      noti.data!.paramDatas!.CLUSTER_ID
    );
    return queryClient.invalidateQueries(invalidatingQueryKey);
  },
  INSTALL_EXPORTER(noti) {
    // TODO: implement after exporter implementation works correctly
    return Promise.resolve();
  },
  RESET_CLUSTER(noti) {
    const invalidatingQueryKey = QUERY_KEYS.clusterDetail(
      noti.data!.paramDatas!.CLUSTER_ID
    );
    return queryClient.invalidateQueries(invalidatingQueryKey);
  },
};

function categorizeTaskEventCode(eventCode: string) {
  const match = eventCode.match(/TASK_(.*)_(SUCCESS|FAILED|START)/);
  if (!match) {
    return null;
  }

  try {
    const [, taskName, eventType] = match;

    return {
      taskName,
      eventType,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
  }

  return null;
}

function invalidateNotiMatchedQueries(taskQueue: Notification[]) {
  return Promise.all(
    taskQueue
      .map((noti) => {
        const cat = categorizeTaskEventCode(noti.eventCode) as TaskCategory;
        if (cat) {
          // console.log(noti.eventCode, noti);
          return TASK_QUERY_INVALIDATION_MAP[cat.taskName](noti, cat);
        }
        return null;
      })
      .filter(Boolean)
  );
}
