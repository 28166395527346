import { authCheck } from "./common";

const taskList = [
  "scheduled",
  "wait-polling",
  "hand-over",
  "wait-exec",
  "running",
  "recovering",
  "wait-iteration-schedule",
  "complete",
  "failed-recover",
  "failed",
  "canceled",
  "stopped",
  "timeout",
];

export const getTasks = (zoneId: number | string) => {
  return authCheck({
    url: `/lari/tasks/clusters/${zoneId}?${taskList
      .map((h) => `status=${h}`)
      .join("&")}`,
    method: "GET",
  });
};
