import { createAction } from "typesafe-actions";

import { IOpenMessage, ISetData } from "./types";

export const OPEN_MESSAGE = "status/OPEN_MESSAGE";
export const CLOSE_MESSAGE = "status/CLOSE_MESSAGE";
export const SET_DATA = "status/SET_DATA";
export const COLLAPSE_MOBILE_MENU = "status/COLLAPSE_MOBILE_MENU";

export const openMessage = createAction(OPEN_MESSAGE)<IOpenMessage>();
export const closeMessage = createAction(CLOSE_MESSAGE)();
export const setData = createAction(SET_DATA)<ISetData>();
export const collapseMobileMenu = createAction(COLLAPSE_MOBILE_MENU)();
