import React, { useState } from "react";
import { MoreOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  ColumnSection,
  Flex,
  Loading,
  SpaceBetween,
  TitleSection,
} from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";
import { Input } from "antd";

import {
  Dropdown,
  DropdownMenu,
  SmallDropdownMenuItem,
} from "components/common/Dropdown";

import { UserGroup, useUserGroup, useUserGroups } from "hooks/user";
import { request } from "api/common";

import { Circle, Member } from "./common";
import { CreateUserGroupModal, EditUserGroupModal } from "./Modal";

import modal from "utils/modal";

const UserGroups = () => {
  const { data: userGroups, refetch } = useUserGroups();
  const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>();
  const [showingModal, setShowingModal] = useState<"CREATE" | "EDIT" | null>(
    null
  );
  const [groupSearchQuery, setGroupSearchQuery] = useState("");

  if (!userGroups) {
    return (
      <ColumnSection>
        <TitleSection>
          <h3>User Groups</h3>
        </TitleSection>
        <Loading />
      </ColumnSection>
    );
  }

  return (
    <ColumnSection>
      <TitleSection>
        <h3>User Groups</h3>
      </TitleSection>
      <SpaceBetween>
        <x.div
          w={selectedGroupId ? "69%" : "100%"}
          h="400px"
          overflowY="scroll"
        >
          <SpaceBetween>
            <Input
              prefix={<SearchOutlined />}
              placeholder="Find a user group ..."
              style={{ width: "60%" }}
              onChange={(e) => {
                const nextValue = e.target.value.trim();
                if (groupSearchQuery !== nextValue) {
                  setGroupSearchQuery(nextValue);
                }
              }}
            />
            <Button
              variant="primary"
              onClick={() => {
                setSelectedGroupId(undefined);
                setShowingModal("CREATE");
              }}
            >
              Create a Group
            </Button>
          </SpaceBetween>
          <SpaceBetween mt="20px" flexWrap="wrap">
            {userGroups
              .filter((group) => {
                if (!groupSearchQuery) {
                  return true;
                }
                if (group.name.includes(groupSearchQuery)) {
                  return true;
                }
                return false;
              })
              .map((group, i) => {
                return (
                  <MemberGroupListItem
                    key={i}
                    group={group}
                    selectedGroupId={selectedGroupId}
                    onClick={() => {
                      setSelectedGroupId(group.id);
                    }}
                    onSelectAdd={() => {
                      setShowingModal("EDIT");
                    }}
                    onSelectDelete={async () => {
                      await request.delete(`/users/groups/${group.id}`);
                      modal.success(`Deleted user group "${group.name}"`);
                      setSelectedGroupId(undefined);
                      refetch();
                    }}
                  />
                );
              })}
          </SpaceBetween>
        </x.div>
        {selectedGroupId ? (
          <SelectedGroup
            groupId={selectedGroupId}
            onSelect={(groupId) => {
              setSelectedGroupId(groupId);
              setShowingModal("EDIT");
            }}
            onClose={() => {
              setSelectedGroupId(undefined);
            }}
          />
        ) : null}
      </SpaceBetween>
      {showingModal === "CREATE" && (
        <CreateUserGroupModal
          showModal={showingModal === "CREATE"}
          onClose={() => {
            setShowingModal(null);
            refetch();
          }}
        />
      )}
      {showingModal === "EDIT" && (
        <EditUserGroupModal
          settingGroupId={selectedGroupId}
          showModal={showingModal === "EDIT"}
          onClose={() => {
            setShowingModal(null);
            refetch();
          }}
        />
      )}
    </ColumnSection>
  );
};

const SelectedGroup: React.FC<{
  groupId: number;
  onSelect(groupId: number): void;
  onClose(): void;
}> = ({ groupId, onClose, onSelect }) => {
  const { data: group, refetch } = useUserGroup(groupId);

  if (!group) {
    return <Loading />;
  }

  return (
    <x.div
      w="30%"
      h="400px"
      overflowY="scroll"
      p="15px"
      backgroundColor="white"
      border="1px solid"
      borderColor="gray-100"
    >
      <x.div pb="20px" position="relative">
        <x.div
          onClick={onClose}
          position="absolute"
          top="5px"
          right="5px"
          cursor="pointer"
        >
          x
        </x.div>
        <x.div fontWeight="bold" pb="10px">
          {group.name}
        </x.div>
        <x.div color="gray-500" fontSize="0.9rem">
          {group.memberCount} members
        </x.div>
      </x.div>
      {group.memberCount === 0 && (
        <x.div fontSize="0.9rem">There is no user in this group yet.</x.div>
      )}
      {group.members?.map((member, i) => {
        return (
          <Member
            key={i}
            member={member}
            groupId={groupId}
            onUpdate={() => {
              refetch();
            }}
          />
        );
      })}
      <x.div
        pt="10px"
        textDecoration="underline"
        fontSize="1rem"
        color="blue-700"
        cursor="pointer"
        onClick={() => {
          onSelect(group.id);
        }}
      >
        Add members
      </x.div>
    </x.div>
  );
};

export const MemberGroupListItemPane: React.FC<{
  onClick(): void;
  isSelected: boolean;
  group: UserGroup;
}> = ({ children, isSelected = false, onClick, group }) => {
  return (
    <Flex
      backgroundColor="gray-50"
      w="49%"
      h="70px"
      mb="15px"
      p="20px 6px 20px 20px"
      hoverBackgroundColor="gray-100"
      cursor="pointer"
      border={isSelected ? "1px solid" : undefined}
      borderColor="gray-400"
      onClick={onClick}
    >
      <x.div w="70%">
        <x.div fontWeight="bold">{group.name}</x.div>
        <x.div fontSize="0.9em" pt="5px">
          {group.memberCount} members
        </x.div>
      </x.div>
      <MembersCircleDisplay memberCount={group.memberCount} />
      {children}
    </Flex>
  );
};

const MemberGroupListItem: React.FC<{
  group: UserGroup;
  selectedGroupId: number | undefined;
  onClick(): void;
  onSelectAdd(): void;
  onSelectDelete(): void;
}> = ({ group, onClick, selectedGroupId, onSelectAdd, onSelectDelete }) => {
  const isSelected = group.id === selectedGroupId;
  return (
    <MemberGroupListItemPane
      isSelected={isSelected}
      onClick={onClick}
      group={group}
    >
      <Dropdown
        icon={<MoreOutlined style={{ fontSize: "1.8em", color: "#e7e7e7" }} />}
        trigger="click"
        render={(_, __, instance) => {
          return (
            <DropdownMenu>
              <SmallDropdownMenuItem
                onClick={() => {
                  instance?.hide();
                  onSelectAdd();
                }}
              >
                Add members
              </SmallDropdownMenuItem>
              <SmallDropdownMenuItem
                onClick={() => {
                  instance?.hide();
                  const confirmed = window.confirm(
                    "Are you sure to delete this group?"
                  );
                  if (confirmed) {
                    onSelectDelete();
                  }
                }}
              >
                Delete
              </SmallDropdownMenuItem>
            </DropdownMenu>
          );
        }}
      />
    </MemberGroupListItemPane>
  );
};

const MembersCircleDisplay: React.FC<{ memberCount: number }> = ({
  memberCount,
}) => {
  if (memberCount === 0) {
    return null;
  }

  return (
    <Flex>
      {Array.from({ length: Math.min(memberCount, 3) }).map((_, i) => (
        <Circle key={i} />
      ))}
    </Flex>
  );
};

export default UserGroups;
