import { useHistory } from "react-router-dom";
import { Button, Copyboard, Section } from "@nexcloud/nc-ui";

import { useCluster } from "hooks/cluster/useClusterDetailQuery";
import { request } from "api/common";

const SettingTab: React.FC<{ clusterId: number }> = ({ clusterId }) => {
  const { data: cluster } = useCluster(clusterId);
  const installCommand = cluster?.installCommand;

  const history = useHistory();

  const deleteCluster = async () => {
    try {
      await request.delete(`/lari/clusters/${clusterId}`);
      history.push("/");
    } catch (e) {
      alert("There was an error. Please try again.");
    }
  };

  return (
    <Section>
      <Button layoutVariant="outlined" onClick={deleteCluster}>
        Delete
      </Button>
      <Section>
        <h2>Install Script</h2>
        <Copyboard content={installCommand ? installCommand : ""} />
      </Section>
    </Section>
  );
};

export default SettingTab;
