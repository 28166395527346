import { all, call, put, select } from "redux-saga/effects";

import { RootState } from "store/redux";
import { ICreate } from "store/redux/prometheus";
import * as StatusActions from "store/redux/status";
import * as ZoneActions from "store/redux/zone";
import { zonesApi } from "api";

import { IAction } from "./types";

const getZoneDataFromStroe = (state: RootState) => state.zone;
const getPrometheusDataFromStore = (state: RootState) => state.prometheus;

export function* create(action: IAction<ICreate>) {
  try {
    const { data, callback } = action.payload;
    // @ts-ignore
    const prometheusStore = yield select(getPrometheusDataFromStore);
    // @ts-ignore
    const zoneStore = yield select(getZoneDataFromStroe);

    const detailCluster = zoneStore.get("detailZone");
    const suffixList = prometheusStore.get("suffixList");

    if (!detailCluster) return;

    suffixList.forEach((suffix: string) => {
      data[suffix] = `${data[suffix]}Gi`;
    });

    const zoneId = detailCluster.get("id");
    const parameters = JSON.stringify(data);
    yield call(zonesApi.provisinP8S, { zoneId, parameters });

    yield all([
      put(ZoneActions.getZone({ zoneId })),
      put(
        StatusActions.openMessage({
          type: "success",
          msg: "Installation is in progress successfully.",
        })
      ),
    ]);
    callback();
  } catch (error) {
    yield put(
      StatusActions.openMessage({
        type: "error",
        msg: "Install failed",
      })
    );
  }
}
