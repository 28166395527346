import React from "react";
import type { RouteProps } from "react-router-dom";
import { Redirect, Route } from "react-router-dom";

import paths from "paths";

interface IPrivateRouteProps {
  authed: boolean;
  loginPage?: boolean;
}

const PrivateRoute: React.FC<RouteProps & IPrivateRouteProps> = ({
  component: Component,
  children,
  authed,
  loginPage,
  path,
  ...rest
}) => {
  const redirectRoute = loginPage ? paths.dashboard() : paths.login();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authed) {
          return (
            <Redirect to={{ pathname: redirectRoute, state: { from: path } }} />
          );
        }

        if (Component) {
          return <Component {...props} />;
        }

        return children;
      }}
    />
  );
};

export default PrivateRoute;
