import { Description, LinkButton, Panel, SeverityBadge } from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";

import { IAlertListDetail } from "hooks/alert";

import { timeZone } from "const";
import day from "utils/day";

const AlertInfoPanel: React.FC<{ alertDetail: IAlertListDetail | undefined }> =
  ({ alertDetail }) => {
    if (!alertDetail) {
      return null;
    }

    const {
      severity,
      lastUpdatedAt,
      source,
      recipients,
      from,
      description,
      alertRule,
      clusterName,
      node,
      note,
    } = alertDetail;

    return (
      <Panel>
        <x.div display="flex">
          <x.div w="40%" display="flex">
            <Description label="severity">
              <SeverityBadge severity={severity} />
            </Description>
            <Description label="time">
              {day(lastUpdatedAt).tz(timeZone).format("YYYY-MM-DD HH:mm:ss")}
            </Description>
          </x.div>
          <x.div w="60%" display="flex">
            <Description label="source (IP : PORT)">{source}</Description>
            <Description label="recipients">
              <x.div h="fit-content" pt={recipients?.length > 2 ? "9px" : ""}>
                <ul>
                  {recipients?.map((group) => (
                    <x.li mb="3px">
                      <LinkButton to={`/access-groups/${group.id}`}>
                        {group.name}
                      </LinkButton>
                    </x.li>
                  ))}
                </ul>
              </x.div>
            </Description>
            <Description label="from">{from}</Description>
          </x.div>
        </x.div>
        <x.div display="flex">
          <x.div w="40%" display="flex">
            <Description label="description">{description}</Description>
          </x.div>
          <x.div w="60%" display="flex">
            <Description label="alert rule">{alertRule}</Description>
            <Description label="cluster">{clusterName}</Description>
            <Description label="node">{node}</Description>
          </x.div>
        </x.div>
        <Description label="note">{note}</Description>
      </Panel>
    );
  };

export default AlertInfoPanel;
