import React from "react";
// import { PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled/macro";
import { SpaceBetween } from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";
import { Drawer } from "antd";

import { useCurrentUser, useUserTenancies } from "hooks/user";
import { request } from "api/common";

// import CreateTenancyView from "./CreateTenancyView";

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

const OrganizationDrawer: React.FC<{
  showDrawer: boolean;
  onClose(): void;
}> = ({ onClose, showDrawer }) => {
  const { data: tenancies } = useUserTenancies();
  const { data: user } = useCurrentUser();
  // const [showModal, setShowdModal] = useState(false);
  const current = user?.currentTenancy;
  const rest =
    current &&
    (tenancies?.filter((t) => t.tenancyId !== current.tenancyId) ?? null);

  return (
    <StyledDrawer
      placement="left"
      closable={false}
      onClose={onClose}
      visible={showDrawer}
    >
      {current && (
        <x.div>
          <x.div p="20px" backgroundColor="gray-100">
            <x.h3 pb="20px" fontSize="1.1em" fontWeight="bold">
              {current.tenancyName || "(Unnamed Organization)"}
            </x.h3>
            <x.div fontSize="0.85em">{current.tenancyDescription}</x.div>
          </x.div>
          {rest?.length ? (
            <x.div>
              <SpaceBetween p="20px" borderBottom="1px solid #ddd" pb="16px">
                <x.h4 fontSize="0.9em">Other Organizations</x.h4>
                {/* <PlusOutlined
                  style={{
                    padding: "2px",
                    border: "1px solid #ddd",
                    borderRadius: "3px",
                  }}
                  onClick={() => {
                    setShowdModal(true);
                  }}
                /> */}
                {/* {showModal && <CreateTenancyView />} */}
              </SpaceBetween>
              {rest.map((t) => (
                <OrganizationSwitch key={t.tenancyId} id={t.tenancyId}>
                  {t.tenancyName || "(Unnamed Organization)"}
                </OrganizationSwitch>
              ))}
            </x.div>
          ) : // <SpaceBetween p="20px" borderBottom="1px solid #ddd" pb="16px">
          //   <x.h4 fontSize="0.9em">Add a Workspace</x.h4>
          //   <PlusOutlined
          //     style={{
          //       padding: "2px",
          //       border: "1px solid #ddd",
          //       borderRadius: "3px",
          //     }}
          //     onClick={() => {
          //       setShowdModal(true);
          //     }}
          //   />
          //   {showModal && (
          //     <CreateTenancyView
          //       onClose={() => {
          //         setShowdModal(false);
          //       }}
          //     />
          //   )}
          // </SpaceBetween>
          null}
        </x.div>
      )}
    </StyledDrawer>
  );
};

const OrganizationSwitch: React.FC<{ id: number }> = ({ id, children }) => {
  return (
    <x.div
      p="20px"
      hoverBackgroundColor="#eee"
      cursor="pointer"
      fontSize="0.85em"
      borderBottom="1px solid #eee"
      onClick={async () => {
        try {
          await request.put(`/users/accounts/tenancies/${id}`);
          window.location.reload();
        } catch (e) {
          alert("There was an error. Please try again.");
        }
      }}
    >
      {children}
    </x.div>
  );
};

export default OrganizationDrawer;
