import React from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  ColumnSection,
  Loading,
  Section,
  SpaceBetween,
  TitleSection,
} from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";
import { message } from "antd";
import { useForm } from "antd/lib/form/Form";

import { TenancyForm } from "components/CreateTenancyView";
import { showTryAgain } from "components/NotificationMessage";

import { useHasCluster } from "hooks/cluster";
import { useCurrentUser } from "hooks/user";
import { getTenancyId, request } from "api/common";

import modal from "utils/modal";
import paths from "paths";

const Organization = () => {
  const history = useHistory();

  const [form] = useForm();
  const { data: user } = useCurrentUser();
  const hasCluster = useHasCluster();

  const confirmDelete = async () => {
    try {
      const response = await request.delete(
        `/users/tenancies/${getTenancyId()}`
      );
      const { result } = response.data;
      if (result === "OK") {
        message.success("Successfully deleted this organization");
        const response = await request.get(`users/tenancies`);
        const { res } = response.data;
        if (res.length === 0) {
          history.push(paths.tempTenancy());
        } else {
          await request.put(`/users/accounts/tenancies/${res[0].tenancyId}`);
          window.location.reload();
        }
      }
    } catch (e) {
      if (e.response?.data?.message) {
        modal.warning(e.response.data.message);
      } else {
        showTryAgain();
      }
    }
  };

  const deleteOrganization = () => {
    if (hasCluster) {
      message.error(
        "You're not allowed to delete the organization unless you remove all the clusters."
      );
    } else {
      confirmDelete();
    }
  };
  return (
    <ColumnSection>
      <TitleSection>
        <h3>Organization</h3>
      </TitleSection>
      {user ? <TenancyForm form={form} user={user} /> : <Loading />}
      <Section>
        <Button
          layoutVariant="outlined"
          variant="primary"
          onClick={async () => {
            try {
              if (!user) {
                return;
              }
              try {
                await form.validateFields();
              } catch (e) {
                return;
              }
              const { tenancyName: name, tenancyDescription: description } =
                form.getFieldsValue();

              await request.put(`/users/tenancies/${getTenancyId()}`, {
                name,
                description,
              });

              modal.success("Updated");
            } catch (e) {
              alert("There was an error. Please try again later.");
            }
          }}
        >
          Update
        </Button>
      </Section>

      <Section>
        <TitleSection>
          <h3>Danger Zone</h3>
        </TitleSection>
        <x.div fontWeight="bold">Delete this organization</x.div>
        <SpaceBetween>
          <x.div>
            Once you delete this organization, there is no going back. Please be
            certain.
          </x.div>
          <Button
            size="tiny"
            layoutVariant="outlined"
            variant="danger"
            onClick={deleteOrganization}
          >
            Delete this organization
          </Button>
        </SpaceBetween>
      </Section>
    </ColumnSection>
  );
};
export default Organization;
