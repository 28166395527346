import React from "react";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled/macro";
import { Button } from "@nexcloud/nc-ui";
import { Result } from "antd";

import paths from "paths";

const Container = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function NotFoundPage({ backPath = paths.dashboard() }: { backPath: string }) {
  const history = useHistory();

  return (
    <Container>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button
            variant="primary"
            expand
            onClick={() => {
              history.push(backPath);
            }}
          >
            Back Home
          </Button>
        }
      />
    </Container>
  );
}

export default NotFoundPage;
