import { authCheck, prdCode } from "./common";

export const getAccount = () => {
  return authCheck({
    url: "/users/accounts/me",
    method: "GET",
  });
};

export const logout = () => {
  return authCheck({
    url: `/users/logout/${prdCode}`,
    method: "GET",
  });
};

export const getOverallStatus = () => {
  return authCheck({
    url: "/lari/clusters/overall",
    method: "GET",
  });
};

export const getOverallAlert = () => {
  return authCheck({
    url: "/lari/clusters/alert",
    method: "GET",
  });
};
