import React from "react";
import { x } from "@xstyled/emotion";
import { Spin } from "antd";

const Loading: React.FC = ({ children }) => (
  <x.div display="flex" alignItems="center" flexDirection="column">
    <Spin />
    {children ? <x.span pt="20px">{children}</x.span> : null}
  </x.div>
);

export default Loading;
