import { Redirect, Switch, useRouteMatch } from "react-router-dom";

import PrivateRoute from "components/PrivateRoute";

import DetailedAccessGroupUser from "./AccessGroupUserDetail";
import AccessGroupUsersList from "./AccessGroupUsersList";

const AccessGroup: React.FC<{ authed: boolean }> = ({ authed }) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute
        exact
        path={path}
        component={AccessGroupUsersList}
        authed={authed}
      />
      <PrivateRoute path={`${path}/:id`} authed={authed}>
        <DetailedAccessGroupUser />
      </PrivateRoute>
      <Redirect to={path} />
    </Switch>
  );
};

export default AccessGroup;
