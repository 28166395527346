import React from "react";
import { Button, Flex } from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";
import { Form, Input, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";

import { request } from "api/common";

export const AddChannelModal: React.FC<{
  showModal: "CREATE" | "EDIT" | null;
  onClose(): void;
}> = ({ showModal, onClose }) => {
  return (
    <Modal
      title="Add Channel"
      centered
      visible={showModal === "CREATE"}
      onCancel={() => {
        onClose();
      }}
      footer={null}
      closable
      width={1000}
    >
      <AddChannel showModal={showModal === "CREATE"} onClose={onClose} />
    </Modal>
  );
};

const AddChannel: React.FC<{ showModal: boolean; onClose(): void }> = ({
  showModal,
  onClose,
}) => {
  const [form] = useForm<{
    channelType: string;
    name: string;
    description: string;
    apiUrl: string;
    slackChannelName: string;
  }>();

  return (
    <Flex>
      <x.div w="100%">
        <Input.Group>
          <Form layout="vertical" form={form}>
            <Form.Item
              name="channelType"
              label="Channel Type"
              initialValue="SLACK"
              preserve
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="name"
              label="Channel Info"
              rules={[{ required: true, message: "Please put name" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="apiUrl"
              label="Webhook URL"
              rules={[
                {
                  required: true,
                  message: "Please put webhook URL",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="slackChannelName"
              label="Channel Name"
              rules={[
                {
                  required: true,
                  message: "Please put slack channel Name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Input.Group>
        <Flex justifyContent="flex-end" mt="20px">
          <Button
            variant="primary"
            onClick={async () => {
              try {
                await form.validateFields();
                const v = form.getFieldsValue();
                await request.post("/lari/alertchannels/slack", v);
                form.resetFields();
                onClose();
              } catch (e) {}
            }}
          >
            Create
          </Button>
        </Flex>
      </x.div>
    </Flex>
  );
};

export const EditChannelModal: React.FC<{
  showModal: "CREATE" | "EDIT" | null;
  onClose(): void;
  channelId: number | undefined;
}> = ({ showModal, onClose, channelId }) => {
  return (
    <Modal
      title="Edit Channel"
      centered
      visible={showModal === "EDIT"}
      onCancel={() => {
        onClose();
      }}
      footer={null}
      closable
      width={1000}
    >
      <EditChannel
        showModal={showModal === "EDIT"}
        onClose={onClose}
        channelId={channelId}
      />
    </Modal>
  );
};

const EditChannel: React.FC<{
  channelId: number | undefined;
  showModal: boolean;
  onClose(): void;
}> = ({ showModal, onClose, channelId }) => {
  const [form] = useForm<{
    channelType: string;
    name: string;
    description: string;
    apiUrl: string;
    slackChannelName: string;
  }>();
  return (
    <Flex>
      <x.div w="100%">
        <Input.Group>
          <Form layout="vertical" form={form}>
            <Form.Item
              name="channelType"
              label="Channel Type"
              initialValue="SLACK"
              preserve
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="name"
              label="Channel Info"
              rules={[{ required: true, message: "Please put name" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="apiUrl"
              label="Webhook URL"
              rules={[
                {
                  required: true,
                  message: "Please put webhook URL",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="slackChannelName"
              label="Channel Name"
              rules={[
                {
                  required: true,
                  message: "Please put slack channel Name",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Input.Group>
        <Flex justifyContent="flex-end" mt="20px">
          <Button
            variant="primary"
            onClick={async () => {
              try {
                await form.validateFields();
                const v = form.getFieldsValue();
                await request.put(`/lari/alertchannels/slack/${channelId}`, v);
                form.resetFields();
                onClose();
              } catch (e) {}
            }}
          >
            Edit
          </Button>
        </Flex>
      </x.div>
    </Flex>
  );
};
