import React from "react";
import { Link, LinkProps } from "react-router-dom";

type ILink = React.FC<
  LinkProps & {
    to: string;
    openNewWindow?: boolean;
  }
>;

const LinkBox: ILink = ({ to, openNewWindow = false, children, ...props }) => {
  if (to.startsWith("/")) {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    );
  }

  const linkProps = openNewWindow
    ? { target: "_blank", rel: "noopener noreferrer" }
    : undefined;

  return (
    <a href={to} {...linkProps} {...props}>
      {children}
    </a>
  );
};

export default LinkBox;
