import { useEffect, useState } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import { useHistory } from "react-router";
import type { AxiosError } from "axios";
import { fromJS } from "immutable";
import { useAtom } from "jotai";
import { QUERY_KEYS } from "query";

import { currentClusterAtom } from "atoms";
import { IClusterHealth, IZone } from "store/redux/zone";
import { useUpdateStateWithQueryParam } from "hooks/init";
import { useZoneGet } from "hooks/state/get";
import useZoneActions from "hooks/state/useZoneActions";
import useQueryParams from "hooks/util/useQueryParams";
import { zonesApi } from "api";
import { request } from "api/common";
import paths from "api/paths";

import useClusterList from "./useClusterList";

export const useCluster = (
  clusterId: string | number | undefined = 0,
  options?: UseQueryOptions<IZone, AxiosError, any>
) => {
  return useQuery<IZone, AxiosError>(
    QUERY_KEYS.clusterDetail(clusterId),
    async () => {
      if (!clusterId) {
        return;
      }
      const { data } = await request.get(paths.zones.detail(clusterId));
      return data;
    },
    {
      enabled: !!clusterId,
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};

const requestAllZones = (
  zoneActions: any,
  setCurrentCluster: any,
  queryParamId?: any
) => {
  return zonesApi.getAllZone().then((zones: IZone[]) => {
    const firstZone = zones[0];
    if (firstZone) {
      if (!queryParamId) {
        zoneActions.onSetData({
          key: "detailZone",
          value: fromJS(firstZone),
        });
        setCurrentCluster({ id: firstZone.id });
      } else {
        const selected = zones.find((z) => String(z.id) === queryParamId);
        zoneActions.onSetData({
          key: "detailZone",
          value: fromJS(selected),
        });
      }
    }
    return zones;
  });
};

export const useInitCurrentCluster = () => {
  const [, setCurrentCluster] = useSetCurrentCluster();
  const [isLoading, setIsLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const query = useQueryParams();
  const queryParamId = query.get("clusterId") || query.get("zoneId") || "";
  const { data: clusterList } = useClusterList();
  const currentCluster = useZoneGet("detailZone");
  const zoneActions = useZoneActions();

  useEffect(() => {
    if (currentCluster?.get("id")) {
      setInitialized(true);
      return;
    }

    if (queryParamId) {
      setCurrentCluster({ id: Number(queryParamId) });
    }

    if (!clusterList?.length) {
      setIsLoading(true);
      requestAllZones(zoneActions, setCurrentCluster, queryParamId).finally(
        () => {
          setInitialized(true);
          setIsLoading(false);
        }
      );
    }

    return () => {
      setCurrentCluster({ id: undefined });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { initialized, isLoading };
};

// when cluster is changed.
export const useUpdateCurrentCluster = () => {
  const { data: clusterList } = useClusterList();
  const zoneActions = useZoneActions();
  const [currentCluster, setCurrentCluster] = useSetCurrentCluster();

  useEffect(() => {
    const id = currentCluster.id;
    if (id) {
      const cluster = clusterList?.filter((z) => id === z.id);
      if (cluster?.length === 0) {
        zoneActions.onSetData({ key: "detailZone", value: null });
        setCurrentCluster({ id: undefined });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusterList]);
};

export const useSetCurrentCluster = () => {
  return useAtom(currentClusterAtom);
};

export const useClusterHealth = (
  clusterId: string | number | undefined = 0,
  options?: UseQueryOptions<IClusterHealth, AxiosError>
) => {
  return useQuery<IClusterHealth, AxiosError>(
    QUERY_KEYS.clusterHealth(clusterId),
    async () => {
      if (!clusterId) {
        return;
      }
      return zonesApi.getClusterHealth(Number(clusterId));
    },
    {
      enabled: !!clusterId,
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};

export const useCurrentCluster = (
  options?: UseQueryOptions<IZone, AxiosError>,
  forceId?: boolean,
  gotoHomeIf404?: boolean
) => {
  useUpdateStateWithQueryParam();
  const [{ id }, setCurrentCluster] = useSetCurrentCluster();
  const history = useHistory();
  const cluster = useCluster(id, {
    ...options,
    onError(err) {
      options?.onError?.(err);
      if (err.response?.status === 404 && gotoHomeIf404) {
        history.push("/");
      }
    },
  });
  const zoneActions = useZoneActions();

  useEffect(() => {
    if (forceId && !id) {
      requestAllZones(zoneActions, setCurrentCluster);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceId, id]);

  return cluster;
};
