import { all, call, put } from "redux-saga/effects";

import * as AccountActions from "store/redux/account";
import * as StatusActions from "store/redux/status";
import { accountApi } from "api";

import paths from "paths";

export function* getAccount() {
  // @ts-ignore
  const data = yield call(accountApi.getAccount);

  // User account 정보가 없을 경우 아무것도 하지않는다.
  if (!data) return;
  const intervalSeconds = (data.refreshInterval || 3) * 1000;
  yield all([
    put(AccountActions.setData({ key: "googleAccount", value: data })),
    put(AccountActions.setData({ key: "login", value: true })),
    put(
      StatusActions.setData({
        key: "intervalTime",
        value: intervalSeconds,
      })
    ),
  ]);
}

export function* logout() {
  try {
    yield call(accountApi.logout);
  } catch (error) {
  } finally {
    window.location.href = paths.login();
  }
}
