import React, { useEffect, useState } from "react";
import { Steps } from "antd";

import CreateCluster from "components/Cluster/CreateCluster";
import SelectPlatform from "components/Cluster/SelectPlatform";

import { useCurrentCluster } from "hooks/cluster/useClusterDetailQuery";
import useCreateActions from "hooks/state/useCreateActions";
import useZoneActions from "hooks/state/useZoneActions";
import useQueryParams from "hooks/util/useQueryParams";

import CollapTitle from "./CollapTitle";
import { CreateInstallAgent } from "./InstallAgent";

const { Step } = Steps;

interface IPanel {
  TitleComponent: (props: any) => JSX.Element | null;
  title: string;
  Content: (props: any) => JSX.Element | null;
  id: string;
  configKey: "zoneName" | "platform";
  valueVisible: boolean;
}

const STEPS: IPanel[] = [
  {
    TitleComponent: CollapTitle,
    title: "Select Platform",
    Content: SelectPlatform,
    id: "selectPlatform",
    configKey: "platform",
    valueVisible: true,
  },
  {
    TitleComponent: CollapTitle,
    title: "Create Cluster",
    Content: CreateCluster,
    id: "createCluster",
    configKey: "zoneName",
    valueVisible: true,
  },
  {
    TitleComponent: CollapTitle,
    title: "Install Agent",
    Content: CreateInstallAgent,
    id: "installAgent",
    configKey: "zoneName",
    valueVisible: false,
  },
];

function CollapBox() {
  const [currentStep, setCurrentStep] = useState(0);
  const [completeList, setCompleteList] = useState<string[]>([]);
  const query = useQueryParams();

  const clusterIdQueryParam = query.get("clusterId");
  const { data: cluster } = useCurrentCluster({
    enabled: !!clusterIdQueryParam,
  });
  const zoneActions = useZoneActions();

  const createActions = useCreateActions();

  const onNext = (index: number) => {
    if (index >= STEPS.length) return;
    setCurrentStep(currentStep + 1);
    setCompleteList(completeList.concat(STEPS[index].id));
  };

  const onBack = (index: number) => {
    if (index === 0) return;
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    const clusterId = query.get("clusterId");
    if (clusterId) {
      setCompleteList(STEPS.slice(0, STEPS.length - 1).map((l) => l.id));
      setCurrentStep(STEPS.length - 1);
    } else {
      zoneActions.onResetZone();
    }

    return () => {
      createActions.onResetConfig();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!cluster || !clusterIdQueryParam) {
      return;
    }
    createActions.onCreateCluster({
      platform: cluster.platform,
      zoneName: cluster.name,
      zoneId: cluster.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cluster, clusterIdQueryParam]);
  const Content = STEPS[currentStep].Content;
  return (
    <>
      <Steps current={currentStep}>
        {STEPS.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <Content onNext={onNext} index={currentStep} onBack={onBack} />
    </>
  );
}

export default CollapBox;
