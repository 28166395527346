import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import { Copyboard } from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";
import {
  Button as AntdButton,
  Checkbox,
  message,
  Modal,
  Spin,
  Steps,
} from "antd";
import { useAtom } from "jotai";

import { currentClusterAtom } from "atoms";
import { useClusterDeleteScript } from "hooks/cluster";
import { authCheck } from "api/common";

const StepsContent = styled.div`
  margin-top: 16px;
  border-radius: 2px;
  background-color: #fafafa;
  text-align: center;
  padding: 15px;
`;

const StepsAction = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;

const forceDelete = async (clusterId?: number) => {
  if (!clusterId) {
    return;
  }
  if (
    !window.confirm(
      "Please run force delete on this cluster only when the cleanup script excution fails."
    )
  ) {
    return;
  }
  try {
    await authCheck({
      url: `/lari/clusters/${clusterId}`,
      method: "DELETE",
    });
    message.success("Cluster deleted.");
  } catch (e) {
    message.error("Failed to delete cluster. Please try again later.");
  }
};

const DeleteCluster: React.FC<{ showModal: boolean; onClose: any }> = ({
  showModal,
  onClose,
}) => {
  const [{ id: clusterId }] = useAtom(currentClusterAtom);
  const [step, setStep] = useState(0);
  const [agreed, setAgreed] = useState(false);

  const { Step } = Steps;

  const steps = [
    {
      title: "Notice",
      content: (
        <>
          <x.p pb="20px">
            This action cannot be undone. This will{" "}
            <x.span color="red-500" display="inline">
              permanently delete
            </x.span>{" "}
            your agent and remove all configurations.
          </x.p>
          <Checkbox
            onChange={(e) => {
              setAgreed(e.target.checked);
            }}
            checked={agreed}
          >
            Agree
          </Checkbox>
        </>
      ),
    },
    {
      title: "Clean up script",
      content: <DeleteScript clusterId={clusterId} />,
    },
  ];

  const handleClose = () => {
    onClose();
    setStep(0);
    setAgreed(false);
  };

  return showModal ? (
    <Modal visible={showModal} centered footer={null} onCancel={handleClose}>
      <Steps current={step} style={{ paddingRight: "40px" }}>
        {steps.map((item, i) => (
          <Step key={i} title={item.title} />
        ))}
      </Steps>
      <StepsContent>{steps[step].content}</StepsContent>
      <StepsAction>
        {step === 0 && agreed && (
          <AntdButton
            type="primary"
            danger
            onClick={() => {
              setStep(step + 1);
            }}
          >
            OK
          </AntdButton>
        )}
        {step === 1 && (
          <x.div display="flex">
            <AntdButton
              danger
              onClick={() => forceDelete(clusterId)}
              style={{
                marginRight: "10px",
              }}
            >
              Force Delete
            </AntdButton>
            <AntdButton type="primary" onClick={handleClose}>
              Done
            </AntdButton>
          </x.div>
        )}
      </StepsAction>
    </Modal>
  ) : null;
};

export const DeleteScript: React.FC<{ clusterId?: number | string }> = ({
  clusterId,
}) => {
  const { data: deleteScript } = useClusterDeleteScript(clusterId, {
    enabled: !!clusterId,
  });

  if (!deleteScript) {
    return <Spin />;
  }

  return (
    <>
      <p>
        For NexClipper Agent to destroy, make sure you have enabled privileged
        admin within your Kubernetes cluster. Run the following command against
        your cluster. See you again.
      </p>
      <br />
      <Copyboard content={deleteScript} />
    </>
  );
};

export default DeleteCluster;
