import React from "react";
import { useQuery as useReactQuery } from "react-query";
import styled from "@emotion/styled/macro";
import { Loading } from "@nexcloud/nc-ui";

import { getP8sVolumes } from "api/zones";

const Flex = styled.div`
  display: flex;
`;

const ContentRow = styled(Flex)`
  align-items: center;
  margin-bottom: 15px;
`;

const Content = styled.div`
  padding-top: 15px;
  margin-top: 15px;
`;

const Title = styled(Flex)`
  border-top: 1px solid #ececec;
  padding-top: 15px;
  margin-top: 15px;
  font-size: 1.5em;
  font-weight: bold;
  align-items: center;
`;

const ContentTitle = styled(Flex)`
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
  align-items: center;
`;

const SubTitle = styled.div`
  width: 120px;
  font-weight: bold;
  margin-right: 7px;
  font-size: 1.1em;
`;

const SubValue = styled.div`
  width: 120px;
  margin-right: 7px;
`;

const P8sVolumes = ({ clusterId }: { clusterId?: number }) => {
  const { data: p8sVolumes } = useReactQuery(
    ["p8sVolumes", clusterId ? String(clusterId) : undefined],
    getP8sVolumes,
    { enabled: !!clusterId }
  );

  if (!p8sVolumes) {
    return (
      <>
        <Title>Volumes</Title>
        <Content>
          <Loading />
        </Content>
      </>
    );
  }

  const {
    walTotalSize,
    walUsedSize,
    walUsedPercent,
    timescaleTotalSize,
    timescaleUsedSize,
    timescaleUsedPercent,
  } = p8sVolumes;

  return (
    <>
      <Title>Volumes</Title>
      <Content>
        <ContentTitle>WAL Volume PVC</ContentTitle>
        <ContentRow>
          <SubTitle>Capacity</SubTitle>
          <SubValue>{walTotalSize ?? "unknown"}</SubValue>
        </ContentRow>
        <ContentRow>
          <SubTitle>Used</SubTitle>
          <SubValue>
            {walUsedSize ?? "unknown"}{" "}
            {walUsedPercent ? `(${walUsedPercent})` : ""}
          </SubValue>
        </ContentRow>
      </Content>
      <Content>
        <ContentTitle>TimeScale Storage PVC</ContentTitle>
        <ContentRow>
          <SubTitle>Capacity</SubTitle>
          <SubValue>{timescaleTotalSize ?? "unknown"}</SubValue>
        </ContentRow>
        <ContentRow>
          <SubTitle>Used</SubTitle>
          <SubValue>
            {timescaleUsedSize ?? "unknown"}{" "}
            {timescaleUsedSize ? `(${timescaleUsedPercent})` : ""}
          </SubValue>
        </ContentRow>
      </Content>
    </>
  );
};

export default P8sVolumes;
