import { QueryCache, QueryClient } from "react-query";

const queryCache = new QueryCache();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
  queryCache,
});

type Id = number | string;

export const QUERY_KEYS = {
  clusterDetail(clusterId: Id) {
    return ["clusterDetail", Number(clusterId)];
  },
  clusterList() {
    return "clusterList";
  },
  clustersStatus() {
    return "clustersStatus";
  },
  clusterHealth(clusterId: Id) {
    return ["clusterHealth", Number(clusterId)];
  },
  clusterTasks(clusterId: Id) {
    return ["clusterTasks", Number(clusterId)];
  },
  globalViewStatus() {
    return "globalViewStatus";
  },
  globalCluster() {
    return "globalCluster";
  },
  serviceList(clusterId: number) {
    return ["serviceList", clusterId];
  },
  serviceExporters(clusterId: number, serviceId: number) {
    return ["serviceExporters", clusterId, serviceId];
  },
  clusterAccessGroups(clusterId: number) {
    return ["clusterAccessGroups", clusterId];
  },
  clusterService(clusterId: number, serviceId: number) {
    return ["clusterService", clusterId, serviceId];
  },
  clusterNode(clusterId: number) {
    return ["clusterNode", clusterId];
  },
};
