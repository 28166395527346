import { useQuery, UseQueryOptions } from "react-query";
import { AxiosError } from "axios";

import { IChannel } from "hooks/channel";
import { IUser } from "hooks/user";
import { request } from "api/common";

export interface IAccessGroupListItem {
  description: string;
  groupName: string;
  icon?: string;
  id: number;
}

export interface IAccessGroupCluster {
  clusterId: number;
  description: string;
  icon: string;
  name: string;
}

export interface IAccessGroupUser {
  activated: boolean;
  email: string;
  name: string;
  photo: string;
  userId: number;
}

export interface IAccessGroupChannel {
  channelInfo: IChannel;
  id: number;
}

export interface IDetailAccessGroup {
  content: [
    {
      channels: [
        {
          channelInfo: {
            channelType: string;
            createdAt: string;
            createdBy: number;
            description: string;
            id: number;
            name: string;
            updatedAt: string;
            updatedBy: number;
          };
          id: number;
        }
      ];
      clusters: [
        {
          clusterId: number;
          description: string;
          icon: string;
          name: string;
        }
      ];
      description: string;
      icon: string;
      id: number;
      members: [
        {
          activated: boolean;
          email: string;
          name: string;
          photo: string;
          userId: number;
        }
      ];
      name: string;
      totalChannelCount: number;
      totalClusterCount: number;
      totalMemberCount: number;
    }
  ];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    page: number;
    size: number;
    sort: [string];
  };
  size: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  totalElements: number;
  totalPages: number;
}

export interface IMyAccessGroup {
  alertChannels: [
    {
      channelType: "EMAIL" | "SLACK";
      createdAt: string;
      createdBy: number;
      description: string;
      id: number;
      name: string;
      updatedAt: string;
      updatedBy: number;
    }
  ];
  clusterServices: [
    {
      clusterId: number;
      groupId: number;
      id: number;
      name: string;
      namespace: string;
      tenancyId: number;
      type: object;
    }
  ];
  description: string;
  groupName: string;
  id: number;
}

export interface IAccessGroupDetailChannel extends IChannel {
  id: number;
  channelInfo: IChannel & {
    apiUrl: string;
    slackChannelName: string;
  };
}

export interface IAccessGroupUserGroup {
  id: number;
  groupId: number;
  name: string;
  memberCount: number;
}

export interface IAccessGroupDetail extends IAccessGroupListItem {
  members: {
    users: null | IUser[];
    groups: null | IAccessGroupUserGroup[];
  };
  channels: IAccessGroupDetailChannel[];
  clusters: IAccessGroupCluster[];
}

export const useAccessGroups = (
  options?: UseQueryOptions<IAccessGroupListItem[], AxiosError>
) => {
  return useQuery<IAccessGroupListItem[], AxiosError>(
    "accessGroups",
    async () => {
      const { data } = await request.get("/lari/accessgroups");
      return data;
    },
    {
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};

export const useDetailAccessGroups = (
  options?: UseQueryOptions<IDetailAccessGroup, AxiosError>
) => {
  return useQuery<IDetailAccessGroup, AxiosError>(
    "accessGroups",
    async () => {
      const { data } = await request.get("/lari/accessgroups/detail");
      return data;
    },
    {
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};

export const useMyAccessGroups = (
  options?: UseQueryOptions<IMyAccessGroup[], AxiosError>
) => {
  return useQuery<IMyAccessGroup[], AxiosError>(
    "myAccessGroups",
    async () => {
      const { data } = await request.get("/lari/accessgroups/my");
      return data;
    },
    {
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};

export const useAccessGroupDetail = (
  id: number,
  options?: UseQueryOptions<IAccessGroupDetail, AxiosError>
) => {
  return useQuery<IAccessGroupDetail, AxiosError>(
    ["accessGroupDetail", id],
    async () => {
      const { data } = await request.get(`/lari/accessgroups/${id}`);
      return data;
    },
    {
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};

export const useAccessGroupClusterList = (
  id: number,
  options?: UseQueryOptions<IAccessGroupCluster[], AxiosError>
) => {
  return useQuery<IAccessGroupCluster[], AxiosError>(
    ["accessGroupClusterList", id],
    async () => {
      const { data } = await request.get(`/lari/accessgroups/${id}/clusters`);
      return data;
    },
    {
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};

export const useAccessGroupUserList = (
  id: number,
  options?: UseQueryOptions<IAccessGroupUser[], AxiosError>
) => {
  return useQuery<IAccessGroupUser[], AxiosError>(
    ["accessGroupClusterList", id],
    async () => {
      const { data } = await request.get(`/lari/accessgroups/${id}/users`);
      return data;
    },
    {
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};

export const useAccessGroupChannelList = (
  id: number,
  options?: UseQueryOptions<IAccessGroupChannel[], AxiosError>
) => {
  return useQuery<IAccessGroupChannel[], AxiosError>(
    ["accessGroupChannelList", id],
    async () => {
      const { data } = await request.get(`/lari/accessgroups/${id}/channels`);
      return data;
    },
    {
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};
