import { AiOutlineWarning } from "react-icons/ai";
import { CgSandClock } from "react-icons/cg";
import {
  Gauge,
  GaugeItem,
  LinkButton,
  Panel,
  Section,
  SeverityBadge,
  SmallTitle,
  SpaceBetween,
} from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";

import { useAlertCount } from "hooks/alert";
import { useIncidentCount } from "hooks/incident";

interface IElem {
  value?: number;
  type?: string;
}

const Elem: React.FC<IElem> = ({ children }) => {
  return (
    <Section>
      <SpaceBetween>
        <x.div w="63%" h="190px">
          <Panel></Panel>
        </x.div>
        <x.div w="36%" h="190px">
          <Panel>
            <SpaceBetween>
              <x.div>
                <SmallTitle>Alerts in last 24 hrs</SmallTitle>
                <AlertsGauge />
                <x.div textAlign="right" mt="10px">
                  <LinkButton to="/alerts">VIEW ALERTS</LinkButton>
                </x.div>
              </x.div>
              <x.div>
                <SmallTitle>Incidents in last 24 hrs</SmallTitle>
                <IncidentsGauge />
                <x.div textAlign="right" mt="10px">
                  <LinkButton to="/incident">VIEW INCIDENTS</LinkButton>
                </x.div>
              </x.div>
            </SpaceBetween>
          </Panel>
        </x.div>
      </SpaceBetween>
    </Section>
  );
};

const AlertsGauge = () => {
  const { data: alertCount } = useAlertCount();
  const criticalCount = alertCount?.find(
    (i) => i.severity === "Critical"
  )?.count;
  const warningCount = alertCount?.find((i) => i.severity === "Warning")?.count;
  return (
    <SpaceBetween>
      <Gauge size="small" icon={<SeverityBadge small severity="Critical" />}>
        <GaugeItem value={criticalCount} />
      </Gauge>
      <Gauge size="small" icon={<SeverityBadge small severity="Warning" />}>
        <GaugeItem value={warningCount} />
      </Gauge>
    </SpaceBetween>
  );
};

const IncidentsGauge = () => {
  const { data: incidentCount } = useIncidentCount();
  const openCount = incidentCount?.openCount;
  const inprogressCount = incidentCount?.inprogressCount;
  return (
    <SpaceBetween>
      <Gauge size="small" title="Open" icon={<AiOutlineWarning />}>
        <GaugeItem value={openCount} />
      </Gauge>
      <Gauge size="small" title="In-progress" icon={<CgSandClock />}>
        <GaugeItem value={inprogressCount} />
      </Gauge>
    </SpaceBetween>
  );
};
export default Elem;
