import { Tag } from "antd";

import { IZone } from "store/redux/zone";

interface ClusterParamData {
  AGENT_STATUS: IZone["agentStatus"];
  CLUSTER_ID: string; // stringified number i.e.) "0"
  CLUSTER_NAME: string;
  CLUSTER_TYPE: IZone["clusterType"];
  GROUP_ID: string; // stringified number i.e.) "0"
  INIT_STATUS: IZone["initStatus"];
}

export const EVENT_CODES = {
  TASK_CREATE_CLUSTER_SUCCESS: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="green">Success</Tag>Create cluster
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Successfully created ${paramDatas.CLUSTER_NAME}.`;
    },
    action(paramDatas: ClusterParamData) {
      return [
        "Go",
        `/cluster/prometheus-detail?clusterId=${paramDatas.CLUSTER_ID}`,
      ];
    },
  },
  TASK_CREATE_CLUSTER_FAILED: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="red">Fail</Tag>Create cluster
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Failed to create cluster ${paramDatas.CLUSTER_NAME}.`;
    },
    action(paramDatas: ClusterParamData) {
      return [
        "Go",
        `/cluster/prometheus-detail?clusterId=${paramDatas.CLUSTER_ID}`,
      ];
    },
  },
  TASK_INIT_CLUSTER_START: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="gold">Start</Tag>Init cluster
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Started to initiate cluster ${paramDatas.CLUSTER_NAME}.`;
    },
    action(paramDatas: ClusterParamData) {
      return [
        "Go",
        `/cluster/prometheus-detail?clusterId=${paramDatas.CLUSTER_ID}`,
      ];
    },
  },
  TASK_INIT_CLUSTER_SUCCESS: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="green">Success</Tag>Init cluster
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Successfully initited ${paramDatas.CLUSTER_NAME}.`;
    },
    action(paramDatas: ClusterParamData) {
      return [
        "Go",
        `/cluster/prometheus-detail?clusterId=${paramDatas.CLUSTER_ID}`,
      ];
    },
  },
  TASK_INIT_CLUSTER_FAILED: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="red">Fail</Tag>Init cluster
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Failed to initiate cluster ${paramDatas.CLUSTER_NAME}`;
    },
    action(paramDatas: ClusterParamData) {
      return [
        "Go",
        `/cluster/prometheus-detail?clusterId=${paramDatas.CLUSTER_ID}`,
      ];
    },
  },
  TASK_DELETE_CLUSTER_SUCCESS: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="green">Success</Tag>Delete cluster
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Successfully deleted cluster ${paramDatas.CLUSTER_NAME}`;
    },
    action(paramDatas: ClusterParamData) {
      return null;
    },
  },
  TASK_DELETE_CLUSTER_FAILED: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="red">Fail</Tag>Delete cluster
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Failed to delete cluster ${paramDatas.CLUSTER_NAME}`;
    },
    action(paramDatas: ClusterParamData) {
      return [
        "Go",
        `/cluster/prometheus-detail?clusterId=${paramDatas.CLUSTER_ID}`,
      ];
    },
  },
  TASK_CONFIGCHANGE_CLUSTER_START: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="gold">Start</Tag>Change cluster config
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Started changing cluster config`;
    },
    action(paramDatas: ClusterParamData) {
      return ["Go", `/cluster`];
    },
  },
  TASK_CONFIGCHANGE_CLUSTER_SUCCESS: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="green">Success</Tag>Change cluster config
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Successfully changed cluster config`;
    },
    action(paramDatas: ClusterParamData) {
      return ["Go", `/cluster`];
    },
  },
  TASK_CONFIGCHANGE_CLUSTER_FAILED: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="red">Fail</Tag>Change cluster config
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Failed to change cluster config`;
    },
    action(paramDatas: ClusterParamData) {
      return ["Go", `/cluster`];
    },
  },
  TASK_EXPOSE_CLUSTER_START: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="gold">Start</Tag>Expose cluster
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Started exposing cluster ${paramDatas.CLUSTER_NAME}`;
    },
    action(paramDatas: ClusterParamData) {
      return ["Go", `/cluster`];
    },
  },
  TASK_EXPOSE_CLUSTER_SUCCESS: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="green">Success</Tag>Expose cluster
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Successfully exposed cluster ${paramDatas.CLUSTER_NAME}`;
    },
    action(paramDatas: ClusterParamData) {
      return ["Go", `/cluster`];
    },
  },
  TASK_EXPOSE_CLUSTER_FAILED: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="red">Fail</Tag>Expose cluster
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Failed monitoring cluster ${paramDatas.CLUSTER_NAME}`;
    },
    action(paramDatas: ClusterParamData) {
      return ["Go", `/cluster`];
    },
  },
  TASK_MONITORING_CLUSTER_START: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="gold">Start</Tag>Monitoring cluster
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Started monitoring cluster ${paramDatas.CLUSTER_NAME}`;
    },
    action(paramDatas: ClusterParamData) {
      return ["Go", `/cluster`];
    },
  },
  TASK_MONITORING_CLUSTER_SUCCESS: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="green">Success</Tag>Monitoring cluster
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Successfully monitoring cluster ${paramDatas.CLUSTER_NAME}`;
    },
    action(paramDatas: ClusterParamData) {
      return ["Go", `/cluster`];
    },
  },
  TASK_MONITORING_CLUSTER_FAILED: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="red">Fail</Tag>Monitoring cluster
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Failed monitoring cluster ${paramDatas.CLUSTER_NAME}`;
    },
    action(paramDatas: ClusterParamData) {
      return ["Go", `/cluster`];
    },
  },
  TASK_INSTALL_EXPORTER_START: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="gold">Start</Tag>Install exporter
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Started installing exporter for ${paramDatas.CLUSTER_NAME}`;
    },
    action(paramDatas: ClusterParamData) {
      return ["Go", `/cluster`];
    },
  },
  TASK_INSTALL_EXPORTER_SUCCESS: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="green">Success</Tag>Install exporter
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Successfully installed exporter for ${paramDatas.CLUSTER_NAME}`;
    },
    action(paramDatas: ClusterParamData) {
      return ["Go", `/cluster`];
    },
  },
  TASK_INSTALL_EXPORTER_FAILED: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="red">Fail</Tag>Install exporter
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Failed to installed exporter for ${paramDatas.CLUSTER_NAME}`;
    },
    action(paramDatas: ClusterParamData) {
      return ["Go", `/cluster`];
    },
  },
  TASK_RESET_CLUSTER_START: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="gold">Start</Tag>Reset cluster
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Started to reset cluster ${paramDatas.CLUSTER_NAME}`;
    },
    action(paramDatas: ClusterParamData) {
      return ["Go", `/cluster`];
    },
  },
  TASK_RESET_CLUSTER_SUCCESS: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="green">Success</Tag>Reset cluster
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Successfully reset cluster ${paramDatas.CLUSTER_NAME}`;
    },
    action(paramDatas: ClusterParamData) {
      return ["Go", `/cluster`];
    },
  },
  TASK_RESET_CLUSTER_FAILED: {
    title(paramDatas: ClusterParamData) {
      return (
        <>
          <Tag color="red">Fail</Tag>Reset cluster
        </>
      );
    },
    message(paramDatas: ClusterParamData) {
      return `Failed to reset cluster ${paramDatas.CLUSTER_NAME}`;
    },
    action(paramDatas: ClusterParamData) {
      return ["Go", `/cluster`];
    },
  },
};

export const getProcessedNotiParams = (eventCode: string, paramData: any) => {
  const code = EVENT_CODES[eventCode as keyof typeof EVENT_CODES];
  return {
    title: code.title(paramData),
    message: code.message(paramData),
    action: code.action(paramData),
  };
};
