import styled from "@emotion/styled";

const Layout = styled.div`
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
`;

const ColumnSection: React.FC = ({ children }) => {
  return <Layout>{children}</Layout>;
};

export default ColumnSection;
