import styled from "@emotion/styled";

const Layout = styled.div`
  width: 100%;
  max-width: 720px;
`;

const SmallFormLayout: React.FC = ({ children }) => {
  return <Layout>{children}</Layout>;
};

export default SmallFormLayout;
