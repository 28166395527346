import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Flex, Loading, SpaceBetween } from "@nexcloud/nc-ui";
import styled, { x } from "@xstyled/emotion";
import {
  Button as AntdButton,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Select,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { fetchOption } from "config";

import { MemberGroupListItemPane } from "components/Settings/UserGroups";

import { IClusterServices } from "store/redux/zone";
import { useChannels } from "hooks/channel";
import {
  IAccessGroupUserGroup,
  useAccessGroupDetail,
  useAccessGroups,
} from "hooks/cluster/service";
import { useCurrentCluster } from "hooks/cluster/useClusterDetailQuery";
import { useUserGroups } from "hooks/user";
import useQueryParams from "hooks/util/useQueryParams";
import { request } from "api/common";

import { SelectedService } from "./ClusterServices";

const { Title } = Typography;
const { Option } = Select;

const CollapContainer = styled.div`
  width: 100%;
  padding: 50px;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
`;

const DescContainer = styled.div`
  margin-right: 50px;
  height: 100%;
  overflow: auto;
  max-width: 1100px;
`;

const ExporterDrawer: React.FC<{
  show: boolean;
  onClose(): void;
  services: IClusterServices;
  selectedService: SelectedService | null;
}> = ({ onClose, show, services, selectedService }) => {
  const [selectedServiceGroup, setSelectedServiceGroup] = useState();
  const { data, refetch } = useAccessGroups();
  const qp = useQueryParams();

  const isCreateGroup = qp.get("createGroup");
  const [selectedGroupId, setSelectedGroupId] = useState<number>();
  const [showCreateModal, setShowCreateModal] = useState(
    !!isCreateGroup || false
  );

  const [mysqlForm] = useForm<{
    pass: string;
    user: string;
    port: string;
  }>();
  const [nginxForm] = useForm<{
    port: string;
  }>();
  const [result, setResult] = useState(Array);
  const nginx: any = result[0];
  const mysql: any = result[1];
  const { data: cluster } = useCurrentCluster(fetchOption);
  const clusterId = cluster?.id;
  const serviceId = selectedService?.id;
  const appName = selectedService?.serviceName;
  const namespace = selectedService?.serviceKey;

  useEffect(() => {
    async function getExporterList() {
      const response = await request.get(
        `/lari/clusters/${clusterId}/services/${serviceId}/exporters`
      );
      setResult(response.data);
    }
    if (serviceId !== undefined) {
      getExporterList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId]);

  if (!result) {
    return null;
  }
  const installNginx = async () => {
    const v = nginxForm.getFieldsValue();
    const param = {
      serviceGroupId: selectedServiceGroup,
      exporterHubKey: nginx?.exporterHubKey,
      namespace: namespace,
      appName: appName,
      installParams: JSON.stringify(v),
    };
    const response = await request.post(
      `/lari/clusters/${clusterId}/services/${serviceId}/exporters`,
      param
    );
    if (response.status === 200) {
      message.success("Exporter install success!");
      nginxForm.resetFields();
    }
  };

  const installMysql = async () => {
    const v = mysqlForm.getFieldsValue();
    const params = {
      serviceGroupId: selectedServiceGroup,
      exporterHubKey: mysql?.exporterHubKey,
      namespace: namespace,
      appName: appName,
      installParams: JSON.stringify(v),
    };
    const response = await request.post(
      `/lari/clusters/${clusterId}/services/${serviceId}/exporters`,
      params
    );
    if (response.status === 200) {
      message.success("Exporter install success!");
      mysqlForm.resetFields();
    }
  };
  // const steps = [
  //   {
  //     title: "Select Exporter",
  //     content: <SelectExporterStep selectedService={selectedService} />,
  //   },
  //   {
  //     title: "Select Service Group",
  //     content: <SelectClusterGroupStep />,
  //   },
  // ];
  // const [current, setCurrent] = useState(0);

  // const next = () => {
  //   setCurrent(current + 1);
  // };

  // const prev = () => {
  //   setCurrent(current - 1);
  // };
  if (!data) {
    return <Loading />;
  }

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      visible={show}
      width="90%"
    >
      <CollapContainer>
        <DescContainer>
          <x.div>
            <Title level={3}>Exporter Matching and Alert Config</Title>
            {/* <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps> */}
            <x.div mx="auto" w="80%" py="30px">
              <SpaceBetween>
                <x.div fontSize="1.1em" pb="10px">
                  Service Groups
                </x.div>
              </SpaceBetween>
              {data.length > 0 ? (
                <Select
                  onChange={(value: any) => setSelectedServiceGroup(value)}
                >
                  {data?.map((i) => (
                    <Option value={i.id}>{i.groupName}</Option>
                  ))}
                </Select>
              ) : (
                <SpaceBetween>
                  <Unavailable>There are no service groups yet.</Unavailable>
                  <AntdButton
                    type="primary"
                    onClick={() => {
                      setShowCreateModal(true);
                    }}
                  >
                    + Create Group
                  </AntdButton>
                </SpaceBetween>
              )}
              <CreateGroupModal
                onClose={() => {
                  refetch();
                  setShowCreateModal(false);
                }}
                visible={showCreateModal}
              />
              {!!selectedGroupId && (
                <GroupViewModal
                  groupId={selectedGroupId}
                  onClose={() => {
                    setSelectedGroupId(undefined);
                  }}
                />
              )}
            </x.div>
            <x.div>
              <Form>
                <Form.Item
                  name="exporter"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select placeholder="Select an exporter" allowClear>
                    <Option value="nginx">NGINX</Option>
                    <Option value="mysql">MYSQL</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.exporter !== currentValues.exporter
                  }
                >
                  {({ getFieldValue }) =>
                    getFieldValue("exporter") === "mysql" ? (
                      <Flex>
                        <Form form={mysqlForm}>
                          <Form.Item
                            name="user"
                            label="DB User"
                            rules={[
                              {
                                required: true,
                                message: "Please input DB User",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name="pass"
                            label="DB Pass"
                            rules={[
                              {
                                message: "Please input DB Pass",
                                required: true,
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name="port"
                            label="DB Port"
                            rules={[
                              {
                                required: true,
                                message: "Please input DB Port",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Form>
                        <AntdButton type="primary" onClick={installMysql}>
                          Install
                        </AntdButton>
                      </Flex>
                    ) : (
                      <>
                        <Form form={nginxForm}>
                          <Form.Item
                            name="port"
                            label="HTTP Port"
                            rules={[
                              {
                                required: true,
                                message: "Please input HTTP Port",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Form>
                        <AntdButton type="primary" onClick={installNginx}>
                          Install
                        </AntdButton>
                      </>
                    )
                  }
                </Form.Item>
              </Form>
            </x.div>
          </x.div>
        </DescContainer>
        {/* {steps[current].content}
        <x.div mt="24px">
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => message.success("Processing complete!")}
            >
              Done
            </Button>
          )}
          {current > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              Previous
            </Button>
          )}
        </x.div> */}
      </CollapContainer>
    </Drawer>
  );
};

// const SelectExporterStep: React.FC<{
//   selectedService: SelectedService | null;
// }> = (selectedService) => {
//   const [mysqlForm] = useForm<{
//     pass: string;
//     user: string;
//     port: string;
//   }>();
//   const [nginxForm] = useForm<{
//     port: string;
//   }>();
//   const [result, setResult] = useState(Array);
//   const nginx: any = result[0];
//   const mysql: any = result[1];
//   const { data: cluster } = useCurrentCluster({
//     refetchInterval: 5000,
//   });
//   const clusterId = cluster?.id;
//   const serviceId = selectedService.selectedService?.id;
//   const appName = selectedService.selectedService?.serviceName;
//   const namespace = selectedService.selectedService?.serviceKey;
//   useEffect(() => {
//     async function getExporterList() {
//       const response = await request.get(
//         `/lari/clusters/${clusterId}/services/${serviceId}/exporters`
//       );
//       setResult(response.data);
//     }
//     if (serviceId !== undefined) {
//       getExporterList();
//     }
//   }, [serviceId]);
//   if (!result) {
//     return null;
//   }
//   const installNginx = async () => {
//     const v = nginxForm.getFieldsValue();
//     const param = {
//       serviceGroupId: selectedServiceGroup,
//       exporterHubKey: nginx?.exporterHubKey,
//       namespace: namespace,
//       appName: appName,
//       installParams: JSON.stringify(v),
//     };
//     const response = await request.post(
//       `/lari/clusters/${clusterId}/services/${serviceId}/exporters`,
//       param
//     );
//     if (response.status === 200) {
//       message.success("Exporter install success!");
//       nginxForm.resetFields();
//     }
//   };

//   const installMysql = async () => {
//     const v = mysqlForm.getFieldsValue();
//     const params = {
//       serviceGroupId: selectedServiceGroup,
//       exporterHubKey: mysql?.exporterHubKey,
//       namespace: namespace,
//       appName: appName,
//       installParams: JSON.stringify(v),
//     };
//     const response = await request.post(
//       `/lari/clusters/${clusterId}/services/${serviceId}/exporters`,
//       params
//     );
//     if (response.status === 200) {
//       message.success("Exporter install success!");
//       mysqlForm.resetFields();
//     }
//   };

//   return (
//     <x.div>
//       <Form>
//         <Form.Item
//           name="exporter"
//           rules={[
//             {
//               required: true,
//             },
//           ]}
//         >
//           <Select placeholder="Select an exporter" allowClear>
//             <Option value="nginx">NGINX</Option>
//             <Option value="mysql">MYSQL</Option>
//           </Select>
//         </Form.Item>
//         <Form.Item
//           shouldUpdate={(prevValues, currentValues) =>
//             prevValues.exporter !== currentValues.exporter
//           }
//         >
//           {({ getFieldValue }) =>
//             getFieldValue("exporter") === "mysql" ? (
//               <Flex>
//                 <Form form={mysqlForm}>
//                   <Form.Item
//                     name="user"
//                     label="DB User"
//                     rules={[
//                       {
//                         required: true,
//                         message: "Please input DB User",
//                       },
//                     ]}
//                   >
//                     <Input />
//                   </Form.Item>
//                   <Form.Item
//                     name="pass"
//                     label="DB Pass"
//                     rules={[
//                       {
//                         message: "Please input DB Pass",
//                         required: true,
//                       },
//                     ]}
//                   >
//                     <Input />
//                   </Form.Item>
//                   <Form.Item
//                     name="port"
//                     label="DB Port"
//                     rules={[
//                       {
//                         required: true,
//                         message: "Please input DB Port",
//                       },
//                     ]}
//                   >
//                     <Input />
//                   </Form.Item>
//                 </Form>
//                 <Button type="primary" onClick={installMysql}>
//                   Install
//                 </Button>
//               </Flex>
//             ) : (
//               <>
//                 <Form form={nginxForm}>
//                   <Form.Item
//                     name="port"
//                     label="HTTP Port"
//                     rules={[
//                       {
//                         required: true,
//                         message: "Please input HTTP Port",
//                       },
//                     ]}
//                   >
//                     <Input />
//                   </Form.Item>
//                 </Form>
//                 <Button type="primary" onClick={installNginx}>
//                   Install
//                 </Button>
//               </>
//             )
//           }
//         </Form.Item>
//       </Form>
//     </x.div>
//   );
// };

const Unavailable: React.FC = ({ children }) => (
  <x.div textAlign="center" py="5px" fontSize="0.9em" color="gray-400">
    {children}
  </x.div>
);

const CreateGroupModal: React.FC<{ onClose(): void; visible: boolean }> = ({
  onClose,
  visible,
}) => {
  const { data: userGroups } = useUserGroups();
  const { data: channels } = useChannels();

  const [groupSearchQuery, setGroupSearchQuery] = useState("");
  const [groupName, setGroupName] = useState("");
  const [selected, setSelected] = useState<{ [k: string]: boolean }>({});

  const channelArray: any = [];

  return (
    <Modal
      title="Create Service Group"
      visible={visible}
      onCancel={onClose}
      onOk={async () => {
        if (!groupName) {
          message.warn("Please put group name");
          return;
        }

        try {
          const selectedGroupIds = Object.keys(selected).filter(
            (k) => selected[k]
          );

          if (selectedGroupIds.length === 0) {
            message.warn("Please select user groups");
            return;
          }

          const { data: groupId } = await request.post("/lari/accessgroups", {
            groupName: groupName,
          });

          const dto = selectedGroupIds.map((k) => ({
            memberId: k,
            memberType: "GROUP",
          }));

          await request.post(`/lari/accessgroups/${groupId}/members`, dto);

          await request.post(
            `/lari/accessgroups/${groupId}/channels`,
            channelArray
          );

          message.success("Group added");
          onClose();
        } catch (e) {
          message.warn("There was an error. Please try again");
        }
      }}
    >
      <x.div py="10px">
        <x.div p="5px">Group Name</x.div>
        <Input
          placeholder="Group name"
          onChange={(e) => {
            const nextValue = e.target.value.trim();
            if (groupName !== nextValue) {
              setGroupName(nextValue);
            }
          }}
        />
      </x.div>
      <x.div py="10px">
        <x.div p="5px">Select user groups</x.div>
        <Input
          prefix={<SearchOutlined />}
          placeholder="Find a user group ..."
          onChange={(e) => {
            const nextValue = e.target.value.trim();
            if (groupSearchQuery !== nextValue) {
              setGroupSearchQuery(nextValue);
            }
          }}
        />
        {userGroups
          ?.filter((group) => {
            if (!groupSearchQuery) {
              return true;
            }
            if (group.name.includes(groupSearchQuery)) {
              return true;
            }
            return false;
          })
          .map((group, i) => {
            return (
              <MemberGroupListItemPane
                key={i}
                isSelected={selected[group.id]}
                group={group}
                onClick={() => {
                  setSelected({ ...selected, [group.id]: !selected[group.id] });
                }}
              />
            );
          }) ?? <Loading />}
        <Select
          onChange={(value: any) => {
            channelArray.push(value);
          }}
        >
          {channels?.map((i) => (
            <Option value={i.id}>{i.name}</Option>
          ))}
        </Select>
      </x.div>
    </Modal>
  );
};

const GroupViewModal: React.FC<{ groupId: number; onClose(): void }> = ({
  groupId,
  onClose,
}) => {
  const { data: group } = useAccessGroupDetail(groupId);

  if (!group) {
    return null;
  }

  // const { users, groups: userGroups } = group.members;
  // const isEmpty = (users?.length ?? 0) + (userGroups?.length ?? 0) === 0;
  const { groups: userGroups } = group.members;
  const isEmpty = (userGroups?.length ?? 0) === 0;

  return (
    <Modal
      title={`Group ${group.groupName}`}
      visible
      onCancel={onClose}
      footer={null}
    >
      {isEmpty ? (
        <Unavailable>There are no members in this group yet.</Unavailable>
      ) : (
        <x.div>
          {userGroups?.map((userGroup, i) => (
            <ModalGroupView key={`usergroup-${i}`} userGroup={userGroup} />
          )) ?? null}
          {/* {users?.map((user, i) => (
              <ModalMemberView user={user} key={`user-${i}`} />
            )) ?? null} */}
        </x.div>
      )}
    </Modal>
  );
};

// const ChannelItem: React.FC<{ title: string }> = ({ title }) => {
//   return (
//     <Tooltip title={title}>
//       <SlackOutlined />
//     </Tooltip>
//   );
// };

// const Member: React.FC = ({ children }) => (
//   <x.div p="10px" backgroundColor="white" borderColor="gray-100">
//     {children}
//   </x.div>
// );

const ModalGroupView: React.FC<{ userGroup: IAccessGroupUserGroup }> = ({
  userGroup,
}) => {
  return (
    <x.div border="1px solid" borderColor="gray-200" p="20px" my="10px">
      <SpaceBetween>
        <x.div>{userGroup.name}</x.div>
        <x.div>{userGroup.memberCount} members</x.div>
      </SpaceBetween>
    </x.div>
  );
};

// const ModalMemberView: React.FC<{ user: IUser }> = ({ user }) => {
//   return (
//     <x.div border="1px solid" borderColor="gray-200" p="20px" my="10px">
//       <SpaceBetween>
//         <x.div>{user.name}</x.div>
//         <x.div>User</x.div>
//       </SpaceBetween>
//     </x.div>
//   );
// };

// const ServiceGroupListItem: React.FC<{
//   groupInfo: IServiceGroupListItem;
//   onSelect(groupId: number): void;
// }> = ({ groupInfo, onSelect }) => {
//   const { data: group } = useServiceGroupDetail(groupInfo.id);

//   let groupView = null;
//   if (group) {
//     const { groups: userGroups } = group.members;
//     const isEmpty = (userGroups?.length ?? 0) === 0;

//     // groupView = isEmpty ? (
//     //   <Member>
//     //     <Unavailable>There are no members in this group yet.</Unavailable>
//     //   </Member>
//     // ) : (
//     //   <x.div>
//     //     {userGroups?.map((group, i) => (
//     //       <Member key={`usergroup-${i}`}>{group.name}</Member>
//     //     )) ?? null}
//     //     {/* {users?.map((user, i) => (
//     //         <Member key={`user-${i}`}>{user.name}</Member>
//     //       )) ?? null} */}
//     //   </x.div>
//     // );
//   }

//   return (
//     <x.div mt="40px">
//       {/* <SpaceBetween p="10px" backgroundColor="gray-200">
//         {groupInfo.groupName}{" "}
//         {group ? (
//           <x.div>
//             {group.channels?.map((channel, i) => {
//               return <ChannelItem key={i} title={channel.channelInfo.apiUrl} />;
//             }) ?? null}
//             <Tooltip title="View Group">
//               <TeamOutlined onClick={() => onSelect(group.id)} />
//             </Tooltip>
//           </x.div>
//         ) : null}
//       </SpaceBetween>
//       {group ? groupView : <Loading />} */}
//     </x.div>
//   );
// };

export default ExporterDrawer;
