import { useCallback } from "react";
import { useDispatch } from "react-redux";

import * as AccountActions from "store/redux/account";
import { ISetData } from "store/redux/account";

export default function useAccountActions() {
  const dispatch = useDispatch();

  const onSetData = useCallback(
    (param: ISetData) => dispatch(AccountActions.setData(param)),
    [dispatch]
  );

  const onLogout = useCallback(
    () => dispatch(AccountActions.logout()),
    [dispatch]
  );

  const onGetAccount = useCallback(
    () => dispatch(AccountActions.getAccount()),
    [dispatch]
  );

  return { onSetData, onGetAccount, onLogout };
}
