import { IoPersonCircleOutline } from "react-icons/io5";
import { VscCircleFilled } from "react-icons/vsc";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { x } from "@xstyled/emotion";

interface IUserBadge {
  name?: string;
  avatar?: string;
  role?: string;
  isOnline?: boolean;
  isSelected?: boolean;
  isSmall?: boolean;
  isUnassigned?: boolean;
}

const Wrapper = styled.div<IUserBadge>((props) => {
  const { role, isSelected, isSmall, isUnassigned } = props;
  return css`
    display: flex;
    border: none;
    outline: none;
    .role {
      margin-left: 10px;
      font-size: 12px;
      font-weight: 500;
      color: #b2b3bd;
    }
    ${!role &&
    `
    align-items: center;
    `}
    ${isSmall &&
    `
    width:150px;
    font-size: 16px;
    line-height:1em;
    .role {
      font-size: 11px;
      font-weight:300;
      font-family:poppins;
      color: #9C9C9C
    }
      img {
        width:24px;
        height:24px;
      }
      `}
    ${!isSmall &&
    `
    padding:13px;
    border-radius: 13px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    img {
      width:40px;
      height:40px;
    }
    ${
      !isUnassigned &&
      `
      width: 216px;
      height:73px;
      background: #fff;
      &:hover {
        background: #e4e4e4;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.250929);
       cursor: pointer;
      }
      `
    }
      `};
    ${isSelected &&
    `
      background-color: #0068A5;
  color: #fff;
  .role {
    color:#fff;
  }
  &:hover {
    color: #808191;
  }

      `}
  `;
});

const UserBadge: React.FC<IUserBadge> = ({
  children,
  name,
  avatar,
  role,
  isOnline,
  isSelected,
  isSmall,
  isUnassigned,
}) => {
  return (
    <Wrapper
      isSelected={isSelected}
      isSmall={isSmall}
      isUnassigned={isUnassigned}
    >
      {isUnassigned ? (
        <x.div fontSize="26px" color="#9C9C9C" mr="5px">
          <IoPersonCircleOutline />
        </x.div>
      ) : (
        <div>
          <x.img borderRadius="full" src={avatar} />
        </div>
      )}
      {isUnassigned ? (
        <x.p color="#9C9C9C" fontWeight="300" fontSize="16px">
          Unassigned
        </x.p>
      ) : (
        <x.div w="100%">
          <x.div ml="10px" display="flex" justifyContent="space-between">
            <p>{name}</p>
            <div>
              {isOnline && (
                <VscCircleFilled color={isOnline ? "#6FB62C" : "#ffffff"} />
              )}
            </div>
          </x.div>
          <div>
            <p className="role">{role}</p>
          </div>
        </x.div>
      )}
    </Wrapper>
  );
};

export default UserBadge;
