// import React, { useState } from "react";
import { useForm } from "react-hook-form";
// import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Button,
  ColumnSection,
  Flex,
  FormInput,
  InputLayout,
  LinkTextCell,
  Loading,
  Section,
  SmallFormLayout,
  Table,
  // SpaceBetween,
  TitleSection,
} from "@nexcloud/nc-ui";

// import { x } from "@xstyled/emotion";
// import { Button as AntdButton, Checkbox, message, Modal } from "antd";
// import { showTryAgain } from "components/NotificationMessage";
import {
  // AccountsGroup,
  useCurrentUser,
  useMyAccessGroup,
  // useUserTenancies,
} from "hooks/user";
import { getTenancyId, request } from "api/common";

import modal from "utils/modal";
// import paths from "paths";

type IUserProfile = {
  username: any;
  email: string;
};

const MyAccessGroupColumns = [
  {
    Header: "ACCESS GROUP NAME",
    accessor: "groupName",
    Cell: (props: any) => {
      return <LinkTextCell {...props} base="/access-groups" />;
    },
    idAccessor: "id",
  },
  {
    Header: "DESCRIPTION",
    accessor: "description",
    disableSortBy: true,
  },
];

const UserProfile = () => {
  // const history = useHistory();

  const { data: user, refetch } = useCurrentUser();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IUserProfile>({
    mode: "onChange",
    defaultValues: {
      username: user?.name,
      email: user?.email,
    },
  });

  const { data: isMyAccessGroup } = useMyAccessGroup();

  // const { data: tenancies } = useUserTenancies();

  // const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // const [deletionOption, setDeletionOption] = useState(false);

  // const isOwner = user?.currentTenancy?.owner;

  // const hasTenancy = tenancies && tenancies.length > 0;

  // FIXME : Danger Zone 숨기기
  // const confirmLeaveOrg = async () => {
  //   try {
  //     const response = await request.delete(
  //       `/users/tenancies/${getTenancyId()}/withdrawal`
  //     );
  //     const { result } = response.data;
  //     if (result === "OK") {
  //       message.success("Successfully left this organization");
  //       const response = await request.get(`users/tenancies`);
  //       const { res } = response.data;
  //       if (res.length === 0) {
  //         history.push(paths.tempTenancy());
  //       } else {
  //         await request.put(`/users/accounts/tenancies/${res[0].tenancyId}`);
  //         window.location.reload();
  //       }
  //     }
  //   } catch (e) {
  //     if (e.response?.data?.message) {
  //       modal.warning(e.response.data.message);
  //     } else {
  //       showTryAgain();
  //     }
  //   }
  // };

  const Label = styled.label`
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 16px;
    color: #374151;
    font-family: poppins;
  `;

  const Error = styled.p`
    font-size: 14px;
    line-height: 24px;
    color: #ff754c;
    padding-left: 10px;
    font-weight: 500;
    font-family: poppins;
  `;

  // FIXME : Danger Zone 숨기기
  // const onClickLeaveOrg = () => {
  //   if (isOwner) {
  //     message.error(
  //       "Organization owner is not allowed to leave the organization."
  //     );
  //   } else {
  //     confirmLeaveOrg();
  //   }
  // };

  // FIXME : Danger Zone 숨기기
  // const onClickDeleteAcc = async () => {
  //   setDeletionOption(false);

  //   if (hasTenancy) {
  //     message.error(
  //       "You're not allowed to delete the account unless you leave all the organizations."
  //     );
  //   } else {
  //     await request.delete(`users/accounts/me`);
  //     message.success("Successfully deleted your account");
  //   }
  // };

  // FIXME : Danger Zone 숨기기
  // const showDeleteModal = () => {
  //   setIsDeleteModalOpen(true);
  // };

  if (!isMyAccessGroup) {
    return <Loading />;
  }

  const hasMyAccessGroup = isMyAccessGroup.length > 0;

  const onSubmit = async (data: IUserProfile) => {
    try {
      if (!user) {
        return;
      }

      await request.put(
        `/users/tenancies/${getTenancyId()}/members/${user.id}`,
        {
          name: data.username,
        }
      );
      await reset({
        username: data.username,
        email: user?.email,
      });
      await refetch();

      modal.success("Updated");
    } catch (e) {
      alert("There was an error. Please try again later.");
    }
  };

  return (
    <ColumnSection>
      <TitleSection>
        <h3>Profile</h3>
      </TitleSection>
      {user ? (
        <SmallFormLayout>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Label htmlFor="username">Username</Label>
            <FormInput
              id="username"
              type="text"
              {...register("username", {
                required: true,
              })}
            />
            {errors.username && <Error>Enter your username.</Error>}
            <fieldset disabled>
              <InputLayout>
                <Label htmlFor="email">Email Address</Label>
                <FormInput
                  type="text"
                  id="email"
                  {...register("email", {
                    required: true,
                    pattern: /^[\w.]+@[\w.]+\.[A-Za-z]{2,3}$/i,
                  })}
                />
              </InputLayout>
            </fieldset>
            {errors?.email?.type === "required" && (
              <Error>You must enter your email address.</Error>
            )}
            <Button layoutVariant="outlined" variant="primary" type="submit">
              Update
            </Button>
          </form>
        </SmallFormLayout>
      ) : (
        <Loading />
      )}

      <Section>
        <TitleSection>
          <h3>My Access-group</h3>
        </TitleSection>
        {hasMyAccessGroup ? (
          <Table
            columns={MyAccessGroupColumns}
            data={isMyAccessGroup ? isMyAccessGroup : []}
          />
        ) : (
          <Flex h="30px" color="gray-400">
            There's no access-group you belong to.
          </Flex>
        )}
      </Section>
      {/* FIXME : Danger Zone 숨기기 */}
      {/* <Section>
        <TitleSection>
          <h3>Danger Zone</h3>
        </TitleSection>
        <Section>
          <DangerZone
            showDeleteModal={showDeleteModal}
            setDeletionOption={setDeletionOption}
            isOwner={isOwner}
          />
        </Section>
        <DeleteModal
          visible={isDeleteModalOpen}
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          deletionOption={deletionOption}
          accountGroups={isAccountsGroups}
          onClickLeaveOrg={onClickLeaveOrg}
          onClickDeleteAcc={onClickDeleteAcc}
        />
      </Section> */}
    </ColumnSection>
  );
};

// FIXME : Danger Zone 숨기기
// const DangerZoneContent = [
//   {
//     title: "Leave organization",
//     desc: "Removing yourself from this organization. Once you have left the organization, you will no longer have access to the organization. You must receive a new invitation to rejoin.",
//   },
//   {
//     title: "Delete account",
//     desc: "Once you delete your account, there is no going back. Please be certain",
//   },
// ];

// FIXME : Danger Zone 숨기기
// const DangerZone: React.FC<{
//   showDeleteModal(): void;
//   setDeletionOption: any;
//   isOwner: boolean | undefined;
// }> = ({ showDeleteModal, setDeletionOption, isOwner }) => {
//   return (
//     <x.div>
//       {DangerZoneContent.map((item, i) => (
//         <React.Fragment key={i}>
//           <x.div fontWeight="bold">{item.title}</x.div>
//           <SpaceBetween color="gray-400" mt="10px" mb="10px">
//             <x.div>{item.desc}</x.div>
//             <Button
//               size="tiny"
//               layoutVariant="outlined"
//               variant="danger"
//               onClick={() => {
//                 if (isOwner) {
//                   message.error(
//                     "Organization owner is not allowed to leave the organization"
//                   );
//                 } else {
//                   if (item.title === "Leave organization") {
//                     setDeletionOption(true);
//                   } else {
//                     setDeletionOption(false);
//                   }
//                   showDeleteModal();
//                 }
//               }}
//             >
//               {item.title === "Leave organization"
//                 ? "Leave organization"
//                 : "Delete you account"}
//             </Button>
//           </SpaceBetween>
//         </React.Fragment>
//       ))}
//     </x.div>
//   );
// };

// FIXME : Danger Zone 숨기기
// const DeleteModal: React.FC<{
//   onClose(): void;
//   visible: boolean;
//   deletionOption: boolean;
//   accountGroups: AccountsGroup[];
//   onClickLeaveOrg(): void;
//   onClickDeleteAcc(): void;
// }> = ({
//   onClose,
//   visible,
//   deletionOption,
//   accountGroups,
//   onClickLeaveOrg,
//   onClickDeleteAcc,
// }) => {
//   const [confirmChecked, setConfirmChecked] = useState(false);
//   const adminGroups = accountGroups.filter((group) => group.admin);
//   const isAdmin = adminGroups.length > 0;

//   return (
//     <Modal
//       visible={visible}
//       onCancel={onClose}
//       width="600px"
//       centered
//       footer={[
//         <x.div display="flex" justifyContent="space-between">
//           <Checkbox
//             defaultChecked={false}
//             disabled={isAdmin}
//             onChange={() => {
//               setConfirmChecked((confirmChecked) => !confirmChecked);
//             }}
//           >
//             I have read the above information and I want to proceed
//           </Checkbox>
//           <AntdButton
//             danger
//             onClick={() => {
//               if (deletionOption) {
//                 onClickLeaveOrg();
//               } else {
//                 onClickDeleteAcc();
//               }
//               onClose();
//             }}
//             disabled={!confirmChecked || isAdmin}
//           >
//             {deletionOption ? "Leave organization" : "Delete account"}
//           </AntdButton>
//         </x.div>,
//       ]}
//     >
//       <x.p fontWeight="bold" fontSize="1.3em" marginBottom="1em">
//         Are you sure you want to{" "}
//         {deletionOption ? "leave the organization" : "delete your account"}?
//       </x.p>
//       <x.p mb="20px">
//         If you{" "}
//         {deletionOption ? "leave the organization" : "delete your account"},
//         please keep in mind.
//       </x.p>
//       <x.div w="100%" overflowY="scroll">
//         {isAdmin ? (
//           <x.p>
//             You are the admin of the groups below. Hand over the admin and
//             proceed again as a regular user.
//           </x.p>
//         ) : (
//           <>
//             <x.p mb="10px">
//               After your account leaves, you must receive a new invitation to
//               rejoin the organization.
//             </x.p>
//             <x.p>
//               If you continue to leave this organization, all your data here
//               will be deleted permanently and irreversible.
//             </x.p>
//           </>
//         )}
//         {isAdmin && (
//           <SpaceBetween mt="20px" flexWrap="wrap">
//             {accountGroups.map((group, i) => {
//               return <MemberGroup key={i} group={group} />;
//             })}
//           </SpaceBetween>
//         )}
//       </x.div>
//     </Modal>
//   );
// };

// const MemberGroup: React.FC<{
//   group: AccountsGroup;
// }> = ({ group }) => {
//   return (
//     <SpaceBetween
//       backgroundColor="gray-50"
//       w="49%"
//       h="30px"
//       mb="15px"
//       p="20px 6px 20px 20px"
//       border="1px solid"
//       borderColor="gray-100"
//     >
//       <x.div fontWeight="bold">{group.name}</x.div>
//       <x.div fontSize="0.9em" pt="5px" color="gray-400">
//         {group.admin ? "admin" : "member"}
//       </x.div>
//     </SpaceBetween>
//   );
// };

export default UserProfile;
