import { Children } from "react";
import styled from "@emotion/styled";

interface IColumn {
  columnsGap?: string;
}

type WrapperProps = {
  cols: number;
  gap?: string;
};

const Wrapper = styled.ol<WrapperProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 -${(props) => props.gap};
  li {
    padding: 0 ${(props) => props.gap};
  }
`;

const Column: React.FC<IColumn> = (props) => {
  const children =
    Children.map(props.children, (child) => {
      return child;
    }) || [];

  return (
    <Wrapper cols={children.length} gap={props.columnsGap}>
      {children.map((child) => {
        return <li>{child}</li>;
      })}
    </Wrapper>
  );
};

export default Column;
