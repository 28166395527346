import { AiOutlineSmile } from "react-icons/ai";
import { BiMemoryCard } from "react-icons/bi";
import { FaHdd } from "react-icons/fa";
import { FiCpu, FiGrid, FiLayers } from "react-icons/fi";
import {
  Gauge,
  GaugeItem,
  LinkButton,
  Panel,
  PanelTitleSection,
  Section,
  SpaceBetween,
} from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";

interface IElem {
  cluster?: any;
}

const Elem: React.FC<IElem> = ({ cluster }) => {
  return (
    <Section>
      <Panel>
        <PanelTitleSection>
          <SpaceBetween>
            <x.div fontSize="18px" fontWeight="600">
              {cluster.platform}: {cluster.name}
            </x.div>
            <LinkButton to={`/clusters/${cluster.id}`}>VIEW DETAIL</LinkButton>
          </SpaceBetween>
        </PanelTitleSection>
        <SpaceBetween>
          <Gauge title="Nodes" icon={<FiLayers />}>
            <GaugeItem value={0} />
          </Gauge>
          <Gauge title="Pods" icon={<FiGrid />}>
            <GaugeItem value={0} />
          </Gauge>
          <Gauge title="Pods Usage" icon={<FiGrid />}>
            <GaugeItem value={0} unit="%" unitPlural="%" />
          </Gauge>
          <Gauge title="Uptime" icon={<AiOutlineSmile />}>
            <GaugeItem value={0} unit="hours" unitPlural="hours" />
          </Gauge>
          <Gauge title="CPU Avg Usage" icon={<FiCpu />}>
            <GaugeItem value={0} unit="Mhz" unitPlural="Mhz" />
          </Gauge>
          <Gauge title="Memory Avg Usage" icon={<BiMemoryCard />}>
            <GaugeItem value={0} unit="GB" unitPlural="GB" />
          </Gauge>
          <Gauge title="Disk Usage" icon={<FaHdd />}>
            <GaugeItem value={0} unit="%" unitPlural="%" />
          </Gauge>
        </SpaceBetween>
      </Panel>
    </Section>
  );
};

export default Elem;
