import React, { useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  EditOutlined,
  FolderOpenOutlined,
  ReloadOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled/macro";
import { Button as AntdButton } from "antd";

import { ConfigStatus } from "store/redux/zone";

import { device } from "style/device";

const ButtonBox = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const StyledButton = styled(AntdButton)`
  margin-right: 7px;

  @media ${device.mobileL} {
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }
`;

interface IFooterProps {
  editable: boolean;
  status: ConfigStatus | null;
  handleEdit: () => void;
  handleTestAndSave: () => void;
  handleApplyAndReload: () => void;
  isBackup: boolean;
}

export default function Footer({
  editable,
  status,
  isBackup,
  handleEdit,
  handleTestAndSave,
  handleApplyAndReload,
}: IFooterProps) {
  const [disabled, setDisabled] = useState(false);
  const ableApplyButton: any[] = ["TEST_OK", "APPLY_ERROR", "APPLY_FAILED"];

  useEffect(() => {
    const disableList: any[] = ["TESTING", "UPDATING"];
    setDisabled(disableList.includes(status));
  }, [status]);

  return (
    <ButtonBox>
      {editable ? (
        <StyledButton
          icon={<ArrowLeftOutlined />}
          onClick={handleEdit}
          disabled={disabled}
        >
          Cancel
        </StyledButton>
      ) : (
        <StyledButton
          type="primary"
          icon={<EditOutlined />}
          onClick={handleEdit}
          disabled={disabled}
        >
          Edit
        </StyledButton>
      )}
      <StyledButton
        icon={<ReloadOutlined />}
        disabled={!editable || disabled}
        type="primary"
        onClick={handleTestAndSave}
      >
        Save & Test
      </StyledButton>
      <StyledButton
        type="primary"
        icon={<VerticalAlignBottomOutlined />}
        disabled={!ableApplyButton.includes(status)}
        onClick={handleApplyAndReload}
      >
        Apply & Reload
      </StyledButton>
      {isBackup && (
        <StyledButton icon={<FolderOpenOutlined />} disabled={true}>
          Backup
        </StyledButton>
      )}
    </ButtonBox>
  );
}
