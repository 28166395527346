import React, { useEffect, useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import styled from "@emotion/styled/macro";

import { useCreateGet } from "hooks/state/get";

import InnerLoading from "./InnerLoading";

type Active = { active: boolean };

const dynamicColor = ({ active }: Active) => {
  return active ? "#ffffff" : "#686868";
};

const counterBackground = ({ active }: Active) => {
  return active ? "#1890ff" : "transparent";
};

const counterColor = ({ active }: Active) => {
  return active ? "#ffffff" : "#1890ff";
};

const TitleBox = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  color: ${dynamicColor};
  font-weight: bold;
  font-size: 1.1em;
  margin-left: 10px;
`;

const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Counter = styled(Circle)`
  border: 3px solid #1890ff;
  background-color: ${counterBackground};
  color: ${counterColor};
  font-size: 1.1em;
  font-weight: bold;
`;

const IconBox = styled(Circle)`
  background-color: green;
  color: #ffffff;
  svg {
    font-size: 1.1em;
  }
`;

const LoadingBox = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Value = styled.div`
  font-size: 0.9em;
  color: #ffffff;
  font-weight: bold;
  margin-left: 10px;
  padding: 3px 7px;
  background-color: #1890ff;
`;

interface ICollapTitleProps {
  id: string;
  completeList: string[];
  title: string;
  index: number;
  activeKey: string;
  configKey: "zoneName" | "platform";
  valueVisible: boolean;
}

function CollapTitle({
  id,
  activeKey,
  completeList,
  title,
  index,
  configKey,
  valueVisible,
}: ICollapTitleProps) {
  const value = useCreateGet(configKey);
  const loading = useCreateGet("loading");
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    setComplete(completeList.findIndex((c) => c === id) >= 0);
  }, [completeList, id]);

  return (
    <TitleBox>
      {complete ? (
        <IconBox>
          <CheckOutlined />
        </IconBox>
      ) : loading && id === activeKey ? (
        <LoadingBox>
          <InnerLoading />
        </LoadingBox>
      ) : (
        <Counter active={id === activeKey}>{index + 1}</Counter>
      )}
      <Title active={id === activeKey}>{title}</Title>
      {valueVisible && value && <Value>{value}</Value>}
    </TitleBox>
  );
}

export default CollapTitle;
