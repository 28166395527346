const reset = `
/* Reset */
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 16px;
    vertical-align: baseline;
    box-sizing: border-box;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  body .ant-form-item {
    margin-bottom: 10px;
  }

  .ant-form-item-explain div {
    font-size: 0.8em;
  }

  .ant-checkbox-group-item {
    width: 100%;
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after,
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #c5c5c5;
  }

  .ant-menu-inline {
    border-right: 0;
  }

  .ant-menu-inline-collapsed div.ant-menu-submenu-title,
  .ant-menu-inline-collapsed li.ant-menu-item {
    width: 100%;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-menu-inline-collapsed div.ant-menu-submenu-title svg,
  .ant-menu-inline-collapsed li.ant-menu-item svg,
  ul.ant-menu-inline li svg {
    font-size: 1.3em;
  }

  .ant-menu-item {
    padding-left: 52px;
  }

  .ant-layout-sider-children {
    height: 100%;
  }

  ul.ant-menu {
    overflow: hidden;
  }

  ul.ant-menu-inline li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .ant-layout-sider-light .ant-layout-sider-trigger {
    border-top: 1px solid rgb(228, 228, 228);
  }

  .ant-table-cell {
    text-align: center !important;
  }

  .ant-table-row {
    cursor: pointer;
  }

  .ant-select-dropdown {
    width: auto !important;
  }

  // steps icon not being vert. centered
  .ant-steps-item-icon {
    font-size: 1rem;
  }
  .ant-menu-submenu {
    transition: none;
  }
`;

export default reset;
