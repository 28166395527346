import { createAction } from "typesafe-actions";

import { ISetData } from "./types";

export const SET_DATA = "account/SET_DATA";
export const GET_ACCOUNT = "account/GET_ACCOUNT";
export const LOGOUT = "account/LOGOUT";
export const RESET_ACCOUNT = "account/RESET_ACCOUNT";

export const setData = createAction(SET_DATA)<ISetData>();
export const getAccount = createAction(GET_ACCOUNT)();
export const logout = createAction(LOGOUT)();
export const resetAccount = createAction(RESET_ACCOUNT)();
