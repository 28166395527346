import React from "react";
import { x } from "@xstyled/emotion";
import { Form, FormInstance, Input, Modal } from "antd";

import { IUser, useCurrentUser } from "hooks/user";
import { getTenancyId, request } from "api/common";

import modal from "utils/modal";

const { useForm } = Form;

const RESULTS = {
  INVALID_TENANCY_CODE_PATTERN: "INVALID_TENANCY_CODE_PATTERN",
  DUPLICATED_CODE: "DUPLICATED_CODE",
  ALREADY_CREATED: "ALREADY_CREATED",
};

const CreateTenancyView: React.FC<{ onClose?(): void; showModal: boolean }> = ({
  onClose,
  showModal,
}) => {
  const [form] = useForm<{
    userName: string;
    tenancyName: string;
    tenancyDescription: string;
    tenancyCode: string;
  }>();
  const { data: user } = useCurrentUser();

  if (!user) {
    return null;
  }

  if (!user.currentTenancy) {
    return (
      <div>
        user does not have tenancy: setup screen goes in `CreateTenancyView`{" "}
      </div>
    );
  }

  const isOwner = user.currentTenancy.owner === true;

  return (
    <Modal
      title={isOwner ? "Create Your Organization" : "Please put your username"}
      centered
      visible={showModal}
      onCancel={onClose}
      okText="Continue"
      cancelText
      onOk={async () => {
        try {
          if (!user) {
            return;
          }
          try {
            await form.validateFields();
          } catch (e) {
            return;
          }
          const { userName, tenancyName, tenancyDescription, tenancyCode } =
            form.getFieldsValue();

          if (isOwner) {
            const { data } = await request.post(
              `/users/tenancies/${getTenancyId()}`,
              {
                tenancyCode,
                tenancyDescription,
                tenancyName,
                userName,
              }
            );

            // organization code not suitable for domain name
            if (data === RESULTS.INVALID_TENANCY_CODE_PATTERN) {
              modal.error(
                "The organization code contains not allowed pattern. Organization code pattern is 4 to 16 lowercase alphanumeric character and non-continuous hyphen(-)."
              );
              return;
            }

            if (data === RESULTS.DUPLICATED_CODE) {
              modal.error(
                "The organization code is used by another organization already, Please try another code."
              );
              return;
            }

            if (data === RESULTS.ALREADY_CREATED) {
              modal.error("The organization is activated already.");
            }

            window.location.reload();
            return;
          }

          await request.post(`/users/tenancies/${getTenancyId()}/members`, {
            userName,
          });
          window.location.reload();
        } catch (e) {
          alert("There was an error. Please try again later.");
        }
      }}
      width={1000}
    >
      <x.div pb="20px">
        {isOwner && !user.currentTenancy.activated && (
          <p>As admin user, you need to create the organization first.</p>
        )}
        <p>
          Please provide name of this organization and your desired user name.
        </p>
      </x.div>
      <TenancyForm form={form} user={user} />
    </Modal>
  );
};

export const TenancyForm: React.FC<{
  form: FormInstance<any>;
  user: IUser;
}> = ({ form, user }) => {
  const tenancy = user.currentTenancy;

  if (!tenancy) {
    return null;
  }

  const isOwner = tenancy.owner;
  if (!isOwner) {
    return (
      <Form form={form} layout="vertical">
        <Form.Item
          required
          name="userName"
          label="Username"
          initialValue={user.name}
          rules={[
            {
              required: true,
              message: "Please put username",
            },
          ]}
        >
          <Input placeholder="Your username for this organization" />
        </Form.Item>
      </Form>
    );
  }

  return (
    <Form form={form} layout="vertical">
      {tenancy.activated ? (
        <Form.Item
          name="tenancyCode"
          label="Organization Code"
          initialValue={tenancy.tenancyCode}
        >
          <Input disabled />
        </Form.Item>
      ) : (
        <Form.Item
          required
          name="tenancyCode"
          label="Organization Code"
          rules={[{ required: true, message: "Please put organization code" }]}
        >
          <Input placeholder="Organization Code (4 to 16 lowercase alphanumeric character and non-continuous hyphen(-))" />
        </Form.Item>
      )}
      <Form.Item
        required
        name="tenancyName"
        label="Organization Name"
        initialValue={tenancy.tenancyName}
        rules={[
          { required: true, message: "Please put organization name" },
          {
            max: 20,
            message: "Organization name should be less then 20 characters",
          },
        ]}
      >
        <Input placeholder="Name of this organization" />
      </Form.Item>
      {!tenancy.activated && (
        <Form.Item
          name="userName"
          label="Username"
          initialValue={user.name}
          rules={[
            {
              required: true,
              message: "Please put username",
            },
          ]}
        >
          <Input
            placeholder="Your username for this organization"
            disabled={isOwner && tenancy.activated}
          />
        </Form.Item>
      )}
      <Form.Item
        name="tenancyDescription"
        label="Organization Description (Optional)"
        initialValue={tenancy.tenancyDescription}
        rules={[
          {
            max: 100,
            message: "Description should be less then 100 characters",
          },
        ]}
      >
        <Input.TextArea
          autoSize
          placeholder="Description of this organization"
        />
      </Form.Item>
    </Form>
  );
};

export default CreateTenancyView;
