import React, { useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FiMoreVertical } from "react-icons/fi";
import { useParams } from "react-router-dom";
import type { Row } from "react-table";
import {
  Breadcrumb,
  Button,
  Description,
  EmptyBox,
  Flex,
  FormInput,
  FormTextArea,
  IconDisplay,
  IconSelector,
  InputLayout,
  Page,
  Panel,
  Section,
  SelectingTable,
  SmallFormLayout,
  SortableCard,
  SortableDoubleDeck,
  SortableItemsType,
  SpaceBetween,
  Table,
  TitleSection,
  UserBadge,
} from "@nexcloud/nc-ui";
import styled, { x } from "@xstyled/emotion";
import { difference } from "lodash-es";

import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "components/common/Dropdown";

import { useChannels } from "hooks/channel";
import {
  IAccessGroupDetailChannel,
  useAccessGroupDetail,
} from "hooks/cluster/service";
import { useAccessUsers } from "hooks/user";
import { request } from "api/common";

const clusterColumns = [
  {
    Header: "CLUSTER NAMES",
    accessor: "name",
  },
  {
    Header: "DESCRIPTION",
    accessor: "description",
    disableSortBy: true,
  },
  // {
  //   Header: "SERVICES",
  //   accessor: "clusterId",
  // },
];

const addedChannelColumns = [
  {
    Header: "CHANNELS",
    accessor: (d: IAccessGroupDetailChannel) => d.channelInfo.name,
  },
  {
    Header: "DESCRIPTION",
    accessor: (d: IAccessGroupDetailChannel) => d.channelInfo.description,
    disableSortBy: true,
  },
];

const AccessGroupIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  font-size: 25px;
  border-radius: 5px;
`;

const EditAccessGroup: React.FC<{
  groupDesc: string | undefined;
  groupName: string | undefined;
  groupIcon: string | undefined;
  onClose: any;
  groupId: number;
  refetch: any;
}> = ({ groupName, groupDesc, groupIcon, onClose, groupId, refetch }) => {
  const [newGroupName, setNewGroupName] = useState(groupName);
  const [newGroupDesc, setNewGroupDesc] = useState(groupDesc);
  const [newGroupIcon, setNewGroupIcon] = useState(groupIcon);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);

    try {
      const response = await request.put(`/lari/accessgroups/${groupId}`, {
        groupName: newGroupName,
        description: newGroupDesc,
        icon: newGroupIcon,
      });

      if (response.status === 200) {
        setIsSubmitting(false);
        refetch();
        onClose("info");
      }
    } catch (e) {
      alert("There was an error. Please try again later.");
    }
  };

  return (
    <Section>
      <SmallFormLayout>
        <TitleSection>
          <h2>Edit Access Group</h2>
        </TitleSection>
        <InputLayout>
          <FormInput
            value={newGroupName}
            label="New Access Group Name"
            onChange={(e) => {
              setNewGroupName(e.target.value);
            }}
          />
        </InputLayout>
        <InputLayout>
          <FormTextArea
            value={newGroupDesc}
            maxLength="250"
            label="Access Group Description"
            rows={6}
            onChange={(e) => {
              setNewGroupDesc(e.target.value);
            }}
          />
        </InputLayout>
        <InputLayout>
          <IconSelector
            initialValue={groupIcon}
            onChange={(value: string) => {
              setNewGroupIcon(value);
            }}
            type="accessGroup"
          />
        </InputLayout>
        <SpaceBetween>
          <Button
            loading={isSubmitting}
            variant="primary"
            onClick={onSubmit}
            disabled={newGroupName === "" ? true : false}
          >
            Save
          </Button>
          <Button
            layoutVariant="outlined"
            variant="primary"
            onClick={() => onClose("info")}
          >
            Cancel
          </Button>
        </SpaceBetween>
      </SmallFormLayout>
    </Section>
  );
};

const Menu = styled.div`
  .menuIcon {
    font-size: 16px;
    color: #909aae;
    cursor: pointer;
  }
`;

const AlertMenu: React.FC<{
  value: string;
  row: Row<IAccessGroupDetailChannel>;
}> = ({ value, row }) => {
  const params = useParams<{ id: string }>();
  const groupId = Number(params.id);
  const { refetch } = useAccessGroupDetail(groupId);

  const onDelete = async () => {
    if (
      window.confirm(
        "Are you sure you want to remove this channel? All the users in this access group will stop receiving message through this channel."
      )
    ) {
      const channelId = row.original.id;

      try {
        const response = await request.delete(
          `/lari/accessgroups/${groupId}/channels`,
          {
            data: [channelId],
          }
        );

        if (response.status === 200) {
          refetch();
        }
      } catch (e) {
        alert("There was an error. Please try again later.");
      }
    }
  };

  return (
    <Menu>
      <Dropdown
        placement="left-start"
        trigger="click"
        render={(attrs) => {
          return (
            <DropdownMenu>
              <x.div w="222px" p="12px 14px" pb="30px">
                {[["Remove Channel", "", <AiFillDelete />, onDelete]].map(
                  ([title, link, icon, onClick], i) => (
                    <DropdownMenuItem
                      key={i}
                      icon={icon}
                      title={title}
                      // @ts-ignore
                      link={link}
                      // @ts-ignore
                      onClick={onClick}
                    />
                  )
                )}
              </x.div>
            </DropdownMenu>
          );
        }}
      >
        <FiMoreVertical className="menuIcon" />
      </Dropdown>
    </Menu>
  );
};

const editModechannelColumn = [
  {
    Header: "CHANNELS",
    accessor: (d: IAccessGroupDetailChannel) => d.channelInfo.name,
  },
  {
    Header: "DESCRIPTION",
    accessor: (d: IAccessGroupDetailChannel) => d.channelInfo.description,
    disableSortBy: true,
  },
  {
    Header: "",
    accessor: "id",
    Cell: AlertMenu,
    id: "popupMenu",
    disableSortBy: true,
  },
];

const notAddChannelColumn = [
  {
    Header: "CHANNELS",
    accessor: "name",
  },
  {
    Header: "DESCRIPTION",
    accessor: "description",
  },
];

function filterById(mainArr: any, filterArr: any) {
  let idSet = new Set(filterArr.map((obj: any) => obj.id));
  return mainArr.filter((obj: any) => !idSet.has(obj.id));
}

const EditAccessGroupChannel: React.FC<{
  channels: any;

  groupId: number;
  refetch: any;
}> = ({ channels, groupId, refetch }) => {
  const { data: allChannelInfo } = useChannels();
  const [isAddChannel, setIsAddChannel] = useState(false);
  const AccessGroupChannleInfo = channels.map((el: any) => el.channelInfo);
  const notAddedChannel =
    allChannelInfo && filterById(allChannelInfo, AccessGroupChannleInfo);

  let selectedChannel: any = [];

  const onSubmit = async () => {
    const selected = selectedChannel?.map((row: any) => row.original.id);

    try {
      const response = await request.post(
        `/lari/accessgroups/${groupId}/channels`,
        selected
      );

      if (response.status === 200) {
        setIsAddChannel(false);
        refetch();
      }
    } catch (e) {
      alert("There was an error. Please try again later.");
    }
  };

  return (
    <Section>
      <Section>
        {!isAddChannel && channels?.length !== 0 ? (
          <Table
            columns={editModechannelColumn}
            data={channels ? channels : []}
          />
        ) : (
          <EmptyBox />
        )}
        {isAddChannel && notAddedChannel?.length !== 0 ? (
          <SelectingTable
            columns={notAddChannelColumn}
            data={notAddedChannel ? notAddedChannel : []}
            setSelectedRow={(selectedFlatRows: any) => {
              selectedChannel = [...selectedFlatRows];
            }}
          />
        ) : (
          <EmptyBox />
        )}
      </Section>
      <SpaceBetween>
        {isAddChannel && (
          <Button variant="primary" size="tiny" onClick={onSubmit}>
            Save
          </Button>
        )}
        <Button
          layoutVariant="outlined"
          variant="primary"
          size="tiny"
          onClick={() => setIsAddChannel(!isAddChannel)}
        >
          {isAddChannel ? "Cancel" : "Add Channel"}
        </Button>
      </SpaceBetween>
    </Section>
  );
};

const diffMembers = (
  initialSnapshot: SortableItemsType,
  state: SortableItemsType
) => {
  return {
    removing: difference(state.left, initialSnapshot.left),
    adding: difference(state.right, initialSnapshot.right),
  };
};

const EditAccessGroupUser: React.FC<{
  groupId: number;
  refetch: any;
  members: any;
  onFinish: any;
}> = ({ groupId, refetch, members, onFinish }) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<SortableItemsType>(() => ({
    left: [],
    right: [],
  }));
  const [initialSnapshot, setInitialSnapshot] = useState<SortableItemsType>(
    () => ({
      left: [],
      right: [],
    })
  );

  const { data } = useAccessUsers({
    onSuccess(data) {
      const snapshot = {
        left: data.content
          .filter(({ id }) => !members?.find((m: any) => m.userId === id))
          .map(({ id }) => String(id)),
        right: members?.map(({ userId }: any) => String(userId)) ?? [],
      };
      setInitialSnapshot(snapshot);
      setItems(snapshot);
    },
  });

  const diff = diffMembers(initialSnapshot, items);

  return (
    <Section>
      <SortableDoubleDeck
        titles={{ left: "Team Members", right: "Access Group Users" }}
        items={items}
        setItems={setItems}
        renderInnerItem={({ itemId, containerId, listeners }) => {
          const user = data?.content?.find((c) => String(c.id) === itemId);
          return (
            <SortableCard
              {...{ itemId, containerId, items, setItems, listeners }}
            >
              <UserBadge
                isSmall
                name={user?.name}
                avatar="https://www.w3schools.com/images/picture.jpg"
              />
            </SortableCard>
          );
        }}
      />
      <Button
        loading={loading}
        onClick={async () => {
          if (diff.adding.length > 0) {
            await request.post(
              `/lari/accessgroups/${groupId}/members`,
              diff.adding.map((id) => ({
                memberId: Number(id),
                memberType: "USER",
              }))
            );
          }

          if (diff.removing.length > 0) {
            await request.delete(`/lari/accessgroups/${groupId}/members`, {
              data: members
                .filter((m: any) => diff.removing.includes(String(m.userId)))
                .map((m: any) => m.id),
            });
          }
          setLoading(true);
          await refetch();
          onFinish();
        }}
        disabled={diff.removing.length === 0 && diff.adding.length === 0}
      >
        Confirm
      </Button>
    </Section>
  );
};

const AccessGroupDetail = () => {
  const params = useParams<{ id: string }>();
  const groupId = Number(params.id);

  const [isGroupInfoEditMode, setIsGroupInfoEditMode] = useState(false);
  const [isChannelEditMode, setIsChannelEditMode] = useState(false);
  const [isGroupUserEditMode, setIsGroupUserEditMode] = useState(false);

  const { data: group, refetch } = useAccessGroupDetail(groupId);

  const members = group?.members.users;
  const clusters = group?.clusters;
  const channels = group?.channels;

  const onClose = (target: string) => {
    if (target === "info") {
      setIsGroupInfoEditMode(false);
    }

    if (target === "channel") {
      setIsChannelEditMode(false);
    }
  };

  return (
    <Page>
      <Section>
        <TitleSection>
          <span>
            <Breadcrumb title="Access Groups" path="/access-groups" />
            <Flex>
              {group?.icon && (
                <AccessGroupIcon>
                  <IconDisplay
                    value={group?.groupName}
                    selected={group?.icon}
                    type="accessGroup"
                  />
                </AccessGroupIcon>
              )}
              <h2>{group?.groupName || "No name"}</h2>
            </Flex>
          </span>
          {!isGroupInfoEditMode && (
            <Button
              layoutVariant="outlined"
              variant="primary"
              size="tiny"
              onClick={() => setIsGroupInfoEditMode(true)}
            >
              Edit
            </Button>
          )}
        </TitleSection>
      </Section>
      <Section>
        <Panel>
          <Description label="Description">{group?.description}</Description>
        </Panel>
      </Section>
      {isGroupInfoEditMode && (
        <EditAccessGroup
          groupName={group?.groupName}
          groupDesc={group?.description}
          groupIcon={group?.icon}
          onClose={onClose}
          groupId={groupId}
          refetch={refetch}
        />
      )}
      <Section>
        <TitleSection>
          <h2>USERS</h2>
          <Button
            layoutVariant="outlined"
            variant="primary"
            size="tiny"
            onClick={() => setIsGroupUserEditMode(true)}
          >
            Edit
          </Button>
        </TitleSection>
        <Panel>
          <Flex>
            {members?.map((user) => (
              <UserBadge
                avatar={
                  user.photo
                    ? user.photo
                    : "https://www.w3schools.com/images/picture.jpg"
                }
                name={user.name}
                role="User"
                isSmall
              />
            ))}
          </Flex>
        </Panel>
      </Section>
      {isGroupUserEditMode && (
        <EditAccessGroupUser
          members={members}
          groupId={groupId}
          refetch={refetch}
          onFinish={() => {
            setIsGroupUserEditMode(false);
          }}
        />
      )}
      <Section>
        <TitleSection>
          <h2>CLUSTERS</h2>
          <Button
            layoutVariant="contained"
            variant="primary"
            size="tiny"
            disabled
          >
            Edit
          </Button>
        </TitleSection>
        <Panel>
          {clusters?.length === 0 ? (
            <EmptyBox />
          ) : (
            <Table columns={clusterColumns} data={clusters ? clusters : []} />
          )}
        </Panel>
      </Section>
      <Section>
        <TitleSection>
          <h2>CHANNELS</h2>
          <Button
            layoutVariant={isChannelEditMode ? "contained" : "outlined"}
            variant="primary"
            size="tiny"
            onClick={() => setIsChannelEditMode(!isChannelEditMode)}
          >
            {isChannelEditMode ? "Done" : "Edit"}
          </Button>
        </TitleSection>
        {!isChannelEditMode && (
          <Panel>
            {channels?.length === 0 ? (
              <EmptyBox />
            ) : (
              <Table
                columns={addedChannelColumns}
                data={channels ? channels : []}
              />
            )}
          </Panel>
        )}
        {isChannelEditMode && (
          <EditAccessGroupChannel
            channels={channels}
            groupId={groupId}
            refetch={refetch}
          />
        )}
      </Section>
    </Page>
  );
};

export default AccessGroupDetail;
