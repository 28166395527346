import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import Join from "components/Auth/Join";
import Login from "components/Auth/Login";
import ResetPassword from "components/Auth/ResetPassword";
import ResetPasswordCheck from "components/Auth/ResetPasswordCheck";
import LoginSuccess from "pages/LoginSuccess";
import NotFoundPage from "pages/NotFoundPage";

function UnauthorizedView() {
  return (
    <Router>
      <Switch>
        <Redirect from="/" to="/login" exact />
        <Redirect from="/success" to="/login/success" exact />
        <Redirect from="/(prometheus|cluster)" to="/" />
        <Route exact path="/login/success" component={LoginSuccess} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/join" component={Join} />
        <Route exact path="/reset" component={ResetPasswordCheck} />
        <Route exact path="/resetpassword" component={ResetPassword} />
        <Route exact path="/404">
          <NotFoundPage backPath="/" />
        </Route>
        <Redirect to="/404" />
      </Switch>
    </Router>
  );
}

export default UnauthorizedView;
