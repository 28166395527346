import React from "react";
import styled from "@emotion/styled/macro";
import { x } from "@xstyled/emotion";
import { Tooltip } from "antd";

import ClusterStatusIcon from "components/Cluster/ClusterStatusIcon";
import MonitoringButton from "components/Cluster/MonitoringButton";
import PlatformIcon from "components/PlatformIcon";

import type { Platform } from "store/redux/create";
import { IGlobalView, IZone } from "store/redux/zone";
import { platformList } from "store/redux/zone/reducer";

import { timeZone } from "const";
import day from "utils/day";
import paths from "paths";

import Prometheus from "assets/prometheus.png";

const ManageBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ManageIcon = styled.div`
  width: 25px;
  height: 25px;
  margin: 0 5px;
  overflow: hidden;
  border-radius: 50%;
`;

const ClusterName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    padding-right: 4px;
  }
`;

export const clusterTableColumns = [
  {
    title: "Type",
    dataIndex: "platform",
    key: "platform",
    filters: platformList
      .map((platform) => ({
        text: platform.get("title"),
        value: platform.get("id"),
      }))
      .toJS() as { text: string; value: string }[],
    onFilter: (value: any, row: any) => row.platform.indexOf(value) === 0,
    render(platform: Platform, { provider: _provider }: IZone | IGlobalView) {
      const provider = _provider?.toLowerCase();
      if (provider === "AWS") {
        return <PlatformIcon id="AWS" type="text" />;
      }
      if (!provider || provider === "unknown") {
        return <PlatformIcon id={platform} type="text" />;
      }
      // FIXME: add icons
      return provider;
    },
  },
  {
    title: "Cluster Name",
    dataIndex: "name",
    key: "name",
    render(value: any, row: any) {
      if (value === "GLOBAL_VIEW") {
        return (
          <Tooltip
            title="Global-view is internal managed service.
            Detail view is not available"
          >
            <ClusterName>
              <x.span color="GrayText">{value}</x.span>
              <ClusterStatusIcon status={row.agentStatus} />
            </ClusterName>
          </Tooltip>
        );
      } else {
        return (
          <ClusterName>
            <x.span>{value}</x.span>
            <ClusterStatusIcon status={row.agentStatus} />
          </ClusterName>
        );
      }
    },
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    render(value: any) {
      return day(value).tz(timeZone).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    title: "Manage",
    key: "action",
    render(value: any, row: any) {
      if (row.name === "GLOBAL_VIEW") {
        return null;
      }

      return (
        <ManageBox>
          <ManageIcon>
            <MonitoringButton
              installed={row.initStatus === "ACTIVE"}
              img={Prometheus}
              innerUrl={
                row.initStatus === "ACTIVE"
                  ? paths.cluster.dashboard(row.id)
                  : undefined
              }
            />
          </ManageIcon>
        </ManageBox>
      );
    },
  },
];
