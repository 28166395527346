import { AiOutlineLoading } from "react-icons/ai";
import styled from "@emotion/styled";

const Container = styled.div`
  .loading {
    font-size: 21px;
    animation: load 1.1s infinite linear;
  }

  @keyframes load {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
// TODO: size 변경 가능하게 수정
const LoadingIcon = () => {
  return (
    <Container>
      <AiOutlineLoading className="loading" />
    </Container>
  );
};

export default LoadingIcon;
