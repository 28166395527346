import React, { useEffect, useState } from "react";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import styled from "@emotion/styled/macro";
import { message, notification, Tag } from "antd";
import { useAtom } from "jotai";

import Editor from "components/Editor";

import { currentClusterAtom } from "atoms";
import type { ConfigFileType, ConfigStatus } from "store/redux/zone/types";

import fileName from "./filename.json";
import Footer from "./Footer";
import {
  useApplyConfig,
  useEditConfig,
  useGetConfig,
  useResetConfig,
} from "./hooks";
import Modal from "./Modal";

import { capitalize } from "utils";

const Loading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const EditorBox = styled.div`
  width: 100%;
  flex: 1;
`;

const FileName = styled.div`
  font-size: 1em;
  font-weight: bold;
  color: #222222;
  margin: 10px 0;
  min-height: 22px;
  display: flex;
  align-items: center;
`;

const updatingText = {
  prometheus: "Prometheus Config",
  alertRules: "Alert Rules",
  recordingRules: "Recording Rules",
  alert: "Alert Config",
};

const updatingStatus = ["UPDATING", "TESTING"] as const;

const ConfigFileComponent: React.FC<{ type: ConfigFileType }> = ({ type }) => {
  const [aceEditor, setAceEditor] = useState<any>(null);
  const [editable, setEditable] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEditContents, setShowEditContents] = useState(false);
  const [editContents, setEditContents] = useState("");
  const [refetchInterval, setRefetchInterval] = useState<undefined | number>();

  const [step, setStep] = useState<
    "testAndSave" | "applyAndReloadP8S" | "applyAndReloadAlert"
  >("testAndSave");

  const yamlName = fileName[type];
  const configIsP8s =
    type === "prometheus" || type === "alertRules" || type === "recordingRules";

  const [{ id: zoneId }] = useAtom(currentClusterAtom);
  const { data: config, refetch } = useGetConfig(zoneId, type, {
    refetchInterval,
  });

  const { mutateAsync: editConfig } = useEditConfig();
  const { mutateAsync: applyConfig } = useApplyConfig();
  const { mutateAsync: resetConfig } = useResetConfig();
  const status = config?.configurationStatus;

  const openNotification = (status?: ConfigStatus) => {
    // Modal이 열려있다면 따로 보여주지 않는다.
    if (showModal || !status) {
      return;
    }

    const args = {
      message: (
        <Loading>
          <LoadingOutlined
            style={{ color: "#096dd9", fontSize: "1.5em", marginRight: "7px" }}
          />
          <span style={{ fontWeight: "bold" }}>{`${capitalize(status)} ${
            updatingText[type]
          }`}</span>
        </Loading>
      ),
      duration: null,
      key: "updating",
    };
    notification.open(args);
  };

  useEffect(() => {
    if (!config || !aceEditor || !zoneId || !status) {
      return;
    }
    // @ts-ignore // FIXME
    const isUpdating = updatingStatus.includes(status);

    if (!updating && isUpdating) {
      openNotification(status);
      setUpdating(true);
      setRefetchInterval(5000);
    } else if (updating && !isUpdating) {
      notification.close("updating");

      if (!showModal && status === "OK") {
        message.success("Successfully updated.");
      }
      setRefetchInterval(undefined);
      setUpdating(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, status]);

  useEffect(() => {
    if (!config || !aceEditor) {
      return;
    }

    if (showEditContents) {
      const contents =
        editContents || config?.notAppliedContents || config?.contents || "";
      aceEditor.setValue(contents);
      aceEditor.getSession().selection.clearSelection();
    } else {
      if (editable) {
        setEditContents(aceEditor.getValue());
      }
      aceEditor.setValue(config?.contents || "");
      aceEditor.getSession().selection.clearSelection();
    }

    if (editable) {
      aceEditor.focus();
      aceEditor.navigateFileEnd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditContents, config]);

  useEffect(() => {
    return () => {
      setRefetchInterval(undefined);
      notification.close("updating");
    };
  }, [type]);

  if (!zoneId) {
    return null;
  }

  const handleApplySuccess = () => {
    setEditable(false);
    setShowEditContents(false);

    refetch();
  };

  const handleEdit = () => {
    setEditable((_editable) => !_editable);

    if (!editable) {
      setShowEditContents(true);
    } else {
      setEditContents("");
      setShowEditContents(false);

      aceEditor.setValue(config?.contents || "");
      aceEditor.getSession().selection.clearSelection();
    }
  };

  const onCancel = () => {
    if (status === "TESTING" || status === "UPDATING") {
      return;
    }
    setShowModal(false);
  };

  const onTestAndSave = async () => {
    const contents = aceEditor.getValue();
    await editConfig({ clusterId: zoneId, contents, type });
    refetch();
  };

  const onApplyAndReload = async () => {
    await applyConfig({ clusterId: zoneId, type });
    refetch();
  };

  const onResetConfig = async () => {
    await resetConfig({ clusterId: zoneId, type });
    refetch();
  };

  const handleShowMode = () => {
    setShowEditContents((s) => !s);
  };

  const handleTestAndSave = () => {
    setShowModal(true);
    setStep("testAndSave");
  };

  const handleApplyAndReload = () => {
    setShowModal(true);
    if (type === "alert") {
      setStep("applyAndReloadAlert");
    } else {
      setStep("applyAndReloadP8S");
    }
  };

  return (
    <>
      <FileName>
        {yamlName}
        {editable && (
          <>
            <EditOutlined style={{ fontSize: "1.1em", margin: "0 5px" }} />
            <Tag color="magenta" style={{ fontSize: "0.8em" }}>
              Editing
            </Tag>
          </>
        )}
        <Tag
          color={showEditContents ? "orange" : "blue"}
          style={{ cursor: "pointer", margin: "0 5px" }}
          onClick={handleShowMode}
        >
          {showEditContents ? "Not Applied Contents" : "Applied Contents"}
        </Tag>
      </FileName>
      <EditorBox>
        <Editor
          mode="yaml"
          onChange={() => {}}
          onLoad={(ace: any) => {
            setAceEditor(ace);
          }}
          readOnly={!editable || !showEditContents}
        />
      </EditorBox>
      <Footer
        editable={editable}
        status={status ?? null}
        handleEdit={handleEdit}
        handleTestAndSave={handleTestAndSave}
        handleApplyAndReload={handleApplyAndReload}
        isBackup={configIsP8s}
      />
      <Modal
        visible={showModal}
        step={step}
        status={status ?? null}
        testLog={config?.testLog}
        onCancel={onCancel}
        onTestAndSave={onTestAndSave}
        onApplyAndReload={onApplyAndReload}
        handleTestSuccess={() => {}}
        handleApplySuccess={handleApplySuccess}
        onResetConfig={onResetConfig}
      />
    </>
  );
};

export default ConfigFileComponent;
