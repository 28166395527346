import React, { useEffect, useMemo, useState } from "react";
import {
  Breadcrumb,
  Button,
  Copyboard,
  ExporterCard,
  Flex,
  FormInput,
  Page,
  Section,
  Tab,
  TitleSection,
} from "@nexcloud/nc-ui";
import { TabPane } from "@nexcloud/nc-ui/src/Tab";
import { x } from "@xstyled/emotion";
import { Collapse, Switch } from "antd";
import Drawer from "antd/es/drawer";
import yamlParse from "js-yaml";
import { map } from "lodash-es";
import raw from "raw.macro";
import type { ValuesType } from "utility-types";

import { useCluster } from "hooks/cluster/useClusterDetailQuery";
import { useClusterService } from "hooks/exporter";

import ExportersMap from "const/exporters";

const MySQLYamlString = raw("./yaml/MySQL_alert.yaml");

type IExporter = ValuesType<typeof ExportersMap>;

const ExporterInstallDrawer: React.FC<{
  visible: boolean;
  onClose(): void;
  options: any;
}> = ({ visible, onClose, options }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedExporterId, setSelectedExporterId] = useState<number>();
  const [step, setStep] = useState<number>(0);

  const { data: cluster } = useCluster(options.clusterId);
  const { data: service } = useClusterService(
    options.clusterId,
    options.serviceId
  );
  const selectedExporter = selectedExporterId
    ? ExportersMap.find(
        (exporter) => exporter.exporterId === selectedExporterId
      )
    : null;

  if (!cluster || !service) {
    return null;
  }

  let yamlRules: any;
  let yamlBody: any;
  // "MySQL Server Exporter"
  if (selectedExporter?.exporterId === 25) {
    yamlBody = yamlParse.load(MySQLYamlString) as any;
    if (yamlBody?.[0]?.rules) {
      yamlRules = yamlBody?.[0]?.rules;
    }
  }

  return (
    <Drawer visible={visible} onClose={onClose} width="70%">
      <Page>
        <TitleSection>
          <div onClick={onClose}>
            <Breadcrumb title={cluster.name} path={`/clusters/${cluster.id}`} />
          </div>
          <TitleSection type="withBreadcrumb">
            <h2>{selectedExporter ? selectedExporter.name : service.name}</h2>
          </TitleSection>
        </TitleSection>
        <Section>
          {step === 0 ? (
            <x.div>
              <x.fieldset>
                <x.div w="50%">
                  <FormInput
                    searchIcon
                    onChange={(e) => {
                      setSearchQuery(e.target.value.trim());
                      setSelectedExporterId(undefined);
                    }}
                  />
                </x.div>
              </x.fieldset>
              <Flex
                w="760px"
                flexWrap="wrap"
                h="650px"
                mt="15px"
                alignItems="flex-start"
                gap="15px"
              >
                {ExportersMap.filter(({ name }) => {
                  if (!searchQuery) {
                    return true;
                  }
                  return name.toLowerCase().includes(searchQuery);
                }).map(
                  (
                    {
                      exporterId,
                      name,
                      logoUrl,
                      category,
                      official,
                      stars,
                      description,
                    },
                    i
                  ) => (
                    <ExporterCard
                      key={i}
                      name={name}
                      logoUrl={logoUrl}
                      category={category}
                      official={official === "Official"}
                      stars={stars}
                      description={description}
                      selected={exporterId === selectedExporterId}
                      onClick={() => {
                        setSelectedExporterId(
                          exporterId === selectedExporterId
                            ? undefined
                            : exporterId
                        );
                        setStep(1);
                      }}
                    />
                  )
                )}
              </Flex>
            </x.div>
          ) : (
            <SelectedExporterView
              exporter={selectedExporter}
              onSelect={() => {
                setSelectedExporterId(undefined);
                setSearchQuery("");
                setStep(0);
              }}
              yamlRules={yamlRules}
              yamlBody={yamlBody}
            />
          )}
        </Section>
      </Page>
    </Drawer>
  );
};

// {
//   "appName": `nc-${serviceName}`,
//   "chartValues": "string",
//   "namespace": "nexclipper",
//   "repoName": "string",
//   "serviceGroupId": 0
// }

const SelectedExporterView: React.FC<{
  exporter: IExporter | null | undefined;
  onSelect(): void;
  yamlRules?: any[];
  yamlBody?: any;
}> = ({ exporter, onSelect, yamlBody, yamlRules }) => {
  const initYamlCheck = () => {
    return Array.from({ length: yamlRules?.length ?? 0 }).map(() => false);
  };

  const [yamlCheck, setYamlCheck] = useState(initYamlCheck());
  // const yamlPayload = yamlParse.dump([
  //   {
  //     name: yamlBody?.[0]?.name,
  //     rules: yamlRules?.filter((_, i) => yamlCheck[i]),
  //   },
  // ]); // use this yamlPayload to send request out

  const panes = useMemo(() => {
    let ret: TabPane[];
    if (!exporter) {
      return;
    }

    ret = [["INSTALL", InstallTab, { exporter, onSelect }]];

    if (yamlRules) {
      ret.push([
        "ALERT RULES",
        AlertRulesTab,
        { onSelect, yamlCheck, setYamlCheck, rules: yamlRules },
      ]);
    }

    return ret;
  }, [exporter, onSelect, yamlRules, yamlCheck]);

  useEffect(() => {
    setYamlCheck(initYamlCheck());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exporter?.exporterId]);

  if (!exporter || !panes) {
    return null;
  }

  return (
    <x.div>
      <TitleSection>ADD EXPORTER</TitleSection>
      <Tab panes={panes} />
    </x.div>
  );
};

const InstallTab: React.FC<{ exporter: IExporter; onSelect(): void }> = ({
  exporter,
  onSelect,
}) => {
  return (
    <x.div pt="20px">
      {exporter.paramSchema
        ? map(exporter.paramSchema, (param, key) => {
            return (
              <x.div>
                {key}{" "}
                <x.input
                  border="1px solid"
                  color="gray-700"
                  type={param.type}
                  defaultValue={
                    // @ts-ignore
                    param?.default ?? ""
                  }
                />
              </x.div>
            );
          })
        : null}
      <Button onClick={onSelect}>Finish Installation</Button>
      <Button variant="tertiary" layoutVariant="outlined" onClick={onSelect}>
        Cancel and Close
      </Button>
    </x.div>
  );
};

const AlertRulesTab: React.FC<{
  rules: any;
  yamlCheck: boolean[];
  setYamlCheck: any;
}> = ({ yamlCheck, setYamlCheck, rules }) => {
  const defaultActiveKey = Array.from({ length: yamlCheck.length }).map(
    (_, i) => String(i)
  );
  return (
    <x.div>
      <Collapse defaultActiveKey={defaultActiveKey}>
        {rules.map((rule: any, i: number) => {
          const content = yamlParse.dump(rule);
          return (
            <Collapse.Panel
              extra={
                <Switch
                  checked={yamlCheck[i]}
                  onClick={(checked, event) => {
                    event.stopPropagation();
                    yamlCheck[i] = checked;
                    setYamlCheck([...yamlCheck]);
                  }}
                />
              }
              key={i}
              header={rule.alert}
            >
              <Copyboard content={content} />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </x.div>
  );
};

export default ExporterInstallDrawer;
