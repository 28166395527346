import { Page, Section, Tab } from "@nexcloud/nc-ui";

import AlertsTab from "./AlertsTab";

const AlertList = () => {
  return (
    <Page>
      <Section>
        <Tab panes={[["Alerts", AlertsTab]]} />
      </Section>
    </Page>
  );
};

export default AlertList;
