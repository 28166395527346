import { List, Map } from "immutable";
import { createReducer } from "typesafe-actions";

import { SET_DATA } from "./actions";
import { PrometheusAction, PrometheusState } from "./types";

const initialState: PrometheusState = Map({
  createData: Map({
    p8s_namespace: "nexclipper",
    p8s_volumesize: "10",
    wal_volumesize: "2",
    p8s_replicas: 1,
    p8s_storageclass: null,
    grafana_credential: "admin",
  }),
  suffixList: List(["p8s_volumesize", "wal_volumesize"]),
});

const reducer = createReducer<PrometheusState, PrometheusAction>(initialState, {
  [SET_DATA]: (state, action) => {
    const { key, value } = action.payload;
    return state.set(key, value);
  },
});
export default reducer;
