import React from "react";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";

import ClusterDetail from "components/Cluster/ClusterDetailLegacy";
import Config from "components/Cluster/Config";
import ClusterLayout from "components/Layout/ClusterLayout";
import PrivateRoute from "components/PrivateRoute";
import ConfigFile from "components/Prometheus/ConfigFile";
import PrometheusDashboard from "components/Prometheus/Dashboard";

import useQueryParams from "hooks/util/useQueryParams";

import { getClusterQueryParams } from "paths";

function ClusterPage({
  authed,
  hasCluster,
}: {
  authed: boolean;
  hasCluster: boolean;
}) {
  const { path } = useRouteMatch();
  const q = useQueryParams();

  if (!hasCluster) {
    return (
      <Switch>
        <PrivateRoute
          path={`${path}/config`}
          authed={authed}
          component={Config}
        />
        <Redirect to="/" />
      </Switch>
    );
  }

  return (
    <Switch>
      <PrivateRoute
        path={`${path}/config`}
        authed={authed}
        component={Config}
      />
      <ClusterLayout>
        <Switch>
          <PrivateRoute
            exact
            path={`${path}/prometheus-dashboard`}
            authed={authed}
            component={PrometheusDashboard}
          />
          <PrivateRoute
            path={`${path}/prometheus-detail`}
            authed={authed}
            component={ClusterDetail}
          />
          <PrivateRoute path={`${path}/prometheus-config`} authed={authed}>
            <ConfigFile type="prometheus" />
          </PrivateRoute>
          <PrivateRoute path={`${path}/alert-rules`} authed={authed}>
            <ConfigFile type="alertRules" />
          </PrivateRoute>
          <PrivateRoute path={`${path}/recording-rules`} authed={authed}>
            <ConfigFile type="recordingRules" />
          </PrivateRoute>
          <PrivateRoute path={`${path}/prometheus-alert`} authed={authed}>
            <ConfigFile type="alert" />
          </PrivateRoute>
          <Redirect
            to={`${path}/prometheus-detail${getClusterQueryParams(
              q.get("clusterId") ?? ""
            )}`}
          />
        </Switch>
      </ClusterLayout>
    </Switch>
  );
}

export default ClusterPage;
