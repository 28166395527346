import { Children } from "react";
import styled from "@emotion/styled";

import InputLayout from "./InputLayout";

export interface LayoutProps {
  children?: React.ReactNode;
}

const InputLayout2Col: React.FC = (props: LayoutProps) => {
  const children = Children.map(props.children, (child) => {
    return child;
  });

  const ColLeft = styled.div`
    width: 50%;
    padding-right: 10px;
  `;

  const ColRight = styled.div`
    width: 50%;
    padding-left: 10px;
  `;

  return (
    <InputLayout>
      <ColLeft>{Array.isArray(children) && children[0]}</ColLeft>
      <ColRight>{Array.isArray(children) && children[1]}</ColRight>
    </InputLayout>
  );
};

export default InputLayout2Col;
