import React, { useState } from "react";
import type { Row } from "react-table";
import { Section, Table } from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";

import { ClusterService, useServiceList } from "hooks/exporter";

import ExporterInstallDrawer from "./ExporterInstallDrawer";

const ServiceNameCell: React.FC<{
  value: string;
  row: Row<ClusterService>;
}> = ({ value, row }) => {
  return (
    <x.div>
      <x.div>{value}</x.div>
      <x.div>
        {row.original.ports.map((portInfo) => `:${portInfo.port}`).join(" ")}
      </x.div>
    </x.div>
  );
};

const ExporterCell: React.FC<{
  value: number;
  row: Row<ClusterService>;
}> = ({ value: serviceId, row }) => {
  const clusterId = row.original.clusterId;
  // const { data: exporters } = useServiceExporters(clusterId, serviceId);
  // if (!exporters) {
  //   return null;
  // }

  const [visible, setVisible] = useState(false);
  return (
    <x.div>
      <button
        onClick={() => {
          setVisible(true);
        }}
      >
        Install
      </button>
      {/* {exporters.map((exporter) => exporter.title).join(" ")} */}
      {visible && (
        <ExporterInstallDrawer
          options={{
            clusterId,
            serviceId,
          }}
          visible={visible}
          onClose={() => {
            setVisible(false);
          }}
        />
      )}
    </x.div>
  );
};

const columns = [
  {
    Header: "Service Names",
    accessor: "name",
    Cell: ServiceNameCell,
  },
  {
    Header: "Exporter",
    accessor: "id",
    Cell: ExporterCell,
  },
];

const ServicesTab: React.FC<{ clusterId: number }> = ({ clusterId }) => {
  const { data } = useServiceList(clusterId);

  if (!data) {
    return null;
  }

  const servicesWithKeys = Object.keys(data).map((serviceKey) => [
    serviceKey,
    data[serviceKey],
  ]) as [string, ClusterService][];

  return (
    <Section>
      {servicesWithKeys.map(([serviceKey, services]) => (
        <x.div>
          <x.div>namespace | {serviceKey}</x.div>
          <Table columns={columns} data={services} />
        </x.div>
      ))}
    </Section>
  );
};

export default ServicesTab;
