import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import styled from "@emotion/styled/macro";
import { x } from "@xstyled/emotion";
import { AxiosError } from "axios";

import useQueryParams from "hooks/util/useQueryParams";
import { request } from "api/common";

import LoginForm from "./Form";
import GoogleForm from "./GoogleForm";

import paths from "paths";

import ClusterMapImg from "assets/grid-v1.png";
import Logo from "assets/logo.png";
import { device } from "style/device";

const GridBg = styled.div`
  transform: translate(0%, 0%) rotateX(55deg) rotateY(0deg) rotateZ(-45deg);
  transform-origin: center center;
  z-index: 1;
  background: url(${ClusterMapImg});
  width: 300vw;
  height: 300vh;
  opacity: 1;
  position: absolute;
  top: -100%;
  left: -100%;
  z-index: 1;
  animation: example 30s linear 0s infinite;

  @keyframes example {
    from {
      transform: translate(0%, 0%) rotateX(55deg) rotateY(0deg) rotateZ(-45deg);
    }
    to {
      transform: translate(10%, -10%) rotateX(55deg) rotateY(0deg)
        rotateZ(-45deg);
    }
  }
`;

const LoginContainer = styled.div`
  max-width: 600px;
  width: 90%;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  display: flex;
  position: absolute;
  z-index: 1000;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoginBox = styled.div`
  padding: 10px 30px;
  margin-bottom: 50px;
`;

const LoginWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
`;

const LogoImg = styled.img`
  width: 100px;
  margin-left: 25px;
`;
LogoImg.defaultProps = { src: Logo };

const Title = styled.div`
  font-size: 1.6em;
  font-weight: 300;
  margin-bottom: 30px;
  line-height: 1.35;
  margin-top: 20px;
  text-align: center;
  color: #808080;
  @media ${device.tablet} {
    font-size: 1.4em;
  }
`;

const Guide = styled.div`
  width: 100%;
  text-align: center;
  font-size: 0.8em;
  opacity: 0.7;
  color: #808090;
  margin: 5px 0;
  position: relative;

  &:after,
  &:before {
    content: "";
    display: block;
    background-color: #d4d4d4;
    height: 1px;
    width: 45%;
    position: absolute;
    top: 5px;
  }
  &:after {
    left: 0;
  }

  &:before {
    right: 0;
  }
`;

const LinkBox = styled.div`
  width: 100%;
  font-size: 0.9em;
  margin-top: 25px;
  margin-bottom: 10px;
  * {
    font-size: 1em;
  }
  span {
    margin-right: 5px;
    color: #808090;
  }
`;

interface ITenancyPartial {
  id: number;
  code: string;
  name: string;
  description: string;
}

const useTenancyLoginInfo = () => {
  const query = useQueryParams();
  const tenancyCode = query.get("tenancyCode") ?? "";
  return useQuery<ITenancyPartial, AxiosError>(
    ["tenancyLoginInfo", tenancyCode],
    async () => {
      const { data } = await request.get(
        `/users/login/tenancies/${tenancyCode}`
      );
      return data;
    },
    {
      enabled: !!tenancyCode,
    }
  );
};

function Login() {
  const query = useQueryParams();
  const verificationCode = query.get("inviteCode");
  const { data: tenancy } = useTenancyLoginInfo();

  return (
    <LoginWrapper>
      <GridBg />
      <LoginContainer>
        <x.div
          mb="0px"
          justifyContent="center"
          alignItems="center"
          w="100%"
          display="flex"
        >
          <LogoImg />
        </x.div>
        <LoginBox>
          <Title>Welcome to NexClipper Cloud</Title>
          <x.div fontSize="12px" w="100%" textAlign="right">
            Release 0.0.0 Beta
          </x.div>
          <LoginForm
            verificationCode={verificationCode}
            tenancyId={tenancy?.id}
          />
          <x.div>
            <Guide>Or</Guide>
            <GoogleForm
              verificationCode={verificationCode}
              tenancyId={tenancy?.id}
            />
            <LinkBox>
              <span>New to NexClipper?</span>
              <Link to={paths.join(verificationCode)}>Sign Up</Link>
            </LinkBox>
          </x.div>
        </LoginBox>
      </LoginContainer>
    </LoginWrapper>
  );
}

export default Login;
