import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import {
  ActionEditor,
  Breadcrumb,
  Button,
  Flex,
  FormInput,
  FormTextArea,
  InputLayout,
  InputLayout2Col,
  Page,
  Section,
  Selector,
  SmallFormLayout,
  Tab,
  Table,
  TitleSection,
  UserBadge,
} from "@nexcloud/nc-ui";
import { TabPane } from "@nexcloud/nc-ui/src/Tab";
import { x } from "@xstyled/emotion";
import { Modal } from "antd";

import { FilterListItem } from "components/DynamicFilter/FilterMenu";

import { IAlertListDetail, useAlertListDetail } from "hooks/alert";
import { useTenancyMembersSearch } from "hooks/user";
import useQueryParams from "hooks/util/useQueryParams";
// import { TimeCell } from "components/common/TableCell";
import { request } from "api/common";

import { AlertsTab, SelectedAlertsTab } from "./AlertsTab";

type IAddIncident = {
  actions: [
    {
      description: string;
      title: string;
    }
  ];
  assigneeId: number;
  dueDate: string;
  note: string;
  priority: "HIGH" | "LOW" | "MEDIUM" | "URGENT";
  relatedAlertIds: [number];
  title: string;
};

const AddIncident = () => {
  const [isOpenActionEditor, setIsOpenActionEditor] = useState(false);
  const [actionData, setActionData] = useState([
    {
      title: "Incident ticket created",
      description: "Incident has been created",
    },
  ]);
  const [selectedAlertIds, setSelectedAlertIds] = useState<Set<number>>(
    new Set()
  );
  const [showAssigneeModal, setShowAssigneeModal] = useState(false);
  const [assignee, setAssignee] = useState<{
    id: number;
    name: string;
    photo: string;
  }>({ id: 0, name: "", photo: "" });
  const history = useHistory();
  const query = useQueryParams();
  const alertId = query.get("alertId");

  useAlertListDetail(Number(alertId), {
    onSuccess(data: IAlertListDetail) {
      setValue("title", data.alertName);
      setValue("note", data.description);
    },
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting },
  } = useForm<IAddIncident>({
    mode: "onChange",
  });

  const handleNewAction = (opts: any) => {
    const { title, description } = opts;
    setActionData([
      ...actionData,
      {
        title: title,
        description: description,
      },
    ]);
  };

  const onSubmit = async (data: IAddIncident) => {
    try {
      await request.post(`/lari/incidents`, {
        actions: actionData,
        assigneeId: assignee.id,
        dueDate: `${data.dueDate}T00:00:00.000Z`,
        note: data.note,
        priority: data.priority.toUpperCase(),
        relatedAlertIds: Array.from(selectedAlertIds),
        title: data.title,
      });
      history.push("/incident");
    } catch (e) {
      alert("There was an error. Please try again later.");
    }
  };

  const panes = useMemo(() => {
    const alertTabProps = {
      addedIds: selectedAlertIds,
      onAddId: (alertId: number) => {
        setSelectedAlertIds(new Set(selectedAlertIds.add(alertId)));
      },
      onRemoveId: (alertId: number) => {
        selectedAlertIds.delete(alertId);
        setSelectedAlertIds(new Set(selectedAlertIds));
      },
    };
    return [
      ["All Alerts", AlertsTab, alertTabProps],
      [
        `Selected Alerts(${selectedAlertIds.size})`,
        SelectedAlertsTab,
        alertTabProps,
      ],
    ];
  }, [selectedAlertIds]) as TabPane[];

  return (
    <Page type="item">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Section>
          <TitleSection>
            <span>
              <Breadcrumb title="Incidents" path="/incident" />
              <h2>Create New Incident</h2>
            </span>
            <Button layoutVariant="outlined" variant="primary" to="/incident">
              CANCEL
            </Button>
          </TitleSection>
          <SmallFormLayout>
            <h3>1. Incident Info</h3>
            <InputLayout>
              <FormInput
                label="Title"
                id="title"
                type="text"
                {...register("title", {
                  required: true,
                })}
              />
            </InputLayout>
            <InputLayout2Col>
              <Selector type="Priority" label="Priority" control={control} />

              <FormInput
                label="Due Date"
                type="date"
                id="dueDate"
                {...register("dueDate")}
              />
            </InputLayout2Col>

            <p>Assignee</p>
            <Flex
              color="#9C9C9C"
              onClick={() => {
                setShowAssigneeModal(true);
              }}
            >
              <UserBadge
                isUnassigned={assignee.id === 0}
                name={assignee.name}
                avatar={assignee.photo ? assignee.photo : undefined}
              />
            </Flex>
            <InputLayout>
              <FormTextArea
                maxLength="500"
                label="Incident Description"
                id="note"
                rows={6}
                {...register("note")}
              />
            </InputLayout>
          </SmallFormLayout>
        </Section>
      </form>
      <Section>
        <h3>2. Choose Alerts</h3>
        <Tab panes={panes} />
      </Section>
      <Section>
        <h3>3. Actions</h3>
        {!isOpenActionEditor && (
          <Button
            layoutVariant="contained"
            variant="primary"
            icon="plus"
            iconRight
            onClick={() => {
              setIsOpenActionEditor(true);
            }}
          >
            ADD NEW ACTION
          </Button>
        )}
        {isOpenActionEditor && (
          <ActionEditor onSave={handleNewAction} title="Add a New Action" />
        )}
      </Section>

      <Section>
        <Table
          columns={actionListColumn}
          data={actionData}
          getRowProps={(row: any) => {
            return {
              ...row,
            };
          }}
        />
      </Section>
      <Section>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3>4. Create Incident</h3>
          <Button
            layoutVariant="contained"
            variant="primary"
            type="submit"
            loading={isSubmitting}
          >
            CREATE INCIDENT AND NOTIFY TEAMS
          </Button>
        </form>
      </Section>
      <AssigneeModal
        onAssign={(data: any) => {
          setAssignee(data);
        }}
        showModal={showAssigneeModal}
        onClose={() => {
          setShowAssigneeModal(false);
        }}
      />
    </Page>
  );
};

const AssigneeModal: React.FC<{
  onAssign(id: any): void;
  showModal: boolean;
  onClose(): void;
}> = ({ onAssign, showModal, onClose }) => {
  const { data: users } = useTenancyMembersSearch("", "username");
  if (!users?.content) {
    return null;
  }

  return (
    <Modal
      width="640px"
      footer={null}
      visible={showModal}
      onCancel={onClose}
      transitionName=""
      maskTransitionName=""
      centered
    >
      {users.content.map((u, i) => (
        <FilterListItem
          key={i}
          onClick={() => {
            onAssign({
              id: u.user.id,
              name: u.user.name,
              photo: u.user.photo,
            });
            onClose();
          }}
        >
          <Flex>
            {u.user.photo && (
              <x.img w="30px" h="auto" mr="15px" src={u.user.photo} />
            )}
            {u.user.name}
          </Flex>
        </FilterListItem>
      ))}
    </Modal>
  );
};

const actionListColumn = [
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Description",
    accessor: "description",
    disableSortBy: true,
  },
];

export default AddIncident;
