import { createAction } from "typesafe-actions";

import {
  IEditP8SConfig,
  IGetConfig,
  IGetZone,
  IP8SConfigPayload,
  IResetConfig,
  ISetData,
} from "./types";

export const SET_DATA = "zone/SET_DATA";
export const GET_ZONES = "zone/GET_ZONES";
export const GET_ZONE = "zone/GET_ZONE";
export const GET_OVERALL_DASHBOARD = "zone/GET_OVERALL_DASHBOARD";
export const GET_INSTALL_SCRIPT = "zone/GET_INSTALL_SCRIPT";
export const RESET_ZONE = "zone/RESET_ZONE";
export const INTERVAL_ZONE = "zone/INTERVAL_ZONE";
export const GET_P8S_CONFIG = "zone/GET_P8S_CONFIG";
export const GET_P8S_SERVICES = "zone/GET_P8S_SERVICES";
export const GET_ALERT_CONFIG = "zone/GET_ALERT_CONFIG";
export const EDIT_P8S_CONFIG = "zone/EDIT_P8S_CONFIG";
export const EDIT_ALERT_CONFIG = "zone/EDIT_ALERT_CONFIG";
export const GET_CLUSTER_HEALTH = "zone/GET_CLUSTER_HEALTH";
export const INTERVAL_CLUSTER_HEALTH = "zone/INTERVAL_CLUSTER_HEALTH";
export const GET_TASKS = "zone/GET_TASKS";
export const APPLY_P8S_CONFIG = "zone/APPLY_P8S_CONFIG";
export const APPLY_ALERT_CONFIG = "zone/APPLY_ALERT_CONFIG";
export const RESET_CONFIG = "zone/RESET_CONFIG";

export const setData = createAction(SET_DATA)<ISetData>();
export const getZones = createAction(GET_ZONES)();
export const getZone = createAction(GET_ZONE)<IGetZone>();
export const resetZone = createAction(RESET_ZONE)();
export const getP8SConfig = createAction(GET_P8S_CONFIG)<IGetConfig>();
export const getP8SServices = createAction(GET_P8S_SERVICES)<number>();
export const getAlertConfig = createAction(GET_ALERT_CONFIG)<IGetConfig>();
export const editP8SConfig = createAction(EDIT_P8S_CONFIG)<IEditP8SConfig>();
export const editAlertConfig =
  createAction(EDIT_ALERT_CONFIG)<IEditP8SConfig>();
export const getClusterHealth = createAction(GET_CLUSTER_HEALTH)<number>();
export const getTasks = createAction(GET_TASKS)<IGetZone>();
export const applyP8SConfig =
  createAction(APPLY_P8S_CONFIG)<IP8SConfigPayload>();
export const applyAlertConfig =
  createAction(APPLY_ALERT_CONFIG)<IP8SConfigPayload>();
export const resetConfig = createAction(RESET_CONFIG)<IResetConfig>();
