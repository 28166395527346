import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "@emotion/styled/macro";

import FixContainer from "components/Layout/FixContainer";

import ResetPasswordCheckForm from "./Form";

import paths from "paths";

import Logo from "assets/logo-hor.png";

const Container = styled.div`
  max-width: 500px;
  width: 90%;
  padding: 30px;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
`;

const LogoBox = styled.div`
  width: 60%;
  margin: auto;
  margin-bottom: 30px;
  cursor: pointer;
`;

const LogoImg = styled.img`
  width: 100%;
`;
LogoImg.defaultProps = { src: Logo };

const Title = styled.div`
  width: 100%;
  font-size: 1.5em;
  font-weight: 500;
  color: #242424;
`;

const Desc = styled.div`
  padding: 15px 0;
  font-size: 1em;
  line-height: 1.5em;
  color: #808090;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 15px;
`;

const SignBox = styled.div`
  font-size: 0.9em;
  margin-top: 20px;

  * {
    font-size: 1em;
  }

  span {
    margin-right: 5px;
    color: #808090;
  }
`;

const LinkBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

function ResetPasswordCheck() {
  const { push } = useHistory();

  return (
    <FixContainer>
      <Container>
        <LogoBox
          onClick={() => {
            push("/");
          }}
        >
          <LogoImg />
        </LogoBox>
        <Title>Reset Password</Title>
        <Desc>We'll email you with a link to reset your password.</Desc>
        <ResetPasswordCheckForm />
        <LinkBox>
          <SignBox>
            <span>Already have an account? </span>
            <Link to={paths.login()}>Sign In</Link>
          </SignBox>
          <SignBox>
            <span>New to NexClipper?</span>
            <Link to={paths.join()}>Sign Up</Link>
          </SignBox>
        </LinkBox>
      </Container>
    </FixContainer>
  );
}

export default ResetPasswordCheck;
