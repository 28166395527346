import { forwardRef, TextareaHTMLAttributes, useState } from "react";
import styled from "@emotion/styled";

interface ITextArea extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  maxLength?: any;
}

const Wrapper = styled.div<ITextArea>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-bottom: ${(props) => props.maxLength && "28px"};

  label {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 21px;
    color: #374151;
    font-family: poppins;
  }

  textarea {
    width: 100%;
    padding: 10px 16px;
    resize: none;
    font-size: 16px;
    line-height: 28px;
    border: 1px solid #9ca3af;
    border-radius: 6px;
    font-family: Roboto;
    transition: border-color 0.75s;

    &:active,
    &:focus {
      outline: 0;
      border-color: #1f29ca;
    }
  }

  span {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: #6b7280;
  }
`;

const FormTextArea = forwardRef<HTMLTextAreaElement, ITextArea>(
  ({ children, label, maxLength, ...props }, ref) => {
    const [inputLength, setInputLength] = useState(0);

    // FIXME : 타입 재정의 하기
    const handleLength = (e: any) => {
      setInputLength(e.target.value.length);
    };

    return (
      <Wrapper maxLength={maxLength}>
        {label && <label>{label}</label>}
        <textarea
          onKeyUp={(e) => {
            handleLength(e);
          }}
          maxLength={maxLength && Number(maxLength)}
          ref={ref}
          {...props}
        ></textarea>

        {maxLength && (
          <span>
            {inputLength}/{maxLength}
          </span>
        )}
      </Wrapper>
    );
  }
);

export default FormTextArea;
