import React, { useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  Button,
  ColumnSection,
  Flex,
  Loading,
  Section,
  SpaceBetween,
  TitleSection,
} from "@nexcloud/nc-ui";
import { defaultTheme, x } from "@xstyled/emotion";
import { Button as AntdButton, Form, Input, message, Modal, Table } from "antd";
import { useForm } from "antd/lib/form/Form";

import {
  useCurrentUser,
  useInvitedUsers,
  useTenancyMembersSearch,
  useTenancyUserDetail,
  useTenancyUserPrivileges,
} from "hooks/user";
import { getTenancyId, request } from "api/common";

import { Circle } from "./UserGroups/common";

const ManageUsers = () => {
  const { data: invitedUsers, refetch } = useInvitedUsers();
  const { data: users, refetch: refetchMembers } = useTenancyMembersSearch(
    "",
    "username"
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedUserIds, setSelectedUserIds] = useState<
    [number, number] | null
  >(); // [userId, memberId]
  const { data: me } = useCurrentUser();
  const [showModal, setShowModal] = useState(false);

  if (!invitedUsers || !users || !me) {
    return (
      <ColumnSection>
        <TitleSection>
          <h3>Users</h3>
        </TitleSection>
        <Loading />
      </ColumnSection>
    );
  }

  const onDelete = async (userIds: number[]) => {
    const confirmed = window.confirm("Are you sure to delete this user(s)?");
    if (!confirmed) {
      return;
    }
    await request.delete(`/users/tenancies/${getTenancyId()}/members`, {
      data: userIds,
    });
    setSelectedRowKeys([]);
    refetchMembers();
  };

  return (
    <ColumnSection>
      <TitleSection>
        <h3>Users</h3>
      </TitleSection>
      <SpaceBetween>
        <x.div>Organization Users</x.div>
        <Button
          layoutVariant="outlined"
          onClick={() => {
            if (selectedRowKeys.length === 0) {
              message.warn("Please select user first");
              return;
            }
            onDelete(selectedRowKeys as number[]);
          }}
        >
          Delete
        </Button>
      </SpaceBetween>
      <Section>
        <SpaceBetween backgroundColor="white" alignItems="normal">
          <x.div w={selectedUserIds ? "68%" : "100%"}>
            <Table
              size="middle"
              dataSource={users.content}
              onRow={(record) => {
                return {
                  onClick: () => {
                    setSelectedUserIds([record.user.id, record.id]);
                  },
                };
              }}
              rowSelection={{
                selectedRowKeys,
                onChange(selectedRowKeys, selectedRows) {
                  if (selectedRows.find((member) => member.user.id === me.id)) {
                    message.warn("You cannot select yourself");
                    return;
                  }
                  setSelectedRowKeys(selectedRowKeys);
                },
              }}
              rowKey="id"
              columns={[
                {
                  title: "ID",
                  dataIndex: "id",
                },
                {
                  title: "Name",
                  dataIndex: ["user", "name"],
                },
                {
                  title: "Email",
                  dataIndex: ["user", "email"],
                },
                {
                  title: "Deleted",
                  dataIndex: "isDeleted",
                  render(value) {
                    return value ? "Y" : "N";
                  },
                },
                {
                  title: "Created at",
                  dataIndex: "createdAt",
                },
              ]}
            />
          </x.div>
          {selectedUserIds ? (
            <SelectedUser
              userId={selectedUserIds[0]}
              isMe={selectedUserIds[0] === me?.id}
              onDelete={() => {
                onDelete([selectedUserIds[1]]);
              }}
              onClose={() => {
                setSelectedUserIds(undefined);
              }}
            />
          ) : null}
        </SpaceBetween>
      </Section>
      <SpaceBetween>
        <x.div>Invited Users</x.div>
        <Button
          variant="primary"
          onClick={() => {
            setShowModal(true);
          }}
          icon="invite"
          iconRight
        >
          Invite users
        </Button>
      </SpaceBetween>
      <Section>
        <Table
          dataSource={invitedUsers.content}
          rowKey="userId"
          columns={[
            {
              title: "ID",
              dataIndex: "userId",
            },
            {
              title: "Name",
              dataIndex: "name",
            },
            {
              title: "Email",
              dataIndex: "email",
            },
            {
              title: "Activated",
              dataIndex: "activate",
              render(value) {
                return value ? "Y" : "N";
              },
            },
            {
              title: "inviteDate",
              dataIndex: "inviteDate",
            },
            {
              title: "joinDate",
              dataIndex: "joinDate",
            },
          ]}
        />
      </Section>
      <InviteUserModal
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
          refetch();
        }}
      />
    </ColumnSection>

    // <SettingsPane title="Users" backgroundColor="none" border="none" p="0">
    //   <SpaceBetween py="20px">
    //     <x.div>Organization Users</x.div>
    //     <Button
    //       onClick={() => {
    //         if (selectedRowKeys.length === 0) {
    //           message.warn("Please select user first");
    //           return;
    //         }
    //         onDelete(selectedRowKeys as number[]);
    //       }}
    //     >
    //       Delete
    //     </Button>
    //   </SpaceBetween>
    //   <SpaceBetween backgroundColor="white" alignItems="normal">
    //     <x.div w={selectedUserIds ? "68%" : "100%"} p="15px">
    //       <Table
    //         size="middle"
    //         dataSource={users.content}
    //         onRow={(record) => {
    //           return {
    //             onClick: () => {
    //               setSelectedUserIds([record.user.id, record.id]);
    //             },
    //           };
    //         }}
    //         rowSelection={{
    //           selectedRowKeys,
    //           onChange(selectedRowKeys, selectedRows) {
    //             if (selectedRows.find((member) => member.user.id === me.id)) {
    //               message.warn("You cannot select yourself");
    //               return;
    //             }
    //             setSelectedRowKeys(selectedRowKeys);
    //           },
    //         }}
    //         rowKey="id"
    //         columns={[
    //           {
    //             title: "ID",
    //             dataIndex: "id",
    //           },
    //           {
    //             title: "Name",
    //             dataIndex: ["user", "name"],
    //           },
    //           {
    //             title: "Email",
    //             dataIndex: ["user", "email"],
    //           },
    //           {
    //             title: "Deleted",
    //             dataIndex: "isDeleted",
    //             render(value) {
    //               return value ? "Y" : "N";
    //             },
    //           },
    //           {
    //             title: "Created at",
    //             dataIndex: "createdAt",
    //           },
    //         ]}
    //       />
    //     </x.div>
    //     {selectedUserIds ? (
    //       <SelectedUser
    //         userId={selectedUserIds[0]}
    //         isMe={selectedUserIds[0] === me?.id}
    //         onDelete={() => {
    //           onDelete([selectedUserIds[1]]);
    //         }}
    //         onClose={() => {
    //           setSelectedUserIds(undefined);
    //         }}
    //       />
    //     ) : null}
    //   </SpaceBetween>
    //   <SpaceBetween py="20px">
    //     <x.div>Invited Users</x.div>
    //     <Button
    //       variant="primary"
    //       onClick={() => {
    //         setShowModal(true);
    //       }}
    //       icon="invite"
    //       iconRight
    //     >
    //       Invite users
    //     </Button>
    //   </SpaceBetween>
    //   <Table
    //     dataSource={invitedUsers.content}
    //     rowKey="userId"
    //     columns={[
    //       {
    //         title: "ID",
    //         dataIndex: "userId",
    //       },
    //       {
    //         title: "Name",
    //         dataIndex: "name",
    //       },
    //       {
    //         title: "Email",
    //         dataIndex: "email",
    //       },
    //       {
    //         title: "Activated",
    //         dataIndex: "activate",
    //         render(value) {
    //           return value ? "Y" : "N";
    //         },
    //       },
    //       {
    //         title: "inviteDate",
    //         dataIndex: "inviteDate",
    //       },
    //       {
    //         title: "joinDate",
    //         dataIndex: "joinDate",
    //       },
    //     ]}
    //   />
    //   <InviteUserModal
    //     showModal={showModal}
    //     onClose={() => {
    //       setShowModal(false);
    //       refetch();
    //     }}
    //   />
    // </SettingsPane>
  );
};

interface InvitePayload {
  targetName: string;
  targetMail: string;
}

const InviteUserModal: React.FC<{
  showModal: boolean;
  onClose(): void;
}> = ({ showModal, onClose }) => {
  const { data: user } = useCurrentUser();
  const [invited, setInvited] = useState<InvitePayload[]>([]);
  const [inviteResult, setInviteResult] = useState<
    {
      email: string;
      result: "SUCCESS" | "FAIL";
      inviteLink: string;
    }[]
  >([]);
  const [form] = useForm<InvitePayload>();

  return (
    <Modal
      title="Invite users"
      centered
      visible={showModal}
      onCancel={() => {
        setInvited([]);
        setInviteResult([]);
        onClose();
      }}
      okText="Send Invites"
      cancelText
      okButtonProps={invited.length === 0 ? { disabled: true } : undefined}
      onOk={async () => {
        if (!user) {
          return;
        }

        try {
          const { data } = await request.post("/users/invite", {
            inviterEmail: user.email,
            inviterName: user.name,
            joinSiteUrl: `${window.location.origin}/join`,
            targetUsers: invited,
          });
          setInvited([]);
          setInviteResult(data.results);
        } catch (e) {}
      }}
      closable
      width={1000}
    >
      <x.div pb="20px">
        <Form layout="vertical" form={form}>
          <Form.Item
            name="targetName"
            label="Username"
            rules={[{ required: true, message: "Please put user name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="targetMail"
            label="User email"
            rules={[
              { required: true, message: "Please put user's email address" },
              { type: "email", message: "Please put valid email address" },
            ]}
          >
            <Input.Search
              enterButton="Add User"
              onSearch={async () => {
                try {
                  await form.validateFields();
                  const v = form.getFieldsValue();

                  if (
                    invited.find((user) => user.targetMail === v.targetMail)
                  ) {
                    alert("Email already exists in the list");
                    return;
                  }

                  setInvited(invited.concat(v));
                  form.resetFields();
                } catch (e) {}
              }}
            />
          </Form.Item>
        </Form>
        {invited.length > 0 ? (
          <x.h4 py="8px" fontWeight="bold">
            Inviting users
          </x.h4>
        ) : null}
        {invited.map((user) => (
          <x.div
            display="flex"
            key={user.targetMail}
            py="4px"
            alignItems="center"
            justifyContent="space-between"
          >
            <x.div>
              {user.targetName} ({user.targetMail}){" "}
            </x.div>
            <CloseCircleOutlined
              style={{ color: defaultTheme.colors["red-500"] }}
              onClick={() => {
                setInvited(
                  invited.filter((u) => u.targetMail !== user.targetMail)
                );
              }}
            />
          </x.div>
        ))}
        {inviteResult.length > 0 ? (
          <x.h4 py="8px" fontWeight="bold">
            User invite result
          </x.h4>
        ) : null}
        {inviteResult.map((result) => (
          <SpaceBetween key={result.email} py="4px">
            <x.div>{result.email}</x.div>{" "}
            <x.div fontWeight="bold">
              ({INVITE_RESULTS[result.result as keyof typeof INVITE_RESULTS]})
            </x.div>
          </SpaceBetween>
        ))}
      </x.div>
    </Modal>
  );
};

const SelectedUser: React.FC<{
  userId: number;
  isMe: boolean;
  onDelete(): void;
  onClose(): void;
}> = ({ isMe, userId, onClose, onDelete }) => {
  const { data } = useTenancyUserDetail(userId);
  const { data: privileges } = useTenancyUserPrivileges(userId);

  if (!data) {
    return <Loading />;
  }

  return (
    <x.div
      w="30%"
      h="400px"
      overflowY="scroll"
      p="15px"
      backgroundColor="white"
      border="1px solid"
      borderColor="gray-100"
      position="relative"
    >
      <x.div position="relative">
        <x.div
          onClick={onClose}
          position="absolute"
          top="0px"
          right="5px"
          cursor="pointer"
        >
          x
        </x.div>
        <x.div fontWeight="bold" pb="10px">
          User information
        </x.div>
      </x.div>
      <x.div py="20px" position="relative">
        <Flex>
          <Circle size="30px" />
          <x.div ml="10px">
            <x.div py="5px">{data.user.name}</x.div>
            <x.div py="5px">{data.user.email}</x.div>
          </x.div>
        </Flex>
      </x.div>
      {privileges?.roles && privileges.roles.length > 0 ? (
        <x.div mt="20px">
          <x.div backgroundColor="gray-100" p="5px" mb="5px">
            Roles
          </x.div>
          {privileges.roles.map((r, i) => (
            <x.div key={i} p="5px">
              {r}
            </x.div>
          ))}
        </x.div>
      ) : null}
      {!isMe && (
        <AntdButton
          style={{ bottom: "10px", position: "absolute" }}
          onClick={onDelete}
        >
          Delete from organization
        </AntdButton>
      )}
    </x.div>
  );
};

const INVITE_RESULTS = {
  ALREADY_JOINED: "Already Joined",
  ALREADY_INVITED: "Already Invited",
  SUCCESS: "Success",
};

export default ManageUsers;
