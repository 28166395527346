import { useQuery } from "react-query";
import type { AxiosError } from "axios";
import { INotiResponse } from "notification";

import { useCurrentUser } from "hooks/user";
import { request } from "api/common";

export const useNotifications = (page: number = 1) => {
  const { data: user } = useCurrentUser();
  return useQuery<INotiResponse, AxiosError>(
    ["Notifications"],
    async () => {
      const { data } = await request.get<INotiResponse>("/notis/note/notes", {
        params: {
          page: page,
          size: 10,
          userId: user?.id,
          viewInvisable: false,
        },
      });
      return data ?? [];
    },
    {
      cacheTime: 5 * 1000,
    }
  );
};
