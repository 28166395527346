import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import { Button as AntdButton, Collapse, Form } from "antd";

import { usePrometheusGet } from "hooks/state/get";
import usePrometheusActions from "hooks/state/usePrometheusActions";

import GrafanaForm from "./GrafanaForm";
import PrometheusForm from "./PrometheusForm";
import { FormList } from "./types";

import "./Collap.css";

const formList: FormList[] = [
  {
    id: "prometheus",
    title: "Prometheus",
    component: PrometheusForm,
    keyList: [
      "p8s_namespace",
      "p8s_volumesize",
      "p8s_replicas",
      "p8s_storageclass",
      "wal_volumesize",
    ],
  },
  {
    id: "grafana",
    title: "Grafana",
    component: GrafanaForm,
    keyList: ["grafana_credential"],
  },
];

const Title = styled.span`
  color: ${({ error }: { error: boolean }) => (error ? "red" : "black")};
`;

const ButtonBox = styled.div`
  width: 100%;
  margin-top: 15px;
  text-align: right;
`;

const StyledButton = styled(AntdButton)`
  margin: 0 5px;
`;

interface ICreatePrometheusFormProps {
  onCancel: () => any;
}

function CreatePrometheusForm({ onCancel }: ICreatePrometheusFormProps) {
  const [errorPanel, setErrorPanel] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const createData = usePrometheusGet("createData");
  const [activeKey, setActiveKey] = useState<string[]>(
    formList.map((form) => form.id)
  );

  const prometheusActions = usePrometheusActions();

  const callback = () => {
    setLoading(false);
    setTimeout(() => onCancel());
  };

  const onFinish = (value: any) => {
    const data = { ...createData.toJS(), ...value };
    prometheusActions.onCreate({ data, callback });
    setErrorPanel([]);
    setLoading(true);
  };

  const onFinishFailed = ({ errorFields }: any) => {
    const errorKeyList: string[] = [].concat.apply(
      [],
      errorFields.map((e: any) => e.name)
    );

    const _errorPanel = formList
      .filter((f) => f.keyList.find((k) => errorKeyList.includes(k)))
      .map((f) => f.id);

    setErrorPanel(_errorPanel);
    setActiveKey([...activeKey, ..._errorPanel]);
  };

  const onChange = (value: any) => {
    // setActiveKey(value);
  };

  return (
    <Form
      name="prometheus"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Collapse
        defaultActiveKey={[formList[0].id]}
        onChange={onChange}
        activeKey={activeKey}
      >
        {formList.map((List) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const error = errorPanel.includes(List.id);
          const Component = List.component;

          return (
            <Collapse.Panel
              header={<Title error={false}>{List.title}</Title>}
              key={List.id}
              showArrow={false}
            >
              <Component />
            </Collapse.Panel>
          );
        })}
      </Collapse>
      <Form.Item>
        <ButtonBox>
          <StyledButton onClick={onCancel} type="default" disabled={loading}>
            Cancel
          </StyledButton>
          <StyledButton type="primary" htmlType="submit" loading={loading}>
            Create
          </StyledButton>
        </ButtonBox>
      </Form.Item>
    </Form>
  );
}

export default CreatePrometheusForm;
