import React, { useEffect } from "react";
import { Redirect, Switch, useLocation, useRouteMatch } from "react-router-dom";

import AlertList from "components/Alerts";
import AlertDetail from "components/Alerts/AlertDetail";
import PrivateRoute from "components/PrivateRoute";

function AlertsPage() {
  const { path } = useRouteMatch();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      <PrivateRoute path={`${path}/`} authed component={AlertList} exact />
      <PrivateRoute
        path={`${path}/:alertId`}
        authed
        component={AlertDetail}
        exact
      />
      <Redirect to={path} />
    </Switch>
  );
}

export default AlertsPage;
