import { GoPin } from "react-icons/go";
import type { Row } from "react-table";
import {
  CopyID,
  Link,
  LinkButton,
  PriorityBadge,
  SeverityBadge,
  StatusBadge,
  UserBadge,
} from "@nexcloud/nc-ui";
import { IDLinkButton } from "@nexcloud/nc-ui/src/LinkButton";
import styled, { x } from "@xstyled/emotion";

import { IAlertItem } from "hooks/alert";

import { timeZone } from "const";
import day from "utils/day";

export interface IAlertListItem {
  id: number;
  no: string;
  incident: string;
  incidentId: number[] | null;
  time: string;
  summary: string;
  severity: string;
  source: string;
  description: string;
  from: string;
  recipients: { id: number; name: string }[];
  isPin: boolean;
}

export interface IIncidentListItem {
  id: number;
  status: string;
  no: string;
  time: string;
  title: string;
  priority: string;
  description: string;
  due: string;
  assignee: {
    avatar: string;
    name: string;
    role: string;
  };
}

const AlertWrapper = styled.div`
  position: relative;
  padding-left: 20px;
  width: fit-content;

  .pinIcon {
    transform: scaleX(-1);
    position: absolute;
    top: -7px;
    left: 4px;
    color: #1f29ca;
  }
`;

export const AlertNoPinCell: React.FC<{
  value: string;
  row: Row<IAlertItem>;
}> = ({ value, row }) => {
  return (
    <AlertWrapper>
      {row.original.isPin && <GoPin className="pinIcon" />}
      <Link to={`/alerts/${value}`}>
        <CopyID id={value} type="alert" />
      </Link>
      {row.original.incidentId &&
        row.original.incidentId.map((id, index) => (
          <x.div mt="9px" key={index}>
            <IDLinkButton to={`/incident/${id}`}>{`INC-${id}`}</IDLinkButton>
          </x.div>
        ))}
    </AlertWrapper>
  );
};

export const AlertNoCell: React.FC<{
  value: string;
  row: Row<IAlertListItem>;
}> = ({ value, row }) => {
  return (
    <>
      <CopyID id={value} type="alert" />
      {row.original.incidentId &&
        row.original.incidentId.map((id, index) => (
          <x.div mt="9px" key={index}>
            <IDLinkButton to={`/incident/${id}`}>{`INC-${id}`}</IDLinkButton>
          </x.div>
        ))}
    </>
  );
};

export const TimeCell: React.FC<{
  value: string;
  row: Row<IAlertItem | IIncidentListItem>;
}> = ({ value, row }) => {
  return (
    <x.p w="90px" lineHeight="20px">
      {day(value).tz(timeZone).format("YYYY-MM-DD HH:mm:ss")}
    </x.p>
  );
};

export const SummaryCell: React.FC<{
  value: string;
  row: Row<IAlertItem | IIncidentListItem>;
}> = ({ value, row }) => {
  return (
    <x.p fontWeight="700" fontSize="16px" lineHeight="24px">
      {value}
    </x.p>
  );
};

export const AlertSeverityCell: React.FC<{
  value: "Information" | "Warning" | "Critical";
  row: Row<IAlertItem>;
}> = ({ value, row }) => {
  return <SeverityBadge severity={value} />;
};

export const AlertSourcePortCell: React.FC<{
  value: string;
  row: Row<IAlertItem>;
}> = ({ value, row }) => {
  return (
    <x.p fontFamily="Poppins" lineHeight="21px">
      {value}
    </x.p>
  );
};

export const AlertRecipientCell: React.FC<{
  row: Row<IAlertItem>;
  value: { id: number; name: string }[];
}> = ({ value, row }) => {
  if (value.length === 0) {
    return null;
  }

  return (
    <>
      {value.map((group) => (
        <x.div w="fit-content">
          <LinkButton to={`/access-groups/${group.id}`}>
            {group.name}
          </LinkButton>
        </x.div>
      ))}
    </>
  );
};

export const IncidentStatusCell: React.FC<{
  value: "OPEN" | "IN_PROGRESS" | "RESOLVED" | "CLOSED";
  row: Row<IIncidentListItem>;
}> = ({ value, row }) => {
  return <StatusBadge status={value} />;
};

export const IncidentNoCell: React.FC<{
  value: string;
  row: Row<IIncidentListItem>;
}> = ({ value, row }) => {
  return (
    <>
      <Link to={`/incident/${row.original.id}`}>
        <CopyID id={value} type="incident" />
      </Link>
    </>
  );
};

export const IncidentPriorityCell: React.FC<{
  value: "HIGH" | "MEDIUM" | "LOW";
  row: Row<IIncidentListItem>;
}> = ({ value, row }) => {
  return <PriorityBadge priority={value} />;
};

export const IncidentAssigneeCell: React.FC<{
  value: string;
  row: Row<IIncidentListItem>;
}> = ({ value, row }) => {
  return (
    <UserBadge
      avatar={row.original.assignee?.avatar}
      name={value}
      role={row.original.assignee?.role}
      isSmall
    />
  );
};

export const OpenAlertDetailCell: React.FC<{
  value: string;
}> = ({ value }) => {
  return <LinkButton to={`alerts/${value}`}>Open</LinkButton>;
};

interface IAccessGroupMember {
  activated: boolean | null;
  email: string | null;
  name: string | null;
  photo: string | null;
  userId: number | null;
}

const AccessGroupMemberPhoto = styled.div<{ src: string }>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 5px;
  background-image: url(${(props) => props.src});

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const AccessGroupMemberCell: React.FC<{
  value: IAccessGroupMember[];
}> = ({ value }) => {
  if (value.length === 1 && !value[0].userId) {
    return null;
  }

  return (
    <x.div display="flex">
      {value.map((member) => (
        <AccessGroupMemberPhoto
          src={
            member.photo
              ? member.photo
              : "https://www.w3schools.com/images/picture.jpg"
          }
        ></AccessGroupMemberPhoto>
      ))}
    </x.div>
  );
};
