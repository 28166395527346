import styled from "@emotion/styled";

const PageTopPadding: React.FC = ({ children, ...props }) => {
  return <Layout>{children}</Layout>;
};

export default PageTopPadding;

const Layout = styled.div`
  width: 100%;
  height: 50px;
`;
