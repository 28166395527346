import { createAction } from "typesafe-actions";

import { ICreateCluster, ISetData, IValidateProps } from "./types";

export const SET_DATA = "create/SET_DATA";
export const RESET_CONFIG = "create/RESET_CONFIG";
export const CREATE_CLUSTER = "create/CREATE_CLUSTER";
export const VALIDATE_CLUSTER_NAME = "create/VALIDATE_CLUSTER_NAME";

export const setData = createAction(SET_DATA)<ISetData>();
export const resetConfig = createAction(RESET_CONFIG)();
export const createCluster = createAction(CREATE_CLUSTER)<ICreateCluster>();
export const validateClusterName = createAction(
  VALIDATE_CLUSTER_NAME
)<IValidateProps>();
