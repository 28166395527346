import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import useQueryParams from "hooks/util/useQueryParams";

import paths from "paths";

function LoginSuccess() {
  const query = useQueryParams();
  const history = useHistory();

  useEffect(() => {
    const loginSuccess = query.get("loginSuccess");

    if (loginSuccess) {
      history.push("/");
    } else {
      history.push(paths.login());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
}

export default LoginSuccess;
