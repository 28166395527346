import React, { ComponentProps, ReactElement } from "react";
import { FiSettings } from "react-icons/fi";
import { Link, NavLink, useHistory } from "react-router-dom";
import { ExportOutlined } from "@ant-design/icons";
import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import { Flex, Link as CommonLink, SpaceBetween } from "@nexcloud/nc-ui";
import Tippy, { TippyProps } from "@tippyjs/react/headless";
import { x } from "@xstyled/emotion";
import { Tag } from "antd";
import cx from "classnames";
import { formatDistance } from "date-fns";
import type { Notification } from "notification";

import { getProcessedNotiParams } from "components/Notification/format";

import paths from "paths";

const StyledDropdownRoot = styled(Link, {
  shouldForwardProp: isPropValid,
})<{
  hasIcon: boolean;
}>`
  display: flex;
  align-items: center;

  ${({ hasIcon }) => {
    return hasIcon
      ? `
    span svg {
    margin-left: 4px;
    font-size: 0.8em;
    color: gray-500;
  }
  `
      : "";
  }}
`;

type TippyPropsWithoutChildren = Omit<TippyProps, "children">;
export const Dropdown: React.FC<
  TippyPropsWithoutChildren & {
    rootPath?: string;
    active?: boolean;
    icon?: ReactElement;
    render: TippyProps["render"];
  }
> = ({ render, rootPath, active, icon = null, children, ...props }) => {
  return (
    <Tippy
      placement="bottom-start"
      render={render}
      interactive
      appendTo={document.body}
      hideOnClick={true}
      {...props}
    >
      <StyledDropdownRoot
        hasIcon={!!icon}
        className={cx("btn", { active })}
        to={rootPath ? rootPath : "/"}
        onClick={(e) => {
          if (!rootPath) {
            e.preventDefault();
          }
        }}
      >
        {children} {icon}
      </StyledDropdownRoot>
    </Tippy>
  );
};

export const DropdownMenu: React.FC<ComponentProps<typeof x.div>> = ({
  ...props
}) => {
  return (
    <x.div
      className="box"
      tabIndex={-1}
      backgroundColor="white"
      p="5px 0"
      boxShadow="0 4px 4px 4px rgb(21 29 29 / 2%), 0 8px 16px 8px rgb(40 54 54 / 5%)"
      borderRadius="8px"
      {...props}
    />
  );
};

const StyledLink = styled(CommonLink)`
  color: gray-700;
  .menu-item:hover {
    background-color: gray-100;
  }
`;

const StyledNavLink = styled(NavLink)`
  color: gray-700;
  .menu-item:hover {
    background-color: gray-100;
  }
  &.active {
    font-weight: bold;
  }
`;

const PADDING = "15px 10px";
export const DropdownMenuItem: React.FC<{
  title: React.ReactNode;
  icon?: React.ReactNode;
  link: string;
  onClick?(): void;
}> = ({ link, title, icon, onClick }) => {
  if (!link.startsWith("/") && link.length !== 0) {
    return (
      <StyledLink to={link} openNewWindow onClick={onClick}>
        <SpaceBetween p={PADDING} className="menu-item">
          {title}
          <ExportOutlined style={{ paddingLeft: "10px" }} />
        </SpaceBetween>
      </StyledLink>
    );
  }

  if (link.length === 0) {
    return (
      <x.div cursor="pointer" onClick={onClick}>
        {!icon && (
          <x.div p={PADDING} className="menu-item">
            {title}
          </x.div>
        )}
        {icon && (
          <Flex
            p="9px 0"
            fontSize="16px"
            lineHeight="24px"
            className="menu-item"
            color="#1F29CA"
          >
            <x.span pr="8px">{icon}</x.span>
            {title}
          </Flex>
        )}
      </x.div>
    );
  }

  return (
    <StyledNavLink to={link}>
      {!icon && (
        <x.div p={PADDING} className="menu-item">
          {title}
        </x.div>
      )}
      {icon && (
        <Flex
          p="9px 0"
          fontSize="16px"
          lineHeight="24px"
          className="menu-item"
          color="#1F29CA"
        >
          <x.span pr="8px">{icon}</x.span>
          {title}
        </Flex>
      )}
    </StyledNavLink>
  );
};

export const SmallDropdown: React.FC = ({ children }) => {
  return <DropdownMenu p="0" />;
};

export const SmallDropdownMenuItem: React.FC<
  { onClick(e?: MouseEvent): void } & ComponentProps<typeof x.div>
> = ({ onClick, ...props }) => {
  return (
    <x.div
      className="menu-item"
      p="8px"
      hoverBackgroundColor="gray-100"
      cursor="pointer"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      {...props}
    />
  );
};

export const Noti: React.FC<{
  render: TippyProps["render"];
  rootPath?: string;
  active?: boolean;
}> = ({ render, rootPath, active, children }) => {
  return (
    <Tippy
      placement="bottom-start"
      render={render}
      interactive
      appendTo={document.body}
      hideOnClick={true}
    >
      <Link to={paths.notification()}>
        {children} <FiSettings fontSize="1.4em" color="#D1D5DB" />
      </Link>
    </Tippy>
  );
};

export const NotiMenu: React.FC = ({ children }) => {
  return (
    <x.div
      className="box"
      tabIndex={-1}
      backgroundColor="white"
      p="5px 0"
      boxShadow="0 4px 4px 4px rgb(21 29 29 / 2%), 0 8px 16px 8px rgb(40 54 54 / 5%)"
      borderRadius="2px"
      color="#000"
    >
      {children}
    </x.div>
  );
};

export const NotiMenuItem: React.FC<{
  noti: Notification;
}> = ({ noti }) => {
  const {
    // title,
    // text: description,
    // eventCode: action,
    createTime: date,
  } = noti;

  const history = useHistory();
  const { title, message, action } = getProcessedNotiParams(
    noti.eventCode,
    noti.data?.paramDatas ?? noti.data?.params
  );

  return (
    <x.div
      w="320px"
      cursor="pointer"
      onClick={(e) => {
        e.stopPropagation();
        if (action) {
          history.push(action[1]);
        }
      }}
    >
      <x.div p={PADDING} border="1px solid #e4e4e4" className="menu-item">
        <x.p fontSize="1.1em" fontWeight="bold" mb="10px">
          {title}
        </x.p>
        <x.p fontSize="0.9em" mb="10px">
          {message}
        </x.p>
        <x.div display="flex" justifyContent="space-between">
          <x.p fontSize="0.8em" color="#0389a1">
            {action && <Tag>{action[0]}</Tag>}
          </x.p>
          <x.p fontSize="0.8em" color="#9c9c9c">
            {formatDistance(new Date(date), new Date(), { addSuffix: true })}
          </x.p>
        </x.div>
      </x.div>
    </x.div>
  );
};
