import React, { useState } from "react";
import {
  Button,
  Flex,
  FormTextArea,
  InputLayout,
  LinkButton,
  Panel,
} from "@nexcloud/nc-ui";
import styled, { x } from "@xstyled/emotion";

import AddImageDrawer from "components/Incident/WhiteBoard/AddImageDrawer";
import AddMetricDrawer from "components/Incident/WhiteBoard/AddMetricDrawer";

import WhiteboardItemSidebar from "./WhiteboardItemSidebar";

const WhiteboardItemWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  .showOrHide {
    margin-top: 1em;
  }
`;

const WhiteboardItem: React.FC<{
  isAddWhiteboardVisible: boolean;
  setIsAddWhiteboardVisible: any;
  incidentDate?: string;
  incidentComment?: string;
  incidentScreenshots?: string[];
  avatar: string;
  userFullName: string;
  role: string;
  newItem?: boolean;
  editable?: boolean;
  unpinable?: boolean;
}> = ({
  isAddWhiteboardVisible = false,
  setIsAddWhiteboardVisible,
  incidentDate = "",
  incidentComment = "",
  incidentScreenshots = [],
  avatar,
  userFullName,
  role,
  newItem = false,
  editable = true,
  unpinable = true,
}) => {
  const [showContent, setShowContent] = useState(false);
  const [showAllImages, setShowAllImages] = useState(false);
  const [addMetric, setAddMetric] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  let showOrHideText = showContent
    ? "Close to view less"
    : "Expand to view more";

  const ShowOrHideClick = () => {
    setShowContent(!showContent);
    setShowAllImages(!showAllImages);
  };

  let imagesToDisplay = showAllImages
    ? incidentScreenshots
    : [incidentScreenshots[0]];

  return newItem ? (
    <WhiteboardItemWrapper>
      <WhiteboardItemSidebar
        incidentDate={incidentDate}
        avatar={avatar}
        userFullName={userFullName}
        role={role}
        editable={editable}
        unpinable={unpinable}
      />
      <Panel>
        <InputLayout>
          <Flex flexDirection="column" alignItems="flex-start">
            <x.div h="246px" w="867px">
              <FormTextArea label="Whiteboard Description" rows={6} />
            </x.div>
            <x.div display="flex" spaceX={2}>
              <x.div
                onClick={() => {
                  setShowImageModal(true);
                }}
              >
                <LinkButton to="#">ADD IMAGE</LinkButton>
              </x.div>
              <AddImageDrawer
                setShowImageModal={setShowImageModal}
                options={
                  {
                    // incidentId,
                  }
                }
                visible={showImageModal}
                onClose={() => {
                  setShowImageModal(false);
                }}
              />
              <p>|</p>
              <x.div
                onClick={() => {
                  setAddMetric(!addMetric);
                }}
              >
                <LinkButton to="#">ADD METRIC</LinkButton>
              </x.div>
              <AddMetricDrawer
                options={
                  {
                    // incidentId,
                  }
                }
                visible={addMetric}
                onClose={() => {
                  setAddMetric(!addMetric);
                }}
              />
            </x.div>
          </Flex>
        </InputLayout>
        <x.div display="flex" justifyContent="space-between">
          <Button variant="primary" icon="save" iconRight>
            Save
          </Button>
          <Button
            layoutVariant="outlined"
            variant="primary"
            onClick={() => {
              setIsAddWhiteboardVisible(false);
            }}
          >
            CANCEL
          </Button>
        </x.div>
      </Panel>
    </WhiteboardItemWrapper>
  ) : (
    <WhiteboardItemWrapper>
      <WhiteboardItemSidebar
        incidentDate={incidentDate}
        avatar={avatar}
        userFullName={userFullName}
        role={role}
        editable={editable}
        unpinable={unpinable}
      />
      <Panel>
        <x.div display="flex" flexWrap="wrap">
          {imagesToDisplay.length > 0 &&
            imagesToDisplay.map((imageUrl) => {
              return (
                <x.div h="100%" mr="15px" mt="15px">
                  <img key={imageUrl} src={imageUrl} />
                </x.div>
              );
            })}
        </x.div>

        <div className="showOrHide" onClick={ShowOrHideClick}>
          <LinkButton to="#">{showOrHideText}</LinkButton>
        </div>
      </Panel>
    </WhiteboardItemWrapper>
  );
};

export default WhiteboardItem;
