import { SelectHTMLAttributes } from "react";
import { Control, Controller } from "react-hook-form";
import Select from "react-select";
import styled from "@emotion/styled";
import { PriorityBadge, SeverityBadge, StatusBadge } from "@nexcloud/nc-ui";

interface ISelector extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: "Status" | "Priority" | "Severity";
  type: "Status" | "Priority" | "Severity";
  control: any;
}

const Wrapper = styled.div`
  width: 100%;
  label {
    font-size: 14px;
    line-height: 21px;
    color: #374151;
    font-family: poppins;
  }
`;

const StatusOption = [
  { value: "open", label: <StatusBadge status="OPEN" /> },
  { value: "inProgress", label: <StatusBadge status="IN_PROGRESS" /> },
  { value: "resolved", label: <StatusBadge status="RESOLVED" /> },
  { value: "closed", label: <StatusBadge status="CLOSED" /> },
];

const PriorityOption = [
  { value: "high", label: <PriorityBadge priority="HIGH" /> },
  { value: "medium", label: <PriorityBadge priority="MEDIUM" /> },
  { value: "low", label: <PriorityBadge priority="LOW" /> },
];

const SeverityOption = [
  { value: "information", label: <SeverityBadge severity="Information" /> },
  { value: "warning", label: <SeverityBadge severity="Warning" /> },
  { value: "critical", label: <SeverityBadge severity="Critical" /> },
];

const customStyle = {
  control: () => ({
    marginTop: "8px",
    display: "flex",
    width: "100%",
    border: "1px solid #9ca3af",
    padding: "6px",
    borderRadius: "6px",
    background: "#fff",
  }),
};

const StatusSelector: React.FC<{ control: Control }> = ({ control }) => {
  return (
    <Controller
      control={control}
      name="status"
      render={({ field: { onChange, value } }) => (
        <Select
          options={StatusOption}
          styles={customStyle}
          value={StatusOption.find((option) => option.value === value)}
          onChange={(val) => onChange(val?.value)}
        />
      )}
      rules={{ required: true }}
    />
  );
};

const PrioritySelector: React.FC<{ control: Control }> = ({ control }) => {
  return (
    <Controller
      control={control}
      name="priority"
      render={({ field: { onChange, value } }) => (
        <Select
          options={PriorityOption}
          styles={customStyle}
          value={PriorityOption.find((option) => option.value === value)}
          onChange={(val) => onChange(val?.value)}
        />
      )}
      rules={{ required: true }}
    />
  );
};

const SeveritySelector: React.FC<{ control: Control }> = ({ control }) => {
  return (
    <Controller
      control={control}
      name="severity"
      render={({ field: { onChange, value } }) => (
        <Select
          options={SeverityOption}
          styles={customStyle}
          value={SeverityOption.find((option) => option.value === value)}
          onChange={(val) => onChange(val?.value)}
        />
      )}
      rules={{ required: true }}
    />
  );
};

const Selector: React.FC<ISelector> = ({ type, label, control }) => {
  return (
    <Wrapper>
      {label && <label>{label}</label>}
      {type === "Status" && <StatusSelector control={control} />}
      {type === "Priority" && <PrioritySelector control={control} />}
      {type === "Severity" && <SeveritySelector control={control} />}
    </Wrapper>
  );
};

export default Selector;
