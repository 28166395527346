import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Column,
  ColumnSection,
  Loading,
  Section,
  SpaceBetween,
  TitleSection,
} from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";
import { Input, message, Table } from "antd";

import { useOrgAdmins } from "hooks/user";
import { getTenancyId, request } from "api/common";

import { AddAdminGroupModal, AddAdminModal } from "./Modal";

const OrgAdmin = () => {
  const { data: orgAdmins, refetch } = useOrgAdmins();
  const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>();
  const [showingModal, setShowingModal] = useState<"USER" | "GROUP" | null>(
    null
  );
  const [selectedUserIds, setSelectedUserIds] = useState<React.Key[]>([]);
  const [groupSearchQuery, setGroupSearchQuery] = useState("");

  if (!orgAdmins) {
    return (
      <ColumnSection>
        <TitleSection>
          <h3>Organization Administrator</h3>
        </TitleSection>
        <Loading />
      </ColumnSection>
    );
  }

  const { groups, users } = orgAdmins;

  return (
    <ColumnSection>
      <TitleSection>
        <h3>Organization Administrator</h3>
      </TitleSection>
      <SpaceBetween>
        <x.div w={selectedGroupId ? "69%" : "100%"} h="500px">
          <SpaceBetween>
            <Input
              prefix={<SearchOutlined />}
              placeholder="Find a user group ..."
              style={{ width: "40%" }}
              onChange={(e) => {
                const nextValue = e.target.value.trim();
                if (groupSearchQuery !== nextValue) {
                  setGroupSearchQuery(nextValue);
                }
              }}
            />
            <Column columnsGap="10px">
              <Button
                layoutVariant="outlined"
                onClick={async () => {
                  if (selectedUserIds.length === 0) {
                    message.warn("Please select user first");
                    return;
                  }
                  const confirmed = window.confirm(
                    "Are you sure removing this user(s) from admin?"
                  );
                  if (!confirmed) {
                    return;
                  }

                  await request.delete(
                    `/users/tenancies/${getTenancyId()}/maintainers`,
                    { data: { userIds: selectedUserIds } }
                  );
                  message.success(`Removed selected users from admin`);
                  refetch();
                }}
              >
                Delete
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setSelectedGroupId(undefined);
                  setShowingModal("USER");
                }}
              >
                Add Admin
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setSelectedGroupId(undefined);
                  setShowingModal("GROUP");
                }}
              >
                Add Admin Group
              </Button>
            </Column>
          </SpaceBetween>
          <x.div>
            <Section>
              <Table
                dataSource={users}
                rowSelection={{
                  selectedRowKeys: selectedUserIds,
                  onChange(selectedRowKeys) {
                    setSelectedUserIds(selectedRowKeys);
                  },
                }}
                rowKey="userId"
                columns={[
                  {
                    title: "Username",
                    dataIndex: "userName",
                    key: "userName",
                  },
                  {
                    title: "Email",
                    dataIndex: "email",
                    key: "email",
                  },
                  {
                    title: "Admin",
                    dataIndex: "isOwner",
                    key: "isOwner",
                    render(value) {
                      return value ? "Y" : "N";
                    },
                  },
                  {
                    title: "Updated",
                    dataIndex: "updatedAt",
                    key: "updatedAt",
                  },
                ]}
              />
            </Section>
            {groups?.map((group, i) => {
              return (
                <SpaceBetween backgroundColor="gray-100" p="10px" key={i}>
                  <x.div>{group.name}</x.div>
                  <Button
                    size="tiny"
                    layoutVariant="outlined"
                    variant="primary"
                    onClick={async () => {
                      const confirmed = window.confirm(
                        "Are you sure removing this group from admin?"
                      );
                      if (!confirmed) {
                        return;
                      }

                      await request.delete(
                        `/users/tenancies/${getTenancyId()}/maintainers`,
                        { data: { groupIds: [group.id] } }
                      );
                      message.success(
                        `Removed Group '${group.name}' from admin`
                      );
                      refetch();
                    }}
                  >
                    Delete
                  </Button>
                </SpaceBetween>
              );
            })}
          </x.div>
        </x.div>
      </SpaceBetween>
      {showingModal === "USER" && (
        <AddAdminModal
          showModal={showingModal === "USER"}
          onClose={() => {
            setShowingModal(null);
            refetch();
          }}
        />
      )}
      {showingModal === "GROUP" && (
        <AddAdminGroupModal
          adminGroups={groups}
          showModal={showingModal === "GROUP"}
          onUpdate={() => {
            refetch();
          }}
          onClose={() => {
            setShowingModal(null);
            refetch();
          }}
        />
      )}
    </ColumnSection>
  );
};

export default OrgAdmin;
