import { Redirect, Switch, useRouteMatch } from "react-router-dom";

import PrivateRoute from "components/PrivateRoute";

import GroupView from "./AccessGroupDetail";
import GroupList from "./AccessGroupsList";
import AddAccessGroup from "./AddAccessGroup";

const AccessGroup: React.FC<{ authed: boolean }> = ({ authed }) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute exact path={path} component={GroupList} authed={authed} />
      <PrivateRoute
        path={`${path}/add`}
        authed
        component={AddAccessGroup}
        exact
      />
      <PrivateRoute path={`${path}/:id`} authed={authed}>
        <GroupView />
      </PrivateRoute>
      <Redirect to={path} />
    </Switch>
  );
};

export default AccessGroup;
