import React from "react";
import { EmptyBox, Section, Table } from "@nexcloud/nc-ui";

import { useClusterNode } from "hooks/exporter";

const clusterNodeColumns = [
  {
    Header: "Node Name",
    accessor: "name",
  },
  {
    Header: "UID",
    accessor: "uid",
    disableSortBy: true,
  },
];

const NodeTab: React.FC<{ clusterId: number }> = ({ clusterId }) => {
  const { data: nodeList } = useClusterNode(clusterId);

  return (
    <Section>
      {nodeList?.length === 0 ? (
        <EmptyBox />
      ) : (
        <Table columns={clusterNodeColumns} data={nodeList ? nodeList : []} />
      )}
    </Section>
  );
};

export default NodeTab;
