import { FormTextArea, InputLayout, UserBadge } from "@nexcloud/nc-ui";
import styled, { x } from "@xstyled/emotion";

const Container = styled.div`
  padding: 20px;
  margin: 20px;
  h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;
const AccessGroupUserDetail = () => {
  return (
    <>
      <Container>
        <h2>USERS</h2>
        <UserBadge
          avatar="https://www.w3schools.com/images/picture.jpg"
          name="Jian Kim"
        />
        <InputLayout>
          <FormTextArea label="Note" rows={6} />
        </InputLayout>
      </Container>
      <Container>
        <h2>ACCESS GROUPS</h2>
        <x.div p="20px" border="1px solid #000">
          TABLE component
        </x.div>
      </Container>
      <Container>
        <h2>ACTIVITIES</h2>
        <x.div p="20px" border="1px solid #000">
          TABLE component
        </x.div>
      </Container>
    </>
  );
};

export default AccessGroupUserDetail;
