import { AiOutlinePrinter } from "react-icons/ai";
import styled from "@xstyled/emotion";

interface IPrintButton {
  onClick?(): void;
}

const PrintBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 24px;
  background-color: inherit;
  .printIcon {
    font-size: 16px;
    margin-right: 5px;
    color: #1f29ca;
  }

  span {
    font-size: 16px;
    line-height: 24px;
    color: #1f29ca;
    text-decoration: underline;
  }
`;
const PrintButton: React.FC<IPrintButton> = ({ onClick }) => {
  return (
    <PrintBtn onClick={onClick} type="button">
      <AiOutlinePrinter className="printIcon" />
      <span> PRINT</span>
    </PrintBtn>
  );
};

export default PrintButton;
