import React from "react";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { useRowSelect, useSortBy, useTable } from "react-table";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { x } from "@xstyled/emotion";

import Link from "./Link";

// FIXME : 타입 재정의 하기
interface IData {
  columns?: any;
  data?: any;
  userCellProps?: any;
  getRowProps?: any;
  tableBodyRowComponent?: any;
  setSelectedRow?: any;
  loading?: boolean;
}

// FIXME : 타입 재정의 하기
interface ICellData {
  value?: any;
  column?: any;
  row?: any;
}

// FIXME : 타입 재정의 하기
interface IClusterCell {
  value?: any;
  column?: any;
  row?: any;
}

// FIXME : 타입 재정의 하기
interface ILinkTextCell {
  value?: any;
  column?: any;
  row?: any;
  base?: String;
}

export const Table: React.FC<IData> = ({
  columns,
  data,
  userCellProps = {},
  getRowProps = () => ({}),
  tableBodyRowComponent: TableBodyRowComponent = "tr",
  loading,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  return (
    <Wrapper loading={loading}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(
                    // @ts-ignore See https://github.com/tannerlinsley/react-table/issues/2970
                    column.getSortByToggleProps()
                  )}
                >
                  <x.div display="flex" alignItems="center">
                    {
                      // @ts-ignore See https://github.com/tannerlinsley/react-table/issues/2970
                      !column.disableSortBy && (
                        <span>
                          {
                            // @ts-ignore See https://github.com/tannerlinsley/react-table/issues/2970
                            column.isSorted ? (
                              // @ts-ignore See https://github.com/tannerlinsley/react-table/issues/2970
                              column.isSortedDesc ? (
                                <FaSortDown />
                              ) : (
                                <FaSortUp />
                              )
                            ) : (
                              <FaSort />
                            )
                          }
                        </span>
                      )
                    }

                    <x.p
                      className={
                        // @ts-ignore See https://github.com/tannerlinsley/react-table/issues/2970
                        column.disableSortBy && "only"
                      }
                    >
                      {column.render("Header")}
                    </x.p>
                  </x.div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <TableBodyRowComponent {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render("Cell", userCellProps)}
                    </td>
                  );
                })}
              </TableBodyRowComponent>
            );
          })}
        </tbody>
      </table>
    </Wrapper>
  );
};

export const SelectingTable: React.FC<IData> = ({
  columns,
  data,
  userCellProps = {},
  getRowProps = () => ({}),
  tableBodyRowComponent: TableBodyRowComponent = "tr",
  setSelectedRow = () => ({}),
  loading,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            // @ts-ignore
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),

            Cell: ({ row }) => (
              // @ts-ignore
              <Checkbox {...row.getToggleRowSelectedProps()} />
            ),
            disableSortBy: true,
          },
          ...columns,
        ];
      });
    }
  );

  React.useEffect(() => {
    // @ts-ignor
    setSelectedRow(selectedFlatRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlatRows]);

  return (
    <Wrapper loading={loading}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(
                    // @ts-ignore See https://github.com/tannerlinsley/react-table/issues/2970
                    column.getSortByToggleProps()
                  )}
                >
                  <x.div display="flex" alignItems="center">
                    {
                      // @ts-ignore See https://github.com/tannerlinsley/react-table/issues/2970
                      !column.disableSortBy && (
                        <span>
                          {
                            // @ts-ignore See https://github.com/tannerlinsley/react-table/issues/2970
                            column.isSorted ? (
                              // @ts-ignore See https://github.com/tannerlinsley/react-table/issues/2970
                              column.isSortedDesc ? (
                                <FaSortDown />
                              ) : (
                                <FaSortUp />
                              )
                            ) : (
                              <FaSort />
                            )
                          }
                        </span>
                      )
                    }

                    <x.p
                      className={
                        // @ts-ignore See https://github.com/tannerlinsley/react-table/issues/2970
                        column.disableSortBy && "only"
                      }
                    >
                      {column.render("Header")}
                    </x.p>
                  </x.div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <TableBodyRowComponent {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render("Cell", userCellProps)}
                    </td>
                  );
                })}
              </TableBodyRowComponent>
            );
          })}
        </tbody>
      </table>
    </Wrapper>
  );
};

// @ts-ignore
export const Checkbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    // @ts-ignore
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <x.div p="0 14px 0 12px">
      {/* @ts-ignore */}
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </x.div>
  );
});

const Wrapper = styled.div<IData>((props) => {
  const { loading } = props;

  return css`
    table {
      width: 100%;
      font-family: Roboto;

      thead {
        tr {
          background-color: #f9fafb;

          th {
            position: relative;
            text-align: start;
            padding: 12px 20px;
            padding: 12px 0;

            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: #6b7280;

            span {
              margin-right: 5px;
            }

            p {
              padding-right: 10px;
              font-size: 14px;
            }

            p.only {
              position: relative;
              top: -3px;
            }
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid #d1d5db;

          td {
            padding: 16px 0;
            font-size: 16px;
            line-height: 24px;
            color: #111827;
            // border: 1px solid red;
          }
        }

        tr.focused {
          background-color: #f9fafb;
        }
      }
    }

    ${loading &&
    `
    opacity: 0.5;
    pointer-events: none;
    `}
  `;
});

const User = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    border-radius: 50%;
    margin-right: 10px;
    width: 50px;
  }
`;

export const AvatarCell: React.FC<ICellData> = ({ value, column, row }) => {
  return (
    <Link to={`/users/${row.original[column.idAccessor]}`}>
      <User>
        <img src={row.original[column.imgAccessor]} alt="" />
        <div>{value}</div>
      </User>
    </Link>
  );
};

export const LinkTextCell: React.FC<ILinkTextCell> = ({
  value,
  column,
  row,
  base,
}) => {
  return (
    <Link to={`${base}/${row.original[column.idAccessor]}`}>
      {value || "No name"}
    </Link>
  );
};

export const ClusterCell: React.FC<IClusterCell> = ({ value, column, row }) => {
  return (
    <x.div display="flex">
      {value.map((cluster: any) => (
        <div> {cluster?.icon}</div>
      ))}
    </x.div>
  );
};

export const ServiceListCell: React.FC<ICellData> = ({
  value,
  column,
  row,
}) => {
  const placeholder = [
    "service",
    "service",
    "service",
    "service",
    "service",
    "service",
    "service",
    "service",
    "service",
    "service",
  ];

  return (
    <ServiceList>
      {placeholder.map((service) => (
        <div></div>
      ))}
    </ServiceList>
    //   <ServiceList>
    //   {value.map((service) => (
    //     <div></div>
    //   ))}
    // </ServiceList>
  );
};

const ServiceList = styled.div`
  display: flex;

  div {
    background-color: #c9dff2;
    width: 20px;
    height: 20px;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const ClusterSubpageLinkCell: React.FC<ICellData> = ({
  value,
  column,
  row,
}) => {
  return (
    <Link to={`/clusters/${row.original[column.idAccessor]}`}>
      <div>
        <p>{value}</p>
      </div>
    </Link>
  );
};
