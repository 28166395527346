import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "@xstyled/emotion";
import { Table } from "antd";

import InstallationDrawer from "components/common/InstallationDrawer";

import type { IGlobalView, IZone } from "store/redux/zone";
import { clusterTableColumns } from "hooks/cluster/useClusterTable";

import { emptyClusterText } from "const";
import { usePath } from "paths";

import { device } from "style/device";

const dimmed = `#f9f9f9`;
const Container = styled.div`
  width: 100%;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  background: #fff;

  @media ${device.mobileL} {
    overflow: auto;
  }
  .ant-table-thead > tr > th.ant-table-cell {
    color: #333;
    padding: 12px 8px;
  }
  .ant-table.ant-table-small .ant-table-tbody tr {
    td {
      padding: 13px 8px;
    }
  }
  .ant-table-tbody > tr > td {
    vertical-align: middle;
  }
  .ant-table-wrapper,
  .ant-table-small .ant-table-thead > tr > th {
    background-color: ${dimmed};
  }
  .ant-table-pagination.ant-pagination {
    margin: 14px 0;
  }
  .ant-pagination-item-active {
    border-color: #dedede;
    a {
      color: #909090;
    }
  }
`;

interface ITableClustersProps {
  data: Array<IZone | IGlobalView>;
}

function TableClusters({ data }: ITableClustersProps) {
  const [showCollapBox, setShowCollapBox] = useState(false);

  const moveToDetail = usePath((paths) => paths.cluster.detail);

  const history = useHistory();

  const onClick = (cluster: IZone | IGlobalView) => {
    if (cluster.initStatus === "NOT_INSTALLED") {
      setShowCollapBox(true);
      history.push(`/?clusterId=${cluster.id}`);
    } else {
      moveToDetail(cluster.id);
    }
  };

  return (
    <Container>
      <Table
        columns={clusterTableColumns}
        dataSource={data}
        rowKey={(row) => row.id}
        size="small"
        locale={{
          emptyText: emptyClusterText,
        }}
        pagination={{ pageSize: 15 }}
        onRow={(cluster: IZone | IGlobalView) => {
          if (cluster.clusterType === "GLOBAL_PRIMARY") {
            return {};
          }
          return {
            onClick: () => onClick(cluster),
          };
        }}
      />
      {showCollapBox && (
        <InstallationDrawer
          showCollapBox={showCollapBox}
          onClose={() => {
            setShowCollapBox(false);
          }}
        />
      )}
    </Container>
  );
}

export default TableClusters;
