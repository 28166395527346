import type { ComponentProps } from "react";
import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";
import {
  Button,
  Flex,
  FormInput,
  Section,
  SpaceBetween,
} from "@nexcloud/nc-ui";
import styled, { x } from "@xstyled/emotion";

import useClusterList from "hooks/cluster/useClusterList";

import { SelectedFilter } from "./common";
import type { Filter } from "./types";

export const FilterListItem: React.FC<ComponentProps<typeof x.div>> = ({
  children,
  ...props
}) => {
  return (
    <Flex
      p="20px"
      mb="10px"
      backgroundColor="#F9FAFB"
      hoverBackgroundColor="gray-200"
      fontSize="1.2rem"
      cursor="pointer"
      {...props}
    >
      {children}
    </Flex>
  );
};

const SubmitBar: React.FC<{
  disabled?: boolean;
  onClick(e?: React.MouseEvent<HTMLInputElement>): void;
}> = ({ disabled, onClick }) => {
  return (
    <Section>
      <Button onClick={onClick} disabled={disabled} variant="primary">
        Submit
      </Button>
    </Section>
  );
};

const FilterListWrapper = styled.div`
  height: 470px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    background-color: #e5e7eb;
    width: 9px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1f29ca;
  }
`;

const FILTER_LIST = [
  ["CLUSTER", "Filter by Cluster"],
  ["TIME_RANGE", "Filter by Time Range"],
  ["SEVERITY", "Filter by Severity"],
  // ["DESCRIPTION", "Filter by Description"],
  // ["FROM", "Filter by From"],
  // ["RECIPIENT", "Filter by Recipient"],
  // ["SUMMARY", "Filter by Summary"],
];

type IFilterMenu = React.FC<{
  addFilter(filter: Filter): void;
  onExit(): void;
}>;

export const FilterSelectMenu: React.FC<{
  selectFilterMenu: React.Dispatch<
    React.SetStateAction<Filter["type"] | undefined>
  >;
}> = ({ selectFilterMenu }) => {
  const [menuSearchQuery, setMenuSearchQuery] = useState("");
  return (
    <div>
      <x.div my="20px">
        <FormInput
          placeholder="Search Filter..."
          searchIcon
          onChange={(e) => {
            setMenuSearchQuery(e.currentTarget.value);
          }}
        />
      </x.div>
      <FilterListWrapper>
        {FILTER_LIST.filter(([key, label]) => {
          const q = menuSearchQuery.trim();
          if (!q) {
            return true;
          }
          if (
            key.toLowerCase().includes(q) ||
            label.toLowerCase().includes(q)
          ) {
            return true;
          }
          return false;
        }).map(([key, label], i) => {
          return (
            <FilterListItem
              key={i}
              onClick={() => {
                selectFilterMenu(key as Filter["type"] | undefined);
              }}
            >
              <x.div mr="17px">
                <BiSearch size="1.3rem" color="#9C9C9C" />
              </x.div>
              {label}
            </FilterListItem>
          );
        })}
      </FilterListWrapper>
    </div>
  );
};

const FilterLabel = styled.div`
  color: #9c9c9c;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 21px;
`;

export const FilterClusterMenu: IFilterMenu = ({ addFilter, onExit }) => {
  const { data: clusterList } = useClusterList();
  return (
    <div>
      <x.div my="20px">
        <FilterLabel>Cluster</FilterLabel>
      </x.div>
      {clusterList &&
        clusterList.map((cluster, i) => {
          return (
            <FilterListItem
              key={i}
              onClick={() => {
                addFilter({
                  type: "CLUSTER",
                  value: {
                    id: cluster.id,
                    name: cluster.name,
                  },
                });
                onExit();
              }}
            >
              {cluster.name}
            </FilterListItem>
          );
        })}
    </div>
  );
};

export const FilterDescriptionMenu: IFilterMenu = ({ addFilter, onExit }) => {
  const [query, setQuery] = useState("");

  const submit = () => {
    addFilter({
      type: "DESCRIPTION",
      value: query,
    });
    onExit();
  };

  return (
    <x.div my="20px">
      <x.div my="20px">
        <FilterLabel>Description</FilterLabel>
      </x.div>
      <Section>
        <FormInput
          onChange={(e) => {
            setQuery(e.currentTarget.value);
          }}
          onKeyDown={(e) => {
            if (e.nativeEvent.key !== "Enter") {
              return;
            }
            submit();
          }}
        />
      </Section>
      <SubmitBar
        disabled={!query.trim()}
        onClick={() => {
          submit();
        }}
      />
    </x.div>
  );
};

export const FilterTimeRangeMenu: IFilterMenu = ({ addFilter, onExit }) => {
  const [fromQuery, setFromQuery] = useState("");
  const [untilQuery, setUntilQuery] = useState("");
  const [errors, setErrors] = useState<string[]>();

  const handleSubmitClick = () => {
    const fromDate = new Date(fromQuery);
    const untilDate = new Date(untilQuery);

    const newErrors = [];
    if (fromQuery.length !== 10 || Number.isNaN(fromDate.getTime())) {
      newErrors.push("From date format is not YYYY-MM-DD");
    }

    if (untilQuery.length !== 10 || Number.isNaN(untilDate.getTime())) {
      newErrors.push("until date format is not YYYY-MM-DD");
    }

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }
    // TODO: more validation

    addFilter({
      type: "TIME_RANGE",
      value: {
        starts: fromDate,
        until: untilDate,
      },
    });
    onExit();
  };

  return (
    <div>
      <div>
        <x.div my="20px">
          <FilterLabel>Time Range</FilterLabel>
        </x.div>
        <x.div my="20px">
          <label>From</label>
          <FormInput
            placeholder="ex) 2021-01-01"
            type="date"
            onChange={(e) => {
              setFromQuery(e.currentTarget.value.trim());
            }}
          />
        </x.div>
        <x.div my="20px">
          <label>Till</label>
          <FormInput
            type="date"
            placeholder="ex) 2021-03-01"
            onChange={(e) => {
              setUntilQuery(e.currentTarget.value.trim());
            }}
          />
        </x.div>
        <SubmitBar
          onClick={handleSubmitClick}
          disabled={!fromQuery || !untilQuery}
        />
        {errors && (
          <x.div color="red">
            {errors.map((error) => (
              <div>{error}</div>
            ))}
          </x.div>
        )}
      </div>
    </div>
  );
};

const SEVERITY_TYPES = ["Warning", "Critical", "Information"] as const;
export const FilterSeverityMenu: IFilterMenu = ({ addFilter, onExit }) => {
  return (
    <x.div>
      <x.div my="20px">
        <FilterLabel>Severity</FilterLabel>
      </x.div>
      {SEVERITY_TYPES.map((key, i) => {
        return (
          <FilterListItem
            key={i}
            onClick={() => {
              addFilter({
                type: "SEVERITY",
                value: key,
              });
              onExit();
            }}
          >
            {key}
          </FilterListItem>
        );
      })}
    </x.div>
  );
};

export const FilterFromMenu: IFilterMenu = ({ addFilter, onExit }) => {
  return (
    <div>
      <x.div my="20px">
        <FilterLabel>From</FilterLabel>
      </x.div>
      {["Prometheus"].map((from, i) => {
        return (
          <FilterListItem
            key={i}
            onClick={() => {
              addFilter({
                type: "FROM",
                value: "Prometheus",
              });
              onExit();
            }}
          >
            {from}
          </FilterListItem>
        );
      })}
    </div>
  );
};

const recipientList = ["DBA", "DBA2", "Developers"]; // FIXME
export const FilterRecipientMenu: IFilterMenu = ({ addFilter, onExit }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [recipients, setRecipients] = useState<string[]>([]);
  return (
    <div>
      <x.div my="20px">
        <FilterLabel>Recipient</FilterLabel>
        {recipients.length > 0 && (
          <SpaceBetween>
            <Flex my="20px">
              {recipients.map((recipient, i) => {
                return (
                  <SelectedFilter
                    key={i}
                    onRemove={() => {
                      setRecipients(
                        recipients.filter((added) => added !== recipient)
                      );
                    }}
                  >
                    {recipient}
                  </SelectedFilter>
                );
              })}
            </Flex>
            <Button
              variant="primary"
              onClick={() => {
                addFilter({
                  type: "RECIPIENT",
                  value: recipients.map((r) => ({ name: r, id: 0 })),
                });
                onExit();
              }}
            >
              Confirm
            </Button>
          </SpaceBetween>
        )}
        <Section>
          <FormInput
            placeholder="Find recipients"
            onChange={(e) => {
              setSearchQuery(e.currentTarget.value);
            }}
          />
        </Section>
      </x.div>
      {recipientList
        .filter((recipient, i) => {
          return recipient.toLowerCase().includes(searchQuery.toLowerCase());
        })
        .map((recipient, i) => {
          return (
            <FilterListItem
              key={i}
              onClick={() => {
                if (!recipients.includes(recipient)) {
                  setRecipients(recipients.concat(recipient));
                }
              }}
            >
              {recipient}
            </FilterListItem>
          );
        })}
    </div>
  );
};

export const FilterSummaryMenu: IFilterMenu = ({ addFilter, onExit }) => {
  const [query, setQuery] = useState("");

  const submit = () => {
    addFilter({
      type: "SUMMARY",
      value: query,
    });
    onExit();
  };

  return (
    <x.div my="20px">
      <FilterLabel>Summary</FilterLabel>
      <Section>
        <FormInput
          onChange={(e) => {
            setQuery(e.currentTarget.value);
          }}
          onKeyDown={(e) => {
            if (e.nativeEvent.key !== "Enter") {
              return;
            }
            submit();
          }}
        />
      </Section>
      <SubmitBar
        disabled={!query.trim()}
        onClick={() => {
          submit();
        }}
      />
    </x.div>
  );
};
