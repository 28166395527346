import React, { useEffect } from "react";
import { Redirect, Switch, useLocation, useRouteMatch } from "react-router-dom";

import AddCluster from "components/Cluster/AddCluster";
import ClusterDetail from "components/Cluster/ClusterDetail";
import EditCluster from "components/Cluster/EditCluster";
import PrivateRoute from "components/PrivateRoute";

function ClustersPage() {
  const { path } = useRouteMatch();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      <PrivateRoute path={`${path}/add`} authed component={AddCluster} exact />
      <PrivateRoute
        path={`${path}/:clusterId/edit`}
        authed
        component={EditCluster}
        exact
      />

      <PrivateRoute
        path={`${path}/:clusterId`}
        authed
        component={ClusterDetail}
        exact
      />
      <Redirect to={path} />
    </Switch>
  );
}

export default ClustersPage;
