import React from "react";
import { keyframes } from "@emotion/react/macro";
import styled from "@emotion/styled/macro";

const loading = keyframes`
0% {
  opacity: .0;
  transform: scale(.15);
  box-shadow: 0 0 2px rgba(black, .1);
}
50% {
  opacity: 1;
  transform: scale(2);
  box-shadow: 0 0 10px rgba(black, .1);
}
100% {
  opacity: .0;
  transform: scale(.15);
  box-shadow: 0 0 2px rgba(black, .1);
}
`;

const Loader = styled.div`
  position: relative;
  width: 15px;
  height: 15px;
`;

const Icon = styled.div`
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  height: 100%;
  width: 100%;
`;

const First = styled(Icon)`
  background: #ffffff;
  animation: ${loading} 1.3s 0.65s infinite;
`;

const Second = styled(Icon)`
  background: #1890ff;
  animation: ${loading} 1.3s infinite;
`;

function InnerLoading() {
  return (
    <Loader>
      <First />
      <Second />
    </Loader>
  );
}

export default InnerLoading;
