import { defaultTheme } from "@xstyled/emotion";

const colors = {
  ...defaultTheme.colors,
  text: "#000",
  background: "#fff",
  primary: "#003156",
  header: "#cecece",
};

const sizes = {
  siderWidth: "200px",
  headerHeight: "61px",
};

const Theme = {
  ...defaultTheme,
  sizes,
  screens: {
    ...defaultTheme.screens,
    ix: "375px",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  fontWeights: {
    body: 400,
    heading: 700,
  },
  fonts: {
    globalFont: '"Roboto", "sans-serif"',
  },
  colors,
};

export default Theme;
