import { useSelector } from "react-redux";

import { RootState } from "store/redux";
import type { IAccountState } from "store/redux/account";
import type { ICreateState } from "store/redux/create";
import type { IPrometheusState } from "store/redux/prometheus";
import type { IStatusState } from "store/redux/status";
import type { IZoneState } from "store/redux/zone";

export function useStatusGet<T extends keyof IStatusState>(
  item: T
): IStatusState[T] {
  return useSelector((state: RootState) => state.status.get(item));
}

export function useAccountGet<T extends keyof IAccountState>(
  item: T
): IAccountState[T] {
  return useSelector((state: RootState) => state.account.get(item));
}

export function useCreateGet<T extends keyof ICreateState>(
  item: T
): ICreateState[T] {
  return useSelector((state: RootState) => state.create.get(item));
}

export function useZoneGet<T extends keyof IZoneState>(item: T): IZoneState[T] {
  return useSelector((state: RootState) => state.zone.get(item));
}

export function usePrometheusGet<T extends keyof IPrometheusState>(
  item: T
): IPrometheusState[T] {
  return useSelector((state: RootState) => state.prometheus.get(item));
}
