import pick from "lodash-es/pick";
import pickBy from "lodash-es/pickBy";

type QueryParamDict = { [key: string]: any };
export type QueryArgs =
  | QueryParamDict
  | string
  | number
  | string[]
  | number[]
  | null
  | undefined;

function convertQueryParamsString(object: QueryParamDict) {
  return new URLSearchParams(pickBy(object)).toString();
}

const ALLOWED_QUERY_PARAMS = ["clusterId", "zoneId"];
export function getClusterQueryParams(queryArgs?: QueryArgs) {
  if (!queryArgs) {
    return "";
  }

  if (Array.isArray(queryArgs)) {
    queryArgs = queryArgs[0];
  }

  if (typeof queryArgs === "string" || typeof queryArgs === "number") {
    queryArgs = { clusterId: queryArgs };
  }

  const ret = convertQueryParamsString(pick(queryArgs, ALLOWED_QUERY_PARAMS));

  return ret ? `?${ret}` : "";
}
