import React from "react";
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  StopTwoTone,
} from "@ant-design/icons";
import styled from "@emotion/styled/macro";

import { device } from "style/device";

const Box = styled.div`
  display: flex;
  align-items: center;

  @media ${device.mobileL} {
    width: 100%;
  }
`;
const IconBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const Title = styled.div`
  font-size: 0.9em;
  color: #3e3e3e;
  margin-left: 3px;
`;

function ClusterIconInfo() {
  return (
    <Box>
      <IconBox>
        <CheckCircleTwoTone
          twoToneColor="#52c41a"
          style={{ fontSize: "1.1em" }}
        />
        <Title>Active</Title>
      </IconBox>
      <IconBox>
        <StopTwoTone twoToneColor="#b0b0b0" style={{ fontSize: "1.1em" }} />
        <Title>InActive</Title>
      </IconBox>
      <IconBox>
        <ExclamationCircleTwoTone
          twoToneColor="#f4524a"
          style={{ fontSize: "1.1em" }}
        />
        <Title>Not Installed</Title>
      </IconBox>
    </Box>
  );
}

export default ClusterIconInfo;
