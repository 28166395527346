import { AiOutlineCheckCircle } from "react-icons/ai";
import styled, { x } from "@xstyled/emotion";

interface IProgressBar {
  progress: string[];
  step: number;
  setStep: any;
}

const Wrapper = styled.div`
  font-family: poppins;
  overflow: hidden;
  .steps {
    border-top-right-radius: 21px;
    border-bottom-right-radius: 21px;
    color: #909aae;
    background-color: #f9fafc;
    margin-left: -25px;

    &:first-of-type {
      margin-left: 0px;
    }

    &.active {
      color: #1f29ca;
      background-color: #eefaff;
      z-index: 100;
    }
  }
  .icon {
    margin-right: 5px;
  }
`;

const ProgressBar: React.FC<IProgressBar> = ({ progress, step, setStep }) => {
  return (
    <Wrapper>
      <x.div display="flex">
        {progress.map((item, i) => (
          <x.div
            className={"steps" + (step < i ? "" : " active")}
            w="1000px"
            h="51px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontWeight={step === i ? 500 : 400}
          >
            {step > i && <AiOutlineCheckCircle className="icon" />}
            {item}
          </x.div>
        ))}
      </x.div>
    </Wrapper>
  );
};

export default ProgressBar;
