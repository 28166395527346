import React from "react";
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  StopTwoTone,
} from "@ant-design/icons";

interface IClusterStatusIconProps {
  status: "NOT_INSTALLED" | "ACTIVE" | "INACTIVE";
  styles?: any;
}

function ClusterStatusIcon({ status, styles }: IClusterStatusIconProps) {
  if (status === "ACTIVE")
    return <CheckCircleTwoTone twoToneColor="#52c41a" style={styles} />;
  if (status === "INACTIVE")
    return <StopTwoTone twoToneColor="#b0b0b0" style={styles} />;
  return <ExclamationCircleTwoTone twoToneColor="#f4524a" style={styles} />;
}

export default ClusterStatusIcon;
