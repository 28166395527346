import { useEffect, useState } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import type { AxiosError } from "axios";
import { atom, useAtom } from "jotai";
import { Pageable } from "types";

import { logout as logoutApi } from "api/account";
import { getTenancyId, request, setTenancyId } from "api/common";

export interface IUser {
  email: string;
  firstName: string | null;
  id: number;
  lastName: string | null;
  mobile: string | null;
  name: string;
  photo: string;
  refreshInterval: number;
  createdAt: string; // date
  updatedAt: string; // date
  lastAccess: string; // date
  currentTenancy: ITenancy | null;
}

interface ITenancy {
  id: number;
  activated: boolean;
  createdAt: string; // date
  updatedAt: string; // date
  owner: boolean;
  prdCode: string;
  selected: boolean;
  tenancyCode: string;
  tenancyDescription: string | null;
  tenancyId: number;
  tenancyName: string | null;
}

let _user: IUser | null;
export function getUserGlobal() {
  return _user;
}
export function setUserGlobal(nextUser: typeof _user) {
  _user = nextUser;
  return _user;
}

const currentUserAtom = atom<{ id: undefined | number }>({ id: undefined });

export const useCurrentUser = () => {
  const [user, setUser] = useAtom(currentUserAtom);
  const [meta, setMeta] = useState({
    isLoading: false,
    error: null,
  });

  const fetchUser = () => {
    setMeta({
      isLoading: true,
      error: null,
    });
    return request
      .get<IUser>("/users/accounts/me")
      .then(({ data }) => {
        setUserGlobal(data);
        setTenancyId(data.currentTenancy?.tenancyId);
        setUser({ id: data.id });
        setMeta({
          isLoading: false,
          error: null,
        });
      })
      .catch((e) => {
        setUserGlobal(null);
        setMeta({
          isLoading: false,
          error: e,
        });
      });
  };

  useEffect(() => {
    if (!user.id) {
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const globalUser = getUserGlobal();
  const showOnboarding =
    globalUser?.currentTenancy === null ||
    globalUser?.currentTenancy?.activated === false;

  return {
    data: globalUser,
    user: globalUser,
    tenancy: globalUser?.currentTenancy,
    isInit: globalUser !== undefined,
    isLoggedIn: !!globalUser?.id,
    showOnboarding,
    refetch: fetchUser,
    async logout() {
      await logoutApi();
      setUserGlobal(null);
      setUser({ id: undefined });
    },
    ...meta,
  };
};

export const useUserTenancies = () => {
  return useQuery<ITenancy[], AxiosError>(
    ["userTenancies"],
    async () => {
      const { data } = await request.get<ITenancy[]>("/users/tenancies");
      return data;
    },
    {
      cacheTime: 5 * 1000,
    }
  );
};

interface InvitedUser {
  activate: false;
  email: string;
  inviteDate: string; // date
  joinDate: string; // date
  name: string;
  userId: number;
}

export const useInvitedUsers = (page: number = 1) => {
  return useQuery<Pageable<InvitedUser[]>, AxiosError>(
    ["invitedUsers"],
    async () => {
      const { data } = await request.get<Pageable<InvitedUser[]>>(
        "/users/invite",
        {
          params: {
            "pageable.page": page,
            "pageable.size": 10,
            showAll: false,
          },
        }
      );
      return data;
    },
    {
      cacheTime: 5 * 1000,
    }
  );
};

// export const usePermission = (
//   role:
//     | "TENANCY_MAINTAINER"
//     | "TENANCY_OWNER"
//     | "PRODUCT_OWNER"
//     | "PRODUCT_MAINTAINER"
//     | "GROUP_MAINTAINER"
// ) => {
//   return useQuery<boolean, AxiosError>(
//     ["permission", role],
//     async () => {
//       const { data } = await request.get<boolean>(
//         `/lari/permissions/hasrole/${role}`
//       );
//       return data;
//     },
//     {
//       cacheTime: 5 * 60 * 1000,
//     }
//   );
// };

type AdminRole =
  | "TENANCY_MAINTAINER"
  | "TENANCY_OWNER"
  | "PRODUCT_OWNER"
  | "PRODUCT_MAINTAINER"
  | "GROUP_MAINTAINER";

export interface ConsolidatedPerm {
  privileges: {
    code: string;
    description: string;
    name: string;
    objectId: number;
    requiredFeilds: ["OBJECT_ID"];
    type: string;
  }[];
  roles: AdminRole[];
}

export const usePermission = () => {
  const client = useQuery<ConsolidatedPerm, AxiosError>(
    ["permission"],
    async () => {
      const { data } = await request.get<ConsolidatedPerm>(
        `/lari/permissions/my`
      );
      return data;
    },
    {
      cacheTime: 10 * 1000,
    }
  );

  const { data } = client;

  return {
    isTenancyMaintainer() {
      return data?.roles.includes("TENANCY_MAINTAINER") ?? false;
    },
    isTenancyOwner() {
      return data?.roles.includes("TENANCY_OWNER") ?? false;
    },
    isProductOwner() {
      return data?.roles.includes("PRODUCT_OWNER") ?? false;
    },
    isProductMaintainer() {
      return data?.roles.includes("PRODUCT_MAINTAINER") ?? false;
    },
    isGroupMaintainer() {
      return (
        (data?.roles.includes("TENANCY_MAINTAINER") ||
          data?.roles.includes("GROUP_MAINTAINER")) ??
        false
      );
    },
    ...client,
  };
};

export interface UserGroup {
  description: string;
  id: number;
  memberCount: number;
  name: string;
}

export interface UserGroupDetail extends UserGroup {
  members: null | GroupUser[];
}

export const useUserGroups = () => {
  return useQuery<UserGroup[], AxiosError>(
    ["userGroups"],
    async () => {
      const { data } = await request.get<UserGroup[]>(`/users/groups`);
      return data;
    },
    {
      cacheTime: 30 * 1000,
    }
  );
};

export const useUserGroup = (id: number | null) => {
  return useQuery<UserGroupDetail, AxiosError>(
    ["userGroup", id],
    async () => {
      const { data } = await request.get<UserGroupDetail>(
        `/users/groups/${id}`
      );
      return data;
    },
    {
      cacheTime: 5 * 1000,
      enabled: !!id,
    }
  );
};

export type IMemberSearchResult = Pageable<Member[]>;
export interface AccountsGroup {
  admin: boolean;
  description: string;
  id: number;
  memberCount: number;
  name: string;
}

export const useAccountsGroups = () => {
  return useQuery<AccountsGroup[], AxiosError>(
    ["accountsgroup"],
    async () => {
      const { data } = await request.get<AccountsGroup[]>(
        `/users/accounts/groups`
      );
      return data;
    },
    {
      cacheTime: 30 * 1000,
    }
  );
};

export type MemberSearch = Pageable<Member[]>;

interface Member {
  activated: boolean;
  createdAt: string; // date
  updatedAt: string; // date
  deleted: boolean;
  id: number;
  user: {
    email: string;
    firstName: string;
    id: number;
    lastAccess: string; // date
    lastName: string;
    mobile: string;
    name: string;
    photo: string | null;
  };
}

export interface GroupUser {
  userId: number;
  userName: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  photo: string;
  createdAt: string; // date
  admin: boolean;
}

export const useTenancyMembersSearch = (
  originalSearchValue: string | null,
  searchType: "username" | "email"
) => {
  const searchValue = originalSearchValue?.trim() ?? "";
  return useQuery<Pageable<Member[]>, AxiosError>(
    ["tenancyMembers", searchValue],
    async () => {
      const { data } = await request.get<Pageable<Member[]>>(
        `/users/tenancies/${getTenancyId()}/members`,
        {
          params:
            searchType === "username"
              ? {
                  fieldType: "LIKE",
                  searchKey: "userName",
                  searchValue,
                  size: 50,
                }
              : {
                  fieldType: "EQUAL",
                  searchKey: "user.email",
                  searchValue,
                  size: 50,
                },
        }
      );
      return data;
    },
    {
      cacheTime: 10 * 1000,
      enabled: originalSearchValue !== null,
    }
  );
};

interface IOrgAdmins {
  groups:
    | null
    | {
        description: string;
        id: number;
        isOwner: boolean;
        memberCount: number;
        name: string;
        owner: boolean;
      }[];
  users: {
    activated: boolean;
    createdAt: string; // date
    updatedAt: string; // date
    email: string;
    firstName: string;
    isActivated: boolean;
    isOwner: boolean;
    lastName: string;
    owner: boolean;
    photo: string;
    userId: number;
    userName: string;
  }[];
}

export const useOrgAdmins = () => {
  return useQuery<IOrgAdmins, AxiosError>(
    ["orgAdmins"],
    async () => {
      const { data } = await request.get<IOrgAdmins>(
        `/users/tenancies/${getTenancyId()}/maintainers`
      );
      return data;
    },
    {
      cacheTime: 3 * 1000,
    }
  );
};

interface IUserDetail {
  activated: boolean;
  createdAt: string; // date
  updatedAt: string; // date
  firstName: string;
  isActivated: boolean;
  deleted: boolean;
  isDeleted: boolean;
  user: {
    id: number;
    email: string;
    firstName: string | null;
    lastAccess: string | null;
    lastName: string | null;
    mobile: string | null;
    name: string;
  };
}

export const useTenancyUserDetail = (userId: number) => {
  return useQuery<IUserDetail, AxiosError>(
    ["tenancyUserDetail", userId],
    async () => {
      const { data } = await request.get<IUserDetail>(
        `/users/tenancies/${getTenancyId()}/members/${userId}`
      );
      return data;
    },
    {
      cacheTime: 10 * 1000,
    }
  );
};

export const useTenancyUserPrivileges = (userId: number) => {
  return useQuery<ConsolidatedPerm, AxiosError>(
    ["tenancyUserPrivileges", userId],
    async () => {
      const { data } = await request.get<ConsolidatedPerm>(
        `/users/commons/roles/privileges`,
        {
          params: {
            userId,
          },
        }
      );
      return data;
    },
    {
      cacheTime: 10 * 1000,
    }
  );
};

type UserAccessGroups = {
  description: string;
  groupName: string;
  icon: string;
  id: number;
};

export interface AccessUserContent {
  accessgroups: UserAccessGroups[];
  active: boolean;
  email: string;
  id: number;
  lastAccessTime: string; // date
  name: string;
  photo: string; // url
}

export interface AccessUser {
  content: AccessUserContent[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    page: number;
    size: number;
    sort: string[];
  };
  size: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  totalElements: number;
  totalPages: number;
}

export const useAccessUsers = (
  options?: UseQueryOptions<AccessUser, AxiosError>
) => {
  return useQuery<AccessUser, AxiosError, AccessUser>(
    "accessUsers",
    async () => {
      const { data } = await request.get(`/lari/users`);
      return data;
    },
    options
  );
};

export interface IMyAccessGroup {
  alertChannels: {
    channelType: "EMAIL" | "SLACK";
    createdAt: string;
    createdBy: number;
    description: string;
    id: number;
    name: string;
    updatedAt: string;
    updatedBy: number;
  }[];
  clusterServices: {
    clusterId: number;
    groupId: number;
    id: number;
    name: string;
    namespace: string;
    serviceType: string;
    tenancyId: number;
    type: {};
  }[];
  desscription: string;
  groupName: string;
  id: number;
}

export const useMyAccessGroup = (
  options?: UseQueryOptions<IMyAccessGroup[], AxiosError>
) => {
  return useQuery<IMyAccessGroup[], AxiosError>(
    "myAccessGroupList",
    async () => {
      const { data } = await request.get("/lari/accessgroups/my");
      return data;
    },
    {
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};
