import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";

import { currentClusterAtom } from "atoms";
import useStatusActions from "hooks/state/useStatusActions";
import useQueryParams from "hooks/util/useQueryParams";

import { size } from "style/device";

export function useUpdateStateWithQueryParam() {
  const qp = useQueryParams();
  const location = useLocation();
  const [cluster, setCluster] = useAtom(currentClusterAtom);
  const queryParamId = Number(qp.get("clusterId") || qp.get("zoneId") || "");

  useEffect(() => {
    if (!!queryParamId && queryParamId !== cluster.id) {
      setCluster({ id: queryParamId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);
}

export function useMobileSize() {
  const statusActions = useStatusActions();
  useEffect(() => {
    const width = window.innerWidth;
    const checkMobile = (width: number) => {
      const mobile = +size.tablet.slice(0, size.tablet.length - 2);
      let value = false;
      if (width <= mobile) {
        value = true;
      }
      statusActions.onSetData({ key: "mobileSize", value });
    };
    const onResize = (event: any) => {
      checkMobile(event.target.innerWidth);
    };
    checkMobile(width);
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
