import React from "react";
import styled from "@emotion/styled/macro";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #f6f8fa;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IFixContainerProps {
  children: JSX.Element | JSX.Element[];
}

function FixContainer({ children }: IFixContainerProps) {
  return <Container>{children}</Container>;
}

export default FixContainer;
