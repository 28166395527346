import React, { useCallback, useEffect, useState } from "react";
import { Button, Flex } from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";
import { Modal } from "antd";

import { SelectedFilter } from "./common";
import {
  FilterClusterMenu,
  FilterDescriptionMenu,
  FilterFromMenu,
  FilterRecipientMenu,
  FilterSelectMenu,
  FilterSeverityMenu,
  FilterSummaryMenu,
  FilterTimeRangeMenu,
} from "./FilterMenu";
import type { Filter } from "./types";

import day from "utils/day";

interface IDynamicFilter {
  onChange?(filters: Filter[]): void;
}

const DynamicFilter: React.FC<IDynamicFilter> = ({ onChange }) => {
  const [filters, setFilters] = useState<Filter[]>([]);
  const [showModal, setShowModal] = useState(false);

  let activeFilters = null;
  const removeFilter = useCallback(
    (filterType: Filter["type"]) => {
      const newFilters = filters.filter((f) => f.type !== filterType);
      setFilters(newFilters);
    },
    [filters]
  );

  useEffect(() => {
    onChange?.(filters);
  }, [onChange, filters]);

  if (filters.length !== 0) {
    activeFilters = (
      <>
        {filters.map((filter) => {
          if (filter.type === "CLUSTER") {
            return (
              <SelectedFilter
                label="Cluster"
                onRemove={() => {
                  removeFilter(filter.type);
                }}
              >
                <x.div fontSize="12px">{filter.value.name}</x.div>
              </SelectedFilter>
            );
          }

          if (filter.type === "TIME_RANGE") {
            return (
              <SelectedFilter
                label="Time Range"
                onRemove={() => {
                  removeFilter(filter.type);
                }}
              >
                <x.div mr="5px" fontSize="12px">
                  {day(filter.value.starts).format("YY-MM-DD")}
                </x.div>{" "}
                ~{" "}
                <x.div ml="5px" fontSize="12px">
                  {day(filter.value.until).format("YY-MM-DD")}
                </x.div>
              </SelectedFilter>
            );
          }

          if (filter.type === "SEVERITY") {
            return (
              <SelectedFilter
                label="Severity"
                onRemove={() => {
                  removeFilter(filter.type);
                }}
              >
                <x.div fontSize="12px">{filter.value}</x.div>
              </SelectedFilter>
            );
          }

          if (filter.type === "DESCRIPTION") {
            return (
              <SelectedFilter
                label="Description"
                onRemove={() => {
                  removeFilter(filter.type);
                }}
              >
                <x.div fontSize="12px">{filter.value}</x.div>
              </SelectedFilter>
            );
          }

          if (filter.type === "FROM") {
            return (
              <SelectedFilter
                label="From"
                onRemove={() => {
                  removeFilter(filter.type);
                }}
              >
                <x.div fontSize="12px">{filter.value}</x.div>
              </SelectedFilter>
            );
          }

          if (filter.type === "RECIPIENT") {
            return (
              <SelectedFilter
                label="Recipient"
                onRemove={() => {
                  removeFilter(filter.type);
                }}
              >
                <x.div fontSize="12px">
                  {filter.value.map((obj) => obj.name).join(", ")}
                </x.div>
              </SelectedFilter>
            );
          }

          if (filter.type === "SUMMARY") {
            return (
              <SelectedFilter
                label="Summary"
                onRemove={() => {
                  removeFilter(filter.type);
                }}
              >
                <x.div fontSize="12px">{filter.value}</x.div>
              </SelectedFilter>
            );
          }

          return null;
        })}
      </>
    );
  }

  return (
    <x.div>
      <Flex>
        {activeFilters}
        <Button
          icon="plus-no-border"
          iconRight
          size="tiny"
          variant="primary"
          layoutVariant="outlined"
          onClick={() => {
            setShowModal(true);
          }}
        >
          Add Filter
        </Button>
      </Flex>
      <AddDynamicFilterModal
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        addFilter={(filter: Filter) => {
          const newFilters = filters
            .filter((f) => f.type !== filter.type)
            .concat(filter);
          setFilters(newFilters);
        }}
      />
    </x.div>
  );
};

const AddDynamicFilterModal: React.FC<{
  showModal: boolean;
  onClose(): void;
  addFilter(filter: Filter): void;
}> = ({ addFilter, showModal, onClose }) => {
  const [selectedFilterMenu, selectFilterMenu] = useState<
    Filter["type"] | undefined
  >();

  const onExit = () => {
    onClose();
    selectFilterMenu(undefined);
  };

  const getMenuElement = (key: Filter["type"] | undefined) => {
    const props = { addFilter, onExit };
    if (key === "CLUSTER") {
      return <FilterClusterMenu {...props} />;
    }

    if (key === "TIME_RANGE") {
      return <FilterTimeRangeMenu {...props} />;
    }

    if (key === "SEVERITY") {
      return <FilterSeverityMenu {...props} />;
    }

    if (key === "DESCRIPTION") {
      return <FilterDescriptionMenu {...props} />;
    }

    if (key === "FROM") {
      return <FilterFromMenu {...props} />;
    }

    if (key === "RECIPIENT") {
      return <FilterRecipientMenu {...props} />;
    }

    if (key === "SUMMARY") {
      return <FilterSummaryMenu {...props} />;
    }

    return <FilterSelectMenu selectFilterMenu={selectFilterMenu} />;
  };

  return (
    <Modal
      width="640px"
      footer={null}
      visible={showModal}
      onCancel={onExit}
      transitionName=""
      maskTransitionName=""
      centered
    >
      {getMenuElement(selectedFilterMenu)}
    </Modal>
  );
};

export default DynamicFilter;
