import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import type { AxiosError } from "axios";
import { QUERY_KEYS } from "query";

import { IInstallParams } from "store/redux/create";
import { IOverAllStatus } from "store/redux/zone";
import { request } from "api/common";
import paths from "api/paths";

import useClusterList from "./useClusterList";

export const useUpdateInstallParams = (
  options?: UseMutationOptions<IInstallParams, AxiosError, any>
) => {
  return useMutation(
    async ({
      clusterId,
      installParams,
    }: {
      clusterId?: number;
      installParams: IInstallParams;
    }) => {
      if (!clusterId) {
        return;
      }
      const { data } = await request.put(paths.zones.installParams(clusterId), {
        installParams: JSON.stringify(installParams),
      });
      return data;
    },
    options
  );
};

export const useInstallParams = (
  clusterId?: string | number,
  options?: UseQueryOptions<IInstallParams, AxiosError, any>
) => {
  return useQuery(
    ["installParams", clusterId],
    async () => {
      if (!clusterId) {
        return;
      }
      const { data } = await request.get(paths.zones.installParams(clusterId));
      return data;
    },
    options
  );
};

export const useHasCluster = () => {
  const { data: clusterList } = useClusterList();
  return clusterList?.length !== 0;
};

export const useClusterDeleteScript = (
  clusterId?: string | number | "global",
  options?: UseQueryOptions<string, AxiosError, any>
) => {
  return useQuery(
    ["clusterDeleteScript", clusterId],
    () => {
      if (!clusterId) {
        return;
      }
      return request
        .get(paths.zones.getClusterDeleteScript(clusterId))
        .then((res) => res.data);
    },
    options
  );
};

export const useClustersStatus = (
  options?: UseQueryOptions<IOverAllStatus, AxiosError>
) => {
  return useQuery<IOverAllStatus, AxiosError>(
    QUERY_KEYS.clustersStatus(),
    () => request.get("/lari/clusters/overall").then((res) => res.data),
    {
      cacheTime: 60 * 1000,
      ...options,
    }
  );
};
