import React from "react";
import styled from "@emotion/styled/macro";

import CollapBox from "./CollapBox";

import { device } from "style/device";

const ContentBox = styled.div`
  width: 95%;
  height: 100%;
  overflow: auto;
  max-width: 1100px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;

  @media ${device.tablet} {
    width: 100%;
  }
`;

function Config() {
  return (
    <Container>
      <ContentBox>
        <CollapBox />
      </ContentBox>
    </Container>
  );
}

export default Config;
