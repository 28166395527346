import React, { useCallback, useRef, useState } from "react";
import { createPortal } from "react-dom";
import {
  closestCenter,
  CollisionDetection,
  defaultDropAnimation,
  DndContext,
  DragOverlay,
  DropAnimation,
  MeasuringStrategy,
  PointerSensor,
  rectIntersection,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import styled from "@xstyled/emotion";

import DroppableContainer from "./DroppableContainer";
import { SortableItem, SorteeItem } from "./SortableItem";
import type { ISortableDoubleDeck, Items } from "./types";

const DndContextBox = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
`;

const SortableDoubleDeck: ISortableDoubleDeck = ({
  titles,
  items,
  setItems,
  renderItem,
  renderInnerItem,
}) => {
  const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));
  const [activeId, setactiveId] = useState<string | null>(null);
  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);
  const [containers, setContainers] = useState(Object.keys(items));
  const isSortingContainer = activeId ? containers.includes(activeId) : false;

  // Custom collision detection strategy optimized for multiple containers
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      // Start by finding any intersecting droppable
      let overId = rectIntersection(args);

      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items
          ),
        });
      }

      if (overId != null) {
        if (overId in items) {
          // @ts-ignore
          const containerItems = items[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  containerItems.includes(container.id)
              ),
            });
          }
        }

        lastOverId.current = overId;

        return overId;
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current;
    },
    [activeId, items]
  );

  const [clonedItems, setClonedItems] = useState<Items | null>(null);

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containrs
      setItems(clonedItems);
    }

    setactiveId(null);
    setClonedItems(null);
  };

  const findContainer = (id: string) => {
    if (id in items) {
      return id as "left" | "right";
    }
    if (items.left.includes(id)) {
      return "left";
    }
    if (items.right.includes(id)) {
      return "right";
    }
  };

  const getIndex = (id: string) => {
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    const index = items[container].indexOf(id);

    return index;
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {
        setactiveId(active.id);
        setClonedItems(items);
      }}
      onDragOver={({ active, over }) => {
        const overId = over?.id;

        if (!overId) {
          return;
        }
        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer !== overContainer) {
          setItems((items) => {
            const activeItems = items[activeContainer];
            const overItems = items[overContainer];
            const overIndex = overItems.indexOf(overId);
            const activeIndex = activeItems.indexOf(active.id);

            let newIndex: number;

            if (overId in items) {
              newIndex = overItems.length + 1;
            } else {
              const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.offsetTop >
                  over.rect.offsetTop + over.rect.height;

              const modifier = isBelowOverItem ? 1 : 0;

              newIndex =
                overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }

            recentlyMovedToNewContainer.current = true;

            return {
              ...items,
              [activeContainer]: items[activeContainer].filter(
                (item) => item !== active.id
              ),
              [overContainer]: [
                ...items[overContainer].slice(0, newIndex),
                items[activeContainer][activeIndex],
                ...items[overContainer].slice(
                  newIndex,
                  items[overContainer].length
                ),
              ],
            };
          });
        }
      }}
      onDragEnd={({ active, over }) => {
        if (active.id in items && over?.id) {
          setContainers((containers) => {
            const activeIndex = containers.indexOf(active.id);
            const overIndex = containers.indexOf(over.id);

            return arrayMove(containers, activeIndex, overIndex);
          });
        }
        const activeContainer = findContainer(active.id);

        if (!activeContainer) {
          setactiveId(null);
          return;
        }

        const overId = over?.id;

        if (!overId) {
          setactiveId(null);
          return;
        }

        const overContainer = findContainer(overId);

        if (overContainer) {
          const activeIndex = items[activeContainer].indexOf(active.id);
          const overIndex = items[overContainer].indexOf(overId);

          if (activeIndex !== overIndex) {
            setItems((items) => ({
              ...items,
              [overContainer]: arrayMove(
                items[overContainer],
                activeIndex,
                overIndex
              ),
            }));
          }
        }

        setactiveId(null);
      }}
      onDragCancel={onDragCancel}
    >
      <DndContextBox>
        {(["left", "right"] as (keyof Items)[]).map((containerId) => (
          <DroppableContainer
            title={titles[containerId]}
            key={containerId}
            id={containerId}
            columns={1}
            items={items[containerId]}
          >
            <SortableContext
              items={items[containerId]}
              strategy={verticalListSortingStrategy}
            >
              {items[containerId].map((id, index) => (
                <SortableItem
                  disabled={isSortingContainer}
                  key={id}
                  id={id}
                  index={index}
                  handle={false}
                  style={() => ({})}
                  wrapperStyle={() => ({})}
                  renderItem={renderItem}
                  renderInnerItem={renderInnerItem}
                  containerId={containerId}
                  getIndex={getIndex}
                />
              ))}
            </SortableContext>
          </DroppableContainer>
        ))}
      </DndContextBox>
      {createPortal(
        <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>
          {activeId ? (
            <SorteeItem
              handle={false}
              id={activeId}
              containerId={findContainer(activeId)}
              value={null}
              renderInnerItem={renderInnerItem}
              dragOverlay
            />
          ) : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
};

const dropAnimation: DropAnimation = {
  ...defaultDropAnimation,
  dragSourceOpacity: 0.5,
};

export default SortableDoubleDeck;
