import styled from "@emotion/styled";

const Wrapper = styled.div`
  margin-bottom: 50px;
  width: 100%;
  margin: 25px auto 35px auto;
  max-width: 1600px;
`;

const Section: React.FC = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default Section;
