import React, { useEffect, useState } from "react";
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import styled from "@emotion/styled/macro";
import { Button as AntdButton, Modal as AntModal } from "antd";

import { ConfigStatus } from "store/redux/zone";

import { capitalize } from "utils";

const Contents = styled.div``;

const ButtonBox = styled.div`
  margin-top: 30px;
  text-align: right;
  width: 100%;

  button {
    margin: 0 5px;
  }
`;

const Wrapper = styled.div``;

const IconBox = styled.div`
  * {
    font-size: 1.9em;
  }
`;

const ResultBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Text = styled.div`
  font-size: 1.2em;
  color: #969696;
  margin-top: 20px;
`;

const LogBox = styled.div`
  width: 100%;
  margin-top: 10px;
  max-height: 200px;
  overflow: auto;
  padding: 20px;
  background-color: #161616;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9em;
`;

type Step = "testAndSave" | "applyAndReloadP8S" | "applyAndReloadAlert";

interface IModalProps {
  visible: boolean;
  step: Step;
  status: ConfigStatus | null;
  testLog: string | undefined;
  onCancel: () => void;
  onTestAndSave: () => void;
  onApplyAndReload: () => void;
  handleTestSuccess: () => void;
  handleApplySuccess: () => void;
  onResetConfig: () => void;
}

const title = {
  testAndSave: "Check Config",
  applyAndReloadP8S: "Apply & Reload Prometheus",
  applyAndReloadAlert: "Apply & Reload Alert manager",
};

const contents = {
  testAndSave:
    "Check for the main configuration file for the Prometheus server. This action outputs its assessment of the validity of the configuration.",
  applyAndReloadP8S:
    "The test is complete. If you want to apply, click the apply button below.",
  applyAndReloadAlert:
    "The test is complete. If you want to apply, click the apply button below.",
};

const componentList: { [p in Step]: (props: IContentsProps) => JSX.Element } = {
  testAndSave: TestAndSaveContents,
  applyAndReloadP8S: ApplyAndReloadContents,
  applyAndReloadAlert: ApplyAndReloadContents,
};

interface IModalWrapperProps {
  title?: string | JSX.Element;
  visible: boolean;
  onOk?: () => void;
  onCancel: () => void;
  okText?: string;
  cancelText?: string;
  style?: any;
  maskClosable?: boolean;
  children: JSX.Element | JSX.Element[] | string | any;
  footer?: any;
}

function ModalWrapper({
  title,
  visible,
  onOk,
  onCancel,
  okText,
  cancelText,
  style,
  maskClosable,
  footer,
  children,
}: IModalWrapperProps) {
  return (
    <AntModal
      maskClosable={maskClosable}
      style={style}
      title={title}
      okText={okText}
      cancelText={cancelText}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={footer}
    >
      {children}
    </AntModal>
  );
}
export default function Modal({
  visible,
  step,
  status,
  testLog,
  onCancel,
  onTestAndSave,
  onApplyAndReload,
  handleTestSuccess,
  handleApplySuccess,
  onResetConfig,
}: IModalProps) {
  const Component = componentList[step];

  return (
    <ModalWrapper
      visible={visible}
      onCancel={onCancel}
      title={<b style={{ fontSize: "1.1em" }}>{title[step]}</b>}
      maskClosable={false}
      footer={null}
    >
      <Component
        step={step}
        status={status}
        onTestAndSave={onTestAndSave}
        onCancel={onCancel}
        handleTestSuccess={handleTestSuccess}
        onApplyAndReload={onApplyAndReload}
        handleApplySuccess={handleApplySuccess}
        onResetConfig={onResetConfig}
        testLog={testLog}
      />
    </ModalWrapper>
  );
}

interface IContentsProps {
  step: Step;
  status: ConfigStatus | null;
  testLog: string | undefined;
  onTestAndSave: () => void;
  onCancel: () => void;
  onApplyAndReload: () => void;
  handleTestSuccess: () => void;
  handleApplySuccess: () => void;
  onResetConfig: () => void;
}

function ApplyAndReloadContents({
  step,
  status,
  testLog,
  onCancel,
  onApplyAndReload,
  handleApplySuccess,
}: IContentsProps) {
  const [apply, setApply] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleClick = () => {
    setApply(true);
    onApplyAndReload();
  };

  const onClose = () => {
    onCancel();

    setTimeout(() => {
      setApply(false);
      setShowError(false);
      setShowSuccess(false);
    }, 500);
  };

  useEffect(() => {
    const loadingStatus: any[] = ["TESTING", "UPDATING", "OK", "TEST_OK"];
    if (status === "ERROR") {
      setApply(false);
    } else if (apply && status === "OK") {
      handleApplySuccess();
      setShowSuccess(true);
    } else if (apply && !loadingStatus.includes(status)) {
      setShowError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <Wrapper>
      {!showSuccess && !showError && (
        <>
          <Contents>{contents[step]}</Contents>
          <ButtonBox>
            <AntdButton disabled={apply} onClick={onClose}>
              Cancel
            </AntdButton>
            <AntdButton type="primary" onClick={handleClick} loading={apply}>
              Apply
            </AntdButton>
          </ButtonBox>
        </>
      )}
      {showSuccess && (
        <ResultBox>
          <IconBox>
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          </IconBox>
          <Text>Applied successfully.</Text>
          <ButtonBox>
            <AntdButton onClick={onClose} type="primary">
              Close
            </AntdButton>
          </ButtonBox>
        </ResultBox>
      )}
      {showError && (
        <ResultBox>
          <IconBox>
            <ExclamationCircleTwoTone twoToneColor="#d9363e" />
          </IconBox>
          <Text>Apply failed</Text>
          <ButtonBox>
            <AntdButton onClick={onClose} type="primary">
              Close
            </AntdButton>
          </ButtonBox>
        </ResultBox>
      )}
    </Wrapper>
  );
}

function TestAndSaveContents({
  step,
  testLog,
  onTestAndSave,
  handleTestSuccess,
  onResetConfig,
  onCancel,
  status,
}: IContentsProps) {
  const [testing, setTesting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const TestCode: any[] = ["TESTING", "UPDATING"];

  const handleClick = () => {
    onTestAndSave();
    setTesting(true);
  };

  const onClose = () => {
    onCancel();
    setTimeout(() => {
      setTesting(false);
      setShowSuccess(false);
      setShowError(false);
    }, 500);
  };

  const handleReset = () => {
    onResetConfig();
    onClose();
  };

  useEffect(() => {
    const failList: any[] = ["TEST_FAILED", "TEST_ERROR"];
    if (!testing) return;

    if (status === "OK" || status === "TEST_OK") {
      setShowSuccess(true);
      handleTestSuccess();
    } else if (failList.includes(status)) {
      setShowError(true);
    } else if (status === "ERROR") {
      setTesting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <Wrapper>
      {!showSuccess && !showError && (
        <>
          <Contents>{contents[step]}</Contents>
          <ButtonBox>
            <AntdButton disabled={testing} onClick={onClose}>
              Cancel
            </AntdButton>
            <AntdButton type="primary" onClick={handleClick} loading={testing}>
              {testing && TestCode.includes(status)
                ? capitalize(status || "")
                : testing
                ? "Testing"
                : "Test"}
            </AntdButton>
          </ButtonBox>
        </>
      )}
      {showSuccess && (
        <ResultBox>
          <IconBox>
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          </IconBox>
          <Text>Successfully completed the test.</Text>
          <ButtonBox>
            <AntdButton onClick={onClose} type="primary">
              Close
            </AntdButton>
          </ButtonBox>
        </ResultBox>
      )}
      {showError && (
        <ResultBox>
          <IconBox>
            <ExclamationCircleTwoTone twoToneColor="#d9363e" />
          </IconBox>
          <Text>Test failed</Text>
          <LogBox>{testLog}</LogBox>
          <ButtonBox>
            <AntdButton onClick={onClose}>Close</AntdButton>
            {status === "TEST_FAILED" && (
              <AntdButton onClick={handleReset} type="primary">
                Reset
              </AntdButton>
            )}
          </ButtonBox>
        </ResultBox>
      )}
    </Wrapper>
  );
}
