import React, { useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "@emotion/styled/macro";
import { Button, Copyboard } from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";
import { message, notification } from "antd";
import { fetchOption } from "config";

import { Platform } from "store/redux/create";
import { useCluster } from "hooks/cluster/useClusterDetailQuery";
import { useCreateGet, useZoneGet } from "hooks/state/get";
import { authCheck } from "api/common";

import { usePath } from "paths";

type IPlatform = {
  [P in Platform]: string;
};

const titleList: IPlatform = {
  kubernetes: "Kubernetes",
  baremetal: "Baremetal or VM",
  AWS: "AWS",
};

const Title = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Intro = styled.div`
  margin-bottom: 30px;
  line-height: 1.5em;
`;

const Paragraph = styled.p`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 1.5em;
`;

const MessageBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  span {
    font-weight: bold;
  }
`;
const ButtonBox = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

const openStatusNotification = (key: string, message: string) => {
  const args = {
    message: (
      <MessageBox>
        <LoadingOutlined
          style={{ color: "#096dd9", fontSize: "1.5em", marginRight: "7px" }}
        />
        <x.span>{message}</x.span>
      </MessageBox>
    ),
    duration: null,
    key,
  };
  notification.open(args);
};

export const CreateInstallAgent = () => {
  const platform = useCreateGet("platform");
  const install = useCreateGet("install");
  const zoneInfo = useCreateGet("zoneInfo");
  const detailZone = useZoneGet("detailZone");
  const clusterId = zoneInfo?.get("id") ?? detailZone?.get("id");
  const moveToDashboard = usePath((paths) => paths.dashboard);

  const script = install || "";

  const { data: cluster } = useCluster(clusterId, fetchOption);
  const clusterInitStatus = cluster?.initStatus;

  useEffect(() => {
    if (clusterInitStatus === "BEING_INSTALLED") {
      openStatusNotification(
        "installing",
        "Agent is being installed. Please wait..."
      );
    } else if (clusterInitStatus === "GLOBALVIEW_CHANGING") {
      openStatusNotification(
        "changing",
        "Status of Global view is being changged. Please wait..."
      );
      notification.close("installing");
    } else {
      notification.close("installing");
      notification.close("changing");
    }

    return () => {
      notification.close("installing");
      notification.close("changing");
    };
  }, [clusterInitStatus]);

  const moveToDetail = usePath((paths) => paths.cluster.detail);

  const confirmDelete = async () => {
    try {
      await authCheck({
        url: `/lari/clusters/${clusterId}`,
        method: "DELETE",
      });
      message.success("Cluster deleted.");
      moveToDashboard();
    } catch (e) {
      message.error("Failed to delete cluster. Please try again later.");
    }
  };

  return (
    <InstallAgent installScript={script} platform={platform}>
      <ButtonBox>
        {clusterInitStatus !== "ACTIVE" && (
          <Button variant="danger" onClick={confirmDelete}>
            Discard
          </Button>
        )}
        <Button
          variant="primary"
          onClick={() => {
            if (!clusterId) {
              return;
            }
            moveToDetail({ clusterId });
          }}
          disabled={clusterInitStatus !== "ACTIVE"}
        >
          Continue
        </Button>
      </ButtonBox>
    </InstallAgent>
  );
};

export const InstallAgent: React.FC<{
  installScript: string;
  platform?: Platform;
}> = ({ children, installScript, platform }) => {
  return (
    <x.div w="100%">
      <Title>Install Agent on your {platform ? titleList[platform] : ""}</Title>
      <Intro>
        For NexClipper Agent to install successfully, make sure you have enabled
        privileged admin within your Kubernetes cluster.
      </Intro>
      <Paragraph>
        Run the following command against your cluster for NexClipper
      </Paragraph>
      <Copyboard content={installScript} />
      {children}
    </x.div>
  );
};
