import React from "react";
import styled from "@emotion/styled/macro";
import { Layout } from "antd";

import StatusIcon from "./Status";

import { device } from "style/device";

const StyledFooter = styled(Layout.Footer)`
  text-align: center;
  padding: 0px;
  border-left: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${device.mobileL} {
    flex-wrap: wrap;
  }
`;

const FooterText = styled.div`
  color: #808080;
  font-weight: 100;
  font-size: 0.9em;
  padding: 18px 50px;
  @media ${device.mobileL} {
    width: 100%;
  }
`;

const Footer: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  return (
    <StyledFooter>
      {!disabled && <StatusIcon />}
      <FooterText>© {new Date().getFullYear()} NexClipper</FooterText>
    </StyledFooter>
  );
};

export default Footer;
