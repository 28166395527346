import { useState } from "react";
import type { Row } from "react-table";
import {
  Button,
  EmptyBox,
  Flex,
  FormInput,
  FormTextArea,
  IconDisplay,
  IconSelector,
  InputLayout,
  Page,
  ProgressBar,
  Section,
  SelectingTable,
  SmallFormLayout,
  SmallTitle,
  SortableCard,
  SortableDoubleDeck,
  SortableItemsType,
  SpaceBetween,
  Table,
  TitleSection,
  UserBadge,
} from "@nexcloud/nc-ui";
import styled from "@xstyled/emotion";
import { fetchOption } from "config";

import { IZone } from "store/redux/zone";
import { useAccessGroupClusterList } from "hooks/accessGroup";
import { IChannel, useChannels } from "hooks/channel";
import {
  IAccessGroupDetailChannel,
  useAccessGroupChannelList,
  useAccessGroupUserList,
} from "hooks/cluster/service";
import useClusterList from "hooks/cluster/useClusterList";
import { useAccessUsers } from "hooks/user";
import { request } from "api/common";

interface IGroupInfo {
  setGroupName: any;
  setGroupDesc: any;
  setGroupIcon: any;
  setGroupId: any;
  setStep: any;
  groupName: string;
  groupDesc: string;
  groupIcon: string;
}

const H2 = styled.h2`
  color: #1f29ca;
`;

const BorderBottom = styled.div`
  padding-bottom: 30px;
  border-bottom: 2px solid #e5e7eb;
`;

const ViewWrapper = styled.div`
  dt {
    margin-bottom: 3px;
    font-size: 14px;
    line-height: 21px;
    color: #374151;
  }

  dd {
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
  }

  .editIcon {
    font-size: 25px;
    cursor: pointer;
  }
`;

const SetGroupInfo: React.FC<IGroupInfo> = ({
  setGroupName,
  setGroupDesc,
  setGroupIcon,
  setGroupId,
  groupName,
  groupDesc,
  groupIcon,
  setStep,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNext = async () => {
    if (!groupName) {
      return;
    }
    await onSubmit();
    setStep(1);
  };

  const onSubmit = async () => {
    setIsSubmitting(true);

    try {
      const response = await request.post(`/lari/accessgroups`, {
        groupName: groupName,
        description: groupDesc,
        icon: groupIcon,
      });

      if (response.status === 200) {
        setIsSubmitting(false);
        setGroupId(response.data);
      }
    } catch (e) {
      alert("There was an error. Please try again later.");
    }
  };

  return (
    <Section>
      <SmallFormLayout>
        <TitleSection>
          <h2>New Access Group</h2>
        </TitleSection>
        <InputLayout>
          <FormInput
            value={groupName}
            label="New Access Group Name"
            onChange={(e) => {
              setGroupName(e.target.value);
            }}
          />
        </InputLayout>
        <InputLayout>
          <FormTextArea
            value={groupDesc}
            maxLength="250"
            label="Access Group Description"
            rows={6}
            onChange={(e) => {
              setGroupDesc(e.target.value);
            }}
          />
        </InputLayout>
        <InputLayout>
          <IconSelector
            onChange={(value: String) => {
              setGroupIcon(value);
            }}
            type="accessGroup"
          />
        </InputLayout>
        <SpaceBetween>
          <Button
            loading={isSubmitting}
            variant="primary"
            onClick={handleNext}
            disabled={groupName === "" ? true : false}
          >
            Next: Add Members
          </Button>
          <Button
            layoutVariant="outlined"
            variant="primary"
            to="/access-groups"
          >
            Cancel
          </Button>
        </SpaceBetween>
      </SmallFormLayout>
    </Section>
  );
};

const AccessGroupIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  font-size: 25px;
  border-radius: 5px;
  position: relative;
  top: -8px;
  color: #1f29ca;
`;

const ViewGroupInfo: React.FC<{
  groupName: string;
  groupDesc: string;
  groupIcon: string;
}> = ({ groupName, groupDesc, groupIcon }) => {
  return (
    <Section>
      <BorderBottom>
        <ViewWrapper>
          <Flex>
            <H2>{groupName}</H2>
            <AccessGroupIcon>
              <IconDisplay selected={groupIcon} type="accessGroup" />
            </AccessGroupIcon>
          </Flex>
          <dt>Description</dt>
          <dd>{groupDesc}</dd>
        </ViewWrapper>
      </BorderBottom>
    </Section>
  );
};

const SetGroupUser: React.FC<{
  setStep: any;
  groupId: number | undefined;
}> = ({ setStep, groupId }) => {
  const [items, setItems] = useState<SortableItemsType>(() => ({
    left: [],
    right: [],
  }));
  const { data: userList } = useAccessUsers({
    onSuccess(data) {
      setItems({
        left: data.content.map(({ id }) => String(id)),
        right: [],
      });
    },
  });
  const userListContent = userList?.content;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    const selected = items.right.map((id) => ({
      memberId: id,
      memberType: "USER",
    }));
    setIsSubmitting(true);

    try {
      const response = await request.post(
        `/lari/accessgroups/${groupId}/members`,
        selected
      );

      if (response.status === 200) {
        setIsSubmitting(false);
        setStep(2);
      }
    } catch (e) {
      alert("There was an error. Please try again later.");
    }
  };

  return (
    <Section>
      <H2>Add Users</H2>
      <Section>
        <SortableDoubleDeck
          titles={{ left: "Team Members", right: "Access Group Users" }}
          items={items}
          setItems={setItems}
          renderInnerItem={({ itemId, containerId, listeners }) => {
            const user = userListContent?.find((c) => String(c.id) === itemId);
            return (
              <SortableCard
                {...{ itemId, containerId, items, setItems, listeners }}
              >
                <UserBadge
                  isSmall
                  name={user?.name}
                  avatar="https://www.w3schools.com/images/picture.jpg"
                />
              </SortableCard>
            );
          }}
        />
      </Section>
      <SpaceBetween>
        <Button
          variant="primary"
          onClick={onSubmit}
          loading={isSubmitting}
          disabled={items.right.length === 0}
        >
          Next: Choose Clusters
        </Button>
        <Button
          layoutVariant="outlined"
          variant="primary"
          onClick={() => setStep(2)}
        >
          Skip
        </Button>
      </SpaceBetween>
    </Section>
  );
};

const ViewGroupUser: React.FC<{ groupId: number | undefined }> = ({
  groupId,
}) => {
  const { data } = useAccessGroupUserList(Number(groupId));

  return (
    <Section>
      <BorderBottom>
        <H2>Users</H2>
        <Flex>
          {data
            ?.filter((user) => user.userId)
            .map((user) => (
              <UserBadge
                avatar={
                  user.photo
                    ? user.photo
                    : "https://www.w3schools.com/images/picture.jpg"
                }
                name={user.name}
                role="User"
                isSmall
              />
            ))}
        </Flex>
      </BorderBottom>
    </Section>
  );
};

const clusterColumn = [
  {
    Header: "CLUSTER NAMES",
    accessor: "name",
  },
  {
    Header: "DESCRIPTION",
    accessor: "description",
  },
];

const SetClusters: React.FC<{ setStep: any; groupId: number | undefined }> = ({
  setStep,
  groupId,
}) => {
  const { data } = useClusterList(fetchOption);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNext = async () => {
    await onSubmit();
  };

  const onSubmit = async () => {
    const selected = selectedRow?.map((row: Row<IZone>) => row.original.id);
    setIsSubmitting(true);

    try {
      const response = await request.post(
        `/lari/accessgroups/${groupId}/clusters`,
        selected
      );

      if (response.status === 200) {
        setIsSubmitting(false);
        setStep(3);
      }
    } catch (e) {
      alert("There was an error. Please try again later.");
    }
  };

  return (
    <Section>
      <H2>Choose Clusters</H2>
      <Section>
        <SelectingTable
          columns={clusterColumn}
          data={data}
          setSelectedRow={setSelectedRow}
        />
      </Section>
      <SpaceBetween>
        <Button variant="primary" onClick={handleNext} loading={isSubmitting}>
          Next: Add Channels
        </Button>
        <Button
          layoutVariant="outlined"
          variant="primary"
          onClick={() => setStep(3)}
        >
          Skip
        </Button>
      </SpaceBetween>
    </Section>
  );
};

const ViewClusters: React.FC<{
  groupId: number | undefined;
}> = ({ groupId }) => {
  const { data } = useAccessGroupClusterList(Number(groupId));

  return (
    <Section>
      <H2>Clusters</H2>
      {data?.length === 0 ? (
        <EmptyBox />
      ) : (
        <Table columns={clusterColumn} data={data ? data : []} />
      )}
    </Section>
  );
};

const channelColumn = [
  {
    Header: "CHANNEL NAMES",
    accessor: "name",
  },
  {
    Header: "DESCRIPTION",
    accessor: "description",
    disableSortBy: true,
  },
];

const SetChannels: React.FC<{ groupId: number | undefined; setStep: any }> = ({
  groupId,
  setStep,
}) => {
  const { data: channels } = useChannels();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // @ts-ignore
  let selectedChannel = [];

  const onSubmit = async () => {
    // @ts-ignore
    const selected = selectedChannel?.map(
      (row: Row<IChannel>) => row.original.id
    );

    setIsSubmitting(true);
    try {
      const response = await request.post(
        `/lari/accessgroups/${groupId}/channels`,
        selected
      );

      if (response.status === 200) {
        setIsSubmitting(false);
        setStep(4);
      }
    } catch (e) {
      alert("There was an error. Please try again later.");
    }
  };

  return (
    <Section>
      <H2>Choose Channels</H2>
      <Section>
        <SelectingTable
          columns={channelColumn}
          data={channels ? channels : []}
          setSelectedRow={(selectedFlatRows: any) => {
            selectedChannel = [...selectedFlatRows];
          }}
        />
      </Section>
      <SpaceBetween>
        <Button variant="primary" onClick={onSubmit} loading={isSubmitting}>
          Add Channel
        </Button>
        <Button
          layoutVariant="outlined"
          variant="primary"
          onClick={() => setStep(4)}
        >
          Skip
        </Button>
      </SpaceBetween>
    </Section>
  );
};

const channelColumns = [
  {
    Header: "CHANNELS",
    accessor: (d: IAccessGroupDetailChannel) => d.channelInfo.name,
  },
  {
    Header: "DESCRIPTION",
    accessor: (d: IAccessGroupDetailChannel) => d.channelInfo.description,
    disableSortBy: true,
  },
];

const ViewChannels: React.FC<{ groupId: number | undefined }> = ({
  groupId,
}) => {
  const { data } = useAccessGroupChannelList(Number(groupId));

  return (
    <Section>
      <H2>Choose Channels</H2>
      {data?.length === 0 ? (
        <EmptyBox />
      ) : (
        <Table columns={channelColumns} data={data ? data : []} />
      )}
    </Section>
  );
};

const AddAccessGroup = () => {
  const [groupName, setGroupName] = useState("");
  const [groupDesc, setGroupDesc] = useState("");
  const [groupIcon, setGroupIcon] = useState("");
  const [step, setStep] = useState(0);
  const [groupId, setGroupId] = useState();
  const progress = [
    "Add Group",
    "Add Members",
    "Choose Clusters",
    "Add Channels",
  ];
  return (
    <Page>
      <Section>
        <SmallTitle>Create Access Group</SmallTitle>
        <ProgressBar progress={progress} step={step} setStep={setStep} />
      </Section>
      {step === 0 && (
        <SetGroupInfo
          groupName={groupName}
          groupDesc={groupDesc}
          groupIcon={groupIcon}
          setGroupName={setGroupName}
          setGroupDesc={setGroupDesc}
          setGroupIcon={setGroupIcon}
          setGroupId={setGroupId}
          setStep={setStep}
        />
      )}
      {step > 0 && (
        <ViewGroupInfo
          groupName={groupName}
          groupDesc={groupDesc}
          groupIcon={groupIcon}
        />
      )}
      {step === 1 && <SetGroupUser groupId={groupId} setStep={setStep} />}
      {step > 1 && <ViewGroupUser groupId={groupId} />}
      {step === 2 && <SetClusters groupId={groupId} setStep={setStep} />}
      {step > 2 && <ViewClusters groupId={groupId} />}
      {step === 3 && <SetChannels groupId={groupId} setStep={setStep} />}
      {step > 3 && <ViewChannels groupId={groupId} />}
      {step === 4 && (
        <Section>
          <Button variant="primary" to={`/access-groups/${groupId}`}>
            Finish
          </Button>
        </Section>
      )}
    </Page>
  );
};

export default AddAccessGroup;
