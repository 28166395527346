import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled/macro";
import { Button } from "@nexcloud/nc-ui";
import { Form, Input, Modal } from "antd";

import {
  showEmailLinkCheck,
  showInvalidEmail,
  showTryAgain,
} from "components/NotificationMessage";

import { prdCode, request } from "api/common";

const ItemBox = styled.div``;

const Label = styled.div`
  font-size: 0.9em;
  color: #808090;
  margin-bottom: 5px;

  * {
    font-size: 1em;
  }
`;

function ResetPasswordCheckForm() {
  const history = useHistory();
  const [loginId, setLoginId] = useState("");
  const [loading, setLoading] = useState(false);

  const passwordLinkSend = async (values: any) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const response = await request.get(
        `/login/conventional/password/${prdCode}`,
        {
          params: {
            loginId,
            redirectUrl: `${window.location.origin}/resetpassword`,
          },
        }
      );
      const result = response.data;
      if (result === "SUCCESS") {
        history.push("/login");
        showEmailLinkCheck();
      } else if (result === "EMAIL_VERIFIED_NOT_YET") {
        return showInvalidEmail();
      } else {
        return showTryAgain();
      }
    } catch (e) {
      if (e.response?.data?.message) {
        Modal.warning({
          title: "Warning",
          content: e.response.data.message,
        });
      } else {
        showTryAgain();
        history.push("/404");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form name="reset" onFinish={() => {}}>
      <ItemBox>
        <Label>
          <span>Email Address</span>
        </Label>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Please input your email address" },
          ]}
        >
          <Input
            placeholder="Email address"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const loginId = e.target.value;
              setLoginId(loginId);
            }}
          />
        </Form.Item>
      </ItemBox>
      <Form.Item>
        <Button
          variant="primary"
          htmlType="submit"
          expand
          onClick={passwordLinkSend}
        >
          Reset your Password
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ResetPasswordCheckForm;
