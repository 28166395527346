import { useQuery, UseQueryOptions } from "react-query";
import { AxiosError } from "axios";

import { request } from "api/common";

export interface IIncidentItem {
  assignee: {
    accessgroups: {
      description: string;
      groupName: string;
      icon: string;
      id: number;
    }[];
    active: boolean;
    email: string;
    id: number;
    lastAccessTime: string;
    name: string;
    photo: string;
  };
  createdAt: string;
  dueDate: string;
  id: number;
  lastUpdatedAt: string;
  priority: "URGENT" | "HIGH" | "MEDIUM" | "LOW";
  status: "CLOSED" | "IN_PROGRESS" | "OPEN" | "RESOLVED";
  title: string;
}

export interface IIncidentList {
  content: IIncidentItem[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    page: number;
    size: number;
    sort: string[];
  };
  size: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  totalElements: number;
  totalPages: number;
}

export interface IIncidentDetail {
  assignee: {
    accessgroups: {
      description: string;
      groupName: string;
      icon: string;
      id: number;
    }[];
    active: boolean;
    email: string;
    id: number;
    lastAccessTime: string;
    name: string;
    photo: string;
  };
  createdAt: string;
  createdBy: number;
  dueDate: string;
  id: number;
  lastUpdatedAt: string;
  note: string;
  priority: "HIGH" | "LOW" | "MEDIUM" | "URGENT";
  status: "CLOSED" | "IN_PROGRESS" | "OPEN" | "RESOLVED";
  title: string;
}

export interface IIncidentAlert {
  alertName: string;
  from: "LARI" | "PROMETHEUS";
  id: number;
  incidentId: number[];
  lastUpdatedAt: string;
  recipients: [
    {
      id: number;
      name: string;
    }
  ];
  severity: string;
  source: string;
  startAt: string;
  status: "CLOSED" | "HIDED" | "OPEN" | "REFERENCED";
  summary: string;
}

export interface IIncidentAction {
  createdAt: string;
  createdBy: number;
  description: string;
  id: number;
  title: string;
  updatedAt: string;
}

export interface IIncidentCount {
  closedCount: number;
  inprogressCount: number;
  openCount: number;
  resolvedCount: number;
}

export const useIncidentList = (
  options?: UseQueryOptions<IIncidentList, AxiosError>
) => {
  return useQuery<IIncidentList, AxiosError>(
    "incidentList",
    async () => {
      const { data } = await request.get("/lari/incidents");
      return data;
    },
    {
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};

export const useIncidentDetail = (
  id: number,
  options?: UseQueryOptions<IIncidentDetail, AxiosError>
) => {
  return useQuery<IIncidentDetail, AxiosError>(
    ["incidentDetail", id],
    async () => {
      const { data } = await request.get(`/lari/incidents/${id}`);
      return data;
    },
    {
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};

export const useIncidentAlert = (
  id: number,
  options?: UseQueryOptions<IIncidentAlert[], AxiosError>
) => {
  return useQuery<IIncidentAlert[], AxiosError>(
    ["incidentAlert", id],
    async () => {
      const { data } = await request.get(
        `/lari/incidents/${id}/relates/alerts`
      );
      return data;
    },
    {
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};

export const useIncidentCount = (
  options?: UseQueryOptions<IIncidentCount, AxiosError>
) => {
  return useQuery<IIncidentCount, AxiosError>(
    "incidentCount",
    async () => {
      const { data } = await request.get("/lari/incidents/counts");
      return data;
    },
    {
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};

export const useIncidentAction = (
  id: number,
  options?: UseQueryOptions<IIncidentAction[], AxiosError>
) => {
  return useQuery<IIncidentAction[], AxiosError>(
    ["incidentAction", id],
    async () => {
      const { data } = await request.get(`/lari/incidents/${id}/actions`);
      return data;
    },
    {
      cacheTime: 5 * 1000,
      ...options,
    }
  );
};
