import { FiMoreVertical } from "react-icons/fi";
import type { Row } from "react-table";
import { Button, Flex, Section, Table } from "@nexcloud/nc-ui";
import styled, { x } from "@xstyled/emotion";

import {
  Dropdown,
  DropdownMenu,
  //   DropdownMenuItem,
} from "components/common/Dropdown";
import DynamicFilter from "components/DynamicFilter";

import screenshot from "assets/screenshot.jpeg";

interface IMetricItem {
  thumbnail: any;
  title: string;
  description: string;
  updatedAt: string;
  id: number;
}

const Menu = styled.div`
  .menuIcon {
    font-size: 16px;
    color: #909aae;
    cursor: pointer;
  }
`;
const ThumbnailBox = styled.div`
  width: 100px;
`;
const ThumbnailImg = styled.img`
  width: 100%;
`;
ThumbnailImg.defaultProps = { src: screenshot };
const ButtonCell: React.FC<{
  value: number;
  row: Row<IMetricItem>;
}> = ({ value, row }) => {
  return (
    <Flex>
      <Button variant="primary" layoutVariant="outlined">
        EDIT
      </Button>
      <x.div w="10px"></x.div>
      <Button variant="primary" layoutVariant="outlined">
        USE
      </Button>
    </Flex>
  );
};

const ThumbnailCell: React.FC<{
  value: number;
  row: Row<IMetricItem>;
}> = ({ value, row }) => {
  return (
    <ThumbnailBox>
      <ThumbnailImg />
    </ThumbnailBox>
  );
};

const MenuCell: React.FC<{
  value: number;
  row: Row<IMetricItem>;
}> = ({ value, row }) => {
  return (
    <Menu>
      <Dropdown
        placement="left-start"
        trigger="click"
        render={(attrs) => {
          return <DropdownMenu></DropdownMenu>;
        }}
      >
        <FiMoreVertical className="menuIcon" />
      </Dropdown>
    </Menu>
  );
};
const ImageTab = () => {
  return (
    <>
      <Section>
        <DynamicFilter
        // onChange={(filters) => {
        //   setFilters(filters);
        // }}
        />
      </Section>
      <Section>
        <Table columns={snapShotColumns} data={mockSnapShots} />
      </Section>
    </>
  );
};
const mockSnapShots = [
  {
    thumbnail: "",
    title: "Fail to install Prometheus during provision #1",
    description: "It shows that installation failed due to timeout.",
    updatedAt: "2021-07-06 02:31:23",
    id: 1,
  },
  {
    thumbnail: "",
    title: "Fail to install Prometheus during provision #2",
    description: "It shows that installation failed due to timeout.",
    updatedAt: "2021-07-06 02:29:51",
    id: 2,
  },
];

const snapShotColumns = [
  {
    Header: "Thumbnail",
    accessor: "thumbnail",
    disableSortBy: true,
    Cell: ThumbnailCell,
  },
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Updated At",
    accessor: "updatedAt",
  },
  {
    Header: "",
    accessor: "id",
    disableSortBy: true,
    Cell: ButtonCell,
  },
  {
    Header: "",
    accessor: "id",
    Cell: MenuCell,
    id: "popupMenu",
    disableSortBy: true,
  },
];
export default ImageTab;
