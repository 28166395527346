import React, { useState } from "react";
import { ExportOutlined } from "@ant-design/icons";
import styled from "@emotion/styled/macro";
import {
  Col,
  message,
  Modal,
  Radio as AntRadio,
  Row,
  Tag,
  Tooltip as AntTooltip,
} from "antd";
import type { ValuesType } from "utility-types";

import { useZoneGet } from "hooks/state/get";
import useZoneActions from "hooks/state/useZoneActions";
import useInterval from "hooks/util/useInterval";
import { authCheck } from "api/common";

import MonitoringIcon from "./MonitoringIcon";

import { device } from "style/device";

const P8SButtonBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
`;

const P8SButton = styled.div`
  width: 30%;
  padding: 15px 10px;
  border: 1px solid #dddddd;
  margin-right: 10px;
  margin-bottom: 10px;
  height: 79px;
  transition: all 0.2s;

  &:hover {
    background-color: #f2f2f2;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`;

const EmptyContent = styled.div`
  font-size: 1em;
  opacity: 0.5;
  margin: 15px 0;
`;

const prometheusButtonList = [
  {
    id: "prometheus",
    title: "Prometheus",
  },
  {
    id: "alertManager",
    title: "Alertmanager",
  },
  {
    id: "grafana",
    title: "Grafana",
  },
  {
    id: "pushGateway",
    title: "PushGateway",
  },
  {
    id: "promlens",
    title: "PromLens",
  },
  {
    id: "metricArk",
    title: "MetricARK",
  },
  {
    id: "metricArk_api",
    title: "MetricARK_API",
  },
] as const;

type P8sButtonsType = ValuesType<typeof prometheusButtonList>;

const ACTIVE_EXPOSE_CHECK_INTERVAL = 3 * 1000;
const IDLE_EXPOSE_CHECK_INTERVAL = 10 * 1000;

const TooltipWrapper = styled.a`
  padding: 3px;
  svg {
    font-size: 1.2em;
  }
`;

const Tooltip: React.FC<{ title: string; to: string }> = ({ to, title }) => {
  return (
    <TooltipWrapper
      target="_blank"
      rel="noopener noreferrer"
      className="tooltip"
      href={to}
    >
      <AntTooltip title={`to ${title}`}>
        <ExportOutlined />
      </AntTooltip>
    </TooltipWrapper>
  );
};

const P8sButtons: React.FC<{ clusterId?: number }> = ({ clusterId }) => {
  const p8sServices = useZoneGet("p8sServices");
  const zoneActions = useZoneActions();

  const [showingModal, showModal] = useState<P8sButtonsType["title"] | null>(
    null
  );
  const [exposedCheckInterval, setExposedCheckInterval] = useState<number>(
    IDLE_EXPOSE_CHECK_INTERVAL
  );

  const [exposeServiceType, setExposeServiceType] = useState<
    "ClusterIP" | "NodePort" | "LoadBalancer"
  >("ClusterIP");

  useInterval(
    (clusterId) => {
      if (clusterId) {
        zoneActions.onGetP8SServices(clusterId);
      }

      if (!p8sServices) {
        return;
      }

      const changing = p8sServices.filter(
        (service) => service.get("status") === "EXPOSE_CHANGING"
      );

      if (changing.size === 0) {
        setExposedCheckInterval(IDLE_EXPOSE_CHECK_INTERVAL);
      }
    },
    exposedCheckInterval,
    true,
    clusterId
  );

  if (!clusterId) {
    return <EmptyContent />;
  }

  return (
    <>
      <P8SButtonBox>
        {prometheusButtonList.map((p) => {
          const p8sService = p8sServices
            ?.filter(
              (service) => service.get("serviceName") === p.id.toUpperCase()
            )
            .get(0);
          const linkUrlData = p8sService?.get("linkUrl");
          const status = p8sService?.get("status");
          const serviceType = p8sService?.get("serviceType");
          const isChanging = status === "EXPOSE_CHANGING";

          let links = null;
          if (!isChanging && linkUrlData) {
            try {
              const json = JSON.parse(linkUrlData);
              const isMetricArkApi =
                p8sService?.get("serviceName").toUpperCase() ===
                "METRICARK_API";

              links = Object.keys(json).map((k) => (
                <Tooltip
                  key={k}
                  title={k}
                  to={`http://${json[k]}${
                    isMetricArkApi ? "/api/swagger-ui.html" : ""
                  }`}
                />
              ));
            } catch (e) {}
          }

          const title = (
            <MonitoringIcon
              id={p.id}
              type="text"
              styles={{ height: "20px", marginBottom: "5px" }}
            >
              {links}
            </MonitoringIcon>
          );

          return (
            <P8SButton
              key={p.id}
              onClick={(e) => {
                // @ts-ignore
                if (e.target.closest(".tooltip")) {
                  return;
                }

                if (isChanging) {
                  return;
                }
                if (serviceType) {
                  setExposeServiceType(serviceType);
                } else {
                  setExposeServiceType("ClusterIP");
                }
                showModal(p.title);
              }}
            >
              {title}
              <div>
                {isChanging ? (
                  <Tag color="#faad14">Changing...</Tag>
                ) : serviceType ? (
                  <Tag color="#096dd9">{serviceType}</Tag>
                ) : null}
              </div>
            </P8SButton>
          );
        })}
      </P8SButtonBox>
      <ExposeModal
        clusterId={clusterId}
        showingModal={showingModal}
        exposeServiceType={exposeServiceType}
        setExposeServiceType={setExposeServiceType}
        onSuccess={() => {
          zoneActions.onGetP8SServices(clusterId);
          setExposedCheckInterval(ACTIVE_EXPOSE_CHECK_INTERVAL);
        }}
        onCancel={() => {
          showModal(null);
        }}
      />
    </>
  );
};

const StyledAntRadio = styled(AntRadio)`
  display: block;
  height: 30px;
  line-height: 30px;
`;

const RadioButton: React.FC<{ value: string }> = ({ value }) => {
  return <StyledAntRadio value={value}>{value}</StyledAntRadio>;
};

interface IExposeModal {
  clusterId: number;
  showingModal: string | null;
  exposeServiceType: any;
  setExposeServiceType: any;
  onCancel(): void;
  onSuccess(): void;
}

export const ExposeModal: React.FC<IExposeModal> = ({
  clusterId,
  showingModal,
  exposeServiceType,
  setExposeServiceType,
  onCancel,
  onSuccess,
}) => {
  const handleApply = async () => {
    try {
      if (!clusterId) {
        return;
      }
      await authCheck({
        url: `/lari/clusters/${clusterId}/expose`,
        method: "PUT",
        data: {
          serviceName: showingModal?.toUpperCase(),
          serviceType: exposeServiceType,
        },
      });
      onSuccess();
    } catch (e) {
      message.error("Service expose failed");
    } finally {
      onCancel();
    }
  };

  return (
    <Modal
      okText="Apply"
      centered={true}
      title={showingModal}
      visible={showingModal !== null}
      onCancel={onCancel}
      onOk={handleApply}
    >
      <Row>
        <Col span={8}>Current Service Type</Col>
        <Col span={12}>{exposeServiceType}</Col>
      </Row>
      <br />
      <Row>
        <Col span={8}>Change Service Type</Col>
        <Col span={12}>
          <AntRadio.Group
            onChange={(e) => {
              setExposeServiceType(e.target.value);
            }}
            value={exposeServiceType}
          >
            <RadioButton value="ClusterIP" />
            <RadioButton value="NodePort" />
            <RadioButton value="LoadBalancer" />
          </AntRadio.Group>
        </Col>
      </Row>
    </Modal>
  );
};

export default P8sButtons;
