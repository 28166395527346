import { useCallback } from "react";
import { useDispatch } from "react-redux";

import * as StatusActions from "store/redux/status";
import { ISetData } from "store/redux/status";

export default function useStatusActions() {
  const dispatch = useDispatch();

  // const onOpenMessage = useCallback(
  //   (param: IOpenMessage) => dispatch(StatusActions.openMessage(param)),
  //   [dispatch]
  // );

  // const onCloseMessage = useCallback(
  //   () => dispatch(StatusActions.closeMessage()),
  //   [dispatch]
  // );

  const onSetData = useCallback(
    (param: ISetData) => dispatch(StatusActions.setData(param)),
    [dispatch]
  );

  const onCollapseMobileMenu = useCallback(
    () => dispatch(StatusActions.collapseMobileMenu()),
    [dispatch]
  );

  return { onCollapseMobileMenu, onSetData };
}
