import React from "react";
import styled from "@emotion/styled";
import { LinkButton, UserBadge } from "@nexcloud/nc-ui";

const WhiteBoardSidebarWrapper = styled.div`
  width: 213px;

  .non-user-content * {
    margin-top: 9px;
  }

  .non-user-content {
    margin-left: 35px;
  }

  .incident-date {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: #4d4d4d;
    margin-top: 5px;
    margin-bottom: 15px;
  }
`;

const WhiteboardItemSidebar: React.FC<{
  incidentDate: string;
  avatar: string;
  userFullName: string;
  role: string;
  editable?: boolean;
  unpinable?: boolean;
}> = ({ incidentDate, avatar, userFullName, role, editable, unpinable }) => {
  return (
    <WhiteBoardSidebarWrapper>
      <UserBadge avatar={avatar} name={userFullName} role={role} isSmall />
      <section className="non-user-content">
        <p className="incident-date">{incidentDate}</p>
        {editable && <LinkButton to="/">EDIT</LinkButton>}
        {unpinable && <LinkButton to="/">UNPIN</LinkButton>}
      </section>
    </WhiteBoardSidebarWrapper>
  );
};

export default WhiteboardItemSidebar;
