import React from "react";
import styled from "@emotion/styled/macro";

import CreatePrometheusForm from "./CreatePrometheusForm";

const Container = styled.div`
  width: 100%;
`;

const Title = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 20px;
`;

const FormBox = styled.div``;

interface ICreatePrometheus {
  onCancel: () => any;
}

function CreatePrometheus({ onCancel }: ICreatePrometheus) {
  return (
    <Container>
      <Title>Prometheus</Title>
      <FormBox>
        <CreatePrometheusForm onCancel={onCancel} />
      </FormBox>
    </Container>
  );
}

export default CreatePrometheus;
