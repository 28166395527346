import type React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { DraggableSyntheticListeners } from "@dnd-kit/core";
import styled, { x } from "@xstyled/emotion";

import { Flex, SpaceBetween } from "../Flex";

import { Items } from "./types";

type ISortableCard = React.FC<{
  containerId: string | undefined;
  itemId: string | undefined;
  items: Items;
  listeners: DraggableSyntheticListeners;
  setItems: React.Dispatch<React.SetStateAction<Items>>;
}>;

const Action = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #6b7280;
`;

const SortableCardAction: ISortableCard = ({
  containerId,
  itemId,
  items,
  setItems,
}) => {
  return (
    <Action
      onClick={() => {
        if (!itemId) {
          return;
        }
        if (containerId === "left") {
          setItems({
            right: items.right.concat(itemId),
            left: items.left.filter(
              (existingItemId) => existingItemId !== itemId
            ),
          });
        } else if (containerId === "right") {
          setItems({
            left: items.left.concat(itemId),
            right: items.right.filter(
              (existingItemId) => existingItemId !== itemId
            ),
          });
        }
      }}
    >
      {containerId === "left" ? "+" : "x"}
    </Action>
  );
};

const SortableCard: ISortableCard = ({ children, ...props }) => {
  return (
    <SpaceBetween w="100%">
      <Flex>
        <Action {...props.listeners}>
          <x.div color="#9ca3af" hoverCursor="grab">
            <BsThreeDotsVertical />
          </x.div>
        </Action>
        {children}
      </Flex>
      <SortableCardAction {...props} />
    </SpaceBetween>
  );
};

export default SortableCard;
