import CopyToClipboard from "react-copy-to-clipboard";
import { AiOutlineCopy } from "react-icons/ai";
import { x } from "@xstyled/emotion";

interface ICopyID {
  title?: string;
  id: string;
  type?: "alert" | "incident";
  menu?: boolean;
}

const CopyID: React.FC<ICopyID> = ({ id, type, title, menu }) => {
  return (
    <x.div display="flex" justifyContent={menu ? "space-between" : "start"}>
      <x.p
        color={menu ? "#4D4D4D" : "#1F29CA"}
        fontFamily="poppins"
        mr="15px"
        lineHeight="21px"
      >
        {type === "alert"
          ? `ALERT-${id}`
          : menu
          ? `${title}-${id}`
          : `INC-${id}`}
      </x.p>
      <x.div onClick={(e) => e.preventDefault()}>
        <CopyToClipboard
          text={
            type === "incident"
              ? `${window.location.host}/incident/${id}`
              : `${window.location.host}/alerts/${id}`
          }
          onCopy={() => window.alert("copied")}
        >
          <x.div color="#6B7280" cursor="pointer">
            <AiOutlineCopy />
          </x.div>
        </CopyToClipboard>
      </x.div>
    </x.div>
  );
};

export default CopyID;
