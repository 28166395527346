import React from "react";
import styled from "@emotion/styled/macro";
import { x } from "@xstyled/emotion";
import { Tooltip } from "antd";

import ClusterStatusIcon from "components/Cluster/ClusterStatusIcon";
import BorderBox from "components/common/BorderBox";
import PlatformIcon from "components/PlatformIcon";

import type { IGlobalView, IZone } from "store/redux/zone";

import MonitoringIcon from "../MonitoringIcon";

import { emptyClusterText } from "const";
import { usePath } from "paths";

import { device } from "style/device";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Card = styled(BorderBox)`
  width: 228px;
  height: 120px;
  margin-right: 10px;
  margin-bottom: 15px;
  background-color: #fff;
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media ${device.laptop} {
    width: 32%;
    margin-right: 1%;
  }

  @media ${device.mobileL} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const CardTitle = styled.div`
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconBox = styled.div`
  margin-left: 5px;
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
`;

const MonitoringButton = styled.div`
  width: 25px;
  height: 25px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
`;

const Empty = styled.div`
  width: 100%;
  padding: 25px 0px;
  font-size: 1.1em;
  opacity: 0.5;
`;

interface ICardClustersProps {
  data: Array<IZone | IGlobalView>;
}

function CardClusters({ data }: ICardClustersProps) {
  const moveToConfig = usePath((paths) => paths.cluster.config);
  const moveToDetail = usePath((paths) => paths.cluster.detail);

  if (!data || data.length <= 0) {
    return <Empty>{emptyClusterText}</Empty>;
  }

  const handlePrometheus = (e: any, cluster: IZone) => {
    e.stopPropagation();
    if (cluster.initStatus === "NOT_INSTALLED") {
      moveToConfig(cluster.id);
    } else {
      moveToDetail(cluster.id);
    }
  };

  return (
    <Container>
      {data.map((d) => {
        if (d.clusterType === "GLOBAL_PRIMARY") {
          return (
            <Card key={d.id}>
              <CardTitle>
                <Tooltip
                  title="Global-view is internal managed service.
            Detail view is not available"
                >
                  <x.span color="GrayText">{d.name}</x.span>
                </Tooltip>
                <x.div display="flex" alignItems="center">
                  <IconBox>
                    <PlatformIcon id={d.platform} type="icon" />
                  </IconBox>
                  <ClusterStatusIcon
                    status={d.agentStatus}
                    styles={{ marginLeft: "5px", fontSize: "1em" }}
                  />
                </x.div>
              </CardTitle>
            </Card>
          );
        }
        return (
          <Card key={d.id} onClick={(e) => handlePrometheus(e, d as IZone)}>
            <CardTitle>
              <Tooltip title={d.name}>
                <x.div>{d.name.slice(0, 20)}</x.div>
              </Tooltip>
              <x.div display="flex" alignItems="center">
                <IconBox>
                  <PlatformIcon id={d.platform} type="icon" />
                </IconBox>
                <ClusterStatusIcon
                  status={d.agentStatus}
                  styles={{ marginLeft: "5px", fontSize: "1em" }}
                />
              </x.div>
            </CardTitle>
            <ButtonBox>
              <MonitoringButton>
                <MonitoringIcon
                  type="icon"
                  id="prometheus"
                  styles={{ width: "100%" }}
                  disabled={d.initStatus !== "ACTIVE"}
                  onClick={(e: any) => handlePrometheus(e, d as IZone)}
                />
              </MonitoringButton>
            </ButtonBox>
          </Card>
        );
      })}
    </Container>
  );
}

export default CardClusters;
