import { FiBox, FiDatabase, FiGrid, FiLayers } from "react-icons/fi";
import { MdToys } from "react-icons/md";
import {
  Button,
  Column,
  Gauge,
  GaugeItem,
  Page,
  Section,
  TitleSection,
} from "@nexcloud/nc-ui";
import styled, { x } from "@xstyled/emotion";
import { fetchOption } from "config";

import useClusterList from "hooks/cluster/useClusterList";

import IsometricTopology from "../Topology/IsometricTopology";

import AlertSummary from "./AlertSummary";
import ClusterDashboardItem from "./ClusterDashboardItem";

const ClusterList = () => {
  const { data } = useClusterList(fetchOption);

  return (
    <>
      <div>
        <Header>
          <Column columnsGap="15px">
            <Gauge title="Clusters" icon={<FiBox />}>
              <GaugeItem value={0} unit="cluster" unitPlural="Up" />
            </Gauge>
            <Gauge title="Nodes" icon={<FiLayers />}>
              <GaugeItem value={0} unit="cluster" unitPlural="Up" />
            </Gauge>
            <Gauge title="Pods" icon={<FiGrid />}>
              <GaugeItem value={0} unit="cluster" unitPlural="Up" />
            </Gauge>
            <Gauge title="Services" icon={<MdToys />}>
              <GaugeItem value={0} unit="cluster" unitPlural="Up" />
            </Gauge>
            <Gauge title="Databases" icon={<FiDatabase />}>
              <GaugeItem value={0} unit="cluster" unitPlural="Up" />
            </Gauge>
          </Column>
        </Header>

        <TopologyWrapper>
          <IsometricTopology />
          <EditButtonGroup>
            <Button
              layoutVariant="contained"
              variant="primary"
              icon="plus"
              size="tiny"
              to="/clusters/add"
              iconRight
            >
              ADD CLUSTER
            </Button>
          </EditButtonGroup>
        </TopologyWrapper>
      </div>
      <x.div mt="-90px">
        <Page>
          <AlertSummaryWrapper>
            <AlertSummary />
          </AlertSummaryWrapper>

          <TitleSection>
            <h2>Clusters</h2>
          </TitleSection>

          <Section>
            {data?.map((cluster: any) => {
              return <ClusterDashboardItem cluster={cluster} />;
            })}
          </Section>
        </Page>
      </x.div>
    </>
  );
};

const Header = styled.div`
  position: absolute;
  width: 100%;
  top: 80px;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
`;

const EditButtonGroup = styled.div`
  position: absolute;
  bottom: 100px;
  right: 50px;
  z-index: 1000;
`;

const TopologyWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`;

const AlertSummaryWrapper = styled.div`
  width: 100%;
  display: block;
  z-index: 1000;
  position: relative;
`;

export default ClusterList;
