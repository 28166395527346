import React, { useRef, useState } from "react";
import styled from "@emotion/styled/macro";
import { Button, SpaceBetween } from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";
import { Input, message, Switch, Tooltip } from "antd";

import { IInstallParams } from "store/redux/create";
import { useCreateGet } from "hooks/state/get";
import useCreateActions from "hooks/state/useCreateActions";

import CreateAdvancedCluster from "./CreateAdvancedCluster";

import { device } from "style/device";

const Container = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
`;

const Title = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Descrip = styled.div`
  font-weight: 100;
`;

const InputBox = styled.div`
  width: 45%;

  @media ${device.mobileL} {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const DescBox = styled.div`
  width: 53%;

  @media ${device.mobileL} {
    width: 100%;
  }
`;

const Label = styled.div`
  margin-bottom: 10px;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;

  @media ${device.mobileL} {
    flex-wrap: wrap;
  }
`;

const ErrorMsg = styled.div`
  color: #ff4d4f;
`;

interface ICreateClusterProps {
  onNext: (index: number) => void;
  onBack: (index: number) => void;
  index: number;
}

function CreateCluster({ onBack, onNext, index }: ICreateClusterProps) {
  const inputRef = useRef<Input | null>(null);
  const descRef = useRef<Input | null>(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [advanced, setAdvanced] = useState(false);

  const loading = useCreateGet("loading");
  const clusterName = useCreateGet("zoneName");
  const description = useCreateGet("description");
  const platform = useCreateGet("platform");

  const createActions = useCreateActions();

  const onClickNext = (installParams?: IInstallParams) => {
    if (!inputRef.current) {
      return;
    }
    const value = inputRef.current.input.value.trim();

    if (!value || errorMsg) {
      message.warning("Please check cluster name");
      return;
    }

    createActions.onValidateClusterName({
      clusterName: value,
      callback: (validate: boolean) => {
        if (!validate) {
          message.error(
            "There is already existing cluster name. Please enter different name."
          );
          return;
        }

        if (!inputRef.current || !descRef.current) {
          return;
        }

        const value = inputRef.current.input.value.trim();
        const descValue = descRef.current.input.value.trim();

        createActions.onSetData({ key: "zoneName", value });
        createActions.onSetData({ key: "description", value: descValue });

        if (installParams && !installParams.grafana_pwd.trim()) {
          installParams.grafana_pwd = "admin";
        }

        createActions.onCreateCluster({
          installParams,
          callback: () => {
            onNext(index);
          },
        });
      },
    });
  };

  const checkEnterKeyEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !advanced) {
      onClickNext();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value && !/^[a-z0-9_-]+$/gi.test(value)) {
      setErrorMsg("Name must be alphanumeric");
    } else {
      setErrorMsg("");
    }
  };

  return (
    <Container>
      <TitleWrapper>
        <Title>Mode select {"&"} configuration</Title>
      </TitleWrapper>
      <Descrip>
        Default setup for Prometheus, Grafana, Alertmanager, Push Gateway, and
        MetricArk will be installed in your Cluster.
        <br />
        If you'd like to install packages above respectively, Click{" "}
        <b>"Advanced Mode"</b>.
      </Descrip>
      <InputWrapper>
        <InputBox>
          <Label>Cluster Name</Label>
          <Input
            ref={inputRef}
            onKeyDown={checkEnterKeyEvent}
            defaultValue={clusterName}
            placeholder="Cluster name"
            onChange={handleChange}
            maxLength={30}
          />
          {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
        </InputBox>
        <DescBox>
          <Label>Description</Label>
          <Input
            ref={descRef}
            onKeyDown={checkEnterKeyEvent}
            placeholder="Description"
            defaultValue={description}
          />
        </DescBox>
      </InputWrapper>
      <SpaceBetween>
        <Tooltip title="toggle to change the value you want to provision.">
          <Switch
            checked={advanced}
            onChange={(value) => {
              setAdvanced(value);
            }}
          />
          <x.span ml="7px" color="#717171" fontSize="0.9rem">
            Advanced Mode
          </x.span>
        </Tooltip>
        {!advanced && (
          <x.div display="flex">
            <Button onClick={() => onBack(index)} loading={loading}>
              Back
            </Button>
            <Button
              variant="primary"
              onClick={() => onClickNext()}
              loading={loading}
            >
              Next
            </Button>
          </x.div>
        )}
      </SpaceBetween>
      {advanced && (
        <CreateAdvancedCluster
          onClickNext={onClickNext}
          onClickBack={() => onBack(index)}
          platform={platform}
        />
      )}
    </Container>
  );
}

export default CreateCluster;
