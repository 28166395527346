import FormInput from "./FormInput";

interface IClusterNameInput {
  label?: string;
  placeholder?: string;
}

const ClusterNameInput: React.FC<IClusterNameInput> = (props) => {
  return (
    <FormInput
      label={props.label || "CLUSTER NAME"}
      placeholder={props.placeholder || "Cluser name"}
      type="text"
      {...props}
      onChange={(e) => {
        const value = e.target.value || "";

        e.target.value = value
          .replace(/[^a-z0-9_-]/g, "-")
          .replace(/-{2,}/g, "-")
          .toLocaleLowerCase();
      }}
    />
  );
};

export default ClusterNameInput;
