import React, { useState } from "react";
import { Flex } from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";
import { Input, message, Modal, Select, Tag } from "antd";

import {
  IMemberSearchResult,
  useTenancyMembersSearch,
  useUserGroups,
} from "hooks/user";
import { getTenancyId, request } from "api/common";

export const AddAdminModal: React.FC<{
  showModal: boolean;
  onClose(): void;
}> = ({ showModal, onClose }) => {
  return (
    <Modal
      title="Add Admins"
      centered
      visible={showModal}
      onCancel={() => {
        onClose();
      }}
      footer={null}
      closable
      width={1000}
    >
      <AddAdmin />
    </Modal>
  );
};

export const AddAdminGroupModal: React.FC<{
  showModal: boolean;
  onClose(): void;
  adminGroups: any;
  onUpdate(): void;
}> = ({ showModal, onClose, onUpdate, adminGroups }) => {
  const { data: userGroups } = useUserGroups();

  return (
    <Modal
      title="Add Admin Groups"
      centered
      visible={showModal}
      onCancel={() => {
        onClose();
      }}
      footer={null}
      closable
      width={1000}
    >
      {userGroups
        ? userGroups.map((group, i) => {
            return (
              <x.div>
                <Flex py="5px" key={i}>
                  <x.div w="20%">{group.name}</x.div>
                  <x.div justifySelf="flex-end" ml="auto">
                    <AdminAddButton
                      id={group.id}
                      type="group"
                      onUpdate={onUpdate}
                    />
                  </x.div>
                </Flex>
              </x.div>
            );
          })
        : null}
    </Modal>
  );
};

const AddAdmin: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchType, setSearchType] = useState<"username" | "email">(
    "username"
  );
  const { data: searchResult } = useTenancyMembersSearch(
    searchQuery ? searchQuery : null,
    searchType
  );

  return (
    <Flex>
      <x.div w="60%">
        <x.div pb="20px">You can search for users by username or email.</x.div>
        <Input.Group compact>
          <Select
            onChange={(value) => {
              setSearchType(value);
            }}
            value={searchType}
          >
            <Select.Option value="username">username</Select.Option>
            <Select.Option value="email">email</Select.Option>
          </Select>
          {searchType === "username" ? (
            <Input
              style={{ width: "70%" }}
              placeholder="Search by username"
              onChange={(p) => {
                setSearchQuery(p.target.value);
              }}
              value={searchQuery}
              allowClear
            />
          ) : (
            <Input.Search
              style={{ width: "70%" }}
              placeholder="Search by email (exact)"
              onSearch={(query) => {
                setSearchQuery(query);
              }}
              allowClear
            />
          )}
        </Input.Group>
        <x.div py="20px">
          <MemberSearchResult
            searchResult={searchResult}
            searchQuery={searchQuery}
            onUpdate={() => {}}
          />
        </x.div>
      </x.div>
    </Flex>
  );
};

const MemberSearchResult: React.FC<{
  searchResult: IMemberSearchResult | undefined;
  searchQuery: string;
  onUpdate(): void;
}> = ({ searchResult, onUpdate, searchQuery }) => {
  const members = searchResult?.content;

  if (!members) {
    return null;
  }

  return (
    <x.div>
      {members.map(({ user }, i) => (
        <Flex py="5px" key={i}>
          <x.div w="20%">{user.name}</x.div>
          <x.div w="50%">{user.email}</x.div>
          <x.div justifySelf="flex-end" ml="auto">
            <AdminAddButton id={user.id} type="user" onUpdate={onUpdate} />
          </x.div>
        </Flex>
      ))}
    </x.div>
  );
};

const AdminAddButton: React.FC<{
  id: number;
  type: "user" | "group";
  onUpdate(): void;
}> = ({ id, type, onUpdate }) => {
  const [added, setAdded] = useState(false);
  const isUser = type === "user";
  if (added) {
    return <>Added</>;
  }

  return (
    <Tag
      onClick={async () => {
        await request.post(
          `/users/tenancies/${getTenancyId()}/maintainers`,
          isUser
            ? {
                userIds: [id],
              }
            : {
                groupIds: [id],
              }
        );
        setAdded(true);
        onUpdate();
        message.success(`Added ${isUser ? "user" : "group"} to admin`);
      }}
      style={{ cursor: "pointer" }}
    >
      Add {isUser ? "User" : "Group"}
    </Tag>
  );
};
