import React from "react";
import { Flex } from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";
import { message } from "antd";

import {
  Dropdown,
  DropdownMenu,
  SmallDropdownMenuItem,
} from "components/common/Dropdown";

import { GroupUser } from "hooks/user";
import { request } from "api/common";

export const Member: React.FC<{
  member: GroupUser;
  groupId: number;
  onUpdate(): void;
}> = ({ member, groupId, onUpdate }) => {
  return (
    <Flex>
      <Circle />
      <x.div flex="0.5" pl="10px">
        {member.userName}
      </x.div>
      <x.div flex="0.2">
        <Dropdown
          trigger="click"
          render={(attrs) => {
            return (
              <DropdownMenu>
                <SmallDropdownMenuItem
                  onClick={async () => {
                    if (member.admin) {
                      return;
                    }
                    await request.put(`/users/groups/${groupId}/members`, [
                      {
                        admin: true,
                        userId: member.userId,
                      },
                    ]);
                    onUpdate();
                    message.success("Successfully set user permission");
                  }}
                >
                  {member.admin && "v"} Admin
                </SmallDropdownMenuItem>
                <SmallDropdownMenuItem
                  onClick={async () => {
                    if (!member.admin) {
                      return;
                    }
                    await request.put(`/users/groups/${groupId}/members`, [
                      {
                        admin: false,
                        userId: member.userId,
                      },
                    ]);
                    onUpdate();
                    message.success("Successfully set user permission");
                  }}
                >
                  {!member.admin && "v"} Member
                </SmallDropdownMenuItem>
              </DropdownMenu>
            );
          }}
        >
          {member.admin ? "Admin" : "Member"}
        </Dropdown>
      </x.div>
      <x.div
        cursor="pointer"
        onClick={async () => {
          const confirmed = window.confirm(
            "Are you sure you want to remove this user from DBA Group?"
          );
          if (!confirmed) {
            return;
          }
          await request.delete(`/users/groups/${groupId}/members`, {
            data: [member.userId],
          });
          message.success("Successfully deleted user");
        }}
      >
        x
      </x.div>
    </Flex>
  );
};

export const Circle: React.FC<{ size?: string }> = ({
  size = "20px",
  children,
}) => (
  <x.div backgroundColor="gray-200" borderRadius="100%" w={size} h={size}>
    {children}
  </x.div>
);
