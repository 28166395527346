import { ReactElement } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  HiOutlineCheckCircle,
  HiOutlinePlusCircle,
  HiOutlineRefresh,
} from "react-icons/hi";
import styled from "@emotion/styled";
import { x } from "@xstyled/emotion";

interface IStatusBadge {
  status: "OPEN" | "IN_PROGRESS" | "RESOLVED" | "CLOSED";
  onClick?(): void;
}

const handleColor = (status: string): string => {
  switch (status) {
    case "OPEN":
      return "#909AAE";
    case "IN_PROGRESS":
      return "#F6CF43";
    case "RESOLVED":
      return "#68D057";
    case "CLOSED":
      return "#565C68";
    default:
      return "none";
  }
};

const handleIcon = (status: string): ReactElement => {
  switch (status) {
    case "OPEN":
      return <HiOutlinePlusCircle />;
    case "IN_PROGRESS":
      return <HiOutlineRefresh />;
    case "RESOLVED":
      return <HiOutlineCheckCircle />;
    case "CLOSED":
      return <AiOutlineCloseCircle />;
    default:
      return <HiOutlinePlusCircle />;
  }
};

const Wrapper = styled.div<IStatusBadge>`
  display: flex;
  align-items: center;
  color: ${(props) => props.status && handleColor(props.status)};
`;

export const StatusBadge: React.FC<IStatusBadge> = ({ status }) => {
  return (
    <Wrapper status={status}>
      {handleIcon(status)}
      <x.p fontFamily="poppins" ml="10px">
        {status}
      </x.p>
    </Wrapper>
  );
};

export default StatusBadge;
