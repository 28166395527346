import React from "react";
import styled from "@emotion/styled/macro";
import { Layout as AntLayout } from "antd";

import Footer from "./Footer";
import Header from "./Header";

import { size } from "style/device";

const Content = styled(AntLayout.Content)`
  background-color: #fff;
  margin: 0 0;
  overflow: auto;
  padding: 0px 0px 0px 0px;
  width: 100%;
`;

const Container = styled.section`
  min-width: ${size.mobileM};
  min-height: 100vh;
  flex-direction: column;
`;
Container.defaultProps = { className: "ant-layout" };

const Layout: React.FC<{ disabled?: boolean }> = ({
  children,
  disabled = false,
}) => {
  return (
    <Container>
      <Header disabled />
      <Content>{children}</Content>

      <Footer disabled />
    </Container>
  );
};

export default Layout;
