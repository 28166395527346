import styled from "@emotion/styled";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const VerticalCenter: React.FC = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default VerticalCenter;
