import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { Loading } from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";
import { Spin } from "antd";
import { useNotificationService } from "notification";

import ClusterList from "components/Cluster/ClusterList";
import OverallDashboard from "components/Cluster/OverallDashboard";
import Layout from "components/Layout";
import PrivateRoute from "components/PrivateRoute";
import AlertsPage from "pages/Alerts";
import Cluster from "pages/Cluster";
import ClustersPage from "pages/Clusters";
import DevPage from "pages/Dev";
import LoginSuccess from "pages/LoginSuccess";
import NotFoundPage from "pages/NotFoundPage";
import NotificationPage from "pages/NotificationPage";
import Settings from "pages/Settings";
import TempTenancyPage from "pages/TempTenancy";
import UnauthorizedView from "pages/UnauthorizedView";
import WelcomePage from "pages/WelcomePage";

import { useHasCluster } from "hooks/cluster";
import { useInitCurrentCluster } from "hooks/cluster/useClusterDetailQuery";
import { useMobileSize, useUpdateStateWithQueryParam } from "hooks/init";
import { useCurrentUser } from "hooks/user";
import useInterval from "hooks/util/useInterval";
import { getTenancyId } from "api/common";

import AccessGroup from "./AccessGroups/AccessGroup";
import AccessGroupUser from "./AccessGroupUsers/AccessGroupUser";
import Incident from "./Incident/Incident";

import { isProduction } from "utils/platform";
import paths from "paths";

const production = isProduction();
if (production) {
  ReactGA.initialize("UA-122075277-9");
}

function App() {
  const location = useLocation();
  const { data: user, isLoggedIn, isInit, showOnboarding } = useCurrentUser();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (production) {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }
  }, [location]);

  useInterval(() => {
    const tid = getTenancyId();
    const isReady = user?.currentTenancy !== undefined && !!tid;
    if (ready !== isReady) {
      setReady(isReady);
    }
    // FIXME: timer should be unset after check and reinstatiated after user get removed(logged out)
  }, 250);

  if (!isInit) {
    return <Loading />;
  }

  if (!isLoggedIn) {
    return <UnauthorizedView />;
  }

  if (showOnboarding) {
    return <TenancySetupView />;
  }

  if (!ready) {
    return <Loading />;
  }

  return <LoginView />;
}

const TenancySetupView = () => {
  return (
    <Router>
      <Switch>
        <Redirect from="/success" to="/login/success" exact />
        <Redirect from="/(login|join|reset|resetpassword)" to="/" exact />
        <Layout disabled>
          <Switch>
            <PrivateRoute authed={true} exact path="/welcome">
              <WelcomePage />
            </PrivateRoute>
            <Redirect from="/" to="/welcome" exact />
          </Switch>
        </Layout>
        <Route exact path="/404" component={NotFoundPage} />
        <Route exact path="/login/success" component={LoginSuccess} />
        <Route exact path={paths.tempTenancy()} component={TempTenancyPage} />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

const LoginView = () => {
  const hasCluster = useHasCluster();

  const login = true;

  const { initialized } = useInitCurrentCluster();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useMobileSize();
  useUpdateStateWithQueryParam();
  useNotificationService();

  return (
    <Router>
      <Switch>
        <Redirect from="/success" to="/login/success" exact />
        <Redirect from="/(login|join|reset|resetpassword)" to="/" exact />
        <Route exact path="/404" component={NotFoundPage} />
        <Route exact path="/login/success" component={LoginSuccess} />
        <Layout>
          {initialized ? (
            <Switch>
              <PrivateRoute path={`/`} authed={login} exact>
                <ClusterList />
              </PrivateRoute>
              <PrivateRoute authed={login} path="/clusters">
                <ClustersPage />
              </PrivateRoute>
              <PrivateRoute
                authed={login}
                exact
                path={paths.notification()}
                component={NotificationPage}
              />
              <PrivateRoute path="/alerts" authed={login}>
                <AlertsPage />
              </PrivateRoute>
              <PrivateRoute path="/incident" authed={login}>
                <Incident authed={login} />
              </PrivateRoute>
              <PrivateRoute path="/access-groups" authed={login}>
                <AccessGroup authed={login} />
              </PrivateRoute>
              <PrivateRoute path="/access-group-users/" authed={login}>
                <AccessGroupUser authed={login} />
              </PrivateRoute>
              <PrivateRoute path="/settings" authed={login}>
                <Settings authed={login} />
              </PrivateRoute>
            </Switch>
          ) : (
            <x.div textAlign="center" p="20px">
              <Spin />
            </x.div>
          )}
          {process.env.NODE_ENV === "development" && (
            <Switch>
              <PrivateRoute path="/dev" authed={login}>
                <DevPage />
              </PrivateRoute>
              <PrivateRoute authed={true} path="/welcome">
                <WelcomePage />
              </PrivateRoute>
              <PrivateRoute path="/cluster" authed={login}>
                <Cluster authed={login} hasCluster={hasCluster} />
              </PrivateRoute>
              <PrivateRoute path="/dev-clusters" authed={login}>
                <OverallDashboard />
              </PrivateRoute>
            </Switch>
          )}
        </Layout>
        <Redirect to="/404" />
      </Switch>
    </Router>
  );
};

export default App;
