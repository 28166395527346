import { useEffect, useState } from "react";
import { CaretDownOutlined, LogoutOutlined } from "@ant-design/icons";
import styled from "@emotion/styled/macro";
import { x } from "@xstyled/emotion";
import { Dropdown, Menu } from "antd";

import { useAccountGet } from "hooks/state/get";
import useAccountActions from "hooks/state/useAccountActions";
import { useCurrentUser } from "hooks/user";

import { device } from "style/device";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 500;

  @media ${device.mobileL} {
    bottom: 0;
  }
`;

const Account = styled.div`
  width: 24px;
  height: 24px;
  background: #1f29ca;
  border-radius: 3px;
  overflow: hidden;
  margin: 5px 0;

  img {
    width: 100%;
  }
`;

const IconBox = styled.div`
  width: 36px;
  height: 36px;
  font-size: 1.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  a {
    display: flex;
    width: 100%;
    color: inherit;
    align-items: center;
    justify-content: center;
  }
`;

const EmptyImg = styled.div`
  width: 100%;
  height: 100%;
  background-color: #1f29ca;
  color: #fff;
  font-size: 18px;
  line-height: 0.8;
  padding: 3px;
`;

const MenuItem = styled(Menu.Item)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ant-menu-vertical &.ant-menu-item {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0 8px;
  }
  a {
    color: #000000;
  }
  &.ant-menu-item > span[role="img"] {
    margin-right: 0;
  }
`;

const Caret = styled(CaretDownOutlined)`
  color: #fff;
  margin-left: 5px;
`;

const OverlayMenu = () => {
  // const googleAccount = useAccountGet("googleAccount");
  const accountActions = useAccountActions();

  return (
    <Menu
      selectable={false}
      style={{
        width: "100px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {/* <MenuItem>
        {googleAccount && <AccountTitle>Preferences</AccountTitle>}
      </MenuItem> */}
      <MenuItem
        onClick={(e) => {
          accountActions.onLogout();
        }}
      >
        Sign out
      </MenuItem>
      <LogoutOutlined />
    </Menu>
  );
};

function UserMenu() {
  const [imgUrl, setImgUrl] = useState<null | string>(null);

  const googleAccount = useAccountGet("googleAccount");

  const { data: user } = useCurrentUser();

  useEffect(() => {
    if (!googleAccount) {
      return;
    }
    setImgUrl(googleAccount.photo);
  }, [googleAccount]);

  return (
    <Container>
      <IconBox>
        <Dropdown overlay={<OverlayMenu />} trigger={["click"]}>
          <x.div display="flex" alignItems="center">
            <Account>
              {imgUrl && googleAccount ? (
                <img src={imgUrl} alt="account profile" />
              ) : (
                <EmptyImg>{user?.name?.[0]}</EmptyImg>
              )}
            </Account>
            <Caret />
          </x.div>
        </Dropdown>
      </IconBox>
    </Container>
  );
}

export default UserMenu;
