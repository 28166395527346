import Select, { components } from "react-select";
import { x } from "@xstyled/emotion";

interface ISelectorWithIcons {
  defaultValue: object;
  options: object[];
}
const { Option } = components;
const IconOption = (props: any) => (
  <Option {...props}>
    <x.div display="flex" alignItems="center">
      <x.div mr="10px">{props.data.icon}</x.div>
      {props.data.label}
    </x.div>
  </Option>
);
const customStyle = {
  control: () => ({
    marginTop: "8px",
    display: "flex",
    width: "100%",
    border: "1px solid #9ca3af",
    padding: "6px",
    borderRadius: "6px",
    background: "#fff",
    fontFamily: "poppins",
    fontSize: "14px",
    color: "#374151",
    lineHeight: "21px",
  }),
};
const SelectorWithIcons: React.FC<ISelectorWithIcons> = ({
  defaultValue,
  options,
}) => {
  return (
    <Select
      defaultValue={defaultValue}
      options={options}
      components={{ Option: IconOption }}
      styles={customStyle}
    />
  );
};

export default SelectorWithIcons;
