import styled from "@emotion/styled";
import { x } from "@xstyled/emotion";

interface IPlatformIcon {
  platformName?:
    | "KUBERNETES"
    | "BAREMETAL/VM"
    | "AWS"
    | "GOOGLE CLOUD"
    | "AZURE";
  platformIcon?: string;
  onClick?(): void;
  isSelected?: boolean;
  disabled?: boolean;
}

const Wrapper = styled.div<IPlatformIcon>`
  width: 150px;
  height: 160px;
  display: flex;
  border: 1px solid #6b7280;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: poppins;
  background: #ffffff;
  cursor: pointer;

  ${(props) =>
    props.isSelected &&
    `
  border: 3.5px solid #1F29CA;
  `}
  ${(props) =>
    props.disabled &&
    `
    opacity:0.35;
  `}
`;

const Img = styled.img`
  height: 80px;
  max-width: 90px;
  object-fit: contain;
  margin-bottom: 12px;
`;

const PlatformButton: React.FC<IPlatformIcon> = ({
  children,
  onClick,
  platformName,
  platformIcon,
  disabled,
  isSelected,
}) => {
  return (
    <Wrapper disabled={disabled} isSelected={isSelected} onClick={onClick}>
      <x.div display="flex" flexDirection="column" alignItems="center">
        <Img src={platformIcon} />
        <div>{platformName}</div>
        {disabled && <x.p>coming soon</x.p>}
      </x.div>
    </Wrapper>
  );
};

export default PlatformButton;
