import { Modal } from "antd";

export function showSuccessEmail() {
  Modal.success({
    title: "Success",
    content: "Validation check completed.",
  });
}

export function showEmailLinkCheck() {
  Modal.success({
    title: "Success",
    content: "Please check the verification mail we've just sent.",
  });
}

export function showEmailLinkError() {
  Modal.warning({
    title: "Warning",
    content: "Please check your email again.",
  });
}

export function showInvalidFormatMail() {
  Modal.warning({
    title: "Warning",
    content: "It does not fit the format.",
  });
}

export function showWrongPwd() {
  Modal.warning({
    title: "Warning",
    content: "Please check your password again",
  });
}
export function showTryAgain() {
  Modal.warning({
    title: "Warning",
    content: "Please try again",
  });
}

export function showInvalidEmail() {
  Modal.warning({
    title: "Warning",
    content: "Email Vefification error. Please check the email address again.",
  });
}

export function showPasswordResetSuccess() {
  Modal.success({
    title: "Success",
    content: "Your password has been reset.",
  });
}
