import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import styled from "@emotion/styled/macro";
import { Button, Link, Loading } from "@nexcloud/nc-ui";
import { x } from "@xstyled/emotion";
import { Form, Input, Modal } from "antd";

import {
  showEmailLinkCheck,
  showInvalidFormatMail,
  showSuccessEmail,
  showTryAgain,
} from "components/NotificationMessage";

import { prdCode, request } from "api/common";

import modal from "utils/modal";
import { passwordValid } from "utils/regExp";
import paths from "paths";

const { Search } = Input;
const required = (styledComponent: any) => styled(styledComponent)`
  &:after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
`;
const Label = styled.div`
  font-size: 0.9em;
  color: #808090;
  margin-bottom: 5px;
  * {
    font-size: 1em;
  }
`;
const RequireLabel = required(Label);

const JoinForm: React.FC<{ verificationCode: string | null }> = ({
  verificationCode,
}) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const onFinish = async (values: any) => {
    if (loading) {
      return <Loading />;
    }
    setLoading(true);

    const requestBody = {
      email: email,
      loginId: email,
      loginPw: password,
      name: username,
      nexcloudRedirectUrl: window.location.origin,
    } as any;

    if (verificationCode) {
      requestBody.verificationCode = verificationCode;
    }

    try {
      const response = await request.post(
        `/users/join/conventional/${prdCode}`,
        requestBody
      );
      const result = response.data;
      if (result === "SUCCESS") {
        showEmailLinkCheck();
        history.push(paths.login());
      } else if (result === "INVALID_EMAIL_FORMAT") {
        modal.warning("Email format is incorrect.");
      } else if (result === "INVALID_PW_FORMAT") {
        modal.warning(
          "The password does not match the suggested format. Password should contain 8-alphabet, 1-symbol, and 1-number."
        );
      } else if (result === "LOGIN_ID_DUPLICATED") {
        modal.warning("Email already exists.");
      } else if (result === "INVALID_VERIFICATION") {
        modal.warning("Verification code is incorrect.");
      } else if (result === "NOT_ALLOWED_JOIN_TYPE") {
        modal.warning("Join Type is not allowed");
      } else if (result === "INVITE_USER_NOT_EXIST") {
        modal.warning("Invite does not exist.");
      } else if (result === "ALREADY_ACTIVATED") {
        modal.warning("Your account is activated already.");
      } else if (result === "INVITE_SUCCESS") {
        modal.success(
          "You have successfully signed up to the invited organization. Please sign in with the same credential you have provided."
        );
        history.push(paths.login());
      }
    } catch (e) {
      if (e.response?.data?.message) {
        modal.warning(e.response.data.message);
      } else {
        showTryAgain();
      }
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (values: any) => {
    Modal.warning({
      title: "Warning",
      content: "Please try again.",
    });
  };

  const verifyEmail = async () => {
    try {
      const response = await request.get(
        `/users/join/conventional/verification/email`,
        {
          params: { email, prdCode },
        }
      );
      const result = response.data;
      if (result === "OK") {
        showSuccessEmail();
      } else if (result === "ALREADY_EXISTS") {
        modal.warning("Email already exists.");
      } else {
        showInvalidFormatMail();
      }
    } catch (e) {
      if (e.response?.data?.message) {
        modal.warning(e.response.data.message);
      } else {
        showTryAgain();
      }
    }
  };

  return (
    <Form
      name="join"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      {verificationCode ? (
        <x.div pb="10px">
          Please finish signup with your invited email address.
        </x.div>
      ) : null}
      <RequireLabel>
        <span>Name</span>
      </RequireLabel>
      <Form.Item
        name="name"
        rules={[{ required: true, message: "Please enter your name." }]}
      >
        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const name = e.target.value;
            setUsername(name);
          }}
          placeholder="Name"
        />
      </Form.Item>
      <RequireLabel>
        <span>Email</span>
      </RequireLabel>
      <Form.Item
        name={["user", "email"]}
        rules={[
          {
            type: "email",
            message: "The input is not valid email",
          },
          {
            required: true,
            message: "Please enter your email",
          },
        ]}
      >
        {verificationCode ? (
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const mail = e.target.value;
              setEmail(mail);
            }}
            placeholder="Email"
          />
        ) : (
          <Search
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const mail = e.target.value;
              setEmail(mail);
            }}
            onSearch={verifyEmail}
            placeholder="Email"
            allowClear
            enterButton="Validate"
          />
        )}
      </Form.Item>
      <RequireLabel>
        <span>Password</span>
      </RequireLabel>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: "Please enter your password" },
          { pattern: passwordValid, message: "Invalid password format." },
        ]}
      >
        <Input.Password
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const password = e.target.value;
            setPassword(password);
          }}
          placeholder="min 8 letter, with at least a symbol and a number"
          iconRender={(visble) =>
            visble ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
      <RequireLabel>
        <span>Comfirm Password</span>
      </RequireLabel>
      <Form.Item
        name="confirmPassword"
        dependencies={["password"]}
        rules={[
          { required: true, message: "Please enter your password again" },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                "The two passwords that you entered do not match!"
              );
            },
          }),
        ]}
      >
        <Input.Password
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const confirmPassword = e.target.value;
            setConfirmPassword(confirmPassword);
          }}
          placeholder="Please enter your password again"
          iconRender={(visble) =>
            visble ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
      <x.p fontSize="0.9em" mt="30px" mb="10px">
        By clicking on Sign up, you agree to Nexclipper's{" "}
        <Link to="https://nexclipper.github.io/term/" openNewWindow={true}>
          Terms and Conditions of Use.
        </Link>
      </x.p>
      <x.p fontSize="0.9em" mb="20px">
        To learn more about how Nexclipper collects, uses, shares and protects
        your personal data please read Nexclipper's{" "}
        <Link to="https://nexclipper.github.io/privacy/" openNewWindow={true}>
          Privacy Policy
        </Link>
        .
      </x.p>
      <Form.Item>
        <Button variant="primary" htmlType="submit" expand>
          Sign Up
        </Button>
      </Form.Item>
    </Form>
  );
};

export default JoinForm;
